import { useState, useEffect, createRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  CardHeader,
  CardBody,
  Card,
} from "reactstrap";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextFieldMui from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import brLocale from "date-fns/locale/pt-BR";
import UserService from "../../../components/Users/UserService";
import NotificationAlert from "react-notification-alert";

import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";

const notification = createRef();
const CreateUserManagment = (props) => {
  const [modal, setModal] = useState(false);
  const [value, setValue] = useState(null);
  const [typeUsers, setTypeUsers] = useState(null);
  const [accountType, setAccountType] = useState(null);
  const [name, setName] = useState(null);
  const [level, setLevel] = useState("Administrador");
  const [email, setEmail] = useState(null);
  const [cnpj, setCnpj] = useState(null);
  const [coringa, setCoringa] = useState(0);
  const [numberRequests, setNumberRequests] = useState(0);
  const [enterprise, setEnterprise] = useState(null);
  const [page, setPage] = useState(null);
  const [phone, setPhone] = useState(null);
  // Yup
  const [yupEmail, setYupEmail] = useState(false);
  const [yupEmailDescription, setYupEmailDescription] = useState("");
  const [yupEnterprise, setYupEnterprise] = useState(false);
  const [yupEnterpriseDescription, setYupEnterpriseDescription] = useState("");
  const [materialAutomatic, setMaterialAutomatic] = useState(false);
  const [signatureRequiredInOrder, setSignatureRequiredInOrder] =
    useState(false);
  const [toolVisible, setToolVisible] = useState(false);

  const [userType] = useState(() => {
    const userTypeStoraged = localStorage.getItem("TOKEN_USERTYPE");
    if (userTypeStoraged) {
      return userTypeStoraged;
    }
    return "";
  });

  const toggle = () => setModal(!modal);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      console.log("email");
      console.log(email);
      UserService.getUsers({ email: email }).then((res) => {
        if (enterprise) {
          UserService.getUsers({ empresa: enterprise }).then((evt) => {
            const response = evt.data;
            const result = res.data;
            console.log(result);
            console.log(result.length);
            if (result.length === 0) {
              if (response.length === 0) {
                const user = {
                  first_name: name,
                  level: level,
                  email: email,
                  phone: phone,
                  materialAutomatic,
                  signatureRequiredInOrder,
                  accountType,
                  toolVisible,
                  // custohh: cost,
                  empresa: enterprise,
                  cnpj: cnpj,
                  inicioContrato: value,
                  site: page,
                  // usuariosCoringa: coringa,
                  // usuariosRequisitantes: numberRequests,
                  username: email,
                  type: typeUsers,
                };
                UserService.createUser(user)
                  .then((response) => {
                    console.log("criou");
                    console.log(response.data);
                    UserService.getUsers({}).then((sender) => {
                      console.log(sender.data);
                      props.setUsers(sender.data);
                    });
                    notifyCode(
                      "success",
                      "Usuário logado com sucesso!",
                      "O usuário foi logado com sucesso!"
                    );
                    toggle();
                    setName(null);
                    setLevel(null);
                    setEmail(null);
                    setPhone(null);
                    setCnpj(null);
                    setValue(null);
                    setCoringa(0);
                    setNumberRequests(0);
                    setTypeUsers(null);

                    setYupEnterprise(false);
                    setYupEnterpriseDescription("");

                    setYupEmail(false);
                    setYupEmailDescription("");
                  })
                  .catch((error) => {
                    console.log(error.message);
                    notifyCode(
                      "danger",
                      "Erro ao criar um usuário",
                      error.message
                    );
                  });
              } else {
                setYupEmail(false);
                setYupEmailDescription("");
                setYupEnterprise(true);
                setYupEnterpriseDescription("Este nome já está em uso!");
                notifyCode(
                  "danger",
                  "Nome da empresa em uso!",
                  "O nome da empresa já está sendo utilizada!"
                );
              }
            } else {
              setYupEmail(true);
              setYupEmailDescription("Email já em uso!");
              notifyCode(
                "danger",
                "Erro ao criar usuário!",
                "Este email já está em uso!"
              );
            }
          });
        } else {
          setYupEnterprise(true);
          setYupEnterpriseDescription("O campo empresa é obrigatório!");
          notifyCode(
            "danger",
            "Empresa obrigatório!",
            "O campo empresa é obrigatório!"
          );
        }
      });
    } else {
      setYupEmail(true);
      setYupEmailDescription("O campo Email é obrigatório!");
      notifyCode(
        "danger",
        "Email obrigatório!",
        "O campo Email é obrigatório!"
      );
    }
  };

  const notifyCode = (event, title, content) => {
    let options = {
      place: "tc",
      message: (
        <div>
          <h2
            style={{ color: "#fff", marginBottom: "0" }}
            className="alert-title"
            data-notify="title"
          >
            {" "}
            {title ? title : "Atenção!"}
            <br />
          </h2>
          <span data-notify="message">
            {content ? content : "Algo deu errado!"}
          </span>
        </div>
      ),
      type: event,
      // icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    notification.current.notificationAlert(options);
  };
  console.log(notification);

  const levelForm = [
    "Administrador",
    // "Corpo Técnico",
    // "Executante",
    // "Requisitante",
  ];
  const typeUser = ["Master", "Tester"];
  const accountTypeArray = ["Condomínio", "Indústria"];

  return (
    <>
      <NotificationAlert ref={notification} />
      <Button
        color="success"
        size="sm"
        // className="top-3 right-1"
        onClick={toggle}
      >
        Nova Empresa
      </Button>
      <Modal isOpen={modal} toggle={toggle} className="mr-0 mt--1 mb--1 mt-5">
        <ModalHeader className="mt-3" toggle={toggle} tag="h2">
          Nova Empresa
        </ModalHeader>
        <ModalBody className="mb-1 mt--2 mb-2">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <TextField
                // required={true}
                // {...bindfirst_name}
                label="Nome Completo"
                margin="normal"
                fullWidth
                variant="outlined"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                // error={requireName}
                // helperText={messageRequireName}
              />
            </div>
            <div className="form-group">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={typeUser}
                // {...bindlevel}
                onChange={(e, v) => {
                  setTypeUsers(v);
                }}
                renderInput={(params) => (
                  <TextField
                    // {...bindlevel}
                    variant="outlined"
                    {...params}
                    label="Tipo de usuário"
                  />
                )}
              />
            </div>
            <div className="form-group">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={accountTypeArray}
                // {...bindlevel}
                onChange={(e, v) => {
                  setAccountType(v);
                }}
                renderInput={(params) => (
                  <TextField
                    // {...bindlevel}
                    variant="outlined"
                    {...params}
                    label="Tipo de assinatura"
                  />
                )}
              />
            </div>
            <div className="form-group">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={levelForm}
                value={level}
                disabled={true}
                onChange={(e, v) => {
                  setLevel(v);
                }}
                renderInput={(params) => (
                  <TextField
                    disabled={true}
                    variant="outlined"
                    {...params}
                    label="Função"
                  />
                )}
              />
            </div>

            <div className="form-group">
              <TextField
                // {...bindemail}
                label="Email"
                margin="normal"
                fullWidth
                value={email}
                error={yupEmail}
                helperText={yupEmailDescription}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                variant="outlined"
                // error={requireEmail}
                // helperText={messageRequireEmail}
              />
            </div>

            <div style={{ display: "flex" }} className="form-group">
              <TextField
                label="Nome da empresa"
                margin="normal"
                fullWidth
                value={enterprise}
                onChange={(e) => {
                  setEnterprise(e.target.value);
                }}
                variant="outlined"
                error={yupEnterprise}
                helperText={yupEnterpriseDescription}
              />
            </div>
            {/* <div className="form-group">
              <TextField
                // required={true}
                // {...bindfirst_name}
                label="CNPJ "
                margin="normal"
                fullWidth
                value={cnpj}
                variant="outlined"
                onChange={(e) => {
                  setCnpj(e.target.value);
                }}
                // error={requireName}
                // helperText={messageRequireName}
              />
            </div> */}
            <FormControl
              style={{ display: "block" }}
              className="form-group"
              component="fieldset"
            >
              <FormLabel component="legend">
                Baixa de material automática?
              </FormLabel>
              <Row>
                <Col>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="top"
                  >
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="Sim"
                      checked={materialAutomatic}
                      onChange={(e, v) => {
                        if (v) {
                          setMaterialAutomatic(!materialAutomatic);
                        }
                      }}
                    />
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="Não"
                      checked={!materialAutomatic}
                      onChange={(e, v) => {
                        if (v) {
                          setMaterialAutomatic(!materialAutomatic);
                        }
                      }}
                    />
                  </RadioGroup>
                </Col>
                <Col></Col>
              </Row>
            </FormControl>
            <FormControl
              style={{ display: "block" }}
              className="form-group"
              component="fieldset"
            >
              <FormLabel component="legend">
                Assinatura no encerramento da ordem?
              </FormLabel>
              <Row>
                <Col>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="top"
                  >
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="Sim"
                      checked={signatureRequiredInOrder}
                      onChange={(e, v) => {
                        if (v) {
                          setSignatureRequiredInOrder(
                            !signatureRequiredInOrder
                          );
                        }
                      }}
                    />
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="Não"
                      checked={!signatureRequiredInOrder}
                      onChange={(e, v) => {
                        if (v) {
                          setSignatureRequiredInOrder(
                            !signatureRequiredInOrder
                          );
                        }
                      }}
                    />
                  </RadioGroup>
                </Col>
                <Col></Col>
              </Row>
            </FormControl>

            <FormControl
              style={{ display: "block" }}
              className="form-group"
              component="fieldset"
            >
              <FormLabel component="legend">Ferramentaria visível?</FormLabel>
              <Row>
                <Col>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="top"
                  >
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="Sim"
                      checked={toolVisible}
                      onChange={(e, v) => {
                        if (v) {
                          setToolVisible(!toolVisible);
                        }
                      }}
                    />
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="Não"
                      checked={!toolVisible}
                      onChange={(e, v) => {
                        if (v) {
                          setToolVisible(!toolVisible);
                        }
                      }}
                    />
                  </RadioGroup>
                </Col>
                <Col></Col>
              </Row>
            </FormControl>
            {/* <div style={{ display: "flex" }} className="form-group">
              <TextField
                label="Usuários coringa"
                margin="normal"
                type="number"
                fullWidth
                variant="outlined"
                value={coringa}
                onChange={(e) => {
                  setCoringa(e.target.value);
                }}
              />
              <TextField
                style={{ marginLeft: "10px" }}
                label="Usuários Requisitantes"
                margin="normal"
                type="number"
                fullWidth
                value={numberRequests}
                onChange={(e) => {
                  setNumberRequests(e.target.value);
                }}
                variant="outlined"
              />
            </div> */}

            <div style={{ display: "flex" }} className="form-group">
              <TextField
                // {...bindphone}
                label="Telefone"
                placeholder="(00) 0 0000-0000"
                margin="normal"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                fullWidth
                variant="outlined"
              />
            </div>
            {typeUsers === "Master" && (
              <div className="form-group">
                <LocalizationProvider
                  locale={brLocale}
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    label="Início do contrato"
                    value={value}
                    variant="outlined"
                    onChange={(newValue) => {
                      setValue(newValue);
                    }}
                    renderInput={(params) => (
                      <TextFieldMui style={{ width: "100%" }} {...params} />
                    )}
                  />
                </LocalizationProvider>
              </div>
            )}
            <ModalFooter>
              <Button type="submit" color="success">
                Criar
              </Button>{" "}
              <Button
                color="secondary"
                onClick={() => {
                  toggle();
                  setName(null);
                  setLevel(null);
                  setEmail(null);
                  setPhone(null);
                  setCnpj(null);
                  setValue(null);
                  setCoringa(0);
                  setNumberRequests(0);
                  setTypeUsers(null);
                }}
              >
                Cancelar
              </Button>
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CreateUserManagment;
