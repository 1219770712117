import React, { useState, useEffect, useContext, createRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
} from "reactstrap";
import SpareService from "./SpareService";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { EnterpriseNameContext } from "../../context/EnterpriseContext";
import InputAdornment from "@material-ui/core/InputAdornment";

import { UserType } from "../../context/UserType";
import NotificationAlert from "react-notification-alert";
import { CartContext } from "../../context/Cart";

const notification = createRef();

function CreateSpare(props) {
  const { setSpare } = useContext(CartContext);
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [produto, setProduto] = useState("");
  const [fornecedores, setFornecedores] = useState("");
  const [categoria, setCategoria] = useState("");
  const [local_deposito, setLocal_deposito] = useState("");

  const [preco, setPreco] = useState("");
  const [descricao, setDescricao] = useState(null);
  const [quantidade, setQuantidade] = useState(null);
  const [part_number, setPart_number] = useState(null);
  const [ressuprimento, setRessuprimento] = useState(0);

  const { enterpriseName } = useContext(EnterpriseNameContext);
  const { userType } = useContext(UserType);

  //YUP IN HAND
  const [requireDescription, setRequireDescription] = useState(false);
  const [messageRequireDescription, setMessageRequireDescription] =
    useState("");

  const [requireProduct, setRequireProduct] = useState(false);
  const [messageRequireProduct, setMessageRequireProduct] = useState("");

  const [requirePartNumber, setRequirePartNumber] = useState(false);
  const [messageRequirePartNumber, setMessageRequirePartNumber] = useState("");

  const [requireProvider, setRequireProvider] = useState(false);
  const [messageRequireProvider, setMessageRequireProvider] = useState("");

  const [requireCategory, setRequireCategory] = useState(false);
  const [messageRequireCategory, setMessageRequireCategory] = useState("");

  const [requireQuantity, setRequireQuantity] = useState(false);
  const [messageRequireQuantity, setMessageRequireQuantity] = useState("");

  // const [requireResupply, setRequireResupply] = useState(false);
  // const [messageRequireResupply, setMessageRequireResupply] = useState("")

  const [requireLocal, setRequireLocal] = useState(false);
  const [messageRequireLocal, setMessageRequireLocal] = useState("");

  const [requirePrice, setRequirePrice] = useState(false);
  const [messageRequirePrice, setMessageRequirePrice] = useState("");

  const [token, setToken] = useState(() => {
    const tokenStoraged = localStorage.getItem("TOKEN_KEY");
    if (tokenStoraged) {
      return tokenStoraged;
    }
    return "";
  });
  const [hash, setHash] = useState(() => {
    const hashStoraged = localStorage.getItem("TOKEN_HASH");
    if (hashStoraged) {
      return hashStoraged;
    }
    return "";
  });
  const [idUserStorage, setIdUserStorage] = useState(() => {
    const idUserStoraged = localStorage.getItem("TOKEN_ID_USER");
    if (idUserStoraged) {
      return idUserStoraged;
    }
    return "";
  });

  const handleSubmit = (evt) => {
    evt.preventDefault();
    SpareService.getSpares({
      part_number: part_number,
      fornecedores: fornecedores,
      token: token,
      hash: hash,
      idUser: idUserStorage,
    }).then((response) => {
      const result = response.data.data;
      console.log("teste");
      if (result.length > 0) {
        notifyConsult("warning");
        setRequireProvider(true);
        setRequirePartNumber(true);
        console.log("entrou aqui1");
      }
      if (
        descricao &&
        produto &&
        categoria &&
        quantidade &&
        local_deposito &&
        preco
      ) {
        const removeDot = preco?.replace(".", "");
        const changeComma = removeDot?.replace(",", ".");
        const result = parseFloat(changeComma).toFixed(2);
        const formatPreco = parseFloat(result);
        console.log("formatPreco", formatPreco);
        let spares = {
          produto: produto,
          descricao: descricao,
          categoria: categoria,
          fornecedores: fornecedores,
          local_deposito: local_deposito,
          preco: formatPreco,
          quantidade: quantidade,
          part_number: part_number,
          ressuprimento: ressuprimento,
          empresa: enterpriseName,
          token: token,
          hash: hash,
          idUser: idUserStorage,
        };
        SpareService.createSpare(spares)
          .then((res) => {
            SpareService.getSpares({
              empresa: enterpriseName,
            }).then((res) => {
              setSpare(res.data.data);
              props.setSpare(res.data.data);
              localStorage.removeItem("TOKEN_KEY");
              localStorage.setItem("TOKEN_KEY", res.data.token);
            });
            setProduto(null);
            setDescricao("");
            setCategoria(null);
            setFornecedores(null);
            setLocal_deposito(null);
            setPreco(0);
            setQuantidade(0);
            setPart_number("");
            setRessuprimento(0);
            notifyGeneral("success");
            setModal(false);
          })
          .catch((error) => console.log(error.response));
      }
    });

    if (!descricao) {
      setRequireDescription(true);
      setMessageRequireDescription("O campo Descrição é obrigatório!");
    } else {
      setRequireDescription(false);
      setMessageRequireDescription("");
    }

    if (!produto) {
      setRequireProduct(true);
      setMessageRequireProduct("O campo Material é obrigatório!");
    } else {
      setRequireProduct(false);
      setMessageRequireProduct("");
    }

    if (!part_number) {
      setRequirePartNumber(true);
      setMessageRequirePartNumber("O campo Part Number é obrigatório!");
    } else {
      setRequirePartNumber(false);
      setMessageRequirePartNumber("");
    }
    if (!fornecedores) {
      setRequireProvider(true);
      setMessageRequireProvider("O campo Fornecedor é obrigatório!");
    } else {
      setRequireProvider(false);
      setMessageRequireProvider("");
    }

    if (!categoria) {
      setRequireCategory(true);
      setMessageRequireCategory("O campo Categoria é obrigatório!");
    } else {
      setRequireCategory(false);
      setMessageRequireCategory("");
    }

    if (!quantidade) {
      setRequireQuantity(true);
      setMessageRequireQuantity("O campo Quantidade é obrigatório!");
    } else {
      setRequireQuantity(false);
      setMessageRequireQuantity("");
    }

    // if(!ressuprimento){
    //   setRequireResupply(true);
    //   setMessageRequireResupply("O campo Quantidade Mínima é obrigatório!")
    // }else{
    //   setRequireResupply(false);
    //   setMessageRequireResupply("")
    // }

    if (!local_deposito) {
      setRequireLocal(true);
      setMessageRequireLocal("O campo Local Depósito é obrigatório!");
    } else {
      setRequireLocal(false);
      setMessageRequireLocal("");
    }

    if (!preco) {
      setRequirePrice(true);
      setMessageRequirePrice("O campo Valor é obrigatório!");
    } else {
      setRequirePrice(false);
      setMessageRequirePrice("");
    }

    //alert(`Submitting Name ${firstName} ${lastName}`);
    //resetFirstName();
    //resetLastName();
  };

  // const [spares, setSpares] = useState([]);

  useEffect(() => {
    SpareService.getSpares({ empresa: enterpriseName }).then((res) => {
      console.log("spares:", res.data.data);
      props.setSpare(res.data.data);
      localStorage.removeItem("TOKEN_KEY");
      localStorage.setItem("TOKEN_KEY", res.data.token);
    });
  }, [enterpriseName]);

  //checar qual usuário está logado
  // let token = localStorage.getItem("TOKEN_KEY");
  // const tokenParts = token.split('.');
  // const encodedPayload = tokenParts[1];
  // const rawPayload = atob(encodedPayload);
  // const user = JSON.parse(rawPayload);

  const notifyGeneral = (event) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <h2
            style={{ color: "#fff", marginBottom: "0" }}
            className="alert-title"
            data-notify="title"
          >
            {" "}
            Concluído!
            <br />
          </h2>
          <span data-notify="message">O material foi criado com sucesso!</span>
        </div>
      ),
      type: event,
      // icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    notification.current.notificationAlert(options);
  };
  const notifyConsult = (event) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <h2
            style={{ color: "#fff", marginBottom: "0" }}
            className="alert-title"
            data-notify="title"
          >
            Atenção! <br />
          </h2>
          <span data-notify="message">
            Um material já foi criado com este Part Number e este Fornecedor!
          </span>
        </div>
      ),
      type: event,
      // icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    notification.current.notificationAlert(options);
  };

  return (
    <div>
      {userType !== "Executante" && userType !== "Requisitante" ? (
        <>
          <Button
            color="success"
            size="sm"
            className="position-absolute top-3 right-9"
            onClick={toggle}
          >
            {" "}
            Novo Material{" "}
          </Button>
        </>
      ) : null}
      <NotificationAlert ref={notification} />
      <Modal
        isOpen={modal}
        toggle={toggle}
        size="lg"
        className="mr-0 mt--1 mb--1 mt-5"
      >
        <ModalHeader toggle={toggle} tag="h3" className="mt-3">
          Novo Material
        </ModalHeader>
        <ModalBody className="mt--2 mb-2">
          <form onSubmit={handleSubmit}>
            <Row style={{ alignItems: "center" }}>
              <Col style={{ marginBottom: "-0.5em" }}>
                <div className="form-group">
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    onChange={(e, v) => setProduto(v)}
                    options={[
                      ...new Set(
                        props.spare.map((option) => {
                          if (option.produto) {
                            return option.produto;
                          }
                          return "";
                        })
                      ),
                    ]}
                    value={produto}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        value={produto}
                        label="Material"
                        argin="normal"
                        onChange={(e) => setProduto(e.target.value)}
                        placeholder="Nome do material. Ex: Rolamento, parafuso..."
                        error={requireProduct}
                        helperText={messageRequireProduct}
                      />
                    )}
                    style={{ width: "100%" }}
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <TextField
                    id="outlined-basic"
                    label="Part number"
                    margin="normal"
                    variant="outlined"
                    placeholder="Entre com o código de referência do fornecedor"
                    name="part_number"
                    value={part_number}
                    error={requirePartNumber}
                    helperText={messageRequirePartNumber}
                    onChange={(e) => {
                      setPart_number(e.target.value);
                    }}
                    style={{ width: "100%" }}
                  />
                </div>
              </Col>
            </Row>
            <div className="form-group">
              <TextField
                error={requireDescription}
                helperText={messageRequireDescription}
                id="outlined-basic"
                placeholder="Descreva o material a ser cadastrado, contendo as informações relevantes"
                label="Descrição"
                variant="outlined"
                rows={4}
                name="descrição"
                onChange={(e) => {
                  setDescricao(e.target.value);
                }}
                multiline="true"
                style={{ width: "100%" }}
              />
            </div>
            <Row>
              <Col>
                <div className="form-group">
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    onChange={(e, v) => {
                      setFornecedores(v);
                    }}
                    options={[
                      ...new Set(
                        props.spare.map((option) => {
                          if (option.fornecedores) {
                            return option.fornecedores;
                          }
                          return "";
                        })
                      ),
                    ]}
                    value={fornecedores}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={requireProvider}
                        helperText={messageRequireProvider}
                        label="Fornecedor"
                        value={fornecedores}
                        margin="normal"
                        variant="outlined"
                        onChange={(e) => {
                          setFornecedores(e.target.value);
                        }}
                        placeholder="Nome do Fornecedor de Referência"
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    onChange={(e, v) => {
                      setCategoria(v);
                    }}
                    value={categoria}
                    options={[
                      ...new Set(
                        props.spare.map((option) => {
                          if (option.categoria) {
                            return option.categoria;
                          }
                          return "";
                        })
                      ),
                    ]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={categoria}
                        label="Categoria"
                        margin="normal"
                        variant="outlined"
                        error={requireCategory}
                        helperText={messageRequireCategory}
                        placeholder="Ex: Mecânica, Elétrica, Instrumentação..."
                        onChange={(e) => {
                          setCategoria(e.target.value);
                        }}
                      />
                    )}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <TextField
                    id="outlined-basic"
                    label="Quantidade"
                    // variant="outlined"
                    name="quantidade"
                    placeholder="Quantidade em estoque"
                    value={quantidade}
                    error={requireQuantity}
                    helperText={messageRequireQuantity}
                    onChange={(e) => {
                      setQuantidade(e.target.value);
                    }}
                    style={{ width: "100%" }}
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <TextField
                    id="outlined-basic"
                    label="Estoque mínimo"
                    // variant="outlined"
                    name="ressuprimento"
                    placeholder="Insira o estoque mínimo necessário"
                    value={ressuprimento}
                    // error={requireResupply}
                    // helperText={messageRequireResupply}
                    onChange={(e) => {
                      setRessuprimento(e.target.value);
                    }}
                    style={{ width: "100%" }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <Autocomplete
                    ListboxProps
                    id="free-solo-demo"
                    freeSolo
                    onChange={(e, v) => {
                      setLocal_deposito(v);
                    }}
                    value={local_deposito}
                    options={[
                      ...new Set(
                        props.spare.map((option) => {
                          if (option.local_deposito) {
                            return option.local_deposito;
                          }
                          return "";
                        })
                      ),
                    ]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={local_deposito}
                        label="Local Depósito"
                        margin="normal"
                        // variant="outlined"
                        error={requireLocal}
                        helperText={messageRequireLocal}
                        placeholder="Insira o local de depósito"
                        onChange={(e) => {
                          setLocal_deposito(e.target.value);
                        }}
                      />
                    )}
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <TextField
                    id="outlined-basic"
                    label="Valor"
                    margin="normal"
                    //variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">R$</InputAdornment>
                      ),
                    }}
                    placeholder="00,00"
                    name="preco"
                    value={preco}
                    error={requirePrice}
                    helperText={messageRequirePrice}
                    onChange={(e) => {
                      setPreco(e.target.value);
                    }}
                    style={{ width: "100%" }}
                  />
                </div>
              </Col>
            </Row>
            <Button type="submit" color="success">
              Criar
            </Button>{" "}
            <Button
              color="secondary"
              onClick={() => {
                toggle();
                setProduto(null);
                setDescricao("");
                setCategoria(null);
                setFornecedores(null);
                setLocal_deposito(null);
                setPreco("");
                setQuantidade(0);
                setPart_number("");
                setRessuprimento(0);
              }}
            >
              Cancelar
            </Button>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default CreateSpare;
