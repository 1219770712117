import { useState, useEffect, useContext } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import { TextField, Autocomplete } from '@mui/material'
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { EnterpriseNameContext } from "../../../context/EnterpriseContext";
import axios from "axios";
import ToolsService from "../service";
const CreateTool = (props) => {
  const { enterpriseName } = useContext(EnterpriseNameContext);
  const [modal, setModal] = useState(false);
  const [image, setImage] = useState();
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("0,00");
  const [typeTool, setTypeTool] = useState("");
  const [comments, setComments] = useState("");
  const [quantity, setQuantity] = useState(0);
  const toggle = () => {
    setModal(!modal);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("entrou aqui");
    let url;
    let filename;
    const formData = new FormData();
    const removeDot = price?.replace(".", "");
    const changeComma = removeDot?.replace(",", ".");
    const converted = parseFloat(changeComma).toFixed(2);
    if (image) {
      formData.append("image", image[0]);
      axios({
        method: "post",
        url: "https://api.darwinx.com.br/images",
        data: formData,
        params: { empresa: enterpriseName },
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => {
        console.log(response.data);
        url = response.data.url;
        filename = response.data.name;
        if (url) {
          const data = {
            tipoFerramenta: typeTool,
            descricao: description,
            caracteristica: comments,
            empresa: enterpriseName,
            preco: converted,
            quantidade: quantity,
            imagem: url,
            nomeImagem: filename,
          };
          ToolsService.createTool(data).then((sender) => {
            console.log("criou");
            ToolsService.getTools({ empresa: enterpriseName }).then((res) => {
              props.setTools(res.data.data);
              setModal(false);
              setQuantity("");
              setComments("");
              setTypeTool("");
              setImage(null);
              setPrice(0);
              setDescription("");
            });
            console.log(sender.data.data);
          });
        }
      });
    } else {
      const data = {
        tipoFerramenta: typeTool,
        descricao: description,
        caracteristica: comments,
        empresa: enterpriseName,
        preco: converted,
        quantidade: quantity,
      };
      ToolsService.createTool(data).then((response) => {
        console.log("criou");
        ToolsService.getTools({ empresa: enterpriseName }).then((res) => {
          props.setTools(res.data.data);
          setModal(false);
          setQuantity("");
          setComments("");
          setTypeTool("");
          setPrice(0);
          setDescription("");
        });
        console.log(response.data.data);
      });
    }
  };

  return (
    <>
      {/* <div style={{display: 'flex', justifyContent: 'flex-end'}}> */}
      <Button color="success" size="sm" onClick={toggle}>
        {" "}
        Nova ferramenta{" "}
      </Button>
      {/* </div> */}

      <Modal isOpen={modal} toggle={toggle} className="mr-0 mt--1 mb--1 mt-5">
        <ModalHeader className="mt-3" toggle={toggle} tag="h2">
          Nova ferramenta
        </ModalHeader>
        <ModalBody className="mb-1 mt--2 mb-2">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <TextField
                style={{ width: "100%" }}
                id="outlined-basic"
                label="Tipo de ferramenta"
                placeholder="Alicate"
                variant="outlined"
                onChange={(e) => {
                  setTypeTool(e.target.value);
                }}
              />
            </div>

            <div className="form-group">
              <TextField
                id="outlined-basic"
                placeholder="50 MHz a 200MHz, Display colorido 7 polegadas, Carregador 220V..."
                label="Descrição"
                variant="outlined"
                rows={4}
                name="descrição"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                multiline="true"
                style={{ width: "100%" }}
              />
            </div>
            <div className="form-group">
              <TextField
                id="outlined-basic"
                label="Quantidade"
                margin="normal"
                //variant="outlined"
                // InputProps={{
                // startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                // }}
                placeholder="10"
                name="Quantidade"
                value={quantity}
                // error={requirePrice}
                // helperText={messageRequirePrice}
                onChange={(e) => {
                  setQuantity(e.target.value);
                }}
                style={{ width: "100%" }}
              />
            </div>
            <div className="form-group">
              <TextField
                id="outlined-basic"
                label="Valor"
                margin="normal"
                //variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
                placeholder="00,00"
                name="preco"
                value={price}
                // error={requirePrice}
                // helperText={messageRequirePrice}
                onChange={(e) => {
                  setPrice(e.target.value);
                }}
                style={{ width: "100%" }}
              />
            </div>

            <div className="form-group">
              <TextField
                style={{ width: "100%" }}
                id="outlined-basic"
                rows={2}
                label="Observações"
                placeholder="Observações pertinentes para sua empresa"
                variant="outlined"
                multiline="true"
                onChange={(e) => {
                  setComments(e.target.value);
                }}
                value={comments}
              />
            </div>

            <div className="form-group">
              <label> Foto: </label>
              <input
                type="file"
                placeholder="Equipamento"
                name="file"
                className="form-control"
                accept="image/png, image/jpeg"
                onChange={(e) => {
                  setImage(e.target.files);
                  // setNameImage()
                  console.log(e.target.files);
                }}
              />
            </div>

            <ModalFooter>
              <Button type="submit" color="success">
                Salvar
              </Button>{" "}
              <Button
                color="secondary"
                onClick={() => {
                  toggle();
                  setQuantity("");
                  setComments("");
                  setTypeTool("");
                  setPrice(0);
                  setDescription("");
                }}
              >
                Cancelar
              </Button>
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CreateTool;
