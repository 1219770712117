// GESTÃO DE Estoque DE MANUTENÇÂO

import axios from "axios";

const SPARE_API_BASE_URL = "https://api.darwinx.com.br/sparesrequest";

class SpareRequestService {
  getSparesRequest(spare) {
    return axios.get(SPARE_API_BASE_URL, { params: spare });
  }

  createSpareRequest(spare) {
    return axios.post(SPARE_API_BASE_URL, spare);
  }

  getSpareRequestById(spareId) {
    return axios.get(SPARE_API_BASE_URL + "/" + spareId);
  }

  updateSpareRequest(spare, spareId) {
    return axios.put(SPARE_API_BASE_URL + "/" + spareId, spare);
  }

  deleteSpareRequest(spareId) {
    return axios.delete(SPARE_API_BASE_URL + "/" + spareId);
  }
}

export default new SpareRequestService();
