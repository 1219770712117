import axios from "axios";

const GET_ALL_SECTORS_BY_ENTERPRISE =
  "https://api.darwinx.com.br/getAllSectorsByEnterprise";

class GetAllSectorsByEnterprise {
  getSectors(filter) {
    return axios.get(GET_ALL_SECTORS_BY_ENTERPRISE, { params: filter });
  }
}

export default new GetAllSectorsByEnterprise();
