// GESTÃO DE USUARIOS

import axios from "axios";

const TOOLSRESERVE_API_BASE_URL = "https://api.darwinx.com.br/toolReserve";

class ReserveToolsService {
  getReserveTools(params) {
    return axios.get(TOOLSRESERVE_API_BASE_URL, { params: params });
  }

  createReserveTool(params) {
    return axios.post(TOOLSRESERVE_API_BASE_URL, params);
  }

  getReserveToolById(params) {
    return axios.get(TOOLSRESERVE_API_BASE_URL + "/" + params);
  }

  updateReserveTool(user, userId) {
    return axios.put(TOOLSRESERVE_API_BASE_URL + "/" + userId, user);
  }

  deleteReserveTool(params) {
    return axios.delete(TOOLSRESERVE_API_BASE_URL + "/" + params);
  }
}

export default new ReserveToolsService();
