import { useState, useEffect, useContext, createRef, useCallback } from "react";

import PrintIcon from "@mui/icons-material/Print";

import ReactToPrint from "react-to-print";
import {
  Button,
  Modal,
  Card,
  CardBody,
  FormGroup,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  Row,
  Col,
} from "reactstrap";

import { TextField } from "@mui/material";

import OrderService from "../../OrderService";
import SpareReserve from "../../Spare_Reserve_Service";
import { EnterpriseNameContext } from "../../../../context/EnterpriseContext";
import Checkbox from "../Checkbox";
import DateTimePicker from "../../../DateTimePicker";

import logoHammer from "../../../../assets/logo-hammertech.jpg";
import { width } from "@mui/system";

const printRef = createRef();

const ModalPrint = ({ modal, setModal, orderId, userName, userType }) => {
  const { enterpriseName } = useContext(EnterpriseNameContext);

  const [order, setOrder] = useState({});
  const [spareReserve, setSpareReserve] = useState([]);
  const [structureCheckbox, setStructureCheckbox] = useState([]);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    OrderService.getOrderById(orderId).then((res) => {
      setOrder(res.data?.data);
      setStructureCheckbox(res.data?.data?.checkboxes);
    });

    SpareReserve.getSparesReserve({ empresa: enterpriseName }).then(
      (response) => {
        setSpareReserve(response.data);
      }
    );
  }, [orderId]);
  const reactToPrintTrigger = useCallback(() => {
    return <PrintIcon style={{ cursor: "pointer" }} />;
  }, []);

  const reactToPrintContent = useCallback(() => {
    return printRef.current;
  }, [printRef.current]);

  console.log("order", order);

  return (
    <>
      <Button onClick={toggle}>
        <PrintIcon />
      </Button>

      <Modal size="lg" isOpen={modal} toggle={toggle}>
        <div ref={printRef}>
          <ModalHeader className="mb--4" tag="h2" toggle={toggle}>
            <div
              style={{
                display: "flex",
              }}
            >
              <div>
                Visão geral da ordem Nº{order?.id}{" "}
                {order?.tipo === "Plano" ? " - " + order.descricao : null}
              </div>
              <div>
                <img
                  style={{
                    width: "100px",
                    position: "absolute",
                    right: "80px",
                    top: "5px",
                  }}
                  src={logoHammer}
                  alt="logoHammer"
                />
              </div>
            </div>
          </ModalHeader>

          {order && (
            <ModalBody>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h4>Resumo do equipamento </h4>
                <ReactToPrint
                  content={reactToPrintContent}
                  documentTitle="printdarwinX"
                  removeAfterPrint
                  trigger={reactToPrintTrigger}
                />
              </div>
              <Card className="shadow-lg">
                <CardBody>
                  <Row>
                    <Col>
                      <h3>Código: {order.equipamento}</h3>
                      <h4>
                        {" "}
                        Descrição:{" "}
                        {order.descriptionPlan
                          ? order.descriptionPlan
                          : order.equipamento_denominacao}{" "}
                      </h4>
                      <h4> Setor instalado: {order.setor_equipamento}</h4>
                    </Col>

                    <Col className="col-auto">
                      {order?.foto && (
                        <img
                          style={{ maxWidth: "700px" }}
                          src={order?.foto}
                          alt=""
                        />
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <br></br>
              <div style={{ marginBottom: "1em" }}>
                <h4>Descrição do problema</h4>
                <Card className="shadow-lg">
                  <CardBody>
                    <Row>
                      <Col>
                        <h4>{order.descricao}</h4>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>

              <h4> Materiais reservados </h4>
              <Card className="shadow-lg">
                <CardBody>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="">
                      <tr>
                        <th scope="col">Produto</th>
                        <th scope="col">Part Number</th>
                        <th scope="col">Quantidade</th>
                      </tr>
                    </thead>
                    <tbody>
                      {spareReserve
                        .filter((response) => response.ordem_id === order._id)
                        .map((response, index) => {
                          return (
                            <tr>
                              <th scope="row">
                                <Media className="align-items-center">
                                  <Media>
                                    <span className="mb-0 text-sm">
                                      {response.produto}
                                    </span>
                                  </Media>
                                </Media>
                              </th>
                              <th scope="row">
                                <Media className="align-items-center">
                                  <Media>
                                    <span className="mb-0 text-sm">
                                      {response.part_number}
                                    </span>
                                  </Media>
                                </Media>
                              </th>
                              <th scope="row">
                                <Media className="align-items-center">
                                  <Media>
                                    <span className="mb-0 text-sm">
                                      {response.quantidade}
                                    </span>
                                  </Media>
                                </Media>
                              </th>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>

              {order.tipo === "Plano" && structureCheckbox.length > 0 ? (
                <div style={{ marginTop: "1.5em", marginBottom: "1.5rem" }}>
                  <h4>Tarefas a serem executadas</h4>
                  <Card className="shadow-lg bl-8" style={{ padding: "1em" }}>
                    <FormGroup style={{ marginBottom: "-0.5em" }}>
                      {console.log("checkbox", structureCheckbox)}
                      {structureCheckbox &&
                        structureCheckbox.map((response, index) => {
                          if (response.description) {
                            return (
                              <Checkbox
                                key={index}
                                state={index}
                                check={response.state}
                                description={response.description}
                                setOrderExecutante={order}
                                order={order}
                                enterprise={enterpriseName}
                                userName={userName}
                              />
                            );
                          } else if (response.label) {
                            return (
                              <Checkbox
                                key={index}
                                state={index}
                                check={response.state}
                                description={response.label}
                                setOrderExecutante={order}
                                order={order}
                                enterprise={enterpriseName}
                                userName={userName}
                              />
                            );
                          }
                        })}
                    </FormGroup>
                  </Card>
                </div>
              ) : null}
              <Card style={{ margin: "1rem 0.5rem 1rem 0.5rem" }}>
                <div style={{ width: "70%" }}>
                  <TextField
                    id="outlined-basic"
                    label="Responsável"
                    placeholder="Observações"
                    variant="outlined"
                    value={order?.responsavel}
                    style={{ width: "100%" }}
                  />
                </div>
              </Card>

              <Card className="shadow-lg">
                <CardBody>
                  <Row>
                    <Col>
                      <>
                        <p>Início do serviço</p>
                        <DateTimePicker
                          disable={true}
                          date={order?.initiateService}
                          placeholder="Início do serviço"
                        />
                      </>
                    </Col>
                    <Col>
                      <>
                        <p>Fim do serviço</p>
                        <DateTimePicker
                          disable={true}
                          date={order.finishedService}
                          placeholder="Fim do serviço"
                        />
                      </>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "1em" }}>
                    <Col>
                      <h3> A ordem foi executada? </h3>
                      <input
                        type="radio"
                        value="Sim"
                        name="parou"
                        checked={order.status === "Concluída"}
                      />{" "}
                      Sim
                      <input
                        type="radio"
                        value="Não"
                        name="parou"
                        className="ml-4 mb-4"
                      />{" "}
                      Não
                    </Col>
                  </Row>
                  <Col>
                    <textarea
                      placeholder="Descreva o que foi executado"
                      name="descricao"
                      // value={order?.historic}
                      className="form-control"
                    />
                  </Col>
                </CardBody>
              </Card>
              <Card style={{ margin: "1rem 0 1rem 0" }} className="shadow-lg">
                <CardBody>
                  <Row>
                    <h3>Assinatura:</h3>
                  </Row>
                  <Row>
                    <img
                      style={{ width: "400px" }}
                      src={order?.photographOfClient}
                      alt="assinatura"
                    />
                  </Row>
                  <Row>
                    <h3>Cliente:</h3>
                    <p>
                      <b style={{ marginLeft: "1rem" }}>{order?.clientName}</b>
                    </p>
                  </Row>
                </CardBody>
              </Card>
            </ModalBody>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ModalPrint;
