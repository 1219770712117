import React, { useState, useEffect, useContext } from "react";
import { useLocation, Route, Switch, Redirect, Link } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
//import quarto from '../../assets/img/quarto.jpg';

import AWS from "aws-sdk";

import {
  Container,
  Row,
  Col,
  CardHeader,
  Button,
  ButtonGroup,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  A,
} from "reactstrap";
import TextFieldMui from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import { EnterpriseNameContext } from "context/EnterpriseContext";
import { IdUserContext } from "../../context/IdUser";
import { useInput } from "../Spares/useInput";
import Header from "components/Headers/Headerfor.js";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import EquipmentService from "../Equipments/EquipmentService";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import RequestService from "../Requests/RequestService";
import axios from "axios";
import brLocale from "date-fns/locale/pt-BR";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
//import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
});

function Qrcode() {
  const classes = useStyles();

  const { idUser } = useContext(IdUserContext);
  const { enterpriseName } = useContext(EnterpriseNameContext);
  const [cSelected, setCSelected] = useState([]);
  const [rSelected, setRSelected] = useState(null);
  const [equipamento, setEquipamento] = useState("");
  const [stateModal, setStateModal] = useState(false);
  const [image, setImage] = useState(null);
  const [machineStop, setMachineStop] = useState(null);
  const [area, setArea] = useState([]);
  const [setor, setSetor] = useState();
  const [denominacao, setDenominacao] = useState([]);
  const [maquina, setMaquina] = useState("");
  const [equipment, setEquipment] = useState([]);
  const [qrCode, setQrCode] = useState([]);
  const [color, setColor] = useState("");

  const [token, setToken] = useState(() => {
    const tokenStoraged = localStorage.getItem("TOKEN_KEY");
    if (tokenStoraged) {
      return tokenStoraged;
    }
    return "";
  });
  const [hash, setHash] = useState(() => {
    const hashStoraged = localStorage.getItem("TOKEN_HASH");
    if (hashStoraged) {
      return hashStoraged;
    }
    return "";
  });
  const [idUserStorage, setIdUserStorage] = useState(() => {
    const idUserStoraged = localStorage.getItem("TOKEN_ID_USER");
    if (idUserStoraged) {
      return idUserStoraged;
    }
    return "";
  });

  useEffect(() => {
    // RequestService.getRequestById().then(response =>{
    //   setTeste(response.data)
    // })
    EquipmentService.getEquipment({
      empresa: enterpriseName,
      token: token,
      hash: hash,
      idUser: idUserStorage,
    }).then((res) => setEquipment(res.data.data));
  }, [enterpriseName, token, hash, idUserStorage]);

  const { bind: bindequipamento } = useInput("");
  const { value: descricao, bind: binddescricao } = useInput("");
  const { bind: bindresponsavel } = useInput("");
  const { value: prazo, bind: bindprazo } = useInput("");
  const { value: duracao, bind: bindduracao } = useInput("");

  // let token = localStorage.getItem("TOKEN_KEY");
  // const tokenParts = token.split('.');
  // const encodedPayload = tokenParts[1];
  // const rawPayload = atob(encodedPayload);
  // const user = JSON.parse(rawPayload);
  // const[teste, setTeste] = useState('');
  const [showImage, setShowImage] = useState();
  useEffect(() => {
    console.log(window.location.href.slice(42));
    if (window.location.href.slice(42)) console.log(window.location.href);
    EquipmentService.getEquipmentById(window.location.href.slice(42), {
      token: token,
      hash: hash,
      idUser: idUserStorage,
    })
      .then((response) => {
        setQrCode(response.data.data);
        try {
          const myBucket = "darwinxperfil";
          const myKey = response.data?.data?.nomeFoto;
          const signedUrlExpireSeconds = 60 * 1;
          const s3 = new AWS.S3({
            accessKeyId: "AKIAVSM7L4TZDZIH7KGO",
            region: "sa-east-1",
            secretAccessKey: "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",
          });

          const url = s3.getSignedUrl("getObject", {
            Bucket: myBucket,
            Key: myKey,
            Expires: signedUrlExpireSeconds,
          });

          console.log(url);
          setShowImage(url);
        } catch (error) {
          console.log(error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSubmit = (evt) => {
    evt.preventDefault();

    //alert(`Submitting Name ${firstName} ${lastName}`);
    //resetFirstName();
    //resetLastName();
  };

  const radiomodal = () => setStateModal(!stateModal);
  const create = async (evt) => {
    let url;
    let filename;
    if (image) {
      const formData = new FormData();
      formData.append("image", image[0]);
      await axios({
        method: "post",
        url: "https://api.darwinx.com.br/images",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          url = response.data.url;
          filename = response.data.name;
          console.log(url);
        })
        .catch((error) => {
          console.log(error);
        });
      if (url) {
        let requests = {
          equipamento: qrCode.codigo,
          descricao: descricao,
          prioridade: rSelected,
          empresa: enterpriseName,
          usuarioResponsavel: idUser,
          maquina_parou: maquina,
          local: qrCode.setor_instalado,
          descricao_equipamento: qrCode.denominacao,
          setor_equipamento: qrCode.setor_instalado,
          foto: url,
          nomeFoto: filename,
          status: "Aberta",
          color: color,
          token: token,
          hash: hash,
          idUser: idUserStorage,
        };
        console.log(requests);
        RequestService.createRequest(requests)
          .then((res) => {
            console.log(res.data);
            console.log("salvo no banco");
            radiomodal();
            //console.log("Etapa 4");
          })
          .catch((error) => console.log(error.response));
      }
    } else {
      let requests = {
        equipamento: qrCode.codigo,
        descricao: descricao,
        prioridade: rSelected,
        empresa: enterpriseName,
        usuarioResponsavel: idUser,
        maquina_parou: maquina,
        local: qrCode.setor_instalado,
        descricao_equipamento: qrCode.denominacao,
        setor_equipamento: qrCode.setor_instalado,
        foto: url,
        nomeFoto: filename,
        status: "Aberta",
        color: color,
        token: token,
        hash: hash,
        idUser: idUserStorage,
      };
      RequestService.createRequest(requests)
        .then((res) => {
          console.log(res.data);
          console.log("salvo no banco");
          radiomodal();
        })
        .catch((error) => console.log(error.response));
    }
  };
  return (
    <>
      <Header />
      <Container className="mt--9" fluid>
        {/* Table */}

        <Row>
          <Col>
            <Card className={classes.root}>
              <CardActionArea>
                {qrCode.image ? (
                  <>
                    <img
                      style={{ maxWidth: "100%" }}
                      src={qrCode.image}
                      alt=""
                    />
                  </>
                ) : null}
                <CardMedia
                  component="img"
                  alt="Equipment"
                  height="140"
                  image={showImage}
                  title="Equipamento"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Código: {qrCode.codigo}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Área: {qrCode.area}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Equipamento: {qrCode.denominacao}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary" onClick={radiomodal}>
                  Requisição
                </Button>
                <Button size="small" color="primary">
                  <Link
                    style={{ textDecoration: "none", color: "white" }}
                    to="index"
                  >
                    Informações
                  </Link>
                </Button>
              </CardActions>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* <Modal
        size="lg"
        className=""
        isOpen={stateModal}
        toggle={radiomodal}
      >
        <ModalHeader>
          <h2> Nova Requisição </h2>
        </ModalHeader>
        <ModalBody className="mt--4">
          <Row>
            <Col>
            <label> Descrição: </label>
                                            <textarea placeholder="Descreva a descrição do serviço." name="descricao" className="form-control" 
                                                //value={descricao}
                                                type="areatext"
                                                //onChange={e => setDescricao(e.target.value)}
                                                >

                                            </textarea>
                
                                            <div className="form-group mt-3">


<label> Prioridade: </label> <br></br>
<ButtonGroup>
<Button color="default" className="" outline onClick={() => setRSelected(1)} active={rSelected === 1}>Nenhuma</Button>
<Button color="default" outline onClick={() => setRSelected(2)} active={rSelected === 2}>Baixa</Button>
<Button color="default" outline onClick={() => setRSelected(3)} active={rSelected === 3}>Média</Button>
<Button color="default" outline onClick={() => setRSelected(4)} active={rSelected === 4}>Alta</Button>
</ButtonGroup>


</div>

<div>
                                        <label> O equipamento parou? </label> <br></br>
                                          <input type="radio" value="Sim" name="parou" /> Sim
                                          <input type="radio" value="Não" name="parou" className="ml-4 mb-4"/> Não
                                        </div>

                                        <div className = "form-group">
                                            <label> Foto: </label>
                                            <input type="file" placeholder="Equipamento" name="equipamento" className="form-control" 
                                               
                                               />
                                        </div>
                          <Button color="danger" className="">
            Salvar
          </Button>

          <Button color="secondary" className="" isOpen={stateModal}
        toggle={radiomodal}>
            Cancelar
          </Button>

            </Col>
          </Row>

        </ModalBody>
        <ModalFooter>
          
        </ModalFooter>
      </Modal> */}
      <Modal
        isOpen={stateModal}
        toggle={radiomodal}
        size="lg"
        className="mr-0 mt--1 mb--1 mt-5"
      >
        <ModalHeader toggle={radiomodal} className="mt-3" tag="h2">
          Nova Requisição
        </ModalHeader>
        <ModalBody className="mb-1 mt--2 mb-2">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <TextField
                id="outlined-basic"
                label="Código do Equipamento"
                style={{ width: "100%" }}
                margin="normal"
                variant="outlined"
                value={qrCode.codigo}
                disabled={true}
              />
            </div>
            <h4>Resumo do equipamento</h4>
            <Card className="shadow-lg">
              <CardBody>
                <Row>
                  <Col>
                    <p>
                      <b style={{ fontWeight: "550" }}>Area: </b> {qrCode.area}
                    </p>

                    <p>
                      <b style={{ fontWeight: "550" }}>Setor Instalado: </b>
                      {qrCode.setor_instalado}
                    </p>

                    <p>
                      <b style={{ fontWeight: "550" }}>Equipamento: </b>
                      {qrCode.denominacao}
                    </p>
                  </Col>

                  <Col className="col-auto">
                    <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                      <i className="ni ni-box-2" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <br></br>
            <div className="form-group">
              <TextField
                id="outlined-basic"
                label="Descrição"
                variant="outlined"
                name="descrição"
                rows={4}
                {...binddescricao}
                multiline="true"
                style={{ width: "100%" }}
              />
            </div>
            <label> Prioridade: </label> <br></br>
            <ButtonGroup className="mb-4">
              <Button
                color="default"
                className=""
                outline
                onClick={() => {
                  setRSelected("Nenhuma");
                  setColor("#7BF7CD");
                }}
                active={rSelected === "Nenhuma"}
              >
                Nenhuma
              </Button>
              <Button
                color="default"
                outline
                onClick={() => {
                  setRSelected("Baixa");
                  setColor("#0091F7");
                }}
                active={rSelected === "Baixa"}
              >
                {" "}
                &nbsp; &nbsp; Baixa &nbsp; &nbsp;{" "}
              </Button>
              <Button
                color="default"
                outline
                onClick={() => {
                  setRSelected("Média");
                  setColor("#ffc400");
                }}
                active={rSelected === "Média"}
              >
                {" "}
                &nbsp; &nbsp; Média &nbsp; &nbsp;
              </Button>
              <Button
                color="default"
                outline
                onClick={() => {
                  setRSelected("Alta");
                  setColor("#dc4664");
                }}
                active={rSelected === "Alta"}
              >
                {" "}
                &nbsp; &nbsp; Alta &nbsp; &nbsp;{" "}
              </Button>
            </ButtonGroup>
            <FormControl
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
              }}
              component="fieldset"
            >
              <FormLabel component="legend">O equipamento parou?</FormLabel>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue="top"
              >
                <FormControlLabel
                  value="Sim"
                  disabled={true}
                  control={<Radio color="primary" />}
                  label="Sim"
                  onChange={(e, v) => {
                    if (v) {
                      setMaquina("Sim");
                      console.log(maquina);
                    }
                  }}
                />
                <FormControlLabel
                  value="Não"
                  disabled={true}
                  control={<Radio color="primary" />}
                  label="Não"
                  onChange={(e, v) => {
                    if (v) {
                      setMaquina("Não");
                      console.log(maquina);
                    }
                  }}
                />
              </RadioGroup>
              {maquina === "Sim" && (
                <LocalizationProvider
                  locale={brLocale}
                  dateAdapter={AdapterDateFns}
                >
                  <DateTimePicker
                    disabled={true}
                    label="Quando parou?"
                    value={qrCode.quandoMaquinaParou}
                    onChange={(newValue) => {
                      setMachineStop(newValue);
                    }}
                    renderInput={(params) => <TextFieldMui {...params} />}
                  />
                </LocalizationProvider>
              )}
            </FormControl>
            <div className="form-group">
              <label> Foto: </label>
              <input
                type="file"
                placeholder="Equipamento"
                name="file"
                className="form-control"
                accept="image/png, image/jpeg"
                onChange={(e) => setImage(e.target.files)}
              />
              {/* <Camera 
                onTakePhoto={(e)=>{console.log(e)}}
              /> */}
            </div>
          </form>
          <Button color="success" onClick={create}>
            Criar
          </Button>{" "}
          <Button color="secondary" onClick={stateModal}>
            Cancelar
          </Button>
        </ModalBody>
      </Modal>
    </>
  );
}

export default Qrcode;
