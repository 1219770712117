import { createRef } from "react";

import { convertDate } from "../../../../utils/convertDate";
import { templateStatus, templatePriority } from "./templates";

import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Toolbar,
  Inject,
  Filter,
  Sort,
  Resize,
  PdfExport,
  ExcelExport,
  ColumnChooser,
  Scroll,
} from "@syncfusion/ej2-react-grids";
import "./style.css";

const ref = createRef();

const Datagrid = ({ lineClicked, dataSource }) => {
  const toolbarClick = (args) => {
    if (
      args.item.text === "PDF Export" ||
      args.item.text === "Exportação de PDF"
    ) {
      ref.current.pdfExport();
    } else if (
      args.item.text === "Excel Export" ||
      args.item.text === "Exportação Excel"
    ) {
      ref.current.excelExport();
    }
  };

  const groupOptions = { showGroupedColumn: true };

  const adapterDeadline = (value) => {
    return convertDate(value?.prazo);
  };

  return (
    <GridComponent
      ref={ref}
      dataSource={dataSource ?? []}
      allowPaging={true}
      allowFiltering={true}
      filterSettings={{ type: "CheckBox" }}
      allowSorting={true}
      allowTextWrap={true}
      allowResizing={true}
      allowPdfExport={true}
      allowExcelExport={true}
      enableHeaderFocus={true}
      hierarchyPrintMode={"All"}
      allowReordering={true}
      showColumnChooser={true}
      groupSettings={groupOptions}
      toolbarClick={toolbarClick}
      toolbar={["PdfExport", "ExcelExport", "Print", "Search", "ColumnChooser"]}
      rowSelected={lineClicked}
    >
      <ColumnsDirective>
        <ColumnDirective field="id" headerText="Ordem" width={200} />
        <ColumnDirective
          field="equipamento"
          headerText="Código do equipamento"
        />
        <ColumnDirective
          field="equipamento_denominacao"
          headerText="Descrição do equipamento"
        />
        <ColumnDirective field="descricao" headerText="Descrição da falha" />
        <ColumnDirective
          field="prazo"
          headerText="Prazo"
          template={adapterDeadline}
        />
        <ColumnDirective
          field="status"
          headerText="Status"
          template={templateStatus}
        />
        <ColumnDirective
          field="prioridade"
          headerText="Prioridade"
          template={templatePriority}
        />
        <ColumnDirective field="responsavel" headerText="Executante" />
        <ColumnDirective field="tipo" headerText="Tipo de ordem" />
        <ColumnDirective field="maquina_parou" headerText="Máquina Parou" />
      </ColumnsDirective>
      <Inject
        services={[
          Page,
          Toolbar,
          Filter,
          Sort,
          ColumnChooser,
          Resize,
          PdfExport,
          ExcelExport,
        ]}
      />
    </GridComponent>
  );
};

export default Datagrid;
