import { createRef, useState, useEffect, useContext } from "react";
import {
  ScheduleComponent,
  ResourcesDirective,
  ResourceDirective,
  ViewsDirective,
  ViewDirective,
  Agenda,
  Year,
  Inject,
  TimelineViews,
  Resize,
  DragAndDrop,
  TimelineMonth,
  resetTime,
} from "@syncfusion/ej2-react-schedule";
import {
  extend,
  closest,
  remove,
  addClass,
  L10n,
  loadCldr,
} from "@syncfusion/ej2-base";
import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import Header from "components/Headers/Headerfor.js";
import moment from "moment";
import ptBR from "@syncfusion/ej2-locale/src/pt-BR.json";

import OrdersService from "../../../Orders/OrderService";
import UsersService from "../../../Users/UserService";
import StructureTimeline from "../../../Strategy/services/StrategyTimeline";
import SpareReserveService from "../../../Spares/Spare_Reserve_Service";

import * as numberingSystems from "../../../../culture-files/numberingSystems.json";
import * as gregorian from "../../../../culture-files/ca-gregorian.json";
import * as numbers from "../../../../culture-files/numbers.json";
import * as timeZoneNames from "../../../../culture-files/timeZoneNames.json";
import DateTimePicker from "../../../DateTimePicker";
import TimePicker from "../../../TimePicker";

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);

import { EnterpriseNameContext } from "../../../../context/EnterpriseContext";
import {
  Container,
  Card,
  Row,
  CardBody,
  Col,
  Table,
  Media,
  Button,
} from "reactstrap";

import "../styles/team.css";
import OrderService from "../../../Orders/OrderService";

const scheduleObj = createRef();
const treeObj = createRef();

L10n.load({
  pt: ptBR["pt-BR"],
});

const Team = () => {
  const { enterpriseName } = useContext(EnterpriseNameContext);
  const [ordersOpen, setOrdersOpen] = useState([]);
  const [users, setUsers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [spareReserve, setSpareReserve] = useState([]);
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [orderSelected, setOrderSelected] = useState();
  const [timeStart, setTimeStart] = useState("");
  const [timeEnd, setTimeEnd] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [handleTime, setHandleTime] = useState(false);

  useEffect(() => {
    OrdersService.getOrders({ empresa: enterpriseName }).then((response) => {
      const ordersOpenFiltered = response?.data?.data?.filter((order) => {
        if (order.status === "Aberta" && !order.start) {
          return true;
        }
        return false;
      });
      setOrdersOpen(ordersOpenFiltered);
    });
    UsersService.getTimelineUsers({
      empresa: enterpriseName,
    }).then((response) => {
      setUsers(response.data);
    });
    StructureTimeline.getTimeline({ empresa: enterpriseName }).then(
      (response) => {
        setOrders(response.data.orders);
      }
    );
    SpareReserveService.getSparesReserve({ empresa: enterpriseName }).then(
      (response) => {
        setSpareReserve(response.data);
      }
    );
  }, []);

  let isTreeItemDropped = false;
  let draggedItemId = "";
  const allowDragAndDrops = true;
  const fields = {
    dataSource: ordersOpen,
    id: "_id",
    text: "equipamento_denominacao",
  };
  const data = extend([], orders, null, true);
  function getConsultantName(value) {
    return value.resourceData[value.resource.textField];
  }
  function getConsultantImage(value) {
    return value.resourceData?.foto;
  }
  function getConsultantDesignation(value) {
    return value.resourceData.Designation;
  }
  function resourceHeaderTemplate(props) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginLeft: "-2rem",
        }}
        className="specialist-category"
      >
        <div>
          {getConsultantImage(props) && (
            <img
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                marginBottom: "0.5rem",
              }}
              src={getConsultantImage(props)}
              alt={getConsultantName(props)}
            />
          )}
        </div>
        <div className="specialist-name">{getConsultantName(props)}</div>
        <div className="specialist-designation">
          {getConsultantDesignation(props)}
        </div>
      </div>
    );
  }
  function treeTemplate(props) {
    return (
      <div id="waiting">
        <div id="waitdetails">
          <div id="waitlist">
            {props.id} - {props.equipamento}
          </div>
          <div id="waitcategory">
            {props.equipamento_denominacao} - {props.setor_equipamento}
          </div>
        </div>
      </div>
    );
  }
  function onItemDrag(event) {
    if (scheduleObj.current.isAdaptive) {
      let classElement =
        scheduleObj.current.element.querySelector(".e-device-hover");
      if (classElement) {
        classElement.classList.remove("e-device-hover");
      }
      if (event.event.target.classList.contains("e-work-cells")) {
        addClass([event.event.target], "e-device-hover");
      }
    }
    if (document.body.style.cursor === "not-allowed") {
      document.body.style.cursor = "";
    }
    if (event.name === "nodeDragging") {
      let dragElementIcon = document.querySelectorAll(
        ".e-drag-item.treeview-external-drag .e-icon-expandable"
      );
      for (let i = 0; i < dragElementIcon.length; i++) {
        dragElementIcon[i].style.display = "none";
      }
    }
  }
  function onActionBegin(event) {
    if (event.requestType === "eventCreate" && isTreeItemDropped) {
      let treeViewData = treeObj.current.fields.dataSource;
      const filteredPeople = treeViewData.filter(
        (item) => item.Id !== parseInt(draggedItemId, 10)
      );
      treeObj.current.fields.dataSource = filteredPeople;
      let elements = document.querySelectorAll(
        ".e-drag-item.treeview-external-drag"
      );
      for (let i = 0; i < elements.length; i++) {
        remove(elements[i]);
      }
    }
  }

  const eventRendered = (event) => {
    if (event.data.status === "Concluída") {
      event.element.style.backgroundColor = "#44D978";
      event.element.firstChild.style.borderLeftColor = "#44D978";
    } else if (event.data.status === "Atrasada") {
      event.element.style.backgroundColor = "#F5365C";
      event.element.firstChild.style.borderLeftColor = "#F5365C";
    } else {
      event.element.firstChild.style.borderLeftColor = "#1474EE";
      event.element.style.backgroundColor = "#1474EE";
    }
  };

  const dragStop = async (event) => {
    let cellData = scheduleObj.current.getCellDetails(event.target);
    let resourceDetails = scheduleObj.current.getResourcesByIndex(
      cellData.groupIndex
    );

    let eventData = {
      start: cellData.startTime,
      end: cellData.endTime,
      performer: resourceDetails?.groupData.performer,
      responsavel: resourceDetails?.groupData.performer,
      nomeExecutante: resourceDetails?.groupData.performer,
      usernameResponsavel: resourceDetails?.groupData.performer,
    };

    await OrdersService.updateOrder(eventData, event.data._id).then(
      (response) => {
        StructureTimeline.getTimeline({ empresa: enterpriseName }).then(
          (response) => {
            setOrders(response.data.orders);
          }
        );
      }
    );
  };

  function onTreeDragStop(event) {
    let treeElement = closest(event.target, ".e-treeview");
    let classElement =
      scheduleObj.current.element.querySelector(".e-device-hover");
    if (classElement) {
      classElement.classList.remove("e-device-hover");
    }

    if (!treeElement) {
      event.cancel = true;
      let scheduleElement = closest(event.target, ".e-content-wrap");
      if (scheduleElement) {
        let treeviewData = treeObj.current.fields.dataSource;
        if (event.target.classList.contains("e-work-cells")) {
          let filteredData = treeviewData.filter(
            (item) => item._id === event.draggedNodeData.id
          );
          let cellData = scheduleObj.current.getCellDetails(event.target);
          let resourceDetails = scheduleObj.current.getResourcesByIndex(
            cellData.groupIndex
          );

          const orderFiltered = filteredData[0];
          if (orderFiltered._id) {
            OrdersService.getOrderById(orderFiltered._id).then((response) => {
              const order = response.data.data;
              order.start = cellData.startTime;
              order.end = cellData.endTime;
              (order.responsavel = resourceDetails?.groupData.performer),
                (order.nomeExecutante = resourceDetails?.groupData.performer),
                (order.usernameResponsavel =
                  resourceDetails?.resourceData?.username),
                (order.provider = null),
                (order.performer = resourceDetails?.groupData.performer),
                (order.status = "Programada");

              OrdersService.updateStatusOfOrder(
                event.draggedNodeData.id,
                order
              ).then((response) => {
                StructureTimeline.getTimeline({
                  empresa: enterpriseName,
                }).then((response) => {
                  setOrders(response.data.orders);
                });
                OrdersService.getOrders({ empresa: enterpriseName }).then(
                  (response) => {
                    const ordersOpenFiltered = response?.data?.data?.filter(
                      (order) => {
                        if (order.status === "Aberta" && !order.start) {
                          return true;
                        }
                        return false;
                      }
                    );
                    setOrdersOpen(ordersOpenFiltered);
                  }
                );
              });
            });
          }
          isTreeItemDropped = true;
          draggedItemId = event.draggedNodeData.id;
        }
      }
    }
  }

  const today = resetTime(new Date());

  const popupOpen = (props) => {
    const data = props.data;
    setOrderSelected(data);
    setStartDate(data.StartTime);
    setEndDate(data.EndTime);

    setTimeStart(
      `${
        String(new Date(data.StartTime).getHours()).length === 1
          ? "0" + new Date(data.StartTime).getHours()
          : new Date(data.StartTime).getHours()
      }:${
        String(new Date(data.StartTime).getMinutes()).length === 1
          ? "0" + new Date(data.StartTime).getMinutes()
          : new Date(data.StartTime).getMinutes()
      }`
    );

    setTimeEnd(
      `${
        String(new Date(data.EndTime).getHours()).length === 1
          ? "0" + new Date(data.EndTime).getHours()
          : new Date(data.EndTime).getHours()
      }:${
        String(new Date(data.EndTime).getMinutes()).length === 1
          ? "0" + new Date(data.EndTime).getMinutes()
          : new Date(data.EndTime).getMinutes()
      }`
    );
  };

  const handleTimeChange = () => {
    setHandleTime(false);
    const start = new Date(
      new Date(
        new Date(startDate).setHours(timeStart.substring(0, 2))
      ).setMinutes(timeStart.substring(3, 5))
    );

    const end = new Date(
      new Date(new Date(endDate).setHours(timeEnd.substring(0, 2))).setMinutes(
        timeEnd.substring(3, 5)
      )
    );

    const source = {
      start,
      end,
    };
    OrderService.updateOrder(source, orderSelected?._id).then((response) => {
      StructureTimeline.getTimeline({ empresa: enterpriseName }).then(
        (response) => {
          setOrders(response.data.orders);
        }
      );
    });
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div>
            <Card
              style={{ width: "100%", paddingTop: "2em" }}
              className="shadow"
            >
              <div className="schedule-control-section">
                <div className="col-lg-12 control-section">
                  <div className="control-wrapper drag-sample-wrapper">
                    <div className="schedule-container">
                      <div className="title-container">
                        <h1 className="title-text">Equipe</h1>
                      </div>
                      <ScheduleComponent
                        locale="pt"
                        ref={scheduleObj}
                        eventRendered={eventRendered}
                        width="100%"
                        height="650px"
                        editorTemplate={(event) => {
                          const costMaterialPerformer =
                            orderSelected?.costMaterialPerformer
                              ? orderSelected?.costMaterialPerformer
                              : 0;
                          const costMaterialProvider =
                            orderSelected?.costMaterialProvider
                              ? orderSelected?.costMaterialProvider
                              : 0;
                          const costServiceProvider =
                            orderSelected?.costServiceProvider
                              ? orderSelected?.costServiceProvider
                              : 0;
                          return (
                            <div key={event.Guid}>
                              <Card
                                style={{ marginTop: "1em" }}
                                className="shadow-lg"
                              >
                                <CardBody>
                                  <Row>
                                    <Col>
                                      <h2>Detalhes da ordem</h2>

                                      <p>
                                        <b style={{ fontWeight: "550" }}>
                                          OS :{" "}
                                        </b>{" "}
                                        {orderSelected?.Id}
                                      </p>

                                      <p>
                                        <b style={{ fontWeight: "550" }}>
                                          Prazo:{" "}
                                        </b>
                                        {moment(orderSelected?.prazo).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </p>

                                      <p>
                                        <b style={{ fontWeight: "550" }}>
                                          Setor Instalado:{" "}
                                        </b>
                                        {orderSelected?.setor_equipamento}
                                      </p>
                                    </Col>

                                    <Col className="col-auto">
                                      <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                        <i className="ni ni-box-2" />
                                      </div>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>

                              {handleTime && (
                                <div
                                  style={{
                                    marginTop: "1em",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Button
                                    color="primary"
                                    onClick={() => handleTimeChange()}
                                  >
                                    Mudar horário
                                  </Button>
                                </div>
                              )}

                              <Card
                                style={{ marginTop: "1em" }}
                                className="shadow-lg"
                              >
                                <CardBody>
                                  <Row>
                                    <Col>
                                      <div style={{ display: "flex" }}>
                                        {orderSelected?.foto && (
                                          <img
                                            style={{
                                              maxWidth: "20%",
                                              marginRight: "1em",
                                            }}
                                            alt="ordem"
                                            src={orderSelected?.foto}
                                          />
                                        )}
                                        <div>
                                          <h2>{orderSelected?.denominacao}</h2>

                                          <p style={{ color: "#AAAA" }}>
                                            {orderSelected?.descricao}
                                          </p>
                                        </div>
                                      </div>
                                    </Col>

                                    {orderSelected?.status === "Atrasada" && (
                                      <Col
                                        style={{
                                          backgroundColor: "#F5365C",
                                          width: "100px",
                                          height: "50px",
                                          borderRadius: "10px",
                                        }}
                                        className="col-auto"
                                      >
                                        <p
                                          style={{
                                            marginTop: "10px",
                                            color: "#FFFF",
                                          }}
                                        >
                                          {orderSelected?.status}
                                        </p>
                                      </Col>
                                    )}
                                    {orderSelected?.status === "Concluída" && (
                                      <Col
                                        style={{
                                          backgroundColor: "#44D978",
                                          width: "105px",
                                          height: "50px",
                                          borderRadius: "10px",
                                        }}
                                        className="col-auto"
                                      >
                                        <p
                                          style={{
                                            marginTop: "10px",
                                            color: "#FFFF",
                                          }}
                                        >
                                          {orderSelected?.status}
                                        </p>
                                      </Col>
                                    )}
                                    {orderSelected?.status !== "Concluída" &&
                                      orderSelected?.status !== "Atrasada" && (
                                        <Col
                                          style={{
                                            backgroundColor: "#1474EE",
                                            width: "120px",
                                            height: "50px",
                                            borderRadius: "10px",
                                          }}
                                          className="col-auto"
                                        >
                                          <p
                                            style={{
                                              marginTop: "10px",
                                              color: "#FFFF",
                                            }}
                                          >
                                            {orderSelected?.status}
                                          </p>
                                        </Col>
                                      )}
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div>
                                          <h4> Início programado:</h4>
                                          <TimePicker
                                            time={timeStart}
                                            setTime={setTimeStart}
                                            placeholder="Início programado"
                                            auxFunction={() =>
                                              setHandleTime(true)
                                            }
                                          />
                                        </div>
                                        <div style={{ marginLeft: "5em" }}>
                                          <h4> Fim programado:</h4>
                                          <TimePicker
                                            time={timeEnd}
                                            setTime={setTimeEnd}
                                            placeholder="Fim programado"
                                            auxFunction={() =>
                                              setHandleTime(true)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>

                              <Card
                                style={{ marginTop: "1em" }}
                                className="shadow-lg"
                              >
                                <CardBody>
                                  <h3 style={{ marginBottom: "10px" }}>
                                    Materiais
                                  </h3>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="">
                                      <tr>
                                        <th scope="col">Produto</th>
                                        <th scope="col">Part Number</th>
                                        <th scope="col">Quantidade</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {orderSelected?._id &&
                                        spareReserve
                                          .filter(
                                            (response) =>
                                              response.ordem_id ===
                                              orderSelected?._id
                                          )
                                          .map((response, index) => {
                                            return (
                                              <tr key={index}>
                                                <th scope="row">
                                                  <Media className="align-items-center">
                                                    <Media>
                                                      <span className="mb-0 text-sm">
                                                        {response.produto}
                                                      </span>
                                                    </Media>
                                                  </Media>
                                                </th>
                                                <th scope="row">
                                                  <Media className="align-items-center">
                                                    <Media>
                                                      <span className="mb-0 text-sm">
                                                        {response.part_number}
                                                      </span>
                                                    </Media>
                                                  </Media>
                                                </th>
                                                <th scope="row">
                                                  <Media className="align-items-center">
                                                    <Media>
                                                      <span className="mb-0 text-sm">
                                                        {response.quantidade}
                                                      </span>
                                                    </Media>
                                                  </Media>
                                                </th>
                                              </tr>
                                            );
                                          })}
                                    </tbody>
                                  </Table>
                                  {spareReserve.filter(
                                    (response) =>
                                      response.ordem_id === orderSelected?._id
                                  ).length == 0 && (
                                    <p
                                      style={{
                                        textAlign: "center",
                                        marginLeft: "-2em",
                                      }}
                                    >
                                      Sem material
                                    </p>
                                  )}
                                </CardBody>
                              </Card>

                              {orderSelected?.costMaterialPerformer ||
                              orderSelected?.costServiceProvider ||
                              orderSelected?.costMaterialProvider ? (
                                <Card style={{ marginTop: "1em" }}>
                                  <CardBody>
                                    <h3 style={{ marginBottom: "10px" }}>
                                      Custo da ordem
                                    </h3>
                                    <Table
                                      responsive
                                      style={{ tableLayout: "fixed" }}
                                    >
                                      <thead>
                                        <tr>
                                          <th>Item</th>
                                          <th>Subtotal</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <th scope="row">
                                            <Media className="align-items-center">
                                              <Media>
                                                <span className="mb-0 text-sm">
                                                  Custo do material (Equipe
                                                  Própria)
                                                </span>
                                              </Media>
                                            </Media>
                                          </th>
                                          <th scope="row">
                                            <Media className="align-items-center">
                                              <Media>
                                                <span className="mb-0 text-sm">
                                                  {orderSelected?.costMaterialPerformer
                                                    ? "R$ " +
                                                      orderSelected?.costMaterialPerformer
                                                    : "R$ 0 "}
                                                </span>
                                              </Media>
                                            </Media>
                                          </th>
                                        </tr>
                                        <tr>
                                          <th scope="row">
                                            <Media className="align-items-center">
                                              <Media>
                                                <span className="mb-0 text-sm">
                                                  Custo do serviço (Fornecedor)
                                                </span>
                                              </Media>
                                            </Media>
                                          </th>
                                          <th scope="row">
                                            <Media className="align-items-center">
                                              <Media>
                                                <span className="mb-0 text-sm">
                                                  {orderSelected?.costServiceProvider
                                                    ? orderSelected?.costServiceProvider
                                                    : "R$ 0 "}
                                                </span>
                                              </Media>
                                            </Media>
                                          </th>
                                        </tr>
                                        <tr>
                                          <th scope="row">
                                            <Media className="align-items-center">
                                              <Media>
                                                <span className="mb-0 text-sm">
                                                  Custo do material (Fornecedor)
                                                </span>
                                              </Media>
                                            </Media>
                                          </th>
                                          <th scope="row">
                                            <Media className="align-items-center">
                                              <Media>
                                                <span className="mb-0 text-sm">
                                                  {orderSelected?.costMaterialProvider
                                                    ? orderSelected?.costMaterialProvider
                                                    : "R$ 0 "}
                                                </span>
                                              </Media>
                                            </Media>
                                          </th>
                                        </tr>
                                      </tbody>
                                    </Table>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        marginRight: "1.7em",
                                      }}
                                    >
                                      <div
                                        style={{ borderTop: "3px solid #012" }}
                                      >
                                        <label
                                          style={{
                                            fontSize: 14,
                                            fontWeight: 700,
                                            marginRight: "3em",
                                            marginTop: "0.5em",
                                          }}
                                        >
                                          Custo total:
                                        </label>
                                        <label style={{ fontSize: 14 }}>
                                          R${" "}
                                          {(
                                            costMaterialPerformer +
                                            costMaterialProvider +
                                            costServiceProvider
                                          )
                                            ?.toString()
                                            ?.replace(".", ",")}
                                        </label>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              ) : null}

                              {orderSelected?.performer && (
                                <Card
                                  style={{ marginTop: "1em" }}
                                  className="shadow-lg"
                                >
                                  <CardBody>
                                    <Row>
                                      <Col>
                                        <div>
                                          <h2>Parecer Técnico</h2>

                                          <p>
                                            <b style={{ fontWeight: "550" }}>
                                              Responsável
                                            </b>
                                            : {orderSelected?.performer}
                                          </p>
                                          {orderSelected?.descriptionCompleted && (
                                            <p style={{ color: "#A4B2C3" }}>
                                              {
                                                orderSelected?.descriptionCompleted
                                              }
                                            </p>
                                          )}
                                        </div>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              )}

                              {/* <Card
                                style={{ marginTop: "1em" }}
                                className="shadow-lg"
                              >
                                <CardBody>
                                  <Row>
                                    <Col>
                                      <h2>Custo da ordem</h2>
      
                                      <p>
                                        <b style={{ fontWeight: "550" }}>
                                          Custo do Serviço:{" "}
                                        </b>{" "}
                                        {order.costServiceProvider}
                                      </p>
      
                                      <p>
                                        <b style={{ fontWeight: "550" }}>
                                          Custo de material do fabricante :{" "}
                                        </b>
                                        {order.costMaterialProvider}
                                      </p>
      
                                      <p>
                                        <b style={{ fontWeight: "550" }}>
                                          Custo de material do executante:{" "}
                                        </b>
                                        {order.costMaterialPerformer}
                                      </p>
                                    </Col>
      
                                    <Col className="col-auto">
                                      <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                        <i className="ni ni-box-2" />
                                      </div>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card> */}
                            </div>
                          );
                        }}
                        selectedDate={today}
                        popupOpen={popupOpen}
                        currentView="TimelineMonth"
                        resourceHeaderTemplate={resourceHeaderTemplate}
                        eventSettings={{
                          dataSource: data,
                          allowDeleting: false,
                          allowAdding: false,
                        }}
                        group={{
                          enableCompactView: false,
                          resources: ["Usuários"],
                        }}
                        actionBegin={onActionBegin}
                        drag={onItemDrag}
                        dragStop={dragStop}
                      >
                        <ResourcesDirective>
                          <ResourceDirective
                            field="performer"
                            title="Usuários"
                            name="Usuários"
                            allowMultiple={false}
                            dataSource={users}
                            textField="first_name"
                            idField="first_name"
                            // startHourField="StartTime"
                            // endHourField="EndTime"
                            // colorField="Color"
                          ></ResourceDirective>
                        </ResourcesDirective>
                        <ViewsDirective>
                          <ViewDirective option="TimelineDay" />
                          <ViewDirective option="TimelineWeek" />
                          <ViewDirective option="TimelineMonth" />
                          <ViewDirective option="Agenda" />
                        </ViewsDirective>
                        <Inject
                          services={[
                            TimelineViews,
                            TimelineMonth,
                            Resize,
                            Agenda,
                            Year,
                            DragAndDrop,
                          ]}
                        />
                      </ScheduleComponent>
                    </div>
                    <div className="treeview-container">
                      <div className="title-container">
                        <h1 className="title-text">Ordens Abertas</h1>
                        {ordersOpen.length === 0 && (
                          <p style={{ textAlign: "center" }}>
                            Não temos ordens abertas
                          </p>
                        )}
                      </div>
                      <TreeViewComponent
                        ref={treeObj}
                        cssClass="treeview-external-drag"
                        dragArea=".drag-sample-wrapper"
                        nodeTemplate={treeTemplate}
                        fields={fields}
                        nodeDragStop={onTreeDragStop}
                        nodeDragging={onItemDrag}
                        allowDragAndDrop={allowDragAndDrops}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Team;
