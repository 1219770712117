import React, { useEffect, useContext, useState, createRef } from "react";
import {
  TimelineViews,
  TimelineMonth,
  Agenda,
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  ResourcesDirective,
  ResourceDirective,
  Inject,
  Resize,
  DragAndDrop,
  Year,
} from "@syncfusion/ej2-react-schedule";

import moment from "moment";

import OrdersService from "../../../Orders/OrderService";

import { extend } from "@syncfusion/ej2-base";
import {
  Container,
  Card,
  Row,
  CardHeader,
  CardBody,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Col,
  Table,
  Media,
} from "reactstrap";
import Header from "components/Headers/Headerfor.js";
import { Link } from "react-router-dom";
import "../../styles/timeline/index.css";

// Services
import TimelineService from "../../services/StrategyTimeline";
import SpareReserveService from "../../../Spares/Spare_Reserve_Service";

// Contexts
import { EnterpriseNameContext } from "../../../../context/EnterpriseContext";
// import { DriveEtaSharp } from "@material-ui/icons";

const scheduleRef = createRef();

const Timeline = () => {
  const [equipments, setEquipments] = useState([]);
  const [sector, setSector] = useState([]);
  const [orders, setOrders] = useState([]);
  const { enterpriseName } = useContext(EnterpriseNameContext);
  const [orderTemplate, setOrderTemplate] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownStatus, setDropdownStatus] = useState(false);
  const [filter, setFilter] = useState("");
  const [spareReserve, setSpareReserve] = useState([]);

  const toggle = () => setDropdownOpen(!dropdownOpen);
  const toggleStatus = () => setDropdownStatus(!dropdownOpen);

  useEffect(() => {
    TimelineService.getTimeline({ empresa: enterpriseName }).then(
      (response) => {
        const source = response.data;
        console.log(source, "souce");
        setEquipments(source.equipments);
        setSector(source.sectors);
        setOrders(source.orders);
        setOrderTemplate(source.orders);
      }
    );
    SpareReserveService.getSparesReserve({ empresa: enterpriseName }).then(
      (response) => {
        setSpareReserve(response.data);
      }
    );
  }, []);

  const data = extend([], orders, null, true);

  const workDays = [0, 1, 2, 3, 4, 5];

  const eventRendered = (event) => {
    if (event.data.status === "Concluída") {
      event.element.style.backgroundColor = "#44D978";
      event.element.firstChild.style.borderLeftColor = "#44D978";
    } else if (event.data.status === "Atrasada") {
      event.element.style.backgroundColor = "#F5365C";
      event.element.firstChild.style.borderLeftColor = "#F5365C";
    } else {
      event.element.firstChild.style.borderLeftColor = "#1474EE";
      event.element.style.backgroundColor = "#1474EE";
    }
  };
  function dragStop(event) {
    let cellData = scheduleRef.current.getCellDetails(event.target);

    let eventData = {
      start: cellData.startTime,
      end: cellData.endTime,
    };
    console.log("eventData", eventData);
    OrdersService.updateOrder(eventData, event.data._id).then((response) => {
      console.log("atualizou a ordem", response.data);
      TimelineService.getTimeline({ empresa: enterpriseName }).then(
        (response) => {
          console.log(response.data);
          setOrders(response.data.orders);
        }
      );
    });
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div>
            <Card style={{ width: "100%" }} className="shadow">
              <CardHeader style={{ width: "100%" }} className="border-0">
                <h3 className="mb-0 mt-2">Agenda</h3>
                <Button size="sm" className="position-absolute top-4 right-4">
                  <Link to="/admin/strategy">Voltar</Link>
                </Button>
              </CardHeader>
              <CardBody>
                <Row className="mt-2 mb-3">
                  <Col xs="auto" sm="auto">
                    <ButtonDropdown
                      size="sm"
                      isOpen={dropdownOpen}
                      className="ml-3 mr-2"
                      toggle={toggle}
                    >
                      <DropdownToggle caret color="info">
                        Tipo da ordem
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          active={filter === "Todos"}
                          onClick={() => {
                            setFilter("Todos");
                            setOrders(orderTemplate);
                          }}
                        >
                          Todas
                        </DropdownItem>
                        <DropdownItem
                          active={filter === "Preventiva"}
                          onClick={() => {
                            setFilter("Preventiva");
                            const filter = orderTemplate.filter(
                              (response) => response.tipo === "Preventiva"
                            );
                            setOrders(filter);
                          }}
                        >
                          Preventiva
                        </DropdownItem>
                        <DropdownItem
                          active={filter === "Preditiva"}
                          onClick={() => {
                            setFilter("Preditiva");
                            const filter = orderTemplate.filter(
                              (response) => response.tipo === "Preditiva"
                            );
                            setOrders(filter);
                          }}
                        >
                          Preditiva
                        </DropdownItem>
                        <DropdownItem
                          active={filter === "Corretiva"}
                          onClick={() => {
                            setFilter("Corretiva");
                            const filter = orderTemplate.filter(
                              (response) => response.tipo === "Corretiva"
                            );
                            setOrders(filter);
                          }}
                        >
                          Corretiva
                        </DropdownItem>
                        <DropdownItem
                          active={filter === "Plano"}
                          onClick={() => {
                            setFilter("Plano");
                            const filter = orderTemplate.filter(
                              (response) => response.tipo === "Plano"
                            );
                            setOrders(filter);
                          }}
                        >
                          Plano
                        </DropdownItem>
                      </DropdownMenu>
                    </ButtonDropdown>
                    <ButtonDropdown
                      size="sm"
                      isOpen={dropdownStatus}
                      className="ml-3 mr-2"
                      toggle={toggleStatus}
                    >
                      <DropdownToggle caret color="info">
                        Status
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          active={filter === "Todos"}
                          onClick={() => {
                            setFilter("Todos");
                            setOrders(orderTemplate);
                          }}
                        >
                          Todas
                        </DropdownItem>
                        <DropdownItem
                          active={filter === "Atrasada"}
                          onClick={() => {
                            setFilter("Atrasada");
                            const filter = orderTemplate.filter(
                              (response) => response.status === "Atrasada"
                            );
                            setOrders(filter);
                          }}
                        >
                          Atrasada
                        </DropdownItem>
                        <DropdownItem
                          active={filter === "Programada"}
                          onClick={() => {
                            setFilter("Preditiva");
                            const filter = orderTemplate.filter(
                              (response) => response.status === "Programada"
                            );
                            setOrders(filter);
                          }}
                        >
                          Programada
                        </DropdownItem>
                        <DropdownItem
                          active={filter === "Concluída"}
                          onClick={() => {
                            setFilter("Concluída");
                            const filter = orderTemplate.filter(
                              (response) => response.status === "Concluída"
                            );
                            setOrders(filter);
                          }}
                        >
                          Concluída
                        </DropdownItem>
                      </DropdownMenu>
                    </ButtonDropdown>
                  </Col>
                </Row>
                <ScheduleComponent
                  locale="pt"
                  ref={scheduleRef}
                  cssClass="schedule-group"
                  // className="schedule-group"
                  // readOnly={true}
                  editorTemplate={(event) => {
                    const order = event;
                    console.log("order - ", order);
                    const costMaterialPerformer = order.costMaterialPerformer
                      ? order.costMaterialPerformer
                      : 0;
                    const costMaterialProvider = order.costMaterialProvider
                      ? order.costMaterialProvider
                      : 0;
                    const costServiceProvider = order.costServiceProvider
                      ? order.costServiceProvider
                      : 0;
                    return (
                      <div key={event.Guid}>
                        <Card
                          style={{ marginTop: "1em" }}
                          className="shadow-lg"
                        >
                          <CardBody>
                            <Row>
                              <Col>
                                <h2>Detalhes da ordem</h2>

                                <p>
                                  <b style={{ fontWeight: "550" }}>OS : </b>{" "}
                                  {order.Id}
                                </p>

                                <p>
                                  <b style={{ fontWeight: "550" }}>Prazo: </b>
                                  {moment(order.prazo).format("DD/MM/YYYY")}
                                </p>

                                <p>
                                  <b style={{ fontWeight: "550" }}>
                                    Setor Instalado:{" "}
                                  </b>
                                  {order.setor_equipamento}
                                </p>
                              </Col>

                              <Col className="col-auto">
                                <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                  <i className="ni ni-box-2" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>

                        <Card
                          style={{ marginTop: "1em" }}
                          className="shadow-lg"
                        >
                          <CardBody>
                            <Row>
                              <Col>
                                <div style={{ display: "flex" }}>
                                  {order.foto && (
                                    <img
                                      style={{
                                        maxWidth: "20%",
                                        marginRight: "1em",
                                      }}
                                      alt="ordem"
                                      src={order.foto}
                                    />
                                  )}
                                  <div>
                                    <h2>{order.denominacao}</h2>

                                    <p style={{ color: "#AAAA" }}>
                                      {order.descricao}
                                    </p>
                                  </div>
                                </div>
                              </Col>

                              {order.status === "Atrasada" && (
                                <Col
                                  style={{
                                    backgroundColor: "#F5365C",
                                    width: "100px",
                                    height: "50px",
                                    borderRadius: "10px",
                                  }}
                                  className="col-auto"
                                >
                                  <p
                                    style={{
                                      marginTop: "10px",
                                      color: "#FFFF",
                                    }}
                                  >
                                    {order.status}
                                  </p>
                                </Col>
                              )}
                              {order.status === "Concluída" && (
                                <Col
                                  style={{
                                    backgroundColor: "#44D978",
                                    width: "105px",
                                    height: "50px",
                                    borderRadius: "10px",
                                  }}
                                  className="col-auto"
                                >
                                  <p
                                    style={{
                                      marginTop: "10px",
                                      color: "#FFFF",
                                    }}
                                  >
                                    {order.status}
                                  </p>
                                </Col>
                              )}
                              {order.status !== "Concluída" &&
                                order.status !== "Atrasada" && (
                                  <Col
                                    style={{
                                      backgroundColor: "#1474EE",
                                      width: "120px",
                                      height: "50px",
                                      borderRadius: "10px",
                                    }}
                                    className="col-auto"
                                  >
                                    <p
                                      style={{
                                        marginTop: "10px",
                                        color: "#FFFF",
                                      }}
                                    >
                                      {order.status}
                                    </p>
                                  </Col>
                                )}
                            </Row>
                          </CardBody>
                        </Card>

                        <Card
                          style={{ marginTop: "1em" }}
                          className="shadow-lg"
                        >
                          <CardBody>
                            <h3 style={{ marginBottom: "10px" }}>Materiais</h3>
                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <thead className="">
                                <tr>
                                  <th scope="col">Produto</th>
                                  <th scope="col">Part Number</th>
                                  <th scope="col">Quantidade</th>
                                </tr>
                              </thead>
                              <tbody>
                                {order._id &&
                                  spareReserve
                                    .filter(
                                      (response) =>
                                        response.ordem_id === order._id
                                    )
                                    .map((response, index) => {
                                      console.log(order._id, "id");
                                      return (
                                        <tr key={index}>
                                          <th scope="row">
                                            <Media className="align-items-center">
                                              <Media>
                                                <span className="mb-0 text-sm">
                                                  {response.produto}
                                                </span>
                                              </Media>
                                            </Media>
                                          </th>
                                          <th scope="row">
                                            <Media className="align-items-center">
                                              <Media>
                                                <span className="mb-0 text-sm">
                                                  {response.part_number}
                                                </span>
                                              </Media>
                                            </Media>
                                          </th>
                                          <th scope="row">
                                            <Media className="align-items-center">
                                              <Media>
                                                <span className="mb-0 text-sm">
                                                  {response.quantidade}
                                                </span>
                                              </Media>
                                            </Media>
                                          </th>
                                        </tr>
                                      );
                                    })}
                              </tbody>
                            </Table>
                            {spareReserve.filter(
                              (response) => response.ordem_id === order._id
                            ).length == 0 && (
                              <p
                                style={{
                                  textAlign: "center",
                                  marginLeft: "-2em",
                                }}
                              >
                                Sem material
                              </p>
                            )}
                          </CardBody>
                        </Card>

                        {order.costMaterialPerformer ||
                        order.costServiceProvider ||
                        order.costMaterialProvider ? (
                          <Card style={{ marginTop: "1em" }}>
                            <CardBody>
                              <h3 style={{ marginBottom: "10px" }}>
                                Custo da ordem
                              </h3>
                              <Table
                                responsive
                                style={{ tableLayout: "fixed" }}
                              >
                                <thead>
                                  <tr>
                                    <th>Item</th>
                                    <th>Subtotal</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th scope="row">
                                      <Media className="align-items-center">
                                        <Media>
                                          <span className="mb-0 text-sm">
                                            Custo do material (Equipe Própria)
                                          </span>
                                        </Media>
                                      </Media>
                                    </th>
                                    <th scope="row">
                                      <Media className="align-items-center">
                                        <Media>
                                          <span className="mb-0 text-sm">
                                            {order.costMaterialPerformer
                                              ? "R$ " +
                                                order.costMaterialPerformer
                                              : "R$ 0 "}
                                          </span>
                                        </Media>
                                      </Media>
                                    </th>
                                  </tr>
                                  <tr>
                                    <th scope="row">
                                      <Media className="align-items-center">
                                        <Media>
                                          <span className="mb-0 text-sm">
                                            Custo do serviço (Fornecedor)
                                          </span>
                                        </Media>
                                      </Media>
                                    </th>
                                    <th scope="row">
                                      <Media className="align-items-center">
                                        <Media>
                                          <span className="mb-0 text-sm">
                                            {order.costServiceProvider
                                              ? order.costServiceProvider
                                              : "R$ 0 "}
                                          </span>
                                        </Media>
                                      </Media>
                                    </th>
                                  </tr>
                                  <tr>
                                    <th scope="row">
                                      <Media className="align-items-center">
                                        <Media>
                                          <span className="mb-0 text-sm">
                                            Custo do material (Fornecedor)
                                          </span>
                                        </Media>
                                      </Media>
                                    </th>
                                    <th scope="row">
                                      <Media className="align-items-center">
                                        <Media>
                                          <span className="mb-0 text-sm">
                                            {order.costMaterialProvider
                                              ? order.costMaterialProvider
                                              : "R$ 0 "}
                                          </span>
                                        </Media>
                                      </Media>
                                    </th>
                                  </tr>
                                </tbody>
                              </Table>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  marginRight: "1.7em",
                                }}
                              >
                                <div style={{ borderTop: "3px solid #012" }}>
                                  <label
                                    style={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      marginRight: "3em",
                                      marginTop: "0.5em",
                                    }}
                                  >
                                    Custo total:
                                  </label>
                                  <label style={{ fontSize: 14 }}>
                                    R${" "}
                                    {(
                                      costMaterialPerformer +
                                      costMaterialProvider +
                                      costServiceProvider
                                    )
                                      ?.toString()
                                      ?.replace(".", ",")}
                                  </label>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        ) : null}

                        {order.performer && (
                          <Card
                            style={{ marginTop: "1em" }}
                            className="shadow-lg"
                          >
                            <CardBody>
                              <Row>
                                <Col>
                                  <div>
                                    <h2>Parecer Técnico</h2>

                                    <p>
                                      <b style={{ fontWeight: "550" }}>
                                        Responsável
                                      </b>
                                      : {order.performer}
                                    </p>
                                    {order.descriptionCompleted && (
                                      <p style={{ color: "#A4B2C3" }}>
                                        {order.descriptionCompleted}
                                      </p>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        )}

                        {/* <Card
                          style={{ marginTop: "1em" }}
                          className="shadow-lg"
                        >
                          <CardBody>
                            <Row>
                              <Col>
                                <h2>Custo da ordem</h2>

                                <p>
                                  <b style={{ fontWeight: "550" }}>
                                    Custo do Serviço:{" "}
                                  </b>{" "}
                                  {order.costServiceProvider}
                                </p>

                                <p>
                                  <b style={{ fontWeight: "550" }}>
                                    Custo de material do fabricante :{" "}
                                  </b>
                                  {order.costMaterialProvider}
                                </p>

                                <p>
                                  <b style={{ fontWeight: "550" }}>
                                    Custo de material do executante:{" "}
                                  </b>
                                  {order.costMaterialPerformer}
                                </p>
                              </Col>

                              <Col className="col-auto">
                                <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                  <i className="ni ni-box-2" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card> */}
                      </div>
                    );
                  }}
                  popupOpen={(event) => {
                    console.log(event);
                  }}
                  // readonly={true}
                  eventRendered={eventRendered}
                  width="100%"
                  style={{ minWidth: "100%" }}
                  height="650px"
                  selectedDate={new Date()}
                  currentView="TimelineMonth"
                  workDays={workDays}
                  eventSettings={{
                    // allowEditing: false,
                    dataSource: data,
                    allowDeleting: false,
                    allowAdding: false,
                  }}
                  group={{ resources: ["Projects", "Categories"] }}
                  dragStop={dragStop}
                >
                  <ResourcesDirective>
                    <ResourceDirective
                      field="setor_equipamento"
                      title="Choose Project"
                      name="Projects"
                      allowMultiple={false}
                      dataSource={sector}
                      textField="setor"
                      idField="id"
                      colorField="color"
                    ></ResourceDirective>
                    <ResourceDirective
                      field="denominacao"
                      title="Category"
                      name="Categories"
                      allowMultiple={true}
                      dataSource={equipments}
                      textField="denominacao"
                      idField="denominacao"
                      groupIDField="setor_instalado"
                      colorField="color"
                    ></ResourceDirective>
                  </ResourcesDirective>
                  <ViewsDirective>
                    <ViewDirective option="TimelineDay" />
                    <ViewDirective option="TimelineWeek" />
                    <ViewDirective option="TimelineMonth" />
                    <ViewDirective option="Agenda" />
                  </ViewsDirective>
                  <Inject
                    services={[
                      TimelineViews,
                      TimelineMonth,
                      Agenda,
                      Resize,
                      DragAndDrop,
                      Year,
                    ]}
                  />
                </ScheduleComponent>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Timeline;
