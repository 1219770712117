// GESTÃO DE PLANOS DE MANUTENÇÂO

import axios from "axios";

const PLAN_API_BASE_URL = "https://api.darwinx.com.br/plan";

class PlanService {
  getPlan(plan) {
    return axios.get(PLAN_API_BASE_URL, { params: plan });
  }

  createPlan(Plan) {
    return axios.post(PLAN_API_BASE_URL, Plan);
  }

  getPlanById(PlanId, token) {
    return axios.get(PLAN_API_BASE_URL + "/" + PlanId, { params: token });
  }

  updatePlan(Plan, PlanId) {
    return axios.put(PLAN_API_BASE_URL + "/" + PlanId, Plan);
  }

  deletePlan(PlanId, token) {
    return axios.delete(PLAN_API_BASE_URL + "/" + PlanId, { params: token });
  }
}

export default new PlanService();
