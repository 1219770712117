import React, { useState, useEffect, useContext, createRef } from "react";
import Button_list from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  CardBody,
  Card,
  Row,
  Col,
} from "reactstrap";
import { useInput } from "../Spares/useInput";
import RequestService from "./RequestService";
import EquipmentService from "../Equipments/EquipmentService";
import TextField from "@material-ui/core/TextField";
import { EnterpriseNameContext } from "context/EnterpriseContext";
import RadioGroup from "@material-ui/core/RadioGroup";
// import  {  } from '../context/IdUser';
import { IdUserContext } from "../../context/IdUser";
import { UserType } from "../../context/UserType";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import NotificationAlert from "react-notification-alert";
import axios from "axios";
import TextFieldMui from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import brLocale from "date-fns/locale/pt-BR";
import DateTimePicker from "../DateTimePicker";

import GetAllEquipmentsByArea from "../Orders/services/GetAllEquipmentsByArea";
import GetAllEquipmentsByEnterprise from "../Orders/services/GetAllAreasByEnterprise";
import GetAllEquipmentsByCustomFilter from "../Strategy/services/GetAllEquipmentsByCustomFilter";
import GetAllSectorsByEnterprise from "../Strategy/services/GetAllSectorsByEnterprise";
import FilterSectorsByArea from "../Orders/services/FilterSectorsByArea";

const notification = createRef();
function CreateRequest(props) {
  const history = useHistory();

  const { enterpriseName } = useContext(EnterpriseNameContext);
  const { idUser } = useContext(IdUserContext);
  const { userType } = useContext(UserType);

  const [equip_selected, setEquip_selected] = useState([]);
  const [rSelected, setRSelected] = useState("Nenhuma");
  const [usuario, setUsuario] = useState([]);

  const [modal, setModal] = useState(false);
  const [image, setImage] = useState();
  const [color, setColor] = useState();
  const [codigo, setCodigo] = useState(null);
  const [setorEquipamento, setSetorEquipamento] = useState();
  const [nameEquipment, setNameEquipment] = useState({
    codigo: null,
    denominacao: null,
  });

  const [nameUser, setNameUser] = useState(() => {
    const userNameStoraged = window.localStorage.getItem("TOKEN_USERNAME");

    if (userNameStoraged) {
      return userNameStoraged;
    }
    return;
  });

  const [area, setArea] = useState("");
  const [setor, setSetor] = useState("");
  const [denominacao, setDenominacao] = useState("");
  const [maquina, setMaquina] = useState("Não");
  const [equipment, setEquipment] = useState([]);
  const [status, setStatus] = useState("Aberta");
  const [machineStop, setMachineStop] = useState(null);

  const [token, setToken] = useState(() => {
    const tokenStoraged = localStorage.getItem("TOKEN_KEY");
    if (tokenStoraged) {
      return tokenStoraged;
    }
    return "";
  });
  const [hash, setHash] = useState(() => {
    const hashStoraged = localStorage.getItem("TOKEN_HASH");
    if (hashStoraged) {
      return hashStoraged;
    }
    return "";
  });
  const [idUserStorage, setIdUserStorage] = useState(() => {
    const idUserStoraged = localStorage.getItem("TOKEN_ID_USER");
    if (idUserStoraged) {
      return idUserStoraged;
    }
    return "";
  });

  const toggle = () => setModal(!modal);
  // const [descricaoEquipamento, setDescricaoEquipamento] = useState();

  const [equipamento, setEquipamento] = useState("");
  const { bind: bindequipamento } = useInput("");
  const {
    value: descricao,
    bind: binddescricao,
    setValue: setDescricao,
  } = useInput("");

  //YUP IN HAND
  const [requireEquipment, setRequireEquipment] = useState(false);
  const [messageRequireEquipment, setMessageRequireEquipment] = useState("");

  const [requireDescription, setRequireDescription] = useState(false);
  const [messageRequireDescription, setMessageRequireDescription] =
    useState("");

  const [areas, setAreas] = useState([]);
  const [areaSelected, setAreaSelected] = useState("");
  const [sector, setSector] = useState("");
  const [sectors, setSectors] = useState([]);

  const redirectLogin = () => {
    swal(
      "Hmm... vi que você não está logado. Clique em ok para fazer o login",
      "",
      "warning",
      {
        buttons: {
          // nope: {
          //   text: 'Me enganei!',
          //   value: 'nope',
          // },
          sure: {
            text: "Ok",
            value: "sure",
          },
        },
      }
    ).then((value) => {
      switch (value) {
        case "sure":
          swal("Operação realizada com sucesso!", "", "success").then((val) => {
            // history.pushState
            // return <Redirect to="/auth/login"/>
            history.push("/auth/login");
          });
          break;
        case "nope":
          swal("Operação cancelada", "", "success");
          break;
        default:
      }
    });
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (codigo && descricao) {
      let url;
      let filename;

      if (image) {
        if (image[0].size > 1024 * 1024 * 4) {
          notifyCode(
            "warning",
            "Atenção!",
            "O tamanho da imagem é muito grande!"
          );
          return;
        }
        const formData = new FormData();
        formData.append("image", image[0]);
        await axios({
          method: "post",
          url: "https://api.darwinx.com.br/images",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((response) => {
            url = response.data.url;
            filename = response.data.name;
            console.log(url);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (url) {
        let requests = {
          equipamento: codigo,
          descricao: descricao,
          prioridade: rSelected,
          empresa: enterpriseName,
          usuarioResponsavel: idUser,
          maquina_parou: maquina,
          local: setor,
          equipmentArea: area,
          setor_equipamento: setor,
          foto: url,
          nomeFoto: filename,
          status: status,
          descricao_equipamento: denominacao,
          color: color,
          quandoMaquinaParou: machineStop,
          datesToAverage: {
            initialDate: Date.now(),
          },
          ownerOpenRequest: nameUser,
          token: token,
          hash: hash,
          idUser: idUserStorage,
        };
        console.log(requests);

        RequestService.createRequest(requests)
          .then((res) => {
            RequestService.getRequests({
              empresa: enterpriseName,
              token: token,
              hash: hash,
              idUser: idUserStorage,
            })
              .then((res) => {
                props.setRequest(res.data.data);
                localStorage.removeItem("TOKEN_KEY");
                localStorage.setItem("TOKEN_KEY", res.data.token);
              })
              .catch((error) => {
                if (error?.response?.data?.auth) {
                } else {
                  // redirectLogin();
                }
              });
            setStatus("Aberta");
            setColor(null);
            setCodigo(null);
            setEquipamento(null);
            setDescricao("");
            setImage(null);
            setNameEquipment({ codigo: null, denominacao: null });
            setRSelected("Nenhuma");
            setMaquina("Não");
            setArea(null);
            setDenominacao(null);
            notifyGeneral("success");
            setModal(false);
          })
          .catch((error) => {
            if (error?.response?.data?.auth) {
            } else {
              // redirectLogin();
            }
          });
      } else {
        let requests = {
          equipamento: codigo,
          descricao: descricao,
          prioridade: rSelected,
          empresa: enterpriseName,
          usuarioResponsavel: idUser,
          maquina_parou: maquina,
          setor_equipamento: setor,
          local: setor,
          foto: url,
          equipmentArea: area,
          nomeFoto: filename,
          status: status,
          descricao_equipamento: denominacao,
          quandoMaquinaParou: machineStop,
          ownerOpenRequest: nameUser,
          color: color,
          datesToAverage: {
            initialDate: Date.now(),
          },
          token: token,
          hash: hash,
          idUser: idUserStorage,
        };
        console.log(requests);

        RequestService.createRequest(requests)
          .then((res) => {
            RequestService.getRequests({
              empresa: enterpriseName,
              token: token,
              hash: hash,
              idUser: idUserStorage,
            })
              .then((res) => {
                props.setRequest(res.data.data);
                localStorage.removeItem("TOKEN_KEY");
                localStorage.setItem("TOKEN_KEY", res.data.token);
              })
              .catch((error) => {
                if (error?.response?.data?.auth) {
                } else {
                  // redirectLogin();
                }
              });
            setStatus("Aberta");
            setColor(null);
            setCodigo(null);
            setEquipamento(null);
            setDescricao("");
            setImage(null);
            setNameEquipment({ codigo: null, denominacao: null });
            setRSelected("Nenhuma");
            setMaquina("Não");
            setArea(null);
            setDenominacao(null);
            notifyGeneral("success");
            setModal(false);
          })
          .catch((error) => {
            if (error?.response?.data?.auth) {
            } else {
              // redirectLogin();
            }
          });
      }
    }

    if (!codigo) {
      setRequireEquipment(true);
      setMessageRequireEquipment("O campo Equipamento é obrigatório!");
    } else {
      setRequireEquipment(false);
      setMessageRequireEquipment("");
    }

    if (!descricao) {
      setRequireDescription(true);
      setMessageRequireDescription("O campo Descrição é obrigatório!");
    } else {
      setRequireDescription(false);
      setMessageRequireDescription("");
    }
    //alert(`Submitting Name ${firstName} ${lastName}`);
    //resetFirstName();
    //resetLastName();
  };

  const notifyCode = (event, title, content) => {
    let options = {
      place: "tc",
      message: (
        <div>
          <h2
            style={{ color: "#fff", marginBottom: "0" }}
            className="alert-title"
            data-notify="title"
          >
            {" "}
            {title ? title : "Atenção!"}
            <br />
          </h2>
          <span data-notify="message">
            {content ? content : "Este código já foi cadastrado!"}
          </span>
        </div>
      ),
      type: event,
      // icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    notification.current.notificationAlert(options);
  };

  const notifyGeneral = (event) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <h2
            style={{ color: "#fff", marginBottom: "0" }}
            className="alert-title"
            data-notify="title"
          >
            {" "}
            Concluído!
            <br />
          </h2>
          <span data-notify="message">
            A requisição foi criada com sucesso!
          </span>
        </div>
      ),
      type: event,
      // icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    notification.current.notificationAlert(options);
  };

  useEffect(() => {
    GetAllSectorsByEnterprise.getSectors({
      enterprise: enterpriseName,
    })
      .then((res) => {
        setSectors(res.data.equipmentArea);
      })
      .catch((error) => {
        console.log(error);
      });
    GetAllEquipmentsByEnterprise.getAllAreas({
      enterprise: enterpriseName,
    })
      .then((res) => {
        console.log("res.data.equipmentArea", res.data.equipmentArea);
        setAreas(res.data.equipmentArea);
      })
      .catch((error) => {
        console.log(error);
      });

    EquipmentService.getEquipment({
      empresa: enterpriseName,
      token: token,
      hash: hash,
      idUser: idUserStorage,
    })
      .then((res) => {
        if (res.data.data) {
          const sorted = res.data.data?.sort((compareA, compareB) => {
            if (compareA.denominacao > compareB.denominacao) {
              return 1;
            }
            if (compareA.denominacao < compareB.denominacao) {
              return -1;
            }
            return 0;
          });
          setEquipment(sorted);
        }
        // localStorage.removeItem("TOKEN_KEY");
        // localStorage.setItem("TOKEN_KEY", res.data.token);
      })
      .catch((error) => {
        if (error?.response?.data?.auth) {
        } else {
          // redirectLogin();
        }
      });
  }, [enterpriseName, token, hash, idUserStorage]);

  const filterEquipments = (area, sector) => {
    if (area && !sector) {
      FilterSectorsByArea.getSectors({ area, empresa: enterpriseName }).then(
        (response) => {
          setSectors(response.data?.filter ?? []);
        }
      );
      GetAllEquipmentsByCustomFilter.getEquipments({
        area: area,
        empresa: enterpriseName,
      }).then((response) => {
        setEquipment(response.data.equipments);
      });
    } else if (sector && area) {
      GetAllEquipmentsByCustomFilter.getEquipments({
        area: area,
        setor_instalado: sector,
        empresa: enterpriseName,
      }).then((response) => {
        setEquipment(response.data.equipments);
      });
    } else if (!area && sector) {
      GetAllEquipmentsByCustomFilter.getEquipments({
        setor_instalado: sector,
        empresa: enterpriseName,
      }).then((response) => {
        console.log("response: ", response.data);
        setEquipment(response.data.equipments);
      });
    } else {
      EquipmentService.getEquipment({
        empresa: enterpriseName,
      })
        .then((res) => {
          if (res.data.data) {
            const sorted = res.data.data?.sort((compareA, compareB) => {
              if (compareA.denominacao > compareB.denominacao) {
                return 1;
              }
              if (compareA.denominacao < compareB.denominacao) {
                return -1;
              }
              return 0;
            });
            setEquipment(sorted);
          }
        })
        .catch((error) => {});
    }
    // } else {
    //   if (area && !sector) {
    //     GetAllEquipmentsByCustomFilter.getEquipments({
    //       area: area,
    //       empresa: enterpriseName,
    //     }).then((response) => {
    //       setEquipment(response.data.equipments);
    //     });
    //   } else if (sector && area) {
    //     GetAllEquipmentsByCustomFilter.getEquipments({
    //       area: area,
    //       setor_instalado: sector,
    //       empresa: enterpriseName,
    //     }).then((response) => {
    //       setEquipment(response.data.equipments);
    //     });
    //   } else if (!area && sector) {
    //     console.log("sector: ", sector);
    //     GetAllEquipmentsByCustomFilter.getEquipments({
    //       setor_instalado: sector,
    //       empresa: enterpriseName,
    //     }).then((response) => {
    //       console.log("response: ", response.data);
    //       setEquipment(response.data.equipments);
    //     });
    //   } else {
    //     EquipmentService.getEquipment({
    //       empresa: enterpriseName,
    //     })
    //       .then((res) => {
    //         if (res.data.data) {
    //           const sorted = res.data.data?.sort((compareA, compareB) => {
    //             if (compareA.denominacao > compareB.denominacao) {
    //               return 1;
    //             }
    //             if (compareA.denominacao < compareB.denominacao) {
    //               return -1;
    //             }
    //             return 0;
    //           });
    //           setEquipment(sorted);
    //         }
    //       })
    //       .catch((error) => {});
    //   }
    // }
  };

  return (
    <div>
      <Button
        color="success"
        size="sm"
        className="position-absolute top-3 right-4"
        onClick={toggle}
      >
        {" "}
        Nova Requisição{" "}
      </Button>
      <NotificationAlert ref={notification} />
      <Modal
        isOpen={modal}
        toggle={toggle}
        size="lg"
        className="mr-0 mt--1 mb--1 mt-5"
      >
        <ModalHeader toggle={toggle} className="mt-3" tag="h2">
          Nova Requisição
        </ModalHeader>
        <ModalBody className="mb-1 mt--2 mb-2">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                onChange={(e, v) => {
                  if (v) {
                    setAreaSelected(v);
                    filterEquipments(v, sector);
                  } else {
                    filterEquipments(null, sector);
                    setAreaSelected("");
                  }
                }}
                value={areaSelected}
                options={areas}
                renderInput={(params) => (
                  <TextField
                    value={areaSelected}
                    {...params}
                    variant="outlined"
                    label="Filtre seus equipamentos pela área"
                    margin="normal"
                    placeholder="Área 1"
                  />
                )}
              />
            </div>
            <div className="form-group">
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                onChange={(e, v) => {
                  if (v) {
                    setSector(v);
                    filterEquipments(areaSelected, v);
                  } else {
                    filterEquipments(areaSelected, null);
                    setSector("");
                  }
                }}
                value={sector}
                options={sectors}
                renderInput={(params) => (
                  <TextField
                    value={sector}
                    {...params}
                    variant="outlined"
                    placeholder="Ex.: Utilidades"
                    label="Filtrar por Setor Instalado"
                    margin="normal"
                  />
                )}
              />
            </div>
            <div className="form-group">
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                options={equipment}
                getOptionLabel={(option) => {
                  if (option.codigo) {
                    return option.codigo + " - " + option.denominacao;
                  }
                  if (option.denominacao) {
                    return option.codigo + " - " + option.denominacao;
                  } else {
                    // return option.codigo + option.denominacao;
                    return "";
                  }
                }}
                value={nameEquipment}
                onChange={(e, value) => {
                  console.log(value);
                  if (value) {
                    EquipmentService.getEquipmentById(value._id, {
                      token: token,
                      hash: hash,
                      idUser: idUserStorage,
                    }).then((res) => {
                      let equipments = res.data.data;
                      setArea(equipments.area);
                      setCodigo(equipments.codigo);
                      setNameEquipment({
                        codigo: equipments.codigo,
                        denominacao: equipments.denominacao,
                      });
                      setDenominacao(equipments.denominacao);
                      setSetor(equipments.setor_instalado);
                      localStorage.removeItem("TOKEN_KEY");
                      localStorage.setItem("TOKEN_KEY", res.data.token);
                    });
                  } else {
                    setArea(null);
                    setDenominacao(null);
                    setSetor(null);
                    setNameEquipment(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    error={requireEquipment}
                    helperText={messageRequireEquipment}
                    {...params}
                    value={nameEquipment}
                    label="Equipamento"
                    margin="normal"
                    variant="outlined"
                  />
                )}
              />
            </div>
            <h4>Resumo do equipamento </h4>
            <Card className="shadow-lg">
              <CardBody>
                <Row>
                  <Col>
                    <p>
                      <b style={{ fontWeight: "550" }}>Area: </b> {area}
                    </p>

                    <p>
                      <b style={{ fontWeight: "550" }}>Setor Instalado: </b>
                      {setor}
                    </p>

                    <p>
                      <b style={{ fontWeight: "550" }}>Equipamento: </b>
                      {denominacao}
                    </p>
                  </Col>

                  <Col className="col-auto">
                    <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                      <i className="ni ni-box-2" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <br></br>
            <div className="form-group">
              <TextField
                error={requireDescription}
                helperText={messageRequireDescription}
                id="outlined-basic"
                label="Descrição"
                variant="outlined"
                rows={4}
                //name="descrição"
                {...binddescricao}
                multiline="true"
                style={{ width: "100%" }}
              />
            </div>
            <label> Prioridade: </label> <br></br>
            <ButtonGroup className="mb-4">
              <Button
                color="default"
                className=""
                outline
                onClick={() => {
                  setRSelected("Nenhuma");
                  setColor("#7BF7CD");
                }}
                active={rSelected === "Nenhuma"}
              >
                Nenhuma
              </Button>
              <Button
                color="default"
                outline
                onClick={() => {
                  setRSelected("Baixa");
                  setColor("#0091F7");
                }}
                active={rSelected === "Baixa"}
              >
                {" "}
                &nbsp; &nbsp; Baixa &nbsp; &nbsp;{" "}
              </Button>
              <Button
                color="default"
                outline
                onClick={() => {
                  setRSelected("Média");
                  setColor("#ffc400");
                }}
                active={rSelected === "Média"}
              >
                {" "}
                &nbsp; &nbsp; Média &nbsp; &nbsp;
              </Button>
              <Button
                color="default"
                outline
                onClick={() => {
                  setRSelected("Alta");
                  setColor("#dc4664");
                }}
                active={rSelected === "Alta"}
              >
                {" "}
                &nbsp; &nbsp; Alta &nbsp; &nbsp;{" "}
              </Button>
            </ButtonGroup>
            <FormControl style={{ display: "block" }} component="fieldset">
              <FormLabel component="legend">O equipamento parou?</FormLabel>
              <Row>
                <Col>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="top"
                  >
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="Sim"
                      checked={maquina === "Sim"}
                      onChange={(e, v) => {
                        if (v) {
                          setMaquina("Sim");
                        }
                      }}
                    />
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="Não"
                      checked={maquina === "Não"}
                      onChange={(e, v) => {
                        if (v) {
                          setMaquina("Não");
                        }
                      }}
                    />
                  </RadioGroup>
                </Col>
                <Col>
                  {maquina === "Sim" && (
                    <DateTimePicker
                      placeholder="Quando parou?"
                      date={machineStop}
                      setDate={setMachineStop}
                    />
                  )}
                </Col>
              </Row>
            </FormControl>
            <div className="form-group">
              <label> Foto: </label>
              <input
                type="file"
                placeholder="Equipamento"
                name="file"
                className="form-control"
                accept="image/png, image/jpeg"
                onChange={(e) => setImage(e.target.files)}
              />
            </div>
            <Button type="submit" color="success">
              Criar
            </Button>{" "}
            <Button
              color="secondary"
              onClick={() => {
                toggle();
                setEquipamento(null);
                setDescricao(null);
                setRSelected("Nenhuma");
                setMaquina("Não");
                setSetor(null);
                setArea(null);
                setDenominacao(null);
                setSetor(null);
                setImage(null);
                setMachineStop(null);
                setNameEquipment({ codigo: "", denominacao: "" });
              }}
            >
              Cancelar
            </Button>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default CreateRequest;
