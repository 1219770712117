import axios from "axios";

const TIMELINE_API_BASE_URL = "https://api.darwinx.com.br/timeline";

class TimelineService {
  getTimeline(filter) {
    return axios.get(TIMELINE_API_BASE_URL, { params: filter });
  }
}

export default new TimelineService();
