import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  createRef,
  useCallback,
  memo,
} from "react";
import OrderService from "./OrderService";
import SpareRequestService from "../Orders/Spare_Request_Service";
import List from "@mui/material/List";

import Select from "../Select";

import FindReplaceIcon from "@mui/icons-material/FindReplace";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

import DialogListOrder from "./DialogListOrder";
import ProvidersService from "../Fornecedores/FornecedoresService";

import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";
import NotificationAlert from "react-notification-alert";

import { EnterpriseNameContext } from "../../context/EnterpriseContext";
import { CartContext } from "../../context/Cart";
import { UserType } from "../../context/UserType";

import axios from "axios";
import AWS from "aws-sdk";

import EquipmentService from "../Equipments/EquipmentService";

import { Button as ButtonMui } from "@mui/material";

import swal from "@sweetalert/with-react";

import StepConnector from "@material-ui/core/StepConnector";
import UserService from "../Users/UserService";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Avatar from "@material-ui/core/Avatar";
import BoxMui from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import StepLabel from "@material-ui/core/StepLabel";
import UpdateIcon from "@material-ui/icons/Update";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import SearchIcon from "@material-ui/icons/Search";

import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";
import HotelIcon from "@material-ui/icons/Hotel";
import RepeatIcon from "@material-ui/icons/Repeat";
import Paper from "@material-ui/core/Paper";

import { Checkbox } from "@mui/material";

import RemoveShoppingCartOutlinedIcon from "@material-ui/icons/RemoveShoppingCartOutlined";
import RestoreRoundedIcon from "@material-ui/icons/RestoreRounded";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import "./ListOrder.css";
import {
  Button,
  Col,
  Row,
  Card,
  CardBody,
  Media,
  Container,
  Modal,
  CardHeader,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  Table,
  Badge as BadgeStrap,
} from "reactstrap";

import ModalReplan from "./ModalReplan";

import DoneOutlineRoundedIcon from "@material-ui/icons/DoneOutlineRounded";
import FormGroup from "@mui/material/FormGroup";
import { Badge } from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import SpareService from "../Spares/SpareService";
import SpareReserveService from "./Spare_Reserve_Service";

import { Menu, MenuItem } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import WarningIcon from "@material-ui/icons/Warning";

import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Header from "components/Headers/Headerfor.js";
import CreateOrder from "./CreateOrder";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";

import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SearchImage from "../../assets/img/icons/search.svg";
import ViewCalendar from "./ViewCalendar";
import moment from "moment";
import Check from "@material-ui/icons/Check";
import InventaryIcon from "../../assets/img/icons/inventory.svg";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useHistory } from "react-router-dom";
import TextFieldMui from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DatePicker from "@mui/lab/DatePicker";
import brLocale from "date-fns/locale/pt-BR";

import SpareServiceMoviment from "../Spares/SpareMoviment_Service";

import ModalCompleteOrder from "./components/ModalCompleteOrder";
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Switch from '@mui/material/Switch';

// import { GoogleMap, Marker, useJsApiLoader  } from '@react-google-maps/api';
// import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import DateTimePicker from "../DateTimePicker";
import Datagrid from "./components/Datagrid";

import ModalPrint from "./components/ModalPrint";

// const Maps = memo((props) => {
//   const { isLoaded } = useJsApiLoader({
//     id: "google-map-script",
//     googleMapsApiKey: "AIzaSyAIZC0_BOVpwYBZiUaaf23K857gaSn_QwY",
//   });

//   const [map, setMap] = useState(null);

//   const onLoad = useCallback(
//     function callback(map) {
//       const bounds = new window.google.maps.LatLngBounds();
//       map.fitBounds(bounds);
//       setMap(map);
//       map.center.lat(props?.initial?.lat);
//       map.center.lng(props?.initial?.lng);
//     },
//     [props?.initial?.lat, props?.initial?.lng]
//   );

//   const onUnmount = useCallback(function callback(map) {
//     setMap(null);
//   }, []);

//   return isLoaded && props.initial ? (
//     <GoogleMap
//       mapContainerStyle={containerStyle}
//       center={props.initial}
//       zoom={10}
//       onLoad={onLoad}
//       onUnmount={onUnmount}
//     >
//       {/* Child components, such as markers, info windows, etc. */}
//       <></>
//       {props.initial ? <Marker position={props.initial} /> : null}
//       {props.finished ? <Marker position={props.finished} /> : null}
//       {/* <Marker
//           position={{
//             lat: 37.772,
//             lng: -122.214
//           }}
//         />
//         <Marker
//           position={{
//             lat: 37.772,
//             lng: -122.000
//           }}
//         /> */}
//     </GoogleMap>
//   ) : (
//     <></>
//   );
// });

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
    margin: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const notification = createRef();

const useStyles_bar = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

function getSteps() {
  return ["Editar ordem", "Reservar Material", "Programar ordem", "Histórico"];
}

function ListOrders(props) {
  const history = useHistory();

  const [user] = useState(() => {
    const idStoraged = localStorage.getItem("TOKEN_ID_USER");
    if (idStoraged) {
      return idStoraged;
    }
    return "";
  });

  const { cart, setCart, spare, setSpare } = useContext(CartContext);
  const { enterpriseName } = useContext(EnterpriseNameContext);
  const { userType } = useContext(UserType);

  const [dates, setDates] = useState();
  const [qtdWaitMaterial, setQtdWaitMaterial] = useState();
  const [spareRequest, setSpareRequest] = useState();
  const [image, setImage] = useState();
  const [printOrder, setPrintOrder] = useState(false);
  const [descriptions, setDescriptions] = useState();
  const [responsibles, setResponsibles] = useState();
  const [stateBtn, setStateBtn] = useState("");

  const [completedOrder, setCompletedOrder] = useState(false);
  const [stateCompletedOrder, setStateCompletedOrder] = useState(false);

  const [nameUser, setNameUser] = useState(() => {
    const userNameStoraged = window.localStorage.getItem("TOKEN_USERNAME");

    if (userNameStoraged) {
      return userNameStoraged;
    }
    return;
  });

  const [equipmentArea, setEquipmentArea] = useState("");

  const [machineStop, setMachineStop] = useState(null);
  const [maquina, setMaquina] = useState("Não");

  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [duration, setDuration] = useState();

  const [showImage, setShowImage] = useState();
  const [order, setOrder] = useState([]);
  const [orderTemplate, setOrderTemplate] = useState([]);
  const [orderStoraged, setOrderStoraged] = useState();
  const [color, setColor] = useState("");
  const [pdf, setPdf] = useState();

  const [cSelected, setCSelected] = useState([]);
  const [rSelected, setRSelected] = useState("Baixa");
  const [status, setStatus] = useState();
  const [cartOpen, setCartOpen] = useState(false);
  const [provider, setProvider] = useState();
  const [valueProvider, setValueProvider] = useState("");
  const [costService, setCostService] = useState(0);
  const [costMaterial, setCostMaterial] = useState(0);
  const [errorStart, setErrorStart] = useState(false);
  const [errorEnd, setErrorEnd] = useState(false);

  const [historic, setHistoric] = useState([]);

  const [performerGeneral, setPerformerGeneral] = useState([]);

  const [structureCheckbox, setStructureCheckbox] = useState([]);

  const [token, setToken] = useState(() => {
    const tokenStoraged = localStorage.getItem("TOKEN_KEY");
    if (tokenStoraged) {
      return tokenStoraged;
    }
    return "";
  });
  const [hash, setHash] = useState(() => {
    const hashStoraged = localStorage.getItem("TOKEN_HASH");
    if (hashStoraged) {
      return hashStoraged;
    }
    return "";
  });
  const [idUserStorage, setIdUserStorage] = useState(() => {
    const idUserStoraged = localStorage.getItem("TOKEN_ID_USER");
    if (idUserStoraged) {
      return idUserStoraged;
    }
    return "";
  });

  // location
  const [firstLocation, setFirstLocation] = useState({});

  const CheckboxUI = memo(
    ({ check, description, state, setOrderExecutante }) => {
      const [checked, setChecked] = useState(check);
      return (
        <FormControlLabel
          control={<Checkbox disabled={true} />}
          style={{
            textDecoration: checked ? "line-through" : "none",
            width: "100%",
            // border: '2px solid red',
            backgroundColor: "#F8F9FA",
            borderRadius: "5px",
          }}
          label={description}
        />
      );
    }
  );
  const redirectLogin = () => {
    swal(
      "Hmm... vi que você não está logado. Clique em ok para fazer o login",
      "",
      "warning",
      {
        buttons: {
          // nope: {
          //   text: 'Me enganei!',
          //   value: 'nope',
          // },
          sure: {
            text: "Ok",
            value: "sure",
          },
        },
      }
    ).then((value) => {
      switch (value) {
        case "sure":
          swal("Operação realizada com sucesso!", "", "success").then((val) => {
            // history.pushState
            // return <Redirect to="/auth/login"/>
            history.push("/auth/login");
          });
          break;
        case "nope":
          swal("Operação cancelada", "", "success");
          break;
        default:
      }
    });
  };

  const notifyGeneralTotal = (event, title, text) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <h2
            style={{ color: "#fff", marginBottom: "0" }}
            className="alert-title"
            data-notify="title"
          >
            {title} <br />
          </h2>
          <span data-notify="message">{text}</span>
        </div>
      ),
      type: event,
      // icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    notification.current.notificationAlert(options);
  };

  const [descriptionOfOrderCompleted, setDescriptionOfOrderCompleted] =
    useState("");
  const [modalSelectComplete, setModalSelectComplete] = useState(false);
  const toggleModalSelectComplete = () => {
    setModalSelectComplete(!modalSelectComplete);
    setDescriptionOfOrderCompleted("");
  };

  const completedOrderStatus = () => {
    swal("Realmente deseja fechar a ordem?", "", "warning", {
      buttons: {
        nope: {
          text: "Me enganei!",
          value: "nope",
        },
        sure: {
          text: "Ok",
          value: "sure",
        },
      },
    }).then((value) => {
      switch (value) {
        case "sure":
          swal("Operação realizada com sucesso!", "", "success").then((val) => {
            const data = {
              performer: nameUser,
              status: "Concluída",
              descriptionCompleted: descriptionOfOrderCompleted,
            };
            OrderService.updateStatusOfOrder(row_id, data).then((response) => {
              notifyGeneralTotal(
                "success",
                "Sucesso!",
                `A ordem Nº${orderState} foi concluída com sucesso!`
              );
              OrderService.getOrders({ empresa: enterpriseName }).then(
                (response) => {
                  gotoPage(state.pageIndex);
                  setOrder(response.data.data);
                  toggleModalSelectComplete();
                  toggle();
                }
              );
            });
            // history.push('/auth/login')
          });
          break;
        case "nope":
          swal("Operação cancelada", "", "success");
          break;
        default:
      }
    });
  };

  const openAgainOrder = () => {
    swal("Realmente deseja reabrir a ordem?", "", "warning", {
      buttons: {
        nope: {
          text: "Me enganei!",
          value: "nope",
        },
        sure: {
          text: "Ok",
          value: "sure",
        },
      },
    }).then((value) => {
      switch (value) {
        case "sure":
          swal("Operação realizada com sucesso!", "", "success").then((val) => {
            const data = {
              status: "Aberta",
              reOpenOrder: true,
              performer: nameUser,
            };
            OrderService.updateStatusOfOrder(row_id, data).then(
              async (response) => {
                notifyGeneralTotal(
                  "success",
                  "Sucesso!",
                  `A ordem Nº${orderState} foi aberta com sucesso!`
                );
                await OrderService.getOrders({ empresa: enterpriseName }).then(
                  (response) => {
                    setOrder(response.data.data);
                    setDescriptionOfOrderCompleted("");
                  }
                );
                structureModal(row_id);
              }
            );
          });
          break;
        case "nope":
          swal("Operação cancelada", "", "success");
          break;
        default:
      }
    });
  };

  const [modalReplan, setModalReplan] = useState(false);
  const toggleModalReplan = () => setModalReplan(!modalReplan);

  const functionToggleModalReplan = (row) => {
    OrderService.getOrderById(row, {
      token: token,
      hash: hash,
      idUser: idUserStorage,
    }).then((order) => {
      const { data } = order;
      setSourceOrder(data.data);
    });
    toggleModalReplan();
  };

  // const Maps = memo(() => {
  //   return(
  //     <>
  //       <LoadScript
  //         onError={(e)=>{
  //           console.error(e,"error")
  //         }}
  //         googleMapsApiKey="AIzaSyAIZC0_BOVpwYBZiUaaf23K857gaSn_QwY"
  //         >
  //         <GoogleMap
  //           mapContainerStyle={containerStyle}
  //           center={center}
  //           zoom={10}
  //         >
  //           { /* Child components, such as markers, info windows, etc. */ }
  //           <></>
  //         </GoogleMap>
  //       </LoadScript>
  //     </>
  //   );
  // });

  const toggle_cart = () => setCartOpen(!cartOpen);

  const onCheckboxBtnClick = (selected) => {
    const index = cSelected.indexOf(selected);
    if (index < 0) {
      cSelected.push(selected);
    } else {
      cSelected.splice(index, 1);
    }
    setCSelected([...cSelected]);
  };

  const [row_id, setRow_id] = useState("");
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
    setHistoric([]);
    setSourceOrder({});
    setEquipmentArea(null);
    setStructureCheckbox([]);
    setValueDialog("Equipe própria");
    setStateMaterialProvided("");
    setTypeOrder("");
    setCompletedOrder(false);
    setStateCompletedOrder(false);
    setMarkerInitial({});
    setMarkerFinished({});
    setStateBtn("");
    setOrderState("");
    setEquipamento("");
    setDescricao("");
    setEquipmentDescription("");
    setEquipmentCode(null);
    setLocal("");
    setMaquina("Não");
    setMachineStop(null);
    setDuration(0);
    setCustoCarrinho(0);
    setCostMaterial(0);
    setCostService(0);
    setNomeResponsavel(null);
    setUsernameResponsavel("");
    setResponsavel({});
    setDates(null);
    setDescriptions(null);
    setPrazo("");
    setDuracao("");
    setRSelected(null);
    setResponsibles(null);
    setStart(null);
    setEnd(null);
    setFirstLocation({});
    setTipoOrdem("");
    setStatus(null);
    setShowImage(null);
  };

  const [users, setUsers] = useState([]);

  const [quantidade, setQuantidade] = useState(0);

  const notifyGeneral = useCallback(
    (event) => {
      let options = {
        place: "tc",
        message: (
          <div className="alert-text">
            <h2
              style={{ color: "#fff", marginBottom: "0" }}
              className="alert-title"
              data-notify="title"
            >
              {" "}
              Concluído!
              <br />
            </h2>
            <span data-notify="message">
              A Ordem de Manutenção Nº {orderState} foi atualizada com sucesso.
            </span>
          </div>
        ),
        type: event,
        // icon: "ni ni-bell-55",
        autoDismiss: 4,
      };
      notification.current.notificationAlert(options);
    },
    [notification]
  );
  const notifyGeneralModal = useCallback(
    (event, title, text) => {
      let options = {
        place: "tc",
        message: (
          <div className="alert-text">
            <h2
              style={{ color: "#fff", marginBottom: "0" }}
              className="alert-title"
              data-notify="title"
            >
              {" "}
              {title}
              <br />
            </h2>
            <span data-notify="message">{text}</span>
          </div>
        ),
        type: event,
        // icon: "ni ni-bell-55",
        autoDismiss: 4,
      };
      notification.current.notificationAlert(options);
    },
    [notification]
  );
  // const options = {
  //   zoomControlOptions: {
  //     position: google.maps.ControlPosition.RIGHT_CENTER // 'right-center' ,
  //     // ...otherOptions
  //   }
  // }
  const notifyReserve = useCallback((evt) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <h2
            style={{ color: "#fff", marginBottom: "0" }}
            className="alert-title"
            data-notify="title"
          >
            {" "}
            Concluído!
            <br />
          </h2>
          <span data-notify="message">
            Materiais reservados para a Ordem de Manutenção Nº {orderState} com
            sucesso.
          </span>
        </div>
      ),
      type: evt,
      // icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    notification.current.notificationAlert(options);
  }, []);

  const structureModal = (row, notification) => {
    OrderService.getOrderById(row, {
      token: token,
      hash: hash,
      idUser: idUserStorage,
    }).then((res) => {
      let orders = res.data.data;
      setCost(orders.custoCarrinho);
      setHistoric(orders.historic ?? []);
      if (orders.tipo === "Plano") {
        setStructureCheckbox(orders.checkboxes);
      }
      setTypeOrder(orders.tipo);
      if (orders.reOpenOrder) {
        setCompletedOrder(true);
      } else {
        setCompletedOrder(false);
      }
      if (orders.status === "Concluída") {
        setStateCompletedOrder(true);
      } else {
        setStateCompletedOrder(false);
      }
      if (orders.latitude.initial) {
        setMarkerInitial({
          lat: parseFloat(orders.latitude.initial),
          lng: parseFloat(orders.longitude.initial),
        });
      }
      if (orders.latitude.finished) {
        setMarkerFinished({
          lat: parseFloat(orders.latitude.finished),
          lng: parseFloat(orders.longitude.finished),
        });
      }
      setStateBtn(orders.status);
      setOrderState(orders.id); //equipments.tag
      setEquipamento(
        orders.equipamento
          ? orders.equipamento
          : "" + " - " + orders.equipamento_denominacao
          ? orders.equipamento_denominacao
          : ""
      );
      setDescricao(orders.descricao);
      setLocal(orders.local);
      setEquipmentDescription(orders.equipamento_denominacao);
      setEquipmentCode(orders.equipamento);
      setCostMaterial(
        orders.values?.costMaterial?.toFixed(2)?.toString().replace(".", ",")
      );
      setCostService(
        orders.values?.costService?.toFixed(2)?.toString().replace(".", ",")
      );
      setMaquina(orders.maquina_parou);
      setMachineStop(orders.quandoMaquinaParou);
      if (orders.custoCarrinho) {
        setCustoCarrinho(orders.custoCarrinho);
      } else {
        setCustoCarrinho(0);
      }
      if (orders.start && orders.end) {
        setDuration(
          moment(orders.end).format("HH") - moment(orders.start).format("HH")
        );
      } else {
        setDuration(0);
      }
      if (orders.usernameResponsavel) {
        if (orders.responsavel) {
          setNomeResponsavel(orders.responsavel);
        }
        setUsernameResponsavel(orders.usernameResponsavel);
        setResponsavel({
          first_name: orders.responsavel,
          hash: Math.random() * 100,
        });
      } else {
        setResponsavel({});
      }
      setDates(orders.dates);
      // setDescricaoNotExecuted(orders.descricoes?.descricao);
      // setMotivo(orders.descricoes?.descricao_motivo);
      setDescriptions(orders.descricoes);
      setPrazo(orders.prazo);

      setDuracao(orders.duracao);
      // setInicio_programado(moment(orders.Inicio_programado).locale('pt-br'));
      setRSelected(orders.prioridade);
      // setStart(orders.start);
      setResponsibles(orders.responsibles);

      if (orders.start) {
        setStart(new Date(orders.start));
        // setStart(moment(orders.start).format("YYYY-MM-DDTHH:mm"));
      } else {
        setStart(null);
      }
      if (orders.end) {
        setEnd(new Date(orders.end));
        // setEnd(moment(orders.end).format("YYYY-MM-DDTHH:mm"));
      } else {
        setEnd(null);
      }
      setMaquina(orders.maquina_parou);
      setTipoOrdem(orders.tipo);
      setStatus(orders.status);
      if (orders.latitude && orders.longitude) {
        setFirstLocation({
          lat: orders.latitude?.initial,
          lng: orders.longitude?.initial,
        });
        setFirstLocation({
          lat: orders.latitude?.finished,
          lng: orders.longitude?.finished,
        });
      }
      if (orders.nomeFoto) {
        try {
          const myBucket = "darwinxperfil";
          const myKey = orders.nomeFoto;
          const signedUrlExpireSeconds = 60 * 1;
          const s3 = new AWS.S3({
            accessKeyId: "AKIAVSM7L4TZDZIH7KGO",
            region: "sa-east-1",
            secretAccessKey: "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",
          });

          const url = s3.getSignedUrl("getObject", {
            Bucket: myBucket,
            Key: myKey,
            Expires: signedUrlExpireSeconds,
          });

          setShowImage(url);
        } catch (error) {
          console.error(error);
        }
      } else {
        setShowImage(null);
      }
      SpareReserveService.getSparesReserve({ ordem_id: row }).then(
        (response) => {
          setPriceGeneral(response.data);
        }
      );
      localStorage.removeItem("TOKEN_KEY");
      localStorage.setItem("TOKEN_KEY", res.data.token);
    });
    if (notification) {
      notifyGeneral(notification);
    }
    OrderService.getOrders({ empresa: enterpriseName }).then((orders) => {
      setOrder(orders.data.data);
      gotoPage(state.pageIndex);
    });
  };

  const addCart = (spareObject) => {
    SpareReserveService.getSparesReserve({
      ordem_id: row_id,
      spare_id: spareObject._id,
    })
      .then((response) => {
        if (response.data.length <= 0) {
          if (spareObject.quantidade > 0) {
            const reservas = {
              produto: spareObject.produto,
              fornecedores: spareObject.fornecedores,
              descricao: spareObject.descricao,
              categoria: spareObject.categoria,
              local_deposito: spareObject.local_deposito,
              preco: spareObject.preco,
              empresa: spareObject.empresa,
              itens_reservados: spareObject.itens_reservados + 1,
              part_number: spareObject.part_number,
              ressuprimento: spareObject.ressuprimento,
              usuarioResponsavel: spareObject.usuarioResponsavel,
            };
            SpareService.updateSpare(reservas, spareObject._id).then(() => {
              SpareService.getSpares({
                empresa: enterpriseName,
                // token: token,
                // hash: hash,
                // idUser: idUserStorage,
              }).then((response) => {
                setSpare(response.data.data);
                localStorage.removeItem("TOKEN_KEY");
                localStorage.setItem("TOKEN_KEY", response.data.token);
              });
            });

            const dataFiltered = spare.filter((product) => {
              return product._id === spareObject._id;
            });

            if (dataFiltered) {
              let spares = {
                ordem_id: row_id,
                spare_id: spareObject._id,
                // disponiveis: data[0].quantidade, essa parte não pode tá aqui
                quantidade: 1,
                produto: dataFiltered[0].produto,
                part_number: dataFiltered[0].part_number,
                empresa: enterpriseName,
                status: "Reservado",
                custoMaterial: dataFiltered[0].preco,
                custoTotal: dataFiltered[0].preco,
                disponiveis:
                  dataFiltered[0].quantidade -
                  dataFiltered[0].itens_reservados -
                  1,
                orderNumber: orderState,
                equipmentCode: equipmentCode,
                equipmentDescription: equipmentDescription,
                token: token,
                hash: hash,
                idUser: idUserStorage,
                equipmentArea,
              };

              SpareReserveService.createSpareReserve(spares).then((res) => {
                if (res.data) {
                  SpareReserveService.getSparesReserve({
                    empresa: enterpriseName,
                  }).then((response) => {
                    setCart(response.data);
                    notifyGeneralTotal(
                      "success",
                      "Material adicionado ao carrinho",
                      "O material foi adicionado ao carrinho com sucesso!"
                    );
                    OrderService.getOrderById(row_id).then((response) => {
                      let request = response.data.data;
                      let count = 0;
                      SpareReserveService.getSparesReserve({
                        ordem_id: row_id,
                      }).then((spareReserve) => {
                        const somaTotal = spareReserve.data.map(
                          (row, index) => {
                            return row.custoTotal;
                          }
                        );
                        for (let i = 0; i < somaTotal.length; i++) {
                          count += somaTotal[i];
                        }
                        request.custoCarrinho = count;
                        request.token = token;
                        request.idUser = idUserStorage;
                        request.hash = hash;
                        OrderService.updateOrder(request, row_id).then(
                          (response) => {
                            // setPriceGeneral(null)
                            setCustoCarrinho(0);
                            structureModal(row_id);
                            localStorage.removeItem("TOKEN_KEY");
                            localStorage.setItem(
                              "TOKEN_KEY",
                              response.data.token
                            );
                          }
                        );
                      });
                    });
                  });
                }
              });
            }

            OrderService.getOrderById(row_id, {
              token: token,
              hash: hash,
              idUser: idUserStorage,
            }).then((response) => {
              let data = response.data.data;
              data.status = "Material Reservado";
              data.dates.dateMaterialProgrammed = Date.now();
              data.responsibles.OwnerMaterialProgrammed = nameUser;
              data.custoCarrinho = data.custoCarrinho + dataFiltered[0].preco;
              data.token = token;
              data.idUser = idUserStorage;
              data.hash = hash;
              data.performer = nameUser;
              OrderService.updateStatusOfOrder(row_id, data).then((res) => {
                OrderService.getOrders({
                  empresa: enterpriseName,
                }).then((sender) => {
                  setOrder(sender.data.data);
                  gotoPage(state.pageIndex);
                });
              });
            });
          }
        } else {
          notifyGeneralTotal(
            "danger",
            "Atenção!",
            "Este material já foi adicionado ao carrinho!"
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const calculateCost = (spareObject) => {};

  const [stateDisable, setStateDisable] = useState(false);

  function reserve(spareObject) {
    addCart(spareObject);
  }

  async function send_back(reserva) {
    SpareReserveService.deleteSpareReserve(reserva._id).then((res) => {
      OrderService.getOrderById(row_id, {
        token: token,
        hash: hash,
        idUser: idUserStorage,
      }).then((response) => {
        let request = response.data;
        let count = 0;
        SpareReserveService.getSparesReserve({ ordem_id: row_id }).then(
          (response) => {
            const somaTotal = response.data.map((row, index) => {
              return row.custoTotal;
            });
            for (let i = 0; i < somaTotal.length; i++) {
              count += somaTotal[i];
            }
            request.custoCarrinho = count;
            request.token = token;
            request.idUser = idUserStorage;
            request.hash = hash;

            OrderService.updateOrder(request, row_id).then((response) => {
              // setPriceGeneral(null)
              setCustoCarrinho(0);
              structureModal(row_id);
              localStorage.removeItem("TOKEN_KEY");
              localStorage.setItem("TOKEN_KEY", response.data.token);
            });
          }
        );
      });
    });
    SpareService.getSpareById(reserva.spare_id, {
      token: token,
      hash: hash,
      idUser: idUserStorage,
    }).then((res) => {
      let reservas = res.data;

      reservas.itens_reservados =
        reservas.itens_reservados - reserva.quantidade;
      reservas.token = token;
      reservas.idUser = idUserStorage;
      reservas.hash = hash;

      SpareService.updateSpare(reservas, reserva.spare_id).then((response) => {
        if (response.data) {
          SpareService.getSpares({
            empresa: enterpriseName,
            // token: token,
            // hash: hash,
            // idUser: idUserStorage,
          }).then((response) => {
            setSpare(response.data.data);
            localStorage.removeItem("TOKEN_KEY");
            localStorage.setItem("TOKEN_KEY", response.data.token);
          });
        }
      });
    });

    const removeRowStateReserve = cart.filter((e) => {
      return e !== reserva;
    });
    setCart(removeRowStateReserve);
    if (removeRowStateReserve) {
      OrderService.getOrderById(row_id).then((response) => {
        let request = response.data;
        let count = 0;
        const somaTotal = cart
          .filter((res) => res.ordem_id === row_id)
          .map((row, index) => {
            return row.custoTotal;
          });
        for (let i = 0; i < somaTotal.length; i++) {
          count += somaTotal[i];
        }
        request.custoCarrinho = count;
        request.token = token;
        request.idUser = idUserStorage;
        request.hash = hash;

        OrderService.updateOrder(request, row_id).then((response) => {});

        structureModal(row_id);
      });
    }
  }

  const waitMaterial = (e) => {
    swal("Informe a quantidade que deseja solicitar:", {
      content: "input",
    }).then((value, a) => {
      if (value) {
        const data = {
          produto: e.produto,
          empresa: e.empresa,
          part_number: e.part_number,
          usuarioResponsavel: e.usuarioResponsavel,
          qtdWaitMaterial: value,
          orderNumber: orderState,
          equipmentArea,
        };
        SpareRequestService.createSpareRequest(data).then((response) => {
          SpareRequestService.getSparesRequest({
            empresa: enterpriseName,
          }).then((response) => {
            setSpareRequest(response.data);
          });
        });
        OrderService.getOrderById(row_id, {
          token: token,
          hash: hash,
          idUser: idUserStorage,
        }).then((response) => {
          let data = response.data.data;
          data.status = "Aguardando Material";
          data.dates.dateWaitMaterial = Date.now();
          data.responsibles.OwnerWaitMaterial = nameUser;
          data.token = token;
          data.idUser = idUserStorage;
          data.hash = hash;
          OrderService.updateOrder(data, row_id).then((response) => {
            OrderService.getOrders({
              empresa: enterpriseName,
              token: token,
              hash: hash,
              idUser: idUserStorage,
            }).then((sender) => {
              setOrder(sender.data.data);
              gotoPage(state.pageIndex);
            });
          });
        });
        swal("Informação enviada com sucesso!");
      }
    });
  };

  const incrementReserve = (reserve) => {
    setStateDisable(true);
    SpareService.getSpareById(reserve.spare_id, {
      token: token,
      hash: hash,
      idUser: idUserStorage,
    }).then((res) => {
      let reservas = res.data.data;
      if (reservas.quantidade - reservas.itens_reservados >= 1) {
        reservas.itens_reservados++;
        reservas.token = token;
        reservas.idUser = idUserStorage;
        reservas.hash = hash;
        SpareService.updateSpare(reservas, reserve.spare_id).then(
          (response) => {
            if (response.data) {
              SpareService.getSpares({
                empresa: enterpriseName,
              }).then((response) => {
                setSpare(response.data.data);
                localStorage.removeItem("TOKEN_KEY");
                localStorage.setItem("TOKEN_KEY", response.data.token);
              });
            }
          }
        );
        SpareReserveService.getSpareReserveById(reserve._id).then(
          (response) => {
            let sparesReserve = response.data;
            sparesReserve.disponiveis =
              reservas.quantidade - reservas.itens_reservados;
            sparesReserve.quantidade++;
            sparesReserve.custoTotal =
              sparesReserve.quantidade * sparesReserve.custoMaterial;
            SpareReserveService.updateSpareReserve(
              sparesReserve,
              reserve._id
            ).then((response) => {
              if (response.data) {
                SpareReserveService.getSparesReserve({
                  empresa: enterpriseName,
                }).then((response) => {
                  setCart(response.data);

                  OrderService.getOrderById(row_id, {
                    token: token,
                    hash: hash,
                    idUser: idUserStorage,
                  }).then((response) => {
                    let request = response.data.data;
                    let count = 0;
                    SpareReserveService.getSparesReserve({
                      ordem_id: row_id,
                    }).then((response) => {
                      const somaTotal = response.data.map((row, index) => {
                        return row.custoTotal;
                      });
                      for (let i = 0; i < somaTotal.length; i++) {
                        count += somaTotal[i];
                      }
                      request.custoCarrinho = count;
                      request.token = token;
                      request.idUser = idUserStorage;
                      request.hash = hash;

                      OrderService.updateOrder(request, row_id).then(
                        (response) => {
                          // setPriceGeneral(null)
                          setCustoCarrinho(0);
                          structureModal(row_id);
                          localStorage.removeItem("TOKEN_KEY");
                          localStorage.setItem(
                            "TOKEN_KEY",
                            response.data.token
                          );
                        }
                      );
                    });
                  });
                });
              }
            });
          }
        );
      }
    });
    structureModal(row_id);
  };

  const decrementReserve = (reserve) => {
    setStateDisable(true);
    SpareReserveService.getSpareReserveById(reserve._id).then((response) => {
      let sparesReserve = response.data;
      if (sparesReserve.quantidade > 1) {
        sparesReserve.custoTotal =
          sparesReserve.quantidade * sparesReserve.custoMaterial;
        SpareService.getSpareById(reserve.spare_id, {
          token: token,
          hash: hash,
          idUser: idUserStorage,
        }).then((res) => {
          let reservas = res.data.data;
          reservas.itens_reservados--;
          reservas.token = token;
          reservas.idUser = idUserStorage;
          reservas.hash = hash;
          SpareService.updateSpare(reservas, reserve.spare_id).then(
            (response) => {
              if (response.data) {
                SpareService.getSpares({
                  empresa: enterpriseName,
                  // token: token,
                  // hash: hash,
                  // idUser: idUserStorage,
                }).then((response) => {
                  setSpare(response.data.data);
                  localStorage.removeItem("TOKEN_KEY");
                  localStorage.setItem("TOKEN_KEY", response.data.token);
                });
              }
              sparesReserve.quantidade--;
              sparesReserve.disponiveis =
                reservas.quantidade - reservas.itens_reservados;
              sparesReserve.custoTotal =
                sparesReserve.quantidade * sparesReserve.custoMaterial;
              SpareReserveService.updateSpareReserve(
                sparesReserve,
                reserve._id
              ).then((response) => {
                if (response.data) {
                  SpareReserveService.getSparesReserve({
                    empresa: enterpriseName,
                  }).then((response) => {
                    setCart(response.data);

                    OrderService.getOrderById(row_id, {
                      token: token,
                      hash: hash,
                      idUser: idUserStorage,
                    }).then((response) => {
                      let request = response.data.data;
                      let count = 0;
                      SpareReserveService.getSparesReserve({
                        ordem_id: row_id,
                      }).then((response) => {
                        const somaTotal = response.data.map((row, index) => {
                          return row.custoTotal;
                        });
                        for (let i = 0; i < somaTotal.length; i++) {
                          count += somaTotal[i];
                        }
                        request.custoCarrinho = count;
                        request.token = token;
                        request.idUser = idUserStorage;
                        request.hash = hash;

                        OrderService.updateOrder(request, row_id).then(
                          (response) => {
                            // setPriceGeneral(null)
                            setCustoCarrinho(0);
                            structureModal(row_id);
                          }
                        );
                      });
                    });
                  });
                }
              });
            }
          );
        });
      }
    });
  };

  const StyledBadge = withStyles((theme) => ({
    badge: {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
    },
  }))(Badge);

  const [orderState, setOrderState] = useState("");
  const [equipamento, setEquipamento] = useState("");
  const [descricao, setDescricao] = useState("");
  const [local, setLocal] = useState("");
  const [responsavel, setResponsavel] = useState({ first_name: "" });
  const [nomeResponsavel, setNomeResponsavel] = useState(null);
  const [prazo, setPrazo] = useState("");
  const [duracao, setDuracao] = useState("");
  const [Inicio_programado, setInicio_programado] = useState();
  const [filterSpare, setFilterSpare] = useState();
  const [tipoOrdem, setTipoOrdem] = useState("");
  const [UsernameResponsavel, setUsernameResponsavel] = useState("");
  const [custoCarrinho, setCustoCarrinho] = useState();
  const [cost, setCost] = useState(0);
  // const [nomeExecutante, setNomeExecutante] = useState();
  const handleSubmit = (evt) => {
    evt.preventDefault();
  };

  const programar = async (evt) => {
    if (responsavel.first_name) {
      if (valueDialog === "Equipe própria") {
        if (start) {
          if (end) {
            let initialWork = moment(start);
            let finishedWork = moment(end);
            const comparedHoursWorked = moment(finishedWork).diff(
              moment(initialWork)
            );
            const totalHoursWoking = moment
              .duration(comparedHoursWorked)
              .asHours();
            if (totalHoursWoking < 0) {
              notifyGeneralModal(
                "warning",
                "Atenção!",
                "A data de início não pode ser maior que a data final!"
              );
              setErrorStart(true);
              setErrorEnd(true);
            } else {
              OrderService.getOrderById(row_id, {
                token: token,
                hash: hash,
                idUser: idUserStorage,
              }).then((response) => {
                setErrorStart(false);
                setErrorEnd(false);
                const costCart = custoCarrinho ? custoCarrinho : 0;
                let data = response.data?.data;
                data.dates.dateOrderProgrammed = Date.now();
                data.responsibles.OwnerOrderProgrammed = nameUser;
                data.responsavel = responsavel.first_name;
                data.start = start;
                data.end = end;
                data.prazo = end;
                data.provider = null;
                data.status = "Programada";
                data.usernameResponsavel = UsernameResponsavel;
                data.nomeExecutante = responsavel.first_name;
                data.token = token;
                data.idUser = idUserStorage;
                data.hash = hash;
                data.costOrder = costCart;
                data.performer = nameUser;
                OrderService.updateStatusOfOrder(row_id, data).then((res) => {
                  SpareReserveService.getSparesReserve({ ordem_id: row_id })
                    .then((response) => {
                      setPriceGeneral(response.data);
                      structureModal(row_id, "success");
                      setErrorStart(false);
                      setErrorEnd(false);
                    })
                    .catch((error) => console.error(error.response));
                  OrderService.getOrders({
                    empresa: enterpriseName,
                    token: token,
                    hash: hash,
                    idUser: idUserStorage,
                  }).then((sender) => {
                    setOrder(sender.data.data);
                    gotoPage(state.pageIndex);
                  });
                });
                localStorage.removeItem("TOKEN_KEY");
                localStorage.setItem("TOKEN_KEY", response.data.token);
              });
            }
          }
        } else {
          const costCart = custoCarrinho ? custoCarrinho : 0;
          OrderService.getOrderById(row_id).then((response) => {
            let data = response.data?.data;
            data.dates.dateOrderProgrammed = Date.now();
            data.responsibles.OwnerOrderProgrammed = nameUser;
            data.responsavel = responsavel.first_name;
            data.provider = null;
            data.status = "Programada";
            data.usernameResponsavel = UsernameResponsavel;
            data.nomeExecutante = responsavel.first_name;
            data.values.costService = parseFloat(
              costService?.toString()?.replace(",", ".")
            )?.toFixed(2);
            data.values.costMaterial = parseFloat(
              costMaterial?.toString()?.replace(",", ".")
            )?.toFixed(2);
            data.duracaoProgramada = 0;
            data.costOrder = costCart;
            data.performer = nameUser;
            OrderService.updateStatusOfOrder(row_id, data).then((res) => {
              SpareReserveService.getSparesReserve({ ordem_id: row_id })
                .then((response) => {
                  setPriceGeneral(response.data.data);
                  structureModal(row_id, "success");
                  setErrorStart(false);
                  setErrorEnd(false);
                  OrderService.getOrders({
                    empresa: enterpriseName,
                    token: token,
                    hash: hash,
                    idUser: idUserStorage,
                  }).then((sender) => {
                    setOrder(sender.data.data);
                    gotoPage(state.pageIndex);
                  });
                  localStorage.removeItem("TOKEN_KEY");
                  localStorage.setItem("TOKEN_KEY", response.data.token);
                })
                .catch((error) => console.error(error.response));
            });
          });
        }
      } else {
        let url;
        let filename;
        const formData = new FormData();
        if (pdf) {
          formData.append("pdf", pdf[0]);
          await axios({
            method: "post",
            url: "https://api.darwinx.com.br/pdfs",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then((response) => {
              url = response.data.url;
              filename = response.data.name;
            })
            .catch((error) => {
              console.error(error);
            });
          if (url) {
            if (start) {
              if (end) {
                let initialWork = moment(start);
                let finishedWork = moment(end);
                const comparedHoursWorked = moment(finishedWork).diff(
                  moment(initialWork)
                );
                const totalHoursWoking = moment
                  .duration(comparedHoursWorked)
                  .asHours();
                if (totalHoursWoking < 0) {
                  notifyGeneralModal(
                    "warning",
                    "Atenção!",
                    "A data de início não pode ser maior que a data final!"
                  );
                  setErrorStart(true);
                  setErrorEnd(true);
                } else {
                  OrderService.getOrderById(row_id, {
                    token: token,
                    hash: hash,
                    idUser: idUserStorage,
                  }).then((response) => {
                    let data = response.data.data;
                    const costCart = custoCarrinho ? custoCarrinho : 0;
                    const performer = duration;
                    data.dates.dateOrderProgrammed = Date.now();
                    data.responsibles.OwnerOrderProgrammed = nameUser;
                    data.responsavel = responsavel.first_name;
                    data.start = start;
                    data.end = end;
                    data.prazo = end;
                    data.provider = valueProvider;
                    data.status = "Programada";
                    data.usernameResponsavel = UsernameResponsavel;
                    data.performer = nameUser;
                    const costServiceWithoutDot = costService
                      .toString()
                      .replace(".", "");
                    data.values.costService = costServiceWithoutDot
                      .toString()
                      .replace(",", ".");
                    const costMaterialWithoutDot = costMaterial
                      .toString()
                      .replace(".", "");
                    data.values.costMaterial = costMaterialWithoutDot
                      .toString()
                      .replace(",", ".");
                    data.token = token;
                    data.idUser = idUserStorage;
                    data.hash = hash;
                    data.costOrder = costCart;
                    data.duracaoProgramada =
                      moment(end).format("HH") - moment(start).format("HH");
                    data.annex = url;
                    data.annexName = filename;
                    OrderService.updateStatusOfOrder(row_id, data)
                      .then((res) => {
                        SpareReserveService.getSparesReserve({
                          ordem_id: row_id,
                        })
                          .then((response) => {
                            setPriceGeneral(response.data);
                            structureModal(row_id, "success");
                            setErrorStart(false);
                            setErrorEnd(false);
                          })
                          .catch((error) => console.error(error.response));
                        OrderService.getOrders({
                          empresa: enterpriseName,
                          token: token,
                          hash: hash,
                          idUser: idUserStorage,
                        }).then((sender) => {
                          setOrder(sender.data.data);
                          gotoPage(state.pageIndex);
                          localStorage.removeItem("TOKEN_KEY");
                          localStorage.setItem("TOKEN_KEY", sender.data.token);
                        });
                      })
                      .catch((error) => {
                        console.error(error);
                      });
                  });
                }
              }
            } else {
              const costCart = custoCarrinho ? custoCarrinho : 0;
              const performer = duration;
              let orders = {
                values: {
                  costMaterial: parseFloat(
                    costMaterial?.toString()?.replace(",", ".")
                  )?.toFixed(2),
                  costService: parseFloat(
                    costService?.toString()?.replace(",", ".")
                  )?.toFixed(2),
                },
                costOrder: costCart,
                responsavel: nomeResponsavel,
                usernameResponsavel: UsernameResponsavel,
                token: token,
                hash: hash,
                idUser: idUserStorage,
              };
              OrderService.updateOrder(orders, row_id).then((res) => {
                SpareReserveService.getSparesReserve({ ordem_id: row_id })
                  .then((response) => {
                    setPriceGeneral(response.data);
                    structureModal(row_id, "success");
                    setErrorStart(false);
                    setErrorEnd(false);
                    OrderService.getOrders({
                      empresa: enterpriseName,
                      token: token,
                      hash: hash,
                      idUser: idUserStorage,
                    }).then((sender) => {
                      setOrder(sender.data.data);
                      gotoPage(state.pageIndex);
                      localStorage.removeItem("TOKEN_KEY");
                      localStorage.setItem("TOKEN_KEY", sender.data.token);
                    });
                  })
                  .catch((error) => console.error(error.response));
              });
            }
          }
        } else {
          if (start) {
            if (end) {
              let initialWork = moment(start);
              let finishedWork = moment(end);
              const comparedHoursWorked = moment(finishedWork).diff(
                moment(initialWork)
              );
              const totalHoursWoking = moment
                .duration(comparedHoursWorked)
                .asHours();
              if (totalHoursWoking < 0) {
                notifyGeneralModal(
                  "warning",
                  "Atenção!",
                  "A data de início não pode ser maior que a data final!"
                );
                setErrorStart(true);
                setErrorEnd(true);
              } else {
                OrderService.getOrderById(row_id, {
                  token: token,
                  hash: hash,
                  idUser: idUserStorage,
                }).then((response) => {
                  let data = response.data.data;
                  const costCart = custoCarrinho ? custoCarrinho : 0;
                  const performer = duration;
                  data.dates.dateOrderProgrammed = Date.now();
                  data.responsibles.OwnerOrderProgrammed = nameUser;
                  data.responsavel = responsavel.first_name;
                  data.start = start;
                  data.end = end;
                  data.prazo = end;
                  data.provider = valueProvider;
                  data.status = "Programada";
                  data.usernameResponsavel = UsernameResponsavel;
                  data.performer = nameUser;
                  const costServiceWithoutDot = costService
                    .toString()
                    .replace(".", "");
                  data.values.costService = costServiceWithoutDot
                    .toString()
                    .replace(",", ".");
                  const costMaterialWithoutDot = costMaterial
                    .toString()
                    .replace(".", "");
                  data.values.costMaterial = costMaterialWithoutDot
                    .toString()
                    .replace(",", ".");
                  data.token = token;
                  data.idUser = idUserStorage;
                  data.hash = hash;
                  data.costOrder = costCart;
                  data.duracaoProgramada =
                    moment(end).format("HH") - moment(start).format("HH");
                  OrderService.updateStatusOfOrder(row_id, data)
                    .then((res) => {
                      SpareReserveService.getSparesReserve({ ordem_id: row_id })
                        .then((response) => {
                          setPriceGeneral(response.data);
                          structureModal(row_id, "success");
                          setErrorStart(false);
                          setErrorEnd(false);
                        })
                        .catch((error) => console.error(error.response));
                      OrderService.getOrders({
                        empresa: enterpriseName,
                        token: token,
                        hash: hash,
                        idUser: idUserStorage,
                      }).then((sender) => {
                        setOrder(sender.data.data);
                        gotoPage(state.pageIndex);
                        localStorage.removeItem("TOKEN_KEY");
                        localStorage.setItem("TOKEN_KEY", sender.data.token);
                      });
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                });
              }
            }
          } else {
            const costCart = custoCarrinho ? custoCarrinho : 0;
            const performer = duration;
            let orders = {
              values: {
                costMaterial: parseFloat(
                  costMaterial?.toString()?.replace(",", ".")
                )?.toFixed(2),
                costService: parseFloat(
                  costService?.toString()?.replace(",", ".")
                )?.toFixed(2),
              },
              costOrder: costCart,
              responsavel: nomeResponsavel,
              usernameResponsavel: UsernameResponsavel,
              token: token,
              hash: hash,
              idUser: idUserStorage,
            };
            OrderService.updateOrder(orders, row_id).then((res) => {
              SpareReserveService.getSparesReserve({ ordem_id: row_id })
                .then((response) => {
                  setPriceGeneral(response.data);
                  structureModal(row_id, "success");
                  setErrorStart(false);
                  setErrorEnd(false);
                  OrderService.getOrders({
                    empresa: enterpriseName,
                    token: token,
                    hash: hash,
                    idUser: idUserStorage,
                  }).then((sender) => {
                    setOrder(sender.data.data);
                    gotoPage(state.pageIndex);
                    localStorage.removeItem("TOKEN_KEY");
                    localStorage.setItem("TOKEN_KEY", sender.data.token);
                  });
                })
                .catch((error) => console.error(error.response));
            });
          }
        }
      }
    } else {
      notifyGeneralModal("warning", "Atenção!", "O executante é obrigatório!");
    }
  };

  const notifyCode = (event, title, content) => {
    let options = {
      place: "tc",
      message: (
        <div>
          <h2
            style={{ color: "#fff", marginBottom: "0" }}
            className="alert-title"
            data-notify="title"
          >
            {" "}
            {title ? title : "Atenção!"}
            <br />
          </h2>
          <span data-notify="message">
            {content ? content : "Este código já foi cadastrado!"}
          </span>
        </div>
      ),
      type: event,
      // icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    notification.current.notificationAlert(options);
  };

  const update = async (evt) => {
    let url;
    let filename;
    if (image) {
      if (image[0].size > 1024 * 1024 * 4) {
        notifyCode(
          "warning",
          "Atenção!",
          "O tamanho da imagem é muito grande!"
        );
        return;
      }
      OrderService.getOrderById(row_id, {
        token: token,
        hash: hash,
        idUser: idUserStorage,
      }).then(async (response) => {
        const res = response.data.data;
        if (res.foto) {
          const s3 = new AWS.S3({
            accessKeyId: "AKIAVSM7L4TZDZIH7KGO",
            region: "sa-east-1",
            secretAccessKey: "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",
          });
          s3.deleteObject(
            { Key: response.data.nomeFoto, Bucket: "darwinxperfil" },
            function (err, data) {}
          );
          axios
            .delete("https://api.darwinx.com.br/images", {
              params: { url: response.data.foto },
            })
            .then(async (response) => {
              if (response.data) {
                const formData = new FormData();
                formData.append("image", image[0]);
                await axios({
                  method: "post",
                  url: "https://api.darwinx.com.br/images",
                  data: formData,
                  headers: { "Content-Type": "multipart/form-data" },
                })
                  .then((response) => {
                    url = response.data.url;
                    filename = response.data.name;
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              }
              if (url) {
                let orders = {
                  equipamento: equipamento,
                  descricao: descricao,
                  local: local,
                  responsavel: nomeResponsavel,
                  prazo: new Date(prazo),
                  duracao: duracao,
                  start: Inicio_programado,
                  prioridade: rSelected || "Baixa",
                  end: end,
                  color: color,
                  tipo: tipoOrdem,
                  foto: url,
                  nomeFoto: filename,
                  token: token,
                  hash: hash,
                  idUser: idUserStorage,
                  usernameResponsavel: UsernameResponsavel,
                };
                OrderService.updateOrder(orders, row_id)
                  .then((res) => {
                    structureModal(row_id, "success");
                  })
                  .catch((error) => console.error(error.response));
              }
            });
        } else {
          const formData = new FormData();
          formData.append("image", image[0]);
          await axios({
            method: "post",
            url: "https://api.darwinx.com.br/images",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then((response) => {
              url = response.data.url;
              filename = response.data.name;
            })
            .catch((error) => {
              console.error(error);
            });
        }
        if (url) {
          let orders = {
            equipamento: equipamento,
            descricao: descricao,
            local: local,
            responsavel: nomeResponsavel,
            prazo: prazo,
            duracao: duracao,
            start: Inicio_programado,
            prioridade: rSelected || "Baixa",
            end: end,
            color: color,
            tipo: tipoOrdem,
            foto: url,
            nomeFoto: filename,
            token: token,
            hash: hash,
            idUser: idUserStorage,
            usernameResponsavel: UsernameResponsavel,
          };
          OrderService.updateOrder(orders, row_id)
            .then((res) => {
              // setModal(!modal);
              // window.location.reload();
              structureModal(row_id, "success");
            })
            .catch((error) => console.error(error.response));
        }
      });
    } else {
      let orders = {
        equipamento: equipamento,
        descricao: descricao,
        local: local,
        responsavel: nomeResponsavel,
        prazo: prazo,
        duracao: duracao,
        start: Inicio_programado,
        prioridade: rSelected || "Baixa",
        end: end,
        color: color,
        tipo: tipoOrdem,
        token: token,
        hash: hash,
        idUser: idUserStorage,
        usernameResponsavel: UsernameResponsavel,
      };
      OrderService.updateOrder(orders, row_id)
        .then((res) => {
          structureModal(row_id, "success");
        })
        .catch((error) => console.error(error.response));
    }
    SpareReserveService.getSparesReserve({ ordem_id: row_id }).then(
      (response) => {
        setPriceGeneral(response.data);
      }
    );
  };
  const [priceGeneral, setPriceGeneral] = useState();
  const [equipmentDescription, setEquipmentDescription] = useState("");
  const [equipmentCode, setEquipmentCode] = useState();
  const [markerInitial, setMarkerInitial] = useState();
  const [markerFinished, setMarkerFinished] = useState();
  const [materialProvided, setMaterialProvided] = useState([]);
  const [typeOrder, setTypeOrder] = useState("");
  const [userId, setUserId] = useState(() => {
    const id = localStorage.getItem("TOKEN_ID_USER");
    return id;
  });

  const AutocompleteComponent = memo(({ responsavel }) => {
    return (
      <div className="form-group">
        <Autocomplete
          disabled={
            userType === "Administrador" || userType === "Corpo Técnico"
              ? false
              : stateCompletedOrder
          }
          id="free-solo-demo"
          freeSolo
          value={responsavel ?? { first_name: "" }}
          onChange={(e, v) => {
            if (v) {
              setResponsavel({ first_name: v.first_name });
              setNomeResponsavel(v?.first_name);
              setUsernameResponsavel(v?.username);
            }
          }}
          options={users}
          getOptionLabel={(option) => option.first_name}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Executante"
              margin="normal"
              variant="outlined"
              // onChange={(e) => {
              //   setResponsavel(e.target.value);
              // }}
            />
          )}
        />
      </div>
    );
  });

  const [stateMaterialProvided, setStateMaterialProvided] = useState("");

  const [sourceOrder, setSourceOrder] = useState({});

  function linhaClicada(source) {
    const row = source.data._id;
    setRow_id(row);
    setModal(true);
    setFilterSpare(null);

    SpareServiceMoviment.getSparesMoviment({
      empresa: enterpriseName,
      ordemResponsavel: row,
    }).then((response) => {
      setMaterialProvided(response.data);
    });

    SpareReserveService.getSparesReserve({ ordem_id: row }).then((response) => {
      setPriceGeneral(response.data);
    });

    OrderService.getOrderById(row, {
      token: token,
      hash: hash,
      idUser: idUserStorage,
    })
      .then((res) => {
        let orders = res.data.data;
        setCost(orders.custoCarrinho);
        setHistoric(orders.historic ?? []);

        if (orders.tipo === "Plano") {
          const adapterEquipment = !orders.equipmentsPlan
            ? [
                {
                  value: orders.equipamento,
                  label:
                    orders.equipamento + " - " + orders.equipamento_denominacao,
                },
              ]
            : orders.equipmentsPlan;
          setEquipmentsSelect(adapterEquipment);
        }

        setSourceOrder(orders);
        setEquipmentArea(orders?.equipmentArea);
        if (orders.tipo === "Plano") {
          setStructureCheckbox(orders.checkboxes);
        }
        if (orders.provider) {
          setValueDialog("Fornecedor");
        } else {
          setValueDialog("Equipe própria");
        }
        setStateMaterialProvided(orders.stateMaterialProvided);
        setTypeOrder(orders.tipo);
        if (orders.reOpenOrder) {
          setCompletedOrder(true);
        } else {
          setCompletedOrder(false);
        }

        if (orders.status === "Concluída") {
          setStateCompletedOrder(true);
        } else {
          setStateCompletedOrder(false);
        }
        if (orders.latitude.initial) {
          setMarkerInitial({
            lat: parseFloat(orders.latitude.initial),
            lng: parseFloat(orders.longitude.initial),
          });
        }
        if (orders.latitude.finished) {
          setMarkerFinished({
            lat: parseFloat(orders.latitude.finished),
            lng: parseFloat(orders.longitude.finished),
          });
        }
        setStateBtn(orders.status);
        setOrderState(orders.id); //equipments.tag
        setEquipamento(
          orders.equipamento
            ? orders.equipamento
            : "" + " - " + orders.equipamento_denominacao
            ? orders.equipamento_denominacao
            : ""
        );
        setDescricao(orders.descricao);
        setEquipmentDescription(orders.equipamento_denominacao);
        setEquipmentCode(orders.equipamento);
        setLocal(orders.local);
        setMaquina(orders.maquina_parou);
        setMachineStop(orders.quandoMaquinaParou);
        if (orders.end && orders.start) {
          setDuration(
            moment(orders.end).format("HH") - moment(orders.start).format("HH")
          );
        } else {
          setDuration(0);
        }
        if (orders.custoCarrinho) {
          setCustoCarrinho(orders.custoCarrinho);
        } else {
          setCustoCarrinho(0);
        }
        setCostMaterial(
          orders.values?.costMaterial?.toFixed(2)?.toString().replace(".", ",")
        );
        setCostService(
          orders.values?.costService?.toFixed(2)?.toString().replace(".", ",")
        );
        if (orders.usernameResponsavel) {
          if (orders.responsavel) {
            setNomeResponsavel(orders.responsavel);
          }
          setUsernameResponsavel(orders.usernameResponsavel);
          setResponsavel({
            first_name: orders.responsavel,
            // hash: Math.random() * 100,
          });
        } else {
          setResponsavel({});
        }
        setDates(orders.dates);
        // setDescricaoNotExecuted(orders.descricoes?.descricao);
        // setMotivo(orders.descricoes?.descricao_motivo);
        setDescriptions(orders.descricoes);
        setPrazo(orders.prazo);
        setDuracao(orders.duracao);
        // setInicio_programado(moment(orders.Inicio_programado).locale('pt-br'));
        setRSelected(orders.prioridade);
        // setStart(orders.start);
        setResponsibles(orders.responsibles);

        if (orders.start) {
          setStart(new Date(orders.start));
          // setStart(moment(orders.start).format("YYYY-MM-DDTHH:mm"));
        } else {
          setStart(null);
        }
        if (orders.end) {
          setEnd(new Date(orders.end));
          // setEnd(moment(orders.end).format("YYYY-MM-DDTHH:mm"));
        } else {
          setEnd(null);
        }
        //{lat:"-7.92978092756435", lng: "-35.03081520432577"}
        if (orders.latitude && orders.longitude) {
          setFirstLocation({
            lat: orders.latitude?.initial,
            lng: orders.longitude?.initial,
          });
          setFirstLocation({
            lat: orders.latitude?.finished,
            lng: orders.longitude?.finished,
          });
        }
        setMaquina(orders.maquina_parou);
        setTipoOrdem(orders.tipo);
        setStatus(orders.status);
        if (orders.nomeFoto) {
          try {
            const myBucket = "darwinxperfil";
            const myKey = res.data.data.nomeFoto;
            const signedUrlExpireSeconds = 60 * 1;
            const s3 = new AWS.S3({
              accessKeyId: "AKIAVSM7L4TZDZIH7KGO",
              region: "sa-east-1",
              secretAccessKey: "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",
            });

            const url = s3.getSignedUrl("getObject", {
              Bucket: myBucket,
              Key: myKey,
              Expires: signedUrlExpireSeconds,
            });

            setShowImage(url);
          } catch (error) {
            console.error(error);
          }
        } else {
          setShowImage(null);
        }
        localStorage.removeItem("TOKEN_KEY");
        localStorage.setItem("TOKEN_KEY", res.data.token);
      })
      .catch((error) => {
        // if (error?.response?.data?.auth) {
        // } else {
        //   redirectLogin()
        // }
      });
  }

  const [open, setOpen] = React.useState(false);
  const [valueDialog, setValueDialog] = useState("Equipe própria");

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleCloseDialog = (newValue) => {
    setOpen(false);

    if (newValue) {
      setValueDialog(newValue);
    }
  };
  const classes = useStyles();
  const classes_bar = useStyles_bar();

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const steps = getSteps();

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  useEffect(() => {
    const getVerifyUserOrderPrint = async () => {
      await UserService.getUserById(user).then((user) => {
        setPrintOrder(user.data?.printOrder);
      });
    };
    getVerifyUserOrderPrint();
    UserService.getUsers({ empresa: enterpriseName })
      .then((res) => {
        setUsers(res.data);
        const filterPerformer = res.data.filter(
          (user) => user.level === "Executante"
        );
        setPerformerGeneral(filterPerformer);
      })
      .catch((error) => {
        console.error(error);
        // if (error?.response?.data?.auth) {

        // } else {
        //   redirectLogin()
        // }
      });
    SpareRequestService.getSparesRequest({ empresa: enterpriseName }).then(
      (response) => {
        setSpareRequest(response.data.data);
      }
    );
    OrderService.getOrders({
      empresa: enterpriseName,
      token: token,
      hash: hash,
      idUser: idUserStorage,
    })
      .then((res) => {
        setOrderTemplate(res.data.data);
        setOrder(res.data.data);
        localStorage.removeItem("TOKEN_KEY");
        localStorage.setItem("TOKEN_KEY", res.data.token);
      })
      .catch((error) => {
        if (error?.response?.data?.auth) {
        } else {
          // redirectLogin();
        }
      });
    ProvidersService.getFornecedores({
      empresa: enterpriseName,
      token: token,
      hash: hash,
      idUser: idUserStorage,
    })
      .then((response) => {
        setProvider(response.data.data);
      })
      .catch((error) => {
        if (error?.response?.data?.auth) {
        } else {
          // redirectLogin();
        }
      });
  }, [enterpriseName, token, hash, idUserStorage, user]);

  const cellcolor = {
    color: "#1193EF",
    marginRight: "0.2em",
    width: "19px",
    // zIndex: 2,
    // position: 'relative',
  };

  //Estilo dos botoes de filtrar colunas
  const PurpleSwitch = withStyles({
    switchBase: {
      color: "#58a3fb",
      "&$checked": {
        color: "#58a3fb",
      },
      "&$checked + $track": {
        backgroundColor: "#58a3fb",
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [modalPrint, setModalPrint] = useState(false);

  const [equipmentsSelect, setEquipmentsSelect] = useState([]);

  const [equipments, setEquipments] = useState([]);

  useEffect(() => {
    EquipmentService.getEquipment({ empresa: enterpriseName }).then(
      (response) => {
        setEquipments(response.data?.data);
      }
    );
  }, []);

  const adapterEquipmentSource = useCallback(() => {
    return equipments.map((equipment) => {
      return {
        value: equipment?.codigo,
        label: equipment?.codigo + " - " + equipment?.denominacao,
      };
    });
  }, [equipments]);

  useEffect(() => {
    setEquipmentsOptions(adapterEquipmentSource());
  }, [equipments]);

  const [equipmentsOptions, setEquipmentsOptions] = useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <FormGroup>
                  {userType === "Administrador" ||
                  userType === "Corpo Técnico" ? (
                    <>
                      {stateBtn === "Concluída" ? (
                        <FormControlLabel
                          onChange={openAgainOrder}
                          control={<Switch checked={false} color="primary" />}
                          label="Reabrir ordem"
                        />
                      ) : (
                        <FormControlLabel
                          onChange={toggleModalSelectComplete}
                          control={<Switch checked={false} color="primary" />}
                          label="Fechar ordem"
                        />
                      )}
                    </>
                  ) : null}
                </FormGroup>
              </div>
              {sourceOrder.tipo === "Plano" ? (
                <Select
                  isDisabled
                  isMulti
                  label={"Equipamentos"}
                  placeholder="Selecione os equipamentos"
                  value={equipmentsSelect}
                  options={equipmentsOptions}
                  onChange={(e) => {
                    setEquipmentsSelect(e);
                  }}
                />
              ) : (
                <div className="form-group">
                  <TextField
                    id="outlined-basic"
                    label="Equipamento"
                    variant="outlined"
                    name="equipamento"
                    value={equipamento}
                    disabled={true}
                    onChange={(e) => setEquipamento(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </div>
              )}

              <div className="form-group">
                <TextField
                  id="outlined-basic"
                  label="Descrição"
                  variant="outlined"
                  name="descricao"
                  value={descricao}
                  multiline
                  disabled={stateCompletedOrder | (typeOrder === "Plano")}
                  rows={4}
                  onChange={(e) => setDescricao(e.target.value)}
                  style={{ width: "100%" }}
                />
              </div>
              {typeOrder === "Plano" && (
                <div
                  style={{ padding: "1em", paddingTop: 0 }}
                  className="form-group"
                >
                  {structureCheckbox.map((checkbox, index) => {
                    return (
                      <CheckboxUI
                        key={index}
                        state={index}
                        check={checkbox.state}
                        description={checkbox.description}
                      />
                    );
                  })}
                </div>
              )}
              {!!tipoOrdem && (
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Col>
                    <div style={{ marginTop: "1em" }} className="form-group">
                      {!!tipoOrdem ? (
                        <>
                          <label> Tipo de Ordem: </label>
                          {tipoOrdem === "Preventiva" && (
                            <select
                              className="form-control select2 select2-hidden-accessible"
                              tabIndex="-1"
                              aria-hidden="true"
                              disabled={stateCompletedOrder}
                              onChange={(e) => {
                                setTipoOrdem(e.target.value);
                              }}
                            >
                              <option value="" disabled>
                                Selecione uma opção
                              </option>
                              <option selected>Preventiva</option>
                              <option>Corretiva</option>
                              <option>Preditiva</option>
                              <option>Melhoria</option>
                            </select>
                          )}
                          {tipoOrdem === "Corretiva" && (
                            <select
                              className="form-control select2 select2-hidden-accessible"
                              tabIndex="-1"
                              aria-hidden="true"
                              disabled={stateCompletedOrder}
                              onChange={(e) => {
                                setTipoOrdem(e.target.value);
                              }}
                            >
                              <option value="" disabled>
                                Selecione uma opção
                              </option>
                              <option>Preventiva</option>
                              <option selected>Corretiva</option>
                              <option>Preditiva</option>
                              <option>Melhoria</option>
                            </select>
                          )}
                          {tipoOrdem === "Preditiva" && (
                            <select
                              className="form-control select2 select2-hidden-accessible"
                              tabIndex="-1"
                              aria-hidden="true"
                              disabled={stateCompletedOrder}
                              onChange={(e) => {
                                setTipoOrdem(e.target.value);
                              }}
                            >
                              <option value="" disabled>
                                Selecione uma opção
                              </option>
                              <option>Preventiva</option>
                              <option>Corretiva</option>
                              <option selected>Preditiva</option>
                              <option>Melhoria</option>
                            </select>
                          )}
                          {tipoOrdem === "Melhoria" && (
                            <select
                              className="form-control select2 select2-hidden-accessible"
                              tabIndex="-1"
                              aria-hidden="true"
                              disabled={stateCompletedOrder}
                              onChange={(e) => {
                                setTipoOrdem(e.target.value);
                              }}
                            >
                              <option value="" disabled>
                                Selecione uma opção
                              </option>
                              <option>Preventiva</option>
                              <option>Corretiva</option>
                              <option>Preditiva</option>
                              <option selected>Melhoria</option>
                            </select>
                          )}
                        </>
                      ) : (
                        <>
                          <label> Tipo de Ordem: </label>
                          <select
                            className="form-control select2 select2-hidden-accessible"
                            tabIndex="-1"
                            aria-hidden="true"
                            disabled={stateCompletedOrder}
                            onChange={(e) => {
                              setTipoOrdem(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Selecione uma opção
                            </option>
                            <option>Preventiva</option>
                            <option>Corretiva</option>
                            <option>Preditiva</option>
                            <option>Melhoria</option>
                          </select>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              )}

              <div className="form-group">
                <label> Prioridade: </label> <br></br>
                <ButtonGroup disabled={stateCompletedOrder} className="mb-4">
                  <Button
                    color="default"
                    disabled={stateCompletedOrder}
                    onChange={() => setRSelected("Nenhuma")}
                    outline
                    onClick={() => {
                      setRSelected("Nenhuma");
                      setColor("#7BF7CD");
                    }}
                    active={rSelected === "Nenhuma"}
                  >
                    Nenhuma
                  </Button>
                  <Button
                    color="default"
                    disabled={stateCompletedOrder}
                    onChange={() => setRSelected("Baixa")}
                    outline
                    onClick={() => {
                      setRSelected("Baixa");
                      setColor("#0091F7");
                    }}
                    active={rSelected === "Baixa"}
                  >
                    {" "}
                    &nbsp; &nbsp; Baixa &nbsp; &nbsp;{" "}
                  </Button>
                  <Button
                    color="default"
                    disabled={stateCompletedOrder}
                    onChange={() => setRSelected("Média")}
                    outline
                    onClick={() => {
                      setRSelected("Média");
                      setColor("#ffc400");
                    }}
                    active={rSelected === "Média"}
                  >
                    {" "}
                    &nbsp; &nbsp; Média &nbsp; &nbsp;
                  </Button>
                  <Button
                    color="default"
                    disabled={stateCompletedOrder}
                    onChange={() => setRSelected("Alta")}
                    outline
                    onClick={() => {
                      setRSelected("Alta");
                      setColor("#dc4664");
                    }}
                    active={rSelected === "Alta"}
                  >
                    {" "}
                    &nbsp; &nbsp; Alta &nbsp; &nbsp;{" "}
                  </Button>
                </ButtonGroup>
              </div>

              <FormControl style={{ display: "block" }} component="fieldset">
                <FormLabel component="legend">O equipamento parou?</FormLabel>
                <Row>
                  <Col>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      defaultValue="top"
                    >
                      <FormControlLabel
                        control={<Radio color="primary" />}
                        label="Sim"
                        disabled={true}
                        checked={maquina === "Sim"}
                        onChange={(e, v) => {
                          if (v) {
                            setMaquina("Sim");
                          }
                        }}
                      />
                      <FormControlLabel
                        control={<Radio color="primary" />}
                        label="Não"
                        disabled={true}
                        checked={maquina === "Não"}
                        onChange={(e, v) => {
                          if (v) {
                            setMaquina("Não");
                          }
                        }}
                      />
                    </RadioGroup>
                  </Col>
                  <Col>
                    {maquina === "Sim" && (
                      <DateTimePicker
                        disable={true}
                        placeholder="Quando parou?"
                        date={machineStop}
                        setDate={setMachineStop}
                      />
                    )}
                  </Col>
                </Row>
              </FormControl>

              <div className="form-group">
                <label> Foto: </label>
                {showImage && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img style={{ width: "70%" }} src={showImage} alt="" />
                  </div>
                )}
                <input
                  type="file"
                  placeholder="Foto"
                  name="file"
                  disabled={stateCompletedOrder}
                  className="form-control"
                  accept="image/png, image/jpeg"
                  onChange={(e) => setImage(e.target.files)}
                />
              </div>
            </form>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {userType !== "Executante" && userType !== "Requisitante" ? (
                <div>
                  <Button color="success" onClick={update}>
                    Atualizar
                  </Button>{" "}
                  <Button
                    color="secondary"
                    onClick={() => {
                      toggle();
                      setResponsavel({});
                    }}
                  >
                    Cancelar
                  </Button>
                </div>
              ) : null}
              {printOrder && sourceOrder.status === "Concluída" && (
                <>
                  {(userType === "Administrador" ||
                    userType === "Corpo Técnico") && (
                    <ModalPrint
                      modal={modalPrint}
                      orderId={row_id}
                      setModal={setModalPrint}
                      userName={nameUser}
                      userType={userType}
                    />
                  )}
                </>
              )}
            </div>
          </ModalBody>
        );
      case 1:
        return (
          <ModalBody className="">
            <h4>Resumo da Ordem </h4>
            <Card className="shadow-lg">
              <CardBody>
                <Row>
                  <Col>
                    {" "}
                    <h4> Equipamento:</h4>
                    <span className="mb-0 text-sm">{equipamento}</span>
                    <br></br> <br></br>
                    <h4> Descrição:</h4>
                    <span className="mb-0 text-sm">{descricao}</span>
                  </Col>

                  <Col className="col-auto">
                    <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                      <i className="ni ni-settings" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <br></br>
            <Row>
              <Col>
                <h4> Materiais disponíveis em estoque </h4>
              </Col>
              <Col className="col-auto">
                {" "}
                <IconButton
                  disabled={
                    userType === "Administrador" ? false : stateCompletedOrder
                  }
                  aria-label="cart"
                >
                  <StyledBadge
                    badgeContent={
                      cart &&
                      cart.filter((res) => res.ordem_id === row_id).length
                    }
                    onClick={() => {
                      toggle_cart();
                      const response = cart.filter(
                        (res) => res.ordem_id === row_id
                      );
                      const idSpares = response.map((res) => {
                        return { _id: res.spare_id };
                      });
                    }}
                    color="secondary"
                  >
                    <ShoppingCartIcon />
                  </StyledBadge>
                </IconButton>
              </Col>
            </Row>

            <Grid container spacing={1} alignItems="flex-end">
              <Grid item>
                <SearchIcon />
              </Grid>
              <Grid item>
                <TextField
                  disabled={stateCompletedOrder}
                  id="input-with-icon-grid"
                  label="Buscar material"
                  onChange={(e) => {
                    const result = e.target.value.toLowerCase();
                    const spareAux = spare;
                    if (!e.target.value) {
                      setFilterSpare(null);
                    } else {
                      const filter = spareAux.filter((response) => {
                        // if(response.part_number == e.target.value || response.produto == e.target.value){
                        //   return true
                        // }
                        if (response?.produto?.toLowerCase().includes(result)) {
                          return true;
                        }
                        if (
                          response?.fornecedores?.toLowerCase().includes(result)
                        ) {
                          return true;
                        }
                        if (
                          response?.part_number?.toLowerCase().includes(result)
                        ) {
                          return true;
                        }
                        return false;
                      });
                      setFilterSpare(filter);
                    }
                  }}
                />
              </Grid>
            </Grid>

            {/*
            <Paper component="form" className={classes_bar.root}>
              <InputBase
                className={classes_bar.input}
                placeholder="Buscar Material, Fornecedor ou Part Number"
                inputProps={{ "aria-label": "search google maps" }}
              />
              <IconButton
                type="submit"
                className={classes_bar.iconButton}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
              <Divider className={classes_bar.divider} orientation="vertical" />
            </Paper>
            */}

            <Card className="shadow-lg">
              <CardBody>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Produto</th>
                      <th scope="col">Fornecedor</th>
                      <th scope="col">Descrição</th>
                      <th scope="col">Reserva</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {filterSpare &&
                      filterSpare.map((row, index) => (
                        <tr
                          style={{ cursor: "pointer", whiteSpace: "pre-wrap" }}
                          key={index}
                        >
                          <td
                            style={{
                              whiteSpace: "pre-wrap",
                              wordWrap: "break-word",
                            }}
                          >
                            {row.produto}
                          </td>
                          <td
                            style={{
                              whiteSpace: "pre-wrap",
                              wordWrap: "break-word",
                            }}
                          >
                            {row.fornecedores}
                          </td>
                          <td
                            style={{
                              maxWidth: "200px",
                              whiteSpace: "pre-wrap",
                              maxHeight: "200px",
                              wordWrap: "break-word",
                            }}
                          >
                            {row.descricao}
                          </td>
                          {/*
                          <th scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {row.produto}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          
                          <th scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {row.fornecedores}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <th scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {row.part_number}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <th scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {row.quantidade}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          */}
                          <th>
                            {row.quantidade - row.itens_reservados > 0 ? (
                              <IconButton aria-label="cart">
                                <StyledBadge
                                  color="secondary"
                                  onClick={() => reserve(row)}
                                >
                                  <AddShoppingCartIcon />
                                </StyledBadge>
                              </IconButton>
                            ) : (
                              <IconButton aria-label="cart">
                                <StyledBadge
                                  color="secondary"
                                  onClick={() => {
                                    waitMaterial(row);
                                  }}
                                >
                                  <RemoveShoppingCartOutlinedIcon color="error" />
                                </StyledBadge>
                              </IconButton>
                            )}
                          </th>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </ModalBody>
        );
      case 2:
        return (
          <ModalBody>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "-3em",
              }}
            >
              <BoxMui
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                <List component="div" role="group">
                  <ListItem
                    button
                    divider
                    aria-haspopup="true"
                    aria-controls="ringtone-menu"
                    aria-label="phone ringtone"
                    onClick={handleClickListItem}
                  >
                    <ListItemText
                      primary="Esta ordem será executada por:"
                      secondary={valueDialog}
                    />
                  </ListItem>
                  <DialogListOrder
                    id="ringtone-menu"
                    keepMounted
                    open={open}
                    value={valueDialog}
                    onClose={handleCloseDialog}
                  />
                </List>
              </BoxMui>
            </div>
            {valueDialog === "Equipe própria" ? (
              <>
                <div className="form-group">
                  <form onSubmit={handleSubmit}>
                    <AutocompleteComponent responsavel={responsavel} />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div>
                        <h4> Início programado:</h4>
                        <DateTimePicker
                          date={start}
                          setDate={setStart}
                          placeholder="Início programado"
                        />
                      </div>
                      <div style={{ marginLeft: "5em" }}>
                        <h4> Fim programado:</h4>
                        <DateTimePicker
                          date={end}
                          setDate={setEnd}
                          placeholder="Fim programado"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <h4>Custos da ordem Nº{orderState}</h4>
                <Card className="shadow-lg">
                  <CardBody>
                    <Row>
                      <Col>
                        <Table responsive style={{ tableLayout: "fixed" }}>
                          <thead>
                            <tr>
                              <th style={{ width: "40%" }}>Item</th>
                              <th style={{ textAlign: "center" }}>
                                Quantidade
                              </th>
                              <th style={{ textAlign: "center" }}>Unitário</th>
                              <th style={{ textAlign: "center" }}>Subtotal</th>
                            </tr>
                          </thead>
                          <tbody>
                            {priceGeneral &&
                              priceGeneral.map((response) => {
                                return (
                                  <tr>
                                    <th>{response.produto}</th>
                                    <th style={{ textAlign: "center" }}>
                                      {response.quantidade}
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                      R${" "}
                                      {response.custoMaterial
                                        ?.toFixed(2)
                                        ?.replace(".", ",")}
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                      R${" "}
                                      {(
                                        response.custoMaterial *
                                        response.quantidade
                                      )
                                        ?.toFixed(2)
                                        .toString()
                                        ?.replace(".", ",")}
                                    </th>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginRight: "1.7em",
                          }}
                        >
                          <div style={{ borderTop: "2px solid #012" }}>
                            <label
                              style={{
                                fontSize: 14,
                                fontWeight: 700,
                                marginRight: "3em",
                              }}
                            >
                              Custo total:
                            </label>
                            <label style={{ fontSize: 14 }}>
                              R${" "}
                              {cost
                                ? `${cost
                                    ?.toFixed(2)
                                    ?.toString()
                                    .replace(".", ",")}`
                                : "0,00"}
                            </label>
                          </div>
                        </div>
                      </Col>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          {/* <i className="ni ni-settings" /> */}
                          <AttachMoneyIcon />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col></Col>
                      <Col className="col-auto my--5">
                        <br />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <br></br>
                {userType === "Administrador" ||
                userType === "Corpo Técnico" ? (
                  <>
                    {sourceOrder.status === "Concluída" ? null : (
                      <>
                        <Button color="success" onClick={programar}>
                          Programar
                        </Button>
                        <Button
                          color="secondary"
                          onClick={() => {
                            toggle();
                            setResponsavel({});
                          }}
                        >
                          Cancelar
                        </Button>
                      </>
                    )}
                  </>
                ) : null}
              </>
            ) : (
              <>
                <div className="form-group">
                  <form>
                    <Row>
                      <Col>
                        <AutocompleteComponent responsavel={responsavel} />
                      </Col>
                      <Col>
                        <div className="form-group">
                          <Autocomplete
                            disabled={
                              userType === "Administrador" ||
                              userType === "Corpo Técnico"
                                ? false
                                : stateCompletedOrder
                            }
                            id="free-solo-demo"
                            freeSolo
                            onChange={(e, v) => {
                              if (v) {
                                setValueProvider(v);
                              } else {
                                setValueProvider("");
                              }
                            }}
                            // options={provider}
                            options={[
                              ...new Set(
                                provider.map((option) => {
                                  if (option.responsavel_fornecedor) {
                                    return option.responsavel_fornecedor;
                                  }
                                  return "";
                                })
                              ),
                            ]}
                            value={valueProvider}
                            getOptionLabel={(option) => {
                              if (option) {
                                return option;
                              }
                              return "";
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={valueProvider}
                                label="Fornecedor"
                                margin="normal"
                                variant="outlined"
                                // onChange={(e) => {
                                //   setResponsavel(e.target.value);
                                // }}
                              />
                            )}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div>
                        <h4> Início programado:</h4>
                        <DateTimePicker
                          date={start}
                          setDate={setStart}
                          placeholder="Início programado"
                        />
                      </div>
                      <div style={{ marginLeft: "5em" }}>
                        <h4> Fim programado:</h4>
                        <DateTimePicker
                          date={end}
                          setDate={setEnd}
                          placeholder="Fim programado"
                        />
                      </div>
                    </div>
                  </form>
                  <div style={{ marginTop: "2em" }}>
                    <h4>Custos da ordem Nº{orderState}</h4>
                    <Card className="shadow-lg">
                      <CardBody>
                        <Row>
                          <Col>
                            <TextField
                              disabled={
                                userType === "Administrador" ||
                                userType === "Corpo Técnico"
                                  ? false
                                  : stateCompletedOrder
                              }
                              className={classes.textField}
                              label="Custo serviço"
                              placeholder="0,00"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => {
                                setCostService(e.target.value);
                              }}
                              value={costService}
                              // InputLabelProps={{
                              //   shrink: true,
                              // }}
                            />
                          </Col>
                          <Col>
                            <TextField
                              disabled={
                                userType === "Administrador" ||
                                userType === "Corpo Técnico"
                                  ? false
                                  : stateCompletedOrder
                              }
                              label="Custo material"
                              className={classes.textField}
                              placeholder="0,00"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => {
                                setCostMaterial(e.target.value);
                              }}
                              value={costMaterial}
                              // InputLabelProps={{
                              //   shrink: true,
                              // }}
                            />
                          </Col>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                              {/* <i className="ni ni-settings" /> */}
                              <AttachMoneyIcon />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col></Col>
                          <Col className="col-auto my--5">
                            <br />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <div style={{ marginTop: "10px" }} className="form-group">
                      <label> Anexo: </label>
                      <input
                        type="file"
                        placeholder="Anexo"
                        name="file"
                        className="form-control"
                        accept="application/pdf"
                        onChange={(e) => {
                          setPdf(e.target.files);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {userType === "Administrador" ||
                userType === "Corpo Técnico" ? (
                  <>
                    <Button color="success" onClick={programar}>
                      Programar
                    </Button>
                    <Button
                      color="secondary"
                      onClick={() => {
                        toggle();
                        setResponsavel({});
                      }}
                    >
                      Cancelar
                    </Button>
                  </>
                ) : null}
              </>
            )}
          </ModalBody>
        );
      case 3:
        return (
          <div style={{ marginLeft: "-80%" }}>
            {historic?.map((item, index) => {
              if (item.status === "Plano") {
                return (
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot variant="outlined">
                        <FormatListBulletedIcon />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <div>
                        {item && (
                          <p
                            style={{
                              fontWeight: 600,
                              fontSize: "0.9em",
                              color: "#8898AA",
                            }}
                          >
                            {moment(item.createdAt).format(
                              "DD/MM/YYYY - HH:mm"
                            )}{" "}
                            - {item.performer ? item.performer : null}
                          </p>
                        )}
                        <p>
                          <b style={{ fontWeight: 600 }}>{item.name}</b>
                        </p>
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                );
              } else if (item.status === "Aberta") {
                return (
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot variant="outlined">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            enableBackground="new 0 0 24 24"
                            height="24px"
                            viewBox="0 0 24 24"
                            width="24px"
                            fill="#123"
                          >
                            <g>
                              <rect fill="none" height="24" width="24" />
                            </g>
                            <g>
                              <g>
                                <path d="M5,5h2v3h10V5h2v5h2V5c0-1.1-0.9-2-2-2h-4.18C14.4,1.84,13.3,1,12,1S9.6,1.84,9.18,3H5C3.9,3,3,3.9,3,5v14 c0,1.1,0.9,2,2,2h6v-2H5V5z M12,3c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S11.45,3,12,3z" />
                                <polygon points="21,11.5 15.51,17 12.5,14 11,15.5 15.51,20 22.5,13" />
                              </g>
                            </g>
                          </svg>
                        </div>
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <div>
                        {item && (
                          <p
                            style={{
                              fontWeight: 600,
                              fontSize: "0.9em",
                              color: "#8898AA",
                            }}
                          >
                            {moment(item.createdAt).format(
                              "DD/MM/YYYY - HH:mm"
                            )}{" "}
                            - {item.performer ? item.performer : null}
                          </p>
                        )}
                        <p>
                          <b style={{ fontWeight: 600 }}>{item.name}</b>
                        </p>
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                );
              } else if (item.status === "Material Reservado") {
                return (
                  <TimelineItem style={{ marginTop: "1em" }}>
                    <TimelineSeparator>
                      <TimelineDot color="primary">
                        <ShoppingCartOutlinedIcon />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <div>
                        {item && (
                          <p
                            style={{
                              fontWeight: 600,
                              fontSize: "0.9em",
                              color: "#8898AA",
                            }}
                          >
                            {moment(item.createdAt).format(
                              "DD/MM/YYYY - HH:mm"
                            )}{" "}
                            - {item.performer ? item.performer : null}
                          </p>
                        )}
                        <p>
                          <b style={{ fontWeight: 600 }}>{item.name}</b>
                        </p>
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                );
              } else if (item.status === "Replanejar") {
                return (
                  <TimelineItem style={{ marginTop: "1em" }}>
                    <TimelineSeparator>
                      <TimelineDot color="inherit" variant="outlined">
                        <FindReplaceIcon />
                      </TimelineDot>
                      <TimelineConnector className={classes.secondaryTail} />
                    </TimelineSeparator>
                    <TimelineContent>
                      <div>
                        {item && (
                          <p
                            style={{
                              fontWeight: 600,
                              fontSize: "0.9em",
                              color: "#8898AA",
                            }}
                          >
                            {moment(item.createdAt).format(
                              "DD/MM/YYYY - HH:mm"
                            )}{" "}
                            - {item.performer ? item.performer : null}
                          </p>
                        )}
                        <p>
                          <b style={{ fontWeight: 600 }}>{item.name}</b>
                        </p>
                        {item.description && (
                          <div style={{ display: "flex" }}>
                            <p>
                              <b
                                style={{ marginRight: "5px", fontWeight: 500 }}
                              >
                                Parecer Técnico:{" "}
                              </b>
                            </p>
                            <p>{item?.description}</p>
                          </div>
                        )}
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                );
              } else if (item.status === "Programada") {
                return (
                  <TimelineItem style={{ marginTop: "1em" }}>
                    <TimelineSeparator>
                      <TimelineDot color="inherit" variant="outlined">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            enableBackground="new 0 0 24 24"
                            height="24px"
                            viewBox="0 0 24 24"
                            width="24px"
                            fill="#000000"
                          >
                            <g>
                              <rect fill="none" height="24" width="24" />
                            </g>
                            <g>
                              <g>
                                <path d="M9,15c-2.67,0-8,1.34-8,4v2h16v-2C17,16.34,11.67,15,9,15z M3,19c0.22-0.72,3.31-2,6-2c2.7,0,5.8,1.29,6,2H3z" />
                                <path d="M4.74,9H5c0,2.21,1.79,4,4,4s4-1.79,4-4h0.26c0.27,0,0.49-0.22,0.49-0.49V8.49c0-0.27-0.22-0.49-0.49-0.49H13 c0-1.48-0.81-2.75-2-3.45V5.5C11,5.78,10.78,6,10.5,6S10,5.78,10,5.5V4.14C9.68,4.06,9.35,4,9,4S8.32,4.06,8,4.14V5.5 C8,5.78,7.78,6,7.5,6S7,5.78,7,5.5V4.55C5.81,5.25,5,6.52,5,8H4.74C4.47,8,4.25,8.22,4.25,8.49v0.03C4.25,8.78,4.47,9,4.74,9z M11,9c0,1.1-0.9,2-2,2s-2-0.9-2-2H11z" />
                                <path d="M21.98,6.23l0.93-0.83l-0.75-1.3l-1.19,0.39c-0.14-0.11-0.3-0.2-0.47-0.27L20.25,3h-1.5L18.5,4.22 c-0.17,0.07-0.33,0.16-0.48,0.27L16.84,4.1l-0.75,1.3l0.93,0.83C17,6.4,17,6.58,17.02,6.75L16.09,7.6l0.75,1.3l1.2-0.38 c0.13,0.1,0.28,0.18,0.43,0.25L18.75,10h1.5l0.27-1.22c0.16-0.07,0.3-0.15,0.44-0.25l1.19,0.38l0.75-1.3l-0.93-0.85 C22,6.57,21.99,6.4,21.98,6.23z M19.5,7.75c-0.69,0-1.25-0.56-1.25-1.25s0.56-1.25,1.25-1.25s1.25,0.56,1.25,1.25 S20.19,7.75,19.5,7.75z" />
                                <path d="M19.4,10.79l-0.85,0.28c-0.1-0.08-0.21-0.14-0.33-0.19L18.04,10h-1.07l-0.18,0.87c-0.12,0.05-0.24,0.12-0.34,0.19 l-0.84-0.28l-0.54,0.93l0.66,0.59c-0.01,0.13-0.01,0.25,0,0.37l-0.66,0.61l0.54,0.93l0.86-0.27c0.1,0.07,0.2,0.13,0.31,0.18 L16.96,15h1.07l0.19-0.87c0.11-0.05,0.22-0.11,0.32-0.18l0.85,0.27l0.54-0.93l-0.66-0.61c0.01-0.13,0.01-0.25,0-0.37l0.66-0.59 L19.4,10.79z M17.5,13.39c-0.49,0-0.89-0.4-0.89-0.89c0-0.49,0.4-0.89,0.89-0.89s0.89,0.4,0.89,0.89 C18.39,12.99,17.99,13.39,17.5,13.39z" />
                              </g>
                            </g>
                          </svg>
                        </div>
                      </TimelineDot>
                      <TimelineConnector className={classes.secondaryTail} />
                    </TimelineSeparator>
                    <TimelineContent>
                      <div>
                        {item && (
                          <p
                            style={{
                              fontWeight: 600,
                              fontSize: "0.9em",
                              color: "#8898AA",
                            }}
                          >
                            {moment(item.createdAt).format(
                              "DD/MM/YYYY - HH:mm"
                            )}{" "}
                            - {item.performer ? item.performer : null}
                          </p>
                        )}
                        <p>
                          <b style={{ fontWeight: 600 }}>{item.name}</b>
                        </p>
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                );
              } else if (item.status === "Concluída") {
                return (
                  <TimelineItem style={{ marginTop: "1em" }}>
                    <TimelineSeparator>
                      <TimelineDot color="inherit" variant="outlined">
                        <DoneOutlineRoundedIcon />
                      </TimelineDot>
                      <TimelineConnector className={classes.secondaryTail} />
                    </TimelineSeparator>
                    <TimelineContent>
                      <div>
                        {item && (
                          <p
                            style={{
                              fontWeight: 600,
                              fontSize: "0.9em",
                              color: "#8898AA",
                            }}
                          >
                            {moment(item.createdAt).format(
                              "DD/MM/YYYY - HH:mm"
                            )}{" "}
                            - {item.performer ? item.performer : null}
                          </p>
                        )}
                        <p>
                          <b style={{ fontWeight: 600 }}>{item.name}</b>
                        </p>
                        <div style={{ display: "flex" }}>
                          <p>
                            <b style={{ marginRight: "5px", fontWeight: 500 }}>
                              Início do serviço:{" "}
                            </b>
                          </p>
                          {item.startService && (
                            <p>
                              {moment(item?.startService).format(
                                "DD/MM/YYYY - HH:mm"
                              )}
                            </p>
                          )}
                        </div>
                        <div style={{ display: "flex" }}>
                          <p>
                            <b style={{ marginRight: "5px", fontWeight: 500 }}>
                              Fim do serviço:{" "}
                            </b>
                          </p>
                          {item.endService && (
                            <p>
                              {moment(item.endService).format(
                                "DD/MM/YYYY - HH:mm"
                              )}
                            </p>
                          )}
                        </div>
                        {item.description && (
                          <div style={{ display: "flex" }}>
                            <p>
                              <b
                                style={{ marginRight: "5px", fontWeight: 500 }}
                              >
                                Parecer Técnico:{" "}
                              </b>
                            </p>
                            <p>{item?.description}</p>
                          </div>
                        )}
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                );
              }
            })}
          </div>
        );
      default:
        return "Unknown step";
    }
  }
  const columnHeader = [
    {
      Header: "Ordem",
      Footer: "Ordem",
      accessor: "id",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Código do equipamento",
      Footer: "Código do equipamento",
      accessor: "equipamento",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Descrição do equipamento",
      Footer: "Descrição do equipamento",
      accessor: "equipamento_denominacao",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Descrição da falha",
      Footer: "Descrição da falha",
      accessor: "descricao",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Prazo",
      Footer: "Prazo",
      accessor: "prazo",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: "status",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Prioridade",
      Footer: "Prioridade",
      accessor: "prioridade",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Executante",
      Footer: "Executante",
      accessor: "responsavel",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Tipo de ordem",
      Footer: "Tipo de ordem",
      accessor: "tipo",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Máquina Parou",
      Footer: "Máquina Parou",
      accessor: "maquina_parou",
      // disableFilters: true,
      sticky: "left",
    },
  ];

  function QontoStepIcon(props) {
    return (
      <div>
        <UpdateIcon />
      </div>
    );
  }
  const columns = useMemo(() => columnHeader, []);
  const data = useMemo(() => order, [order]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    allColumns,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
    // useBlockLayout,
    // useResizeColumns
  );
  // const [value, setValue] = useState(state.globalFilter);
  // const onChange = useAsyncDebounce((value) => {
  //   setGlobalFilter(value);
  // });

  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: "AIzaSyAIZC0_BOVpwYBZiUaaf23K857gaSn_QwY"
  // })

  // const filterStatusOrder = (arrayStatus) => {
  //   if (arrayStatus.length) {
  //     const newList = arrayStatus.map((response) => {
  //       return response.value;
  //     });
  //     const orderToFiltered = orderTemplate;
  //     const resultFilter = orderToFiltered.filter((item) =>
  //       newList.includes(item.status)
  //     );
  //     setOrder(resultFilter);
  //   } else {
  //     setOrder(orderTemplate);
  //   }
  // };

  // const selectOptions = [
  //   { value: "Aberta", label: "Aberta" },
  //   { value: "Material Reservado", label: "Material Reservado" },
  //   { value: "Programada", label: "Programada" },
  //   { value: "Concluída", label: "Concluída" },
  //   { value: "Replanejar", label: "Replanejar" },
  //   { value: "Atrasada", label: "Atrasada" },
  // ];

  return (
    <>
      {/* <Maps/> */}
      <Header />
      {/* Page content */}
      <NotificationAlert ref={notification} />
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                {/* <ViewCalendar /> */}
                {/* <div style={{ maxWidth: "350px" }}>
                  <Select
                    isMulti
                    onChange={(e) => filterStatusOrder(e)}
                    options={selectOptions}
                    placeholder="Filtrar por status"
                  />
                </div> */}
                {userType === "Corpo Técnico" &&
                userType === "Administrador" ? (
                  <>
                    <Button
                      color="success"
                      size="sm"
                      className="position-absolute top-3 right-8"
                    >
                      <Link style={{ color: "white" }} to="/admin/timeline">
                        Calendário
                      </Link>
                    </Button>
                  </>
                ) : null}
                <CreateOrder setOrder={setOrder} />
              </CardHeader>
              <CardBody>
                <Datagrid lineClicked={linhaClicada} dataSource={order} />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <div></div>

      <ModalCompleteOrder
        update={completedOrderStatus}
        modal={modalSelectComplete}
        toggle={toggleModalSelectComplete}
        description={descriptionOfOrderCompleted}
        setDescription={setDescriptionOfOrderCompleted}
      />

      <ModalReplan
        modal={modalReplan}
        toggle={toggleModalReplan}
        sourceOrder={sourceOrder}
        nameUser={nameUser}
        enterprise={enterpriseName}
        userType={userType}
        users={users}
        setOrder={setOrder}
        notifyGeneralModal={notifyGeneralModal}
      />
      <Modal
        size="lg"
        className="mr-0 mt--1 mb--1"
        isOpen={modal}
        toggle={() => {
          toggle();
          setStart(null);
          setEnd(null);
        }}
      >
        <ModalHeader
          className="mb--4 ml-5"
          tag="h2"
          toggle={() => {
            toggle();
            setStart(null);
            setEnd(null);
          }}
        >
          <Row>
            Visão geral - OS {sourceOrder?.id ? "Nº " + sourceOrder?.id : null}
          </Row>
        </ModalHeader>
        <ModalBody>
          {maquina === "Sim" ? (
            <>
              <div style={{ float: "right" }}>
                <Chip
                  style={{ float: "right" }}
                  size="small"
                  icon={<WarningIcon />}
                  label="Equipamento parou"
                  //onDelete={handleDelete}
                  color="secondary"
                />
              </div>
            </>
          ) : null}
          <div className={classes.root}>
            <Stepper nonLinear activeStep={activeStep}>
              {steps.map((label, index) => {
                if (index === 3) {
                  return (
                    <Step key={label}>
                      <button className="btn-stepper">
                        <StepLabel
                          StepIconComponent={QontoStepIcon}
                          onClick={handleStep(index)}
                        >
                          {label}
                        </StepLabel>
                      </button>
                      {/* <StepButton StepIconComponent={QontoStepIcon}  onClick={handleStep(index)}>
                        {label}
                      </StepButton> */}
                    </Step>
                  );
                }
                // if(label === "Programar ordem"){
                //   return(
                //     <Step key={label}>
                //       <StepButton
                //         onClick={()=>{
                //           handleStep(index)
                //           handleClickListItem()}}
                //         >
                //         {label}
                //       </StepButton>
                //     </Step>
                //   )
                // }
                return (
                  <Step key={label}>
                    <StepButton onClick={handleStep(index)}>{label}</StepButton>
                  </Step>
                );
              })}
            </Stepper>
            <div>
              {allStepsCompleted() ? (
                <div></div>
              ) : (
                <div>
                  <Typography className={classes.instructions}>
                    {getStepContent(activeStep)}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="mt--5"></ModalFooter>
      </Modal>

      <Modal
        size="lg"
        //className="mr-0 center"
        //style={{maxWidth: '1200px', width: '100%'}}
        isOpen={cartOpen}
        toggle={toggle_cart}
      >
        <ModalHeader>
          {" "}
          <h2> Materiais Reservados </h2>
        </ModalHeader>
        <ModalBody>
          <Card className="shadow-lg">
            {spareRequest &&
              spareRequest
                .filter(
                  (response) =>
                    response.waitingMaterial === true &&
                    response.order_id === row_id
                )
                .map((response, index) => {
                  return (
                    <div style={{ padding: "0.5em" }}>
                      <h3>Aguardando materiais</h3>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          border: "0.3px solid #D7D9CE",
                          borderRadius: "8px",
                          padding: "0.5em",
                          marginBottom: "0.3em",
                        }}
                      >
                        <p style={{ marginRight: "0.5em" }}>
                          <b>Produto:</b> {response.produto}
                        </p>
                        <p style={{ marginRight: "0.5em" }}>
                          <b>Part Number:</b> {response.part_number}
                        </p>
                        <p style={{ marginRight: "0.5em" }}>
                          <BadgeStrap color="danger">
                            {" "}
                            Quantidade em estoque: {response.quantidade}{" "}
                          </BadgeStrap>
                        </p>
                        <p>
                          <BadgeStrap color="warning">
                            {" "}
                            Quantidade pedida: {response.qtdWaitMaterial}{" "}
                          </BadgeStrap>
                        </p>
                      </div>
                    </div>
                  );
                })}
          </Card>
          <Card className="shadow-lg">
            <CardBody>
              <Table
                className="align-items-center table-flush"
                responsive
                style={{ tableLayout: "fixed" }}
              >
                <thead>
                  <tr>
                    <th className="caractersTableSize" scope="col">
                      Produto
                    </th>
                    <th className="caractersTableSize" scope="col">
                      Part Number
                    </th>
                    <th className="caractersTableSize" scope="col">
                      Disponíveis
                    </th>
                    {sourceOrder.status !== "Concluída" && (
                      <>
                        <th className="caractersTableSize" scope="col">
                          Reservar
                        </th>
                        <th className="caractersTableSize" scope="col">
                          Retirar
                        </th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {cart &&
                    cart
                      .filter((res) => res.ordem_id === row_id)
                      .map((row, index) => (
                        <tr>
                          <th className="caractersTableSize" scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {row.produto}
                                </span>
                              </Media>
                            </Media>
                          </th>

                          <th className="caractersTableSize" scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {row.part_number}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <th className="caractersTableSize" scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {row.disponiveis}
                                </span>
                              </Media>
                            </Media>
                          </th>

                          {sourceOrder.status !== "Concluída" && (
                            <th scope="row">
                              <div
                                style={{
                                  display: "flex",
                                  marginLeft: "-3.5em",
                                  alignItems: "center",
                                }}
                              >
                                <Avatar>
                                  <Button
                                    disabled={stateDisable}
                                    size="small"
                                    disableElevation
                                    variant="contained"
                                    onClick={() => {
                                      decrementReserve(row);
                                      setTimeout(() => {
                                        setStateDisable(false);
                                      }, 3000);
                                    }}
                                  >
                                    -
                                  </Button>
                                </Avatar>
                                <Box p={2} bgcolor="background.paper">
                                  <p style={{ marginTop: "1em" }}>
                                    {row.quantidade}
                                  </p>
                                </Box>
                                <Avatar>
                                  <Button
                                    disabled={stateDisable}
                                    size="small"
                                    disableElevation
                                    variant="contained"
                                    onClick={() => {
                                      if (sourceOrder.status !== "Concluída") {
                                        incrementReserve(row);
                                        setTimeout(() => {
                                          setStateDisable(false);
                                        }, 3000);
                                      }
                                    }}
                                  >
                                    +
                                  </Button>
                                </Avatar>
                              </div>
                            </th>
                          )}
                          {sourceOrder.status !== "Concluída" && (
                            <th area-disabled={stateDisable}>
                              <i
                                aria-disabled={stateDisable}
                                value={row._id}
                                onClick={() => {
                                  if (stateDisable) {
                                    return;
                                  } else {
                                    if (sourceOrder.status !== "Concluída") {
                                      send_back(row);
                                    }
                                  }
                                }}
                                className="fas fa-trash"
                                style={{
                                  cursor: !stateDisable ? "pointer" : "",
                                }}
                              ></i>
                            </th>
                          )}
                        </tr>
                      ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          {sourceOrder.status === "Concluída" ? null : (
            <Button
              color="success"
              onClick={() => {
                toggle_cart();
                notifyReserve("success");
                OrderService.getOrderById(row_id, {
                  token: token,
                  hash: hash,
                  idUser: idUserStorage,
                }).then((response) => {
                  let request = response.data.data;
                  let count = 0;
                  const somaTotal = cart
                    .filter((res) => res.ordem_id === row_id)
                    .map((row, index) => {
                      return row.custoTotal;
                    });
                  for (let i = 0; i < somaTotal.length; i++) {
                    count += somaTotal[i];
                  }
                  request.custoCarrinho = count;
                  request.token = token;
                  request.idUser = idUserStorage;
                  request.hash = hash;

                  OrderService.updateOrder(request, row_id).then((response) => {
                    // structureModal(row_id);
                    localStorage.removeItem("TOKEN_KEY");
                    localStorage.setItem("TOKEN_KEY", response.data.token);
                  });
                });
              }}
            >
              Confirmar Reserva
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ListOrders;
