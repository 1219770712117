import React, { useState, useEffect, useContext, useMemo } from "react";
// javascipt plugin for creating charts
import Chart from "chart.js";

import moment from "moment";

import classnames from "classnames";

import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SearchIcon from "@material-ui/icons/Search";
import {
  Button,
  Card,
  Table,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Row,
  Col,
  CardTitle,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
  Progress,
} from "reactstrap";

import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";

// core components
import { chartOptions, parseOptions } from "variables/charts.js";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine } from "@nivo/line";
import DashboardService from "./DashboardService";
import EquipmentService from "../Equipments/EquipmentService";
import { EnterpriseNameContext } from "../../context/EnterpriseContext";
import { TextField } from "@mui/material";
import DatepickerArgon from "./components/DatepickerArgon.jsx";

import Tooltip from "@mui/material/Tooltip";
const Management = (props) => {
  const { enterpriseName } = useContext(EnterpriseNameContext);
  const [cardsActives, setCardsActives] = useState([]);
  const [columnCustTotal, setColumnCustTotal] = useState([]);
  const [higherCost, setHigherCost] = useState([]);
  const [, setEquipmentChangedAverage] = useState({
    codigo: "",
    denominacao: "",
  });
  const [downtimeGraph, setDowntimeGraph] = useState([]);
  const [, setMttr] = useState(0);

  const [isDowntime, setIsDowntime] = useState(false);

  const toggleNavs = () => {
    setIsDowntime(!isDowntime);
  };
  const [availabilityGraph, setAvailabilityGraph] = useState([]);

  useEffect(() => {
    DashboardService.getActives({ empresa: enterpriseName }).then(
      (response) => {
        const result = response.data;
        console.log(result, "gestao");
        if (result.equipmentArea) {
          setEquipmentArea(
            result.equipmentArea[0] ? result.equipmentArea[0] : []
          );
        }

        if (result.tableOfManagement) {
          setTableOfManagement(
            result.tableOfManagement[0] ? result.tableOfManagement[0] : []
          );
        }

        if (result.availabilityGraph) {
          setAvailabilityGraph(result.availabilityGraph);
        }

        if (result) {
          setCardsActives(result);
        }
        if (result?.arrayCustTotal) {
          console.log(result?.arrayCustTotal, "arrayCustTotal");
          setColumnCustTotal(result?.arrayCustTotal);
        }
        const sorted = result?.higherCost[0]?.sort((a, b) => {
          if (a.value > b.value) {
            return -1;
          }
          if (a.value < b.value) {
            return 1;
          }
        });
        console.log(sorted);
        const filterHigher = sorted?.filter((sender, index) => {
          if (index < 5) {
            return sender;
          }
        });
        console.log("filter", filterHigher);
        const sortedFinished = filterHigher?.sort((a, b) => {
          console.log("parse", parseFloat(a.value));
          if (a.value < b.value) {
            return 1;
          }
          if (a.value > b.value) {
            return -1;
          }
        });
        if (sortedFinished) {
          setHigherCost(sortedFinished);
        }

        const sorted2 = result?.downtimeGraph[0]?.sort((a, b) => {
          if (a.quantity > b.quantity) {
            return -1;
          }
          if (a.quantity < b.quantity) {
            return 1;
          }
        });
        const filterAvailability = sorted2?.filter((sender, index) => {
          if (index < 5) {
            return sender;
          }
        });
        const sortedFinished2 = filterAvailability?.sort((a, b) => {
          if (a.quantity < b.quantity) {
            return 1;
          }
          if (a.quantity > b.quantity) {
            return -1;
          }
        });
        if (sortedFinished2) {
          setDowntimeGraph(sortedFinished2);
        }
      }
    );
    EquipmentService.getEquipment({ empresa: enterpriseName }).then(
      (response) => {
        const result = response.data;
        if (result.data) {
          setEquipments(result.data);
        }
        if (result?.data[0]?.codigo) {
          DashboardService.getAverageTime({
            empresa: enterpriseName,
            equipamento: result?.data[0]?.codigo,
          }).then((dataSource) => {
            setEquipmentChangedAverage({
              codigo: result?.data[0]?.codigo,
              denominacao: result?.data[0]?.denominacao,
            });
            if (dataSource.data.mttr) {
              console.log("mttr", dataSource.data.mttr);
              setMttr(dataSource.data.mttr);
            }
          });
        }
      }
    );
  }, []);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const [startDate, setStartDate] = useState(
    new Date(new Date().setFullYear(new Date().getFullYear() - 1))
  );
  const [endDate, setEndDate] = useState(new Date());
  const [stateButtonTable, setStateButtonTable] = useState(1);

  console.log(availabilityGraph, "availabilityGraph");
  const availabilityTotal = [
    {
      id: "Disponibilidade",
      color: "hsl(281, 70%, 50%)",
      data: availabilityGraph ?? [],
    },
  ];

  const columnHeader = [
    {
      Header: "Equipamento",
      Footer: "Equipamento",
      accessor: "equipment",
      sticky: "left",
    },
    {
      Header: "Descrição",
      Footer: "Descrição",
      accessor: "description",
      sticky: "left",
    },
    {
      Header: "Qtde Falha",
      Footer: "Qtde Falha",
      accessor: "quantity",
      sticky: "left",
    },
    {
      Header: "Custo",
      Footer: "Custo",
      accessor: (originalRow, rowIndex) => {
        if (originalRow.costOrder) {
          return `R$ ${Intl.NumberFormat("pt-br").format(
            parseFloat(originalRow.costOrder).toFixed(2)
          )}`;
        } else {
          return "R$ 0,00";
        }
      },
      sticky: "left",
    },
    {
      Header: "Indisponibilidade (Dias)",
      Footer: "Indisponibilidade (Dias)",
      accessor: (row, rowIndex) => {
        if (row.unavailabilityMachineStopped) {
          return row?.unavailabilityMachineStopped;
        }
        return row?.unavailability ?? 0;
      },
      sticky: "left",
    },
  ];

  const columnHeaderPending = [
    {
      Header: "Material",
      Footer: "Material",
      accessor: "produto",
      sticky: "left",
    },
    {
      Header: "Número da ordem",
      Footer: "Número da ordem",
      accessor: "orderNumber",
      sticky: "left",
    },
    {
      Header: "Part Number",
      Footer: "Part Number",
      accessor: "part_number",
      sticky: "left",
    },
    {
      Header: "Quantidade",
      Footer: "Quantidade",
      accessor: "qtdWaitMaterial",
      sticky: "left",
    },
    {
      Header: "Data",
      Footer: "Data",
      accessor: "createdAt",
      sticky: "left",
    },
  ];

  const [tableOfManagement, setTableOfManagement] = useState([]);

  const columns = useMemo(() => {
    return stateButtonTable === 1 ? columnHeader : columnHeaderPending;
  }, [stateButtonTable]);
  const data = useMemo(() => tableOfManagement, [tableOfManagement]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    allColumns,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const [equipmentArea, setEquipmentArea] = useState([]);
  const [selectedArea, setSelectedArea] = useState([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedArea(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const [value, setValue] = useState(state.globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value);
  });

  return (
    <>
      {/* Page content */}

      <Row>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-checkbox-label">Área</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={selectedArea}
              onChange={handleChange}
              input={<OutlinedInput label="Área" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {equipmentArea.map((name) => (
                <MenuItem key={name} value={name}>
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ display: "flex", width: "80%", marginTop: "1.5em" }}>
            <DatepickerArgon
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
            <div>
              <Button
                style={{ marginLeft: "10px", marginRight: "20px" }}
                type="button"
                color="success"
                onClick={() => {
                  console.log("clicou!");
                  DashboardService.getActives({
                    empresa: enterpriseName,
                    start: new Date(startDate),
                    end: new Date(endDate),
                  }).then((response) => {
                    const result = response.data;
                    console.log(result, "result");

                    if (result.tableOfManagement) {
                      setTableOfManagement(
                        result.tableOfManagement[0]
                          ? result.tableOfManagement[0]
                          : []
                      );
                    }

                    // if (result.availabilityGraph) {
                    //   setAvailabilityGraph(result.availabilityGraph);
                    // }

                    if (result) {
                      setCardsActives(result);
                    }
                    // if (result?.arrayCustTotal) {
                    //   console.log(result?.arrayCustTotal, "arrayCustTotal");
                    //   setColumnCustTotal(result?.arrayCustTotal);
                    // }
                    const sorted = result?.higherCost[0]?.sort((a, b) => {
                      if (a.value > b.value) {
                        return -1;
                      }
                      if (a.value < b.value) {
                        return 1;
                      }
                    });
                    console.log(sorted);
                    const filterHigher = sorted?.filter((sender, index) => {
                      if (index < 5) {
                        return sender;
                      }
                    });
                    console.log("filter", filterHigher);
                    const sortedFinished = filterHigher?.sort((a, b) => {
                      console.log("parse", parseFloat(a.value));
                      if (a.value < b.value) {
                        return 1;
                      }
                      if (a.value > b.value) {
                        return -1;
                      }
                    });
                    if (sortedFinished) {
                      setHigherCost(sortedFinished);
                    }

                    const sorted2 = result?.downtimeGraph[0]?.sort((a, b) => {
                      if (a.quantity > b.quantity) {
                        return -1;
                      }
                      if (a.quantity < b.quantity) {
                        return 1;
                      }
                    });
                    console.log("downtime ?????: ", sorted2);
                    const filterAvailability = sorted2?.filter(
                      (sender, index) => {
                        if (index < 5) {
                          return sender;
                        }
                      }
                    );
                    const sortedFinished2 = filterAvailability?.sort((a, b) => {
                      if (a.quantity < b.quantity) {
                        return 1;
                      }
                      if (a.quantity > b.quantity) {
                        return -1;
                      }
                    });
                    if (sortedFinished2) {
                      setDowntimeGraph(sortedFinished2);
                    }
                  });
                }}
              >
                Filtrar
              </Button>
            </div>
          </div>
        </div>
        <Col className="mb-5 mb-xl-0" xl="12">
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="3">
                <Card>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Custo Total
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          R${" "}
                          {cardsActives.totalCust
                            ? Intl.NumberFormat("pt-br").format(
                                cardsActives.totalCust
                              )
                            : 0}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <Tooltip
                          style={{ cursor: "pointer" }}
                          title="Custo total de Manutenção."
                        >
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-search-dollar" />
                          </div>
                        </Tooltip>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      {/* <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 3.48%
                      </span>{" "} */}
                      <span className="text-nowrap">
                        Entre {moment(startDate).format("DD/MM/YYYY")} e{" "}
                        {moment(endDate).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                {/*  <Card className="card-stats mb-4 mb-xl-0"> */}
                <Card>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Fora de Operação
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {cardsActives.machineStoped
                            ? parseInt(cardsActives.machineStoped)
                            : 0}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <Tooltip
                          style={{ cursor: "pointer" }}
                          title="Quantidade de equipamentos indisponíveis."
                        >
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <HomeRepairServiceIcon />
                          </div>
                        </Tooltip>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      {/* <span className="text-danger mr-2">
                        <i className="fas fa-arrow-down" /> 3.48%
                      </span>{" "} */}
                      <span className="text-nowrap">
                        Entre {moment(startDate).format("DD/MM/YYYY")} e{" "}
                        {moment(endDate).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Tempo de Inatividade
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {cardsActives.downtime
                            ? `${Intl.NumberFormat("pt-br").format(
                                parseFloat(cardsActives.downtime).toFixed(2)
                              )}` + " horas"
                            : "< 1 hora"}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <Tooltip
                          style={{ cursor: "pointer" }}
                          title="Tempo de Inatividade dos equipamentos."
                        >
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-chart-bar" />
                          </div>
                        </Tooltip>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      {/* <span className="text-warning mr-2">
                        <i className="fas fa-arrow-down" /> 1.10%
                      </span>{" "} */}
                      <span className="text-nowrap">
                        Entre {moment(startDate).format("DD/MM/YYYY")} e{" "}
                        {moment(endDate).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Disponibilidade
                        </CardTitle>
                        {/* <span className="h2 font-weight-bold mb-0">91,27%</span> */}

                        <span className="h2 font-weight-bold mb-0">
                          {cardsActives.availabilityCard
                            ? cardsActives?.availabilityCard !== "NaN"
                              ? new Intl.NumberFormat("pt-br", {
                                  style: "percent",
                                  maximumFractionDigits: 2,
                                }).format(cardsActives?.availabilityCard)
                              : 0 + "%"
                            : "0%"}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <Tooltip
                          style={{ cursor: "pointer" }}
                          title={
                            "% de tempo que os equipamentos permaneceram disponíveis entre " +
                            `${moment(startDate).format(
                              "DD/MM/YYYY"
                            )} e ${moment(endDate).format("DD/MM/YYYY")}`
                          }
                        >
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="fas fa-percent" />
                          </div>
                        </Tooltip>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      {/* <span className="text-warning mr-2">
                        <i className="fas fa-arrow-down" /> 1.10%
                      </span>{" "} */}
                      <span className="text-nowrap">
                        Entre {moment(startDate).format("DD/MM/YYYY")} e{" "}
                        {moment(endDate).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col className="mb-5 mb-xl-0">
          <Card>
            <CardHeader className="bg-transparent">
              <Row className="align-items-center">
                <div className="col">
                  <h6 className="text-uppercase ls-1 mb-1">Visão Geral</h6>
                  <h2 className=" mb-0">Custo de manutenção total</h2>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              {/* Chart */}
              <div style={{ height: 500 }}>
                <ResponsiveBar
                  data={columnCustTotal}
                  keys={["Custo Mão de Obra", "Custo Material"]}
                  indexBy="country"
                  margin={{ top: 20, right: 20, bottom: 20, left: 50 }}
                  padding={0.3}
                  valueScale={{ type: "linear" }}
                  indexScale={{ type: "band", round: true }}
                  colors={{ scheme: "nivo" }}
                  defs={[
                    {
                      id: "dots",
                      type: "patternDots",
                      background: "inherit",
                      color: "#38bcb2",
                      size: 4,
                      padding: 1,
                      stagger: true,
                    },
                    {
                      id: "lines",
                      type: "patternLines",
                      background: "inherit",
                      color: "#eed312",
                      rotation: -45,
                      lineWidth: 6,
                      spacing: 10,
                    },
                  ]}
                  borderRadius={4}
                  borderColor={{
                    from: "color",
                    modifiers: [["darker", "1.6"]],
                  }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    legend: "country",
                    legendPosition: "middle",
                    legendOffset: 32,
                    tickRotation: 0,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -40,
                    format: (value) => {
                      return `R$ ${Intl.NumberFormat("pt-br").format(
                        parseFloat(value).toFixed(2)
                      )}`;
                    },
                    legendPosition: "middle",
                    legendOffset: -40,
                    tickValues: 4,
                  }}
                  enableGridY={false}
                  enableLabel={false}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor="black"
                  legends={[]}
                  animate={true}
                  motionStiffness={90}
                  motionDamping={15}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col className="mb-5 mb-xl-0">
          <Card>
            <CardHeader className="bg-transparent">
              <Row className="align-items-center">
                <div className="col">
                  <h6 className="text-uppercase ls-1 mb-1">Visão Geral</h6>
                  <h2 className=" mb-0">Disponibilidade (%)</h2>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <div style={{ height: 530 }}>
                <ResponsiveLine
                  data={availabilityTotal}
                  margin={{ top: 20, right: 110, bottom: 20, left: 60 }}
                  xScale={{ type: "point" }}
                  yScale={{
                    type: "linear",
                    min: 0,
                    max: 100,
                    stacked: false,
                    reverse: false,
                  }}
                  // yFormat=" >-.3%"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    orient: "bottom",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Mês",
                    legendOffset: 30,
                    legendPosition: "middle",
                  }}
                  axisLeft={{
                    orient: "left",
                    tickSize: 5,
                    // legendOffset: -50,
                    tickPadding: 0,
                    // tickRotation: -20,
                    format: (value) => {
                      console.log(value, "value");
                      // console.log(`${parseFloat(value.toFixed(2)*100).toFixed(2)}%`, "value");
                      return `${parseFloat(value)}%`;
                    },
                  }}
                  enableGridX={false}
                  enableGridY={false}
                  colors={{ scheme: "set1" }}
                  pointSize={7}
                  pointColor={{ theme: "background" }}
                  pointBorderWidth={2}
                  pointBorderColor={{ from: "serieColor", modifiers: [] }}
                  pointLabelYOffset={-12}
                  useMesh={true}
                  enableArea={false}
                  enableSlices="x"
                  legends={[]}
                  animate={true}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col className="mb-5 mb-xl-0" xl="4">
          <Card>
            <CardHeader className="bg-transparent">
              <Row className="align-items-center">
                <div className="col">
                  <h6 className="text-uppercase ls-1 mb-1">Custo</h6>
                  <h2 className=" mb-0">Equipamentos em destaque</h2>
                </div>
                <div className="col">
                  <Nav className="justify-content-end" pills>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames("py-2 px-3", {
                          active: !isDowntime,
                        })}
                        onClick={(e) => toggleNavs()}
                      >
                        <span className="d-none d-md-block">Custo</span>
                        <span className="d-md-none">M</span>
                      </NavLink>
                    </NavItem>

                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames("py-2 px-3", {
                          active: isDowntime,
                        })}
                        onClick={(e) => toggleNavs()}
                      >
                        <span className="d-none d-md-block">Downtime</span>
                        <span className="d-md-none">M</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              {/* Chart */}
              <div style={{ height: 500 }}>
                {!isDowntime ? (
                  higherCost.map((response, index) => {
                    return (
                      <div className="progress-wrapper">
                        <div className="progress-secondary">
                          <div className="progress-label">
                            <span>{response.equipment}</span>
                          </div>
                          <div className="progress-percentage">
                            <span>
                              R$:{" "}
                              {Intl.NumberFormat("pt-br").format(
                                response.value
                              )}
                            </span>
                          </div>
                        </div>
                        <Progress
                          max={higherCost[0].value}
                          value={response.value}
                        />
                      </div>
                    );
                  })
                ) : (
                  <>
                    {downtimeGraph.map((response, index) => {
                      return (
                        <div className="progress-wrapper">
                          <div className="progress-secondary">
                            <div className="progress-label">
                              <span>{response?.equipment}</span>
                            </div>
                            <div className="progress-percentage">
                              <span>horas: {response?.quantity}</span>
                            </div>
                          </div>
                          <Progress
                            max={downtimeGraph[0].quantity}
                            value={response.quantity}
                          />
                        </div>
                      );
                    })}
                  </>
                )}
                {/* <ResponsiveBar
                  data={sourceEmphasis[equipmentEmphasis]}
                  keys={["Label"]}
                  indexBy="country"
                  margin={{ top: 20, right: 40, bottom: 20, left: 250 }}
                  padding={0.2}
                  layout="horizontal"
                  valueScale={{ type: "linear" }}
                  indexScale={{ type: "band", round: true }}
                  colors={{ scheme: "nivo" }}
                  defs={[
                    {
                      id: "dots",
                      type: "patternDots",
                      background: "inherit",
                      color: "#38bcb2",
                      size: 4,
                      padding: 1,
                      stagger: true,
                    },
                    {
                      id: "lines",
                      type: "patternLines",
                      background: "inherit",
                      color: "#eed312",
                      rotation: -45,
                      lineWidth: 6,
                      spacing: 10,
                    },
                  ]}
                  borderRadius={7}
                  borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    // legend: 'country',
                    legendPosition: "middle",
                    legendOffset: 32,
                    tickValues: 4,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    // legend: 'food',
                    legendPosition: "middle",
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor="black"
                  // legends={["Teste"]}
                  animate={true}
                  motionStiffness={90}
                  motionDamping={15}
                /> */}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* Second Section of Graphs */}
      <Row className="mt-3">
        <Col className="mb-5 mb-xl-0 mt" xl="6">
          {/* <Card>
            <CardHeader style={{ paddingTop: 0 }} className="bg-transparent">
              <Row className="align-items-center">
                <div className="col">
                  <h6 className="text-uppercase ls-1 mb-1">MTTR</h6>
                  <h2 className="mb-0">Tempo Médio de Reparo</h2>
                </div>
                <div>
                  <Autocomplete
                    style={{ minWidth: "300px", marginRight: "0.75rem" }}
                    freeSolo
                    options={equipments}
                    getOptionLabel={(option) => {
                      if (option.codigo) {
                        return option.codigo + " - " + option.denominacao;
                      }
                      if (option.denominacao) {
                        return option.codigo + " - " + option.denominacao;
                      } else {
                        return "";
                      }
                    }}
                    value={equipmentChangedAverage}
                    onChange={(e, value) => {
                      console.log(value);
                      if (value) {
                        setEquipmentChangedAverage({
                          codigo: value.codigo,
                          denominacao: value.denominacao,
                        });
                        DashboardService.getAverageTime({
                          empresa: enterpriseName,
                          equipamento: value.codigo,
                        }).then((response) => {
                          console.log(response.data);
                          setMttr(response.data.mttr);
                        });
                      } else {
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        // error={requireEquipment}
                        // helperText={messageRequireEquipment}
                        {...params}
                        // value={nameEquipment}
                        label="Equipamento"
                        margin="normal"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <div
                style={{
                  height: 345,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <h1 style={{ fontSize: "3.125rem" }}>{mttr ? mttr : 0}</h1>
                  <p>Média (horas)</p>
                </div>
              </div>
            </CardBody>
          </Card> */}
        </Col>
        <Col className="mb-5 mb-xl-0 mt" xl="6">
          {/* <Card>
            <CardHeader className="bg-transparent">
              <Row className="align-items-center">
                <div className="col">
                  <h6 className="text-uppercase ls-1 mb-1">MTBF</h6>
                  <h2 className=" mb-0">Tempo Médio entre Falhas</h2>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <div style={{ height: 350 }}>
                <ResponsiveLine
                  data={DataLine}
                  margin={{ top: 20, right: 110, bottom: 20, left: 60 }}
                  xScale={{ type: "point" }}
                  yScale={{
                    type: "linear",
                    min: 0,
                    max: "auto",
                    stacked: true,
                    reverse: false,
                  }}
                  yFormat=" >-.2f"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    orient: "bottom",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Mês",
                    legendOffset: 30,
                    legendPosition: "middle",
                  }}
                  axisLeft={{
                    orient: "left",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Quantidade",
                    legendOffset: -40,
                    legendPosition: "middle",
                    tickValues: 4,
                  }}
                  enableGridX={false}
                  enableGridY={false}
                  colors={{ scheme: "set1" }}
                  pointSize={7}
                  pointColor={{ theme: "background" }}
                  pointBorderWidth={2}
                  pointBorderColor={{ from: "serieColor", modifiers: [] }}
                  pointLabelYOffset={-12}
                  useMesh={true}
                  enableArea={true}
                  enableSlices="x"
                  legends={[]}
                  animate={true}
                  onClick={() => {
                    console.log("clicou");
                  }}
                />
              </div>
            </CardBody>
          </Card> */}
        </Col>
      </Row>

      <Row className="mt-5">
        <Col className="mb-5 mb-xl-0" xl="12">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col">
                  <h3 className="mb-0">Tabela</h3>
                </div>
              </Row>
              <Row>
                <div style={{ display: "flex", width: "50%" }}>
                  <div
                    style={{
                      width: "85%",
                      display: "flex",
                      alignItems: "center",
                      margin: "0.5em",
                    }}
                  >
                    <SearchIcon
                      style={{ marginTop: "12px", marginRight: "5px" }}
                    />
                    <TextField
                      id="input-with-icon-grid"
                      value={value || ""}
                      variant="standard"
                      onChange={(e) => {
                        console.log(e.target.value);
                        setValue(e.target.value);
                        onChange(e.target.value);
                      }}
                      style={{ width: "100%" }}
                      label="Filtrar tabela"
                    />
                    {/* <ButtonMui si color="inherit" onClick={handleClick}  variant="contained" style={{ maxHeight: '25px'}}><FilterListOutlinedIcon style={cellcolor}/>Filtar</ButtonMui> */}
                  </div>
                </div>
              </Row>
            </CardHeader>
            <Table
              {...getTableProps()}
              className="align-items-center table-flush"
              responsive
            >
              <thead className="thead-light">
                {headerGroups.map((headerGroup) => (
                  <tr
                    style={{ textAlign: "center" }}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => {
                      return (
                        <th
                          className="text-wrap"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          scope="col"
                        >
                          {column.render("Header")}{" "}
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <ArrowDropDownIcon />
                            ) : (
                              <ArrowDropUpIcon />
                            )
                          ) : (
                            ""
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  console.log(row);
                  prepareRow(row);
                  return (
                    <tr
                      style={{ cursor: "pointer", textAlign: "center" }}
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td
                            className="caractersTableSize"
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card>
          <CardFooter className="py-4">
            <nav aria-label="...">
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem disabled={!canPreviousPage}>
                  <PaginationLink onClick={(e) => gotoPage(0)} tabIndex="-1">
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                {state.pageIndex > 0 ? (
                  <>
                    <PaginationItem disabled={!canPreviousPage}>
                      <PaginationLink onClick={(e) => previousPage()}>
                        {state.pageIndex}
                      </PaginationLink>
                    </PaginationItem>
                  </>
                ) : null}
                <PaginationItem className="active">
                  <PaginationLink>{state.pageIndex + 1}</PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={!canNextPage}>
                  <PaginationLink onClick={(e) => nextPage()}>
                    {state.pageIndex + 2}
                    <span className="sr-only">(current)</span>
                  </PaginationLink>
                </PaginationItem>

                <PaginationItem disabled={!canNextPage}>
                  <PaginationLink onClick={(e) => gotoPage(pageCount - 1)}>
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </CardFooter>
        </Col>{" "}
      </Row>

      <Row className="mt-5">
        <Col className="mb-5 mb-xl-0" xl="12"></Col>
      </Row>
    </>
  );
};

export default Management;
