import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  createRef,
} from "react";
import SpareService from "./SpareService";
import { makeStyles } from "@material-ui/core/styles";
// import DataTable from 'react-data-table-component';

import classnames from "classnames";
// import DataTableExtensions from 'react-data-table-component-extensions';
import swal from "sweetalert";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import { EnterpriseNameContext } from "../../context/EnterpriseContext";
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { UserType } from "../../context/UserType";
import "./Spares.css";

import SearchIcon from "@material-ui/icons/Search";

import SpareReserve from "./Spare_Reserve_Service";

import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CardMui from "@mui/material/Card";

import SpareMovimentService from "./SpareMoviment_Service";

import { Button as ButtonMui } from "@mui/material";
import NotificationAlert from "react-notification-alert";
import { useHistory } from "react-router-dom";
// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  CardBody,
  Col,
  ModalFooter,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Badge,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
// core components
import Header from "components/Headers/Headerfor.js";
import ExitSpare from "./ExitSpare";
import CreateSpare from "./CreateSpare";
import Datagrid from "./components/Datagrid";
// import { isIfStatement } from 'typescript';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const notification = createRef();

function ListSpares(props) {
  const history = useHistory();
  const { enterpriseName } = useContext(EnterpriseNameContext);
  const { userType } = useContext(UserType);
  console.log(enterpriseName);

  const [activeTab, setActiveTab] = useState("1");
  const [resultOrders, setResultOrders] = useState();

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [nameUser, setNameUser] = useState(() => {
    const userNameStoraged = window.localStorage.getItem("TOKEN_USERNAME");

    if (userNameStoraged) {
      return userNameStoraged;
    }
    return;
  });

  const [token, setToken] = useState(() => {
    const tokenStoraged = localStorage.getItem("TOKEN_KEY");
    if (tokenStoraged) {
      return tokenStoraged;
    }
    return "";
  });
  const [hash, setHash] = useState(() => {
    const hashStoraged = localStorage.getItem("TOKEN_HASH");
    if (hashStoraged) {
      return hashStoraged;
    }
    return "";
  });
  const [idUserStorage, setIdUserStorage] = useState(() => {
    const idUserStoraged = localStorage.getItem("TOKEN_ID_USER");
    if (idUserStoraged) {
      return idUserStoraged;
    }
    return "";
  });

  const classes = useStyles();

  const PurpleSwitch = withStyles({
    switchBase: {
      color: "#58a3fb",
      "&$checked": {
        color: "#58a3fb",
      },
      "&$checked + $track": {
        backgroundColor: "#58a3fb",
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const [spare, setSpare] = useState([]);
  const [spareStoraged, setSpareStoraged] = useState([]);
  const [ordersModal, setOrdersModal] = useState(false);
  const [price, setPrice] = useState();
  const [material, setMaterial] = useState("");
  const redirectLogin = () => {
    swal(
      "Hmm... vi que você não está logado. Clique em ok para fazer o login",
      "",
      "warning",
      {
        buttons: {
          // nope: {
          //   text: 'Me enganei!',
          //   value: 'nope',
          // },
          sure: {
            text: "Ok",
            value: "sure",
          },
        },
      }
    ).then((value) => {
      switch (value) {
        case "sure":
          swal("Operação realizada com sucesso!", "", "success").then((val) => {
            // history.pushState
            // return <Redirect to="/auth/login"/>
            history.push("/auth/login");
          });
          break;
        case "nope":
          swal("Operação cancelada", "", "success");
          break;
        default:
      }
    });
  };

  useEffect(() => {
    SpareService.getSpares({
      empresa: enterpriseName,
    })
      .then((res) => {
        setSpare(res.data.data);
      })
      .catch((error) => {
        if (error?.response?.data?.auth) {
        } else {
          // redirectLogin();
        }
      });
  }, [enterpriseName, token, idUserStorage, hash]);

  const [stateFilterList, setStateFilterList] = useState({
    Produto: false,
    Fornecedor: false,
    PartNumber: false,
    Preco: false,
    QuantidadeTotal: false,
    ItensReservados: false,
    LocaldeDeposito: false,
    QuantidadeRemanescente: false,
    Ressuprimento: false,
    Categoria: false,
    Descricao: false,
  });

  const handleChange = (event) => {
    setStateFilterList({
      ...stateFilterList,
      [event.target.name]: event.target.checked,
    });
  };

  const customStyles = {
    rows: {
      style: {
        backgroundColor: "",
        cursor: "pointer",
      },
    },
    headCells: {
      //titulo da tabela.
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        backgroundColor: "#f6f9fc",
        border: "1px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        borderLeft: "1px",
      },
    },
  };

  const cellcolor = {
    color: "#1193EF",
    marginRight: "0.2em",
    width: "19px",
    // zIndex: 2,
    // position: 'relative',
  };

  const TranslateOptions = {
    rowsPerPageText: "Materiais por Página:",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsText: "Todos",
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { buttonLabel, className, actual_id } = props;

  const [row_id, setRow_id] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [produto, setProduto] = useState("");
  const [descricao, setDescricao] = useState("");
  const [categoria, setCategoria] = useState("");
  const [fornecedores, setFornecedores] = useState("");
  const [local_deposito, setLocal_Deposito] = useState("");
  const [preco, setPreco] = useState("");
  const [quantidade, setQuantidade] = useState("");
  const [part_number, setPart_Number] = useState("");
  const [ressuprimento, setRessuprimento] = useState("");
  const [quantidade_anterior, setQuantidade_anterior] = useState("");

  const handleSubmit = (evt) => {
    evt.preventDefault();

    //alert(`Submitting Name ${firstName} ${lastName}`);
    //resetFirstName();
    //resetLastName();
  };

  const update = (evt) => {
    console.log(row_id);
    console.log("price", preco);
    const removeDot = preco?.replace(".", "");
    console.log("removeDot", removeDot);
    const changeComma = removeDot
      ? removeDot.replace(",", ".")
      : price.toString().replace(",", ".");
    const priceConverted = parseFloat(changeComma).toFixed(2);
    console.log("priceConverted", priceConverted);
    let spares = {
      produto: produto,
      descricao: descricao,
      categoria: categoria,
      fornecedores: fornecedores,
      local_deposito: local_deposito,
      preco: priceConverted,
      quantidade: quantidade,
      part_number: part_number,
      ressuprimento: ressuprimento,
      // token: token,
      // hash: hash,
      // idUser: idUserStorage,
    };
    console.log(spares);
    SpareService.updateSpare(spares, row_id)
      .then((res) => {
        setModal(!modal);
        SpareService.getSpares({
          empresa: enterpriseName,
        }).then((res) => {
          setSpare(res.data.data);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [equipmentArea, setEquipmentArea] = useState("");

  const updateMaterial = (evt) => {
    if (parseFloat(quantidade) < 0) {
      console.log(quantidade, "parseQuantity");
      notifyGeneral(
        "danger",
        "Atenção!",
        "Não é possível adicionar material negativo"
      );
      return;
    }
    const parseQuantity = quantidade.replace(",", ".");
    const sum = quantidade_anterior + parseFloat(parseQuantity);
    const removeDot = preco?.replace(".", "");
    console.log("removeDot", removeDot);
    const changeComma = removeDot
      ? removeDot.replace(",", ".")
      : price.toString().replace(",", ".");
    const priceConverted = parseFloat(changeComma).toFixed(2);
    const request = {
      acao: "Entrada", //Entrou ou saiu
      responsavel: nameUser,
      quantidade: parseFloat(parseQuantity),
      precoAtual: priceConverted,
      empresa: enterpriseName,
      codigoSpare: material,
      partNumberSpare: part_number,
      fornecedor: fornecedores,
      descricaoSpare: descricao,
      token: token,
      hash: hash,
      idUser: idUserStorage,
      idOrder: " - ",
      equipmentArea,
      // ordemResponsavel: String,
    };
    console.log("request", request);
    SpareMovimentService.createSpareMoviment(request).then((response) => {
      console.log("created:", response.data);
      // localStorage.removeItem("TOKEN_KEY");
      // localStorage.setItem("TOKEN_KEY", response.data.token);
    });
    let spares = {
      preco: priceConverted,
      quantidade: sum,
    };

    SpareService.updateSpare(spares, row_id)
      .then((res) => {
        setModal(!modal);
        notifyGeneral(
          "success",
          "Concluído",
          `O material com o código ${material} foi atualizado!`
        );
        SpareService.getSpares({
          empresa: enterpriseName,
          // token: token,
          // hash: hash,
          // idUser: idUserStorage,
        }).then((res) => {
          setSpare(res.data.data);
          // localStorage.removeItem("TOKEN_KEY");
          // localStorage.setItem("TOKEN_KEY", res.data.token);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deletar = (evt) => {
    swal("Tem certeza que deletar o material?", "", "warning", {
      buttons: {
        nope: {
          text: "Me enganei!",
          value: "nope",
        },
        sure: {
          text: "Sim, claro.",
          value: "sure",
        },
      },
    }).then((value) => {
      switch (value) {
        case "sure":
          swal("Material deletado com sucesso", "", "success").then((val) => {
            SpareService.deleteSpare(row_id, {
              // token: token,
              // hash: hash,
              // idUser: idUserStorage,
            })
              .then((res) => {
                SpareService.getSpares({
                  empresa: enterpriseName,
                  token: token,
                  hash: hash,
                  idUser: idUserStorage,
                }).then((res) => {
                  setSpare(res.data.data);
                  toggle();
                  // localStorage.removeItem("TOKEN_KEY");
                  // localStorage.setItem("TOKEN_KEY", res.data.token);
                });
              })
              .catch((error) => {
                // if (error?.response?.data?.auth) {
                // } else {
                //   redirectLogin();
                // }
              });
          });
          break;
        case "nope":
          swal("Operação cancelada", "", "success");
          break;
        default:
          swal("material excluído!");
      }
    });
  };

  function linhaClicada(source) {
    console.log(source, "linha clicada");
    const row = source.data._id;
    console.log(row);
    setRow_id(row);
    setModal(true);
    SpareService.getSpareById(row, {
      // token: token,
      // hash: hash,
      // idUser: idUserStorage,
    })
      .then((res) => {
        let spares = res.data.data;
        setProduto(spares.id);
        setMaterial(spares.produto);
        setProduto(spares.produto);
        setDescricao(spares.descricao);
        setCategoria(spares.categoria);
        setFornecedores(spares.fornecedores);
        setLocal_Deposito(spares.local_deposito);
        console.log(
          `${
            spares.preco
              ? parseFloat(spares.preco, 10)
                  .toFixed(2)
                  .toString()
                  .replace(".", ",")
              : "0,00"
          }`,
          "preço"
        );
        const sum = `${
          spares.preco
            ? parseFloat(spares.preco, 10)
                .toFixed(2)
                .toString()
                .replace(".", ",")
            : "0,00"
        }`;
        setPreco(sum);
        setPrice(sum);
        console.log("quantidade", spares.quantidade);
        setQuantidade(spares.quantidade);
        setPart_Number(spares.part_number);
        setRessuprimento(spares.ressuprimento);
        setQuantidade_anterior(spares.quantidade);
        // localStorage.removeItem("TOKEN_KEY");
        // localStorage.setItem("TOKEN_KEY", res.data.token);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const notifyGeneral = (event, title, text) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <h2
            style={{ color: "#fff", marginBottom: "0" }}
            className="alert-title"
            data-notify="title"
          >
            {title} <br />
          </h2>
          <span data-notify="message">{text}</span>
        </div>
      ),
      type: event,
      // icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    notification.current.notificationAlert(options);
  };

  const seeOrders = () => {
    setOrdersModal(true);
    SpareReserve.getSparesReserve({
      spare_id: row_id,
    }).then((response) => {
      setResultOrders(response.data);
      console.log(response.data);
    });
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <NotificationAlert ref={notification} />
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                {/*<h3 className="mb-0">Gestão de Estoque</h3> */}
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  className="pl-5"
                >
                  <h3 className="ml-2 mr-3">
                    {" "}
                    Selecione as opções para ocultar{" "}
                  </h3>
                </Menu>
                <CreateSpare setSpare={setSpare} spare={spare} />
                <ExitSpare setSpare={setSpare} spare={spare} />
              </CardHeader>
              <CardBody>
                <Datagrid
                  dataSource={spare}
                  lineClicked={linhaClicada}
                  setOrdersModal={setOrdersModal}
                  setResultOrders={setResultOrders}
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

      <Modal isOpen={modal} toggle={toggle} className="mr-0 mt-2" size="lg">
        <ModalHeader tag="h3" toggle={toggle} className="mb--4">
          Visão geral
        </ModalHeader>
        <ModalBody className="mb-2">
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggleTab("1");
                  }}
                >
                  Entrada de material
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    toggleTab("2");
                  }}
                >
                  Editar material
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <ModalBody>
                  <Row
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "1rem",
                    }}
                  >
                    <h4>Resumo do material </h4>
                    <Button color="primary" onClick={seeOrders}>
                      Ver ordens
                    </Button>
                  </Row>
                  <Card className="shadow-lg mb-4">
                    <CardBody>
                      <Row>
                        <Col>
                          <h4> Material:</h4>
                          <span className="mb-0 text-sm">{produto}</span>
                          <br></br> <br></br>
                          <h4> Fornecedor: </h4>
                          <span className="mb-0 text-sm">{fornecedores} </span>
                          <br></br> <br></br>
                        </Col>
                        <Col>
                          <h4> Part Number: </h4>
                          <span className="mb-0 text-sm">{part_number} </span>
                          <br></br> <br></br>
                          <h4> Descrição </h4>
                          <span className="mb-0 text-sm">{descricao} </span>
                        </Col>

                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="ni ni-settings" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Row style={{ marginLeft: "0.5em", width: "100%" }}>
                    <div
                      style={{ marginRight: "10px", width: "48%" }}
                      className="form-group"
                    >
                      <TextField
                        id="outlined-basic"
                        label="Quantidade entrando:"
                        variant="outlined"
                        type="number"
                        onChange={(e) => setQuantidade(e.target.value)}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div style={{ width: "48%" }} className="form-group">
                      <TextField
                        // type="number"
                        id="outlined-basic"
                        label="Valor (R$)"
                        variant="outlined"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </Row>

                  <Row>
                    <Col className="mb-5 mb-xl-0" xl="9">
                      {userType !== "Executante" &&
                      userType !== "Requisitante" ? (
                        <>
                          <Button color="success" onClick={updateMaterial}>
                            Atualizar
                          </Button>{" "}
                          <Button color="secondary" onClick={toggle}>
                            Cancelar
                          </Button>
                        </>
                      ) : null}
                    </Col>
                  </Row>
                </ModalBody>
              </TabPane>
              <TabPane tabId="2">
                <ModalBody>
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col className="mb-5 mb-xl-0" xl="6">
                        <div className="form-group">
                          <TextField
                            id="outlined-basic"
                            label="Material"
                            //variant="outlined"
                            value={produto}
                            onChange={(e) => setProduto(e.target.value)}
                            style={{ width: "100%" }}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="form-group">
                          <TextField
                            id="outlined-basic"
                            label="Fornecedores"
                            //variant="outlined"
                            value={fornecedores}
                            onChange={(e) => setFornecedores(e.target.value)}
                            style={{ width: "100%" }}
                          />
                        </div>
                      </Col>
                    </Row>

                    <div className="form-group">
                      <TextField
                        id="outlined-basic"
                        label="Descrição"
                        variant="outlined"
                        value={descricao}
                        multiline
                        rows={4}
                        onChange={(e) => setDescricao(e.target.value)}
                        style={{ width: "100%" }}
                      />
                    </div>

                    <Row>
                      <Col>
                        <div className="form-group">
                          <TextField
                            id="outlined-basic"
                            label="Estoque mínimo"
                            //variant="outlined"
                            value={ressuprimento}
                            onChange={(e) => setRessuprimento(e.target.value)}
                            style={{ width: "100%" }}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="form-group">
                          <TextField
                            id="outlined-basic"
                            label="Categoria"
                            //variant="outlined"
                            value={categoria}
                            onChange={(e) => setCategoria(e.target.value)}
                            style={{ width: "100%" }}
                          />
                        </div>
                      </Col>{" "}
                    </Row>

                    <Row>
                      <Col>
                        <div className="form-group">
                          <TextField
                            id="outlined-basic"
                            label="Part Number"
                            //variant="outlined"
                            value={part_number}
                            onChange={(e) => setPart_Number(e.target.value)}
                            style={{ width: "100%" }}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="form-group">
                          <TextField
                            id="outlined-basic"
                            label="Valor"
                            //variant="outlined"
                            value={preco}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  R$
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => setPreco(e.target.value)}
                            style={{ width: "100%" }}
                          />
                        </div>
                      </Col>
                    </Row>

                    <div className="form-group">
                      <TextField
                        id="outlined-basic"
                        label="Local Depósito"
                        //variant="outlined"
                        value={local_deposito}
                        onChange={(e) => setLocal_Deposito(e.target.value)}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </form>

                  <Row>
                    <Col className="mb-5 mb-xl-0" xl="9">
                      {userType !== "Executante" &&
                      userType !== "Requisitante" ? (
                        <>
                          <Button color="success" onClick={update}>
                            Atualizar
                          </Button>{" "}
                          <Button color="secondary" onClick={toggle}>
                            Cancelar
                          </Button>
                        </>
                      ) : null}
                    </Col>
                    <Col className="bx-4">
                      {userType !== "Executante" &&
                      userType !== "Requisitante" ? (
                        <>
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={deletar}
                          >
                            <DeleteIcon /> Apagar
                          </Button>
                        </>
                      ) : null}
                    </Col>
                  </Row>
                </ModalBody>
              </TabPane>
            </TabContent>
          </div>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </Modal>
      <Modal
        size="lg"
        isOpen={ordersModal}
        toggle={() => setOrdersModal(!ordersModal)}
      >
        <ModalHeader
          className="mb--4"
          tag="h3"
          toggle={() => setOrdersModal(!ordersModal)}
        >
          Reservas
        </ModalHeader>
        <ModalBody>
          {resultOrders &&
            resultOrders.map((response) => {
              return (
                <Box>
                  <CardMui variant="outlined" sx={{ mb: 0.5 }}>
                    <CardContent>
                      <Typography variant="h5" component="div">
                        Número da ordem: {response?.orderNumber}
                      </Typography>
                      {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Número da ordem: {response?.orderNumber}
                        </Typography> */}
                      <Typography
                        sx={{ mb: 0.5, mt: 1 }}
                        color="text.secondary"
                      >
                        Código do equipamento: {response?.equipmentCode}
                      </Typography>
                      <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        Descrição do equipamento:{" "}
                        {response?.equipmentDescription}
                      </Typography>
                      <Typography variant="body2">
                        Quantidade: {response.quantidade}
                      </Typography>
                    </CardContent>
                    {/* <CardActions>
                        <Button size="small">Learn More</Button>
                      </CardActions> */}
                    {/* 
                      Número da ordem: {response?.orderNumber}
                      Descrição do equipamento: {response?.equipmentDescription}
                      Código do equipamento: {response?.equipmentCode} */}
                  </CardMui>
                </Box>
              );
            })}
        </ModalBody>
      </Modal>
    </>
  );
}

export default ListSpares;
