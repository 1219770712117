import { createRef } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Toolbar,
  Inject,
  Filter,
  Sort,
  Resize,
  PdfExport,
  ExcelExport,
  ColumnChooser,
} from "@syncfusion/ej2-react-grids";

import { verifyHierarchy, status } from "./templates";
import { convertDate } from "../../../../utils/convertDate";

import "./style.css";

const ref = createRef();

const Datagrid = ({ lineClicked, dataSource }) => {
  const toolbarClick = (args) => {
    if (
      args.item.text === "PDF Export" ||
      args.item.text === "Exportação de PDF"
    ) {
      ref.current.pdfExport();
    } else if (
      args.item.text === "Excel Export" ||
      args.item.text === "Exportação Excel"
    ) {
      ref.current.excelExport();
    }
  };

  const convertLastOrderCompleted = (props) => {
    return convertDate(props?.lastOrderCompleted);
  };

  const convertDeadlineCurrentOrder = (props) => {
    return convertDate(props?.deadlineCurrentOrder);
  };

  const groupOptions = { showGroupedColumn: true };

  return (
    <GridComponent
      ref={ref}
      dataSource={dataSource ?? []}
      allowPaging={true}
      allowFiltering={true}
      filterSettings={{ type: "CheckBox" }}
      allowSorting={true}
      allowTextWrap={true}
      allowResizing={true}
      allowPdfExport={true}
      allowExcelExport={true}
      enableHeaderFocus={true}
      hierarchyPrintMode={"All"}
      allowReordering={true}
      showColumnChooser={true}
      groupSettings={groupOptions}
      toolbarClick={toolbarClick}
      toolbar={["PdfExport", "ExcelExport", "Print", "Search", "ColumnChooser"]}
      rowSelected={lineClicked}
    >
      <ColumnsDirective>
        <ColumnDirective
          field="name"
          headerText="Código do plano"
          width={200}
        />
        <ColumnDirective field="periodicity" headerText="Peridicidade" />
        <ColumnDirective
          field="lastOrderCompleted"
          headerText="Conclusão da última"
          template={convertLastOrderCompleted}
        />
        <ColumnDirective
          field="deadlineCurrentOrder"
          headerText="Prazo da próxima"
          template={convertDeadlineCurrentOrder}
        />
        <ColumnDirective field="responsibleName" headerText="Responsável" />
        <ColumnDirective
          field="hierarchyType"
          headerText="Equipamento"
          template={(props) => verifyHierarchy(props, "Equipamento")}
        />
        <ColumnDirective
          field="hierarchyType"
          headerText="Setor"
          template={(props) => verifyHierarchy(props, "Setor")}
        />
        <ColumnDirective field="maintenanceType" headerText="Tipo" />
        <ColumnDirective field="status" headerText="Status" template={status} />
      </ColumnsDirective>
      <Inject
        services={[
          Page,
          Toolbar,
          Filter,
          Sort,
          ColumnChooser,
          Resize,
          PdfExport,
          ExcelExport,
        ]}
      />
    </GridComponent>
  );
};

export default Datagrid;
