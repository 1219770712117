import { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Col,
  Row,
} from "reactstrap";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextFieldMui from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import brLocale from "date-fns/locale/pt-BR";
import DateTimePicker from "@mui/lab/DateTimePicker";
import TextField from "@material-ui/core/TextField";
import moment from "moment";

import OrderService from "./OrderService";

const ModalReplan = (props) => {
  const {
    modal,
    toggle,
    order,
    nameUser,
    setOrder,
    enterprise,
    userType,
    users,
    notifyGeneralModal,
    sourceOrder,
  } = props;

  const [deadline, setDeadline] = useState(new Date());
  const [responsavel, setResponsavel] = useState({
    first_name: sourceOrder?.responsavel,
  });
  const [nomeResponsavel, setNomeResponsavel] = useState(
    sourceOrder?.responsavel
  );
  const [UsernameResponsavel, setUsernameResponsavel] = useState(
    sourceOrder?.usernameResponsavel
  );
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());

  const handleChange = () => {
    let initialWork = moment(start);
    let finishedWork = moment(end);
    const comparedHoursWorked = moment(end).diff(moment(start));
    const totalHoursWoking = moment.duration(comparedHoursWorked).asHours();
    if (totalHoursWoking < 0) {
      notifyGeneralModal(
        "warning",
        "Atenção!",
        "A data de início não pode ser maior que a data final!"
      );
      return;
    }
    const updateOrder = {
      initiateService: null,
      finishedService: null,
      finishedSeviceBtn: false,
      status: "Programada",
      performer: nameUser,
      prazo: new Date(deadline),
      usernameResponsavel: UsernameResponsavel,
      responsavel: nomeResponsavel,
      start: new Date(start),
      end: new Date(end),
    };
    console.log(updateOrder, "updateOrder");
    OrderService.updateStatusOfOrder(sourceOrder._id, updateOrder).then(
      (response) => {
        console.log("atualizou!", response.data);
        OrderService.getOrders({ empresa: enterprise }).then((orders) => {
          setOrder(orders.data.data);
        });
        toggle();
      }
    );
  };

  const AutocompleteComponent = ({ responsavel }) => {
    return (
      <div className="form-group">
        <Autocomplete
          id="free-solo-demo"
          freeSolo
          value={responsavel ?? { first_name: "" }}
          onChange={(e, v) => {
            if (v) {
              setResponsavel({ first_name: v.first_name });
              setNomeResponsavel(v?.first_name);
              setUsernameResponsavel(v?.username);
            }
          }}
          options={users}
          getOptionLabel={(option) => option.first_name}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Executante"
              margin="normal"
              variant="outlined"
            />
          )}
        />
      </div>
    );
  };

  return (
    <Modal size="lg mr-4" className="mr-0" isOpen={modal} toggle={toggle}>
      <ModalHeader>
        {" "}
        <h2> Replanejar Ordem </h2>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label> Qual o novo prazo da ordem? </label>
              <LocalizationProvider
                locale={brLocale}
                dateAdapter={AdapterDateFns}
              >
                <DatePicker
                  value={deadline}
                  onChange={(newValue) => {
                    setDeadline(newValue);
                  }}
                  renderInput={(params) => (
                    <TextFieldMui variant="standard" {...params} />
                  )}
                />
              </LocalizationProvider>
            </div>
          </Col>
        </Row>
        <div style={{ marginTop: "1rem" }} className="form-group">
          <form>
            <AutocompleteComponent responsavel={responsavel} />
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <h4> Início programado:</h4>
                <LocalizationProvider
                  locale={brLocale}
                  dateAdapter={AdapterDateFns}
                >
                  <DateTimePicker
                    value={start}
                    onChange={(newValue) => {
                      setStart(newValue);
                    }}
                    renderInput={(params) => <TextFieldMui {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <div style={{ marginLeft: "5em" }}>
                <h4> Fim programado:</h4>
                <LocalizationProvider
                  locale={brLocale}
                  dateAdapter={AdapterDateFns}
                >
                  <DateTimePicker
                    value={end}
                    onChange={(newValue) => {
                      setEnd(newValue);
                    }}
                    renderInput={(params) => <TextFieldMui {...params} />}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </form>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          className="mt--2 col-auto"
          onClick={handleChange}
        >
          Replanejar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalReplan;
