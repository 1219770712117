const { createContext, useContext, useState } = require("react");

export const EnterpriseNameContext = createContext();

// export const EnterpriseName = ({ children }) => {

//     const [enterpriseName, setEnterpriseName] = useState('');
//     return (
//         <EnterpriseNameContext.Provider value={{ enterpriseName, setEnterpriseName, testar: true}}>
//             {children}
//         </EnterpriseNameContext.Provider>
//     );
// }

// export const useEnterpriseName = () => {
//     useContext(EnterpriseNameContext);
// }
