import { convertDate } from "../../utils/convertDate.js";
import "./style.css";

const DateTimePicker = ({ setDate, date, placeholder, disable }) => {
  const brDateFormat = "YYYY-MM-DDTHH:mm";
  let dateValue = date ? new Date(date) : new Date();
  return (
    <>
      <input
        disabled={disable ?? false}
        placeholder={placeholder}
        value={convertDate(dateValue, brDateFormat)}
        className="datetimePicker"
        type={"datetime-local"}
        onChange={(e) => {
          if (setDate) {
            setDate(e.target.value);
          }
        }}
      />
    </>
  );
};

export default DateTimePicker;
