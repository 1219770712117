import { Badge } from "reactstrap";

const templateStatus = (props) => {
  switch (props?.status) {
    case "Aberta":
      return (
        <Badge color="" className="badge-dot mr-4">
          <i
            style={{
              background: "#1474EE",
              borderRadius: "50%",
            }}
          />
          Aberta
        </Badge>
      );
    case "Material Reservado":
      return (
        <Badge color="" className="badge-dot mr-4">
          <i
            style={{
              background: "#44D978",
              borderRadius: "50%",
            }}
          />
          Material Reservado
        </Badge>
      );
    case "Atrasada":
      return (
        <Badge color="" className="badge-dot mr-4">
          <i
            style={{
              background: "#F5365C",
              borderRadius: "50%",
            }}
          />
          Atrasada
        </Badge>
      );
    case "Programada":
      return (
        <Badge color="" className="badge-dot mr-4">
          <i
            style={{
              background: "#78E3FD",
              borderRadius: "50%",
            }}
          />
          Programada
        </Badge>
      );
    case "Concluída":
      return (
        <Badge color="" className="badge-dot mr-4">
          <i
            style={{
              background: "#44D978",
              borderRadius: "50%",
            }}
          />
          Concluída
        </Badge>
      );
    case "Replanejar":
      return (
        <Badge color="" className="badge-dot mr-4">
          <i
            style={{
              background: "#ff7709",
              borderRadius: "50%",
            }}
          />
          Replanejar
        </Badge>
      );

    default:
      return (
        <Badge color="" className="badge-dot mr-4">
          <i
            style={{
              background: "#1474EE",
              borderRadius: "50%",
            }}
          />
          Aberta
        </Badge>
      );
  }
};

export default templateStatus;
