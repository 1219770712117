import axios from "axios";

const EQUIPMENTS_API_BASE_URL = "https://api.darwinx.com.br/equipment/area";

class OrderService {
  getAllEquipments(filter) {
    return axios.get(EQUIPMENTS_API_BASE_URL, { params: filter });
  }
}

export default new OrderService();
