// GESTÃO DE USUARIOS

import axios from "axios";

const USER_API_BASE_URL = "https://api.darwinx.com.br/users";

class UserService {
  getUsers(user) {
    return axios.get(USER_API_BASE_URL, { params: user });
  }

  getTimelineUsers(filter) {
    return axios.post(USER_API_BASE_URL + "/timeline/get-many-users", filter);
  }

  createUser(user) {
    return axios.post(USER_API_BASE_URL, user);
  }

  getUserById(userId) {
    return axios.get(USER_API_BASE_URL + "/" + userId);
  }

  updateUser(user, userId) {
    return axios.put(USER_API_BASE_URL + "/" + userId, user);
  }

  deleteUser(userId) {
    return axios.delete(USER_API_BASE_URL + "/" + userId);
  }
}

export default new UserService();
