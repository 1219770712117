// GESTÃO DE Estoque DE MANUTENÇÂO

import axios from "axios";

const SPARE_API_BASE_URL = "https://api.darwinx.com.br/spares";

class SpareService {
  getSpares(spare) {
    return axios.get(SPARE_API_BASE_URL, { params: spare });
  }

  createSpare(spare) {
    return axios.post(SPARE_API_BASE_URL, spare);
  }

  getSpareById(spareId, token) {
    return axios.get(SPARE_API_BASE_URL + "/" + spareId, { params: token });
  }

  updateSpare(spare, spareId) {
    return axios.put(SPARE_API_BASE_URL + "/" + spareId, spare);
  }

  deleteSpare(spareId, token) {
    return axios.delete(SPARE_API_BASE_URL + "/" + spareId, { params: token });
  }
}

export default new SpareService();
