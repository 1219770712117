import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  memo,
  useCallback,
} from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  Table,
  Media,
  Card,
  CardBody,
} from "reactstrap";
import TextField from "@material-ui/core/TextField";
import { EnterpriseNameContext } from "../../context/EnterpriseContext";

import CloseIcon from "@mui/icons-material/Close";

import swal from "sweetalert";

import Autocomplete from "@material-ui/lab/Autocomplete";

import IconButton from "@material-ui/core/IconButton";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";

import SpareService from "../Spares/SpareService";
import UserService from "../Users/UserService";
import EquipmentService from "../Equipments/EquipmentService";

import ptLocale from "date-fns/locale/pt-BR";

import InputAdornment from "@material-ui/core/InputAdornment";

import { useHistory } from "react-router-dom";
import "./CreateStrategy.css";

import PlanService from "./PlanService";

import { Checkbox } from "@mui/material";

import GetAllAreasByEnterprise from "../Orders/services/GetAllAreasByEnterprise";
import GetAllEquipmentsByCustomFilter from "./services/GetAllEquipmentsByCustomFilter";
import GetAllSectorsByEnterprise from "./services/GetAllSectorsByEnterprise";
import Select from "../Select";

function CreateStrategy(props) {
  const { enterpriseName } = useContext(EnterpriseNameContext);

  let [count, setCount] = useState(0);
  const [spares, setSpares] = useState([]);
  const [name, setName] = useState("");
  const [periodicity, setPeriodicity] = useState();
  const [periodicityType, setPeriodicityType] = useState("Tempo");
  const [time, setTime] = useState(0);
  const [description, setDescription] = useState("");
  const [maintenanceType, setMaintenanceType] = useState("");
  const [hierarchy, setHierarchy] = useState("");
  const [equipmentId, setEquipmentId] = useState("");
  const [hierarchyType, setHierarchyType] = useState("Equipamento");

  const [stateCheckbox, setStateCheckbox] = useState("Tempo");
  const [stateMaterial, setStateMaterial] = useState([]);
  const [material, setMaterial] = useState("");
  const [partNumber, setPartNumber] = useState("");
  const [quantity, setQuantity] = useState("");
  const [performers, setPerformers] = useState([]);
  const [responsibleEmail, setResponsibleEmail] = useState("");
  const [responsibleName, setResponsibleName] = useState("");
  const [username, setUsername] = useState({ first_name: "" });

  const [areas, setAreas] = useState([]);
  const [areaSelected, setAreaSelected] = useState("");

  const [sectorSelected, setSectorSelected] = useState("");

  const [sectors, setSectors] = useState([]);

  const [equipments, setEquipments] = useState([]);

  const [initialPlan, setInitialPlan] = useState(new Date());

  const [materialSelected, setMaterialSelected] = useState({});
  const [area, setArea] = useState("");
  const [sector, setSector] = useState("");

  const [stateCheckboxHierarchy, setStateCheckboxHierarchy] =
    useState("Equipamento");
  const [horimetroColuna] = useState([
    "8 horas",
    "200 horas",
    "1000 horas",
    "1200 horas",
    "2400 horas",
  ]);
  const [periodicidadeColuna] = useState([
    "Diário",
    "Semanal",
    "Quinzenal",
    "Mensal",
    "Bimestral",
    "Trimestral",
    "Semestral",
    "Anual",
    "Bianual",
  ]);

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [token] = useState(() => {
    const tokenStoraged = localStorage.getItem("TOKEN_KEY");
    if (tokenStoraged) {
      return tokenStoraged;
    }
    return "";
  });
  const [hash] = useState(() => {
    const hashStoraged = localStorage.getItem("TOKEN_HASH");
    if (hashStoraged) {
      return hashStoraged;
    }
    return "";
  });
  const [idUserStorage] = useState(() => {
    const idUserStoraged = localStorage.getItem("TOKEN_ID_USER");
    if (idUserStoraged) {
      return idUserStoraged;
    }
    return "";
  });
  const [nameUser] = useState(() => {
    const userNameStoraged = window.localStorage.getItem("TOKEN_USERNAME");

    if (userNameStoraged) {
      return userNameStoraged;
    }
    return;
  });

  useEffect(() => {
    GetAllSectorsByEnterprise.getSectors({
      enterprise: enterpriseName,
    })
      .then((res) => {
        setSectors(res.data.equipmentArea);
      })
      .catch((error) => {
        console.log(error);
      });

    GetAllAreasByEnterprise.getAllAreas({
      enterprise: enterpriseName,
    })
      .then((res) => {
        setAreas(res.data.equipmentArea);
      })
      .catch((error) => {
        console.log(error);
      });
    SpareService.getSpares({ empresa: enterpriseName }).then((spare) => {
      console.log("spare:", spare.data.data);
      setSpares(spare.data.data);
    });
    UserService.getUsers({
      empresa: enterpriseName,
    }).then((response) => {
      setPerformers(response.data);
    });
    EquipmentService.getEquipment({ empresa: enterpriseName }).then(
      (equipment) => {
        setEquipments(equipment.data.data);
      }
    );
  }, []);

  const handleSubmit = (evt) => {
    evt.preventDefault();

    swal("Deseja criar um novo plano?", "", "warning", {
      buttons: {
        nope: {
          text: "Me enganei!",
          value: "nope",
        },
        sure: {
          text: "Sim, claro.",
          value: "sure",
        },
      },
    }).then((value) => {
      switch (value) {
        case "sure":
          swal("Plano criado com sucesso!", "", "success").then((val) => {
            const source = {
              name,
              startDate: initialPlan,
              periodicity,
              periodicityType,
              time,
              area,
              sector,
              description,
              maintenanceType,
              hierarchy,
              hierarchyType,
              status: true,
              enterprise: enterpriseName,
              materials: stateMaterial,
              taskList: descriptionTotal,
              responsibleEmail,
              responsibleName,
              equipments: equipmentsSelected,
              owner: nameUser,
            };
            PlanService.createPlan(source).then((response) => {
              PlanService.getPlan({ enterprise: enterpriseName }).then(
                (plans) => {
                  props.setPlan(plans.data.data);
                }
              );
              toggle();
              setName("");
              setDescription("");
              setEquipmentId("");
              setResponsibleEmail("");
              setResponsibleName("");
              setStateMaterial([]);
              setTaskItem([]);
              setDescriptionTotal([]);
              setHierarchy("");
              setHierarchyType("Equipamento");
              setMaintenanceType("");
              setUsername({ first_name: "" });
              setTime(0);
              setCount(0);
              setInitialPlan(new Date());
            });
          });
          break;
        case "nope":
          swal("Operação cancelada", "", "success");
          break;
        default:
          return;
      }
    });
  };

  const [descriptionTotal, setDescriptionTotal] = useState([]);

  const CheckboxUI = memo(({ task, label, id }) => {
    const [text, setText] = useState(label);

    const onChange = (e) => {
      descriptionTotal[id].description = e.target.value;

      setText(e.target.value);
    };

    return (
      <Row style={{ marginTop: "15px" }} key={id}>
        <Checkbox checked={false} onChange={() => {}} />
        <TextField
          style={{ width: "90%" }}
          placeholder="Digite aqui sua tarefa"
          onChange={onChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    const filter = taskItem.filter((tasks) => {
                      if (tasks.id === task.id) {
                        return false;
                      }
                      return true;
                    });
                    console.log("filter: ", filter);
                    setTaskItem(filter);
                    const filtered = descriptionTotal.filter((description) => {
                      if (description.id === task.id) {
                        return false;
                      }
                      return true;
                    });

                    console.log("filtered: ", filtered);
                    setDescriptionTotal(filtered);
                    setCount((count = count - 1));
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={
            text
              ? text
              : descriptionTotal[id].description
              ? descriptionTotal[id].description
              : ""
          }
        />
      </Row>
    );
  });

  const [taskItem, setTaskItem] = useState([]);
  const Task = memo((props) => {
    return (
      <>
        <div
          style={{ width: "100%", marginLeft: "10px", marginTop: "25px" }}
          className="form-group"
        >
          <h4> Lista de tarefas: </h4>
          {taskItem.map((task, index) => {
            return <CheckboxUI task={task} id={index} key={index} />;
          })}
          {taskItem.length === 0 && (
            <p style={{ textAlign: "center" }}>Nenhuma tarefa adicionada!</p>
          )}
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            color="primary"
            onClick={() => {
              setTaskItem([
                ...taskItem,
                {
                  description: "",
                  id: count,
                },
              ]);
              setDescriptionTotal([
                ...descriptionTotal,
                {
                  description: "",
                  id: count,
                  state: false,
                },
              ]);
              setCount((count = count + 1));
            }}
          >
            Nova tarefa
          </Button>
        </div>
      </>
    );
  });

  const filterEquipments = (area, sector) => {
    if (area && !sector) {
      GetAllEquipmentsByCustomFilter.getEquipments({
        area: area,
        empresa: enterpriseName,
      }).then((response) => {
        setEquipments(response.data.equipments);
      });
    } else if (sector && area) {
      GetAllEquipmentsByCustomFilter.getEquipments({
        area: area,
        setor_instalado: sector,
        empresa: enterpriseName,
      }).then((response) => {
        setEquipments(response.data.equipments);
      });
    } else if (!area && sector) {
      console.log("sector: ", sector);
      GetAllEquipmentsByCustomFilter.getEquipments({
        setor_instalado: sector,
        empresa: enterpriseName,
      }).then((response) => {
        console.log("response: ", response.data);
        setEquipments(response.data.equipments);
      });
    } else {
      EquipmentService.getEquipment({
        empresa: enterpriseName,
      })
        .then((res) => {
          if (res.data.data) {
            const sorted = res.data.data?.sort((compareA, compareB) => {
              if (compareA.denominacao > compareB.denominacao) {
                return 1;
              }
              if (compareA.denominacao < compareB.denominacao) {
                return -1;
              }
              return 0;
            });
            setEquipments(sorted);
          }
        })
        .catch((error) => {});
    }
  };

  const adapterEquipmentSource = useCallback(() => {
    return equipments.map((equipment) => {
      return {
        value: equipment?._id,
        label: equipment?.codigo + " - " + equipment?.denominacao,
      };
    });
  }, [equipments]);

  useEffect(() => {
    setEquipmentsOptions(adapterEquipmentSource());
  }, [equipments]);

  const [equipmentsOptions, setEquipmentsOptions] = useState([]);

  const [equipmentsSelected, setEquipmentsSelected] = useState([]);

  return (
    <div>
      <Button
        color="success"
        size="sm"
        className="position-absolute top-3 right-9"
        onClick={toggle}
      >
        {" "}
        Novo Plano{" "}
      </Button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        size="lg"
        className="mr-0 mt--1 mb--1 mt-5"
        // style={{ maxWidth: "1653px }}
      >
        <ModalHeader toggle={toggle} tag="h3" className="mt-3">
          Novo Plano de Manutenção
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                onChange={(e, v) => {
                  if (v) {
                    setArea(v);
                    filterEquipments(v, sector);
                  } else {
                    filterEquipments(null, sector);
                    setArea("");
                  }
                }}
                value={area}
                options={areas}
                renderInput={(params) => (
                  <TextField
                    value={area}
                    {...params}
                    variant="outlined"
                    placeholder="Ex.: Área 1"
                    label="Filtre seus equipamentos pela área"
                    margin="normal"
                  />
                )}
              />
            </div>
            <div className="form-group">
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                onChange={(e, v) => {
                  if (v) {
                    setSector(v);
                    filterEquipments(area, v);
                  } else {
                    filterEquipments(area, null);
                    setSector("");
                  }
                }}
                value={sector}
                options={sectors}
                renderInput={(params) => (
                  <TextField
                    value={sector}
                    {...params}
                    variant="outlined"
                    placeholder="Ex.: Utilidades"
                    label="Filtrar por Setor Instalado"
                    margin="normal"
                  />
                )}
              />
            </div>
            <div>
              <Select
                isMulti
                options={equipmentsOptions}
                placeholder="Selecione os equipamentos"
                onChange={(e) => {
                  setEquipmentsSelected(e);
                }}
                value={equipmentsSelected}
              />

              {/* <Autocomplete
                  id="outlined-basic"
                  label="Tipo"
                  variant="outlined"
                  freeSolo
                  style={{ marginTop: "-15px", width: "100%" }}
                  onChange={(e, v) => {
                    if (v) {
                      if (hierarchyType === "Equipamento") {
                        setHierarchy(v.denominacao);
                        setEquipmentId(v._id);
                      } else if (hierarchyType === "Setor") {
                        setHierarchy(v.setor_instalado);
                        setEquipmentId(v._id);
                      }
                    }
                  }}
                  options={equipments}
                  getOptionLabel={(option) => {
                    if (hierarchyType === "Setor" && option.setor_instalado) {
                      return option.setor_instalado;
                    }
                    if (hierarchyType === "Equipamento" && option.denominacao) {
                      return option.codigo + " - " + option.denominacao;
                    } else {
                      return "";
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      {...params}
                      label={
                        hierarchyType === "Setor" ? "Setor" : "Equipamento"
                      }
                      placeholder={
                        hierarchyType === "Setor"
                          ? "Ex.: Utilidades"
                          : "Ex.: Bomba Centrífuga"
                      }
                      margin="normal"
                    />
                  )}
                /> */}
            </div>
            <div className="form-group">
              <TextField
                id="outlined-basic"
                label="Código do plano"
                style={{ width: "100%" }}
                variant="outlined"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                placeholder="Ex.: PMP-01"
              />
            </div>
            <div className="form-group">
              {/* <h4>Início do plano</h4> */}
              <LocalizationProvider
                locale={ptLocale}
                dateAdapter={AdapterDateFns}
              >
                <DatePicker
                  label="Início do plano"
                  value={initialPlan}
                  onChange={(newValue) => {
                    setInitialPlan(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      style={{ width: "100%" }}
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: "-10px",
                  alignItems: "center",
                }}
              >
                <h4>Periodicidade</h4>
                <div className="mt-3">
                  <input
                    onClick={() => {
                      setPeriodicityType("Tempo");
                    }}
                    checked={periodicityType === "Tempo"}
                    type="radio"
                    value="Tempo"
                    name="Tempo"
                  />{" "}
                  Tempo
                  <input
                    onClick={() => {
                      setPeriodicityType("Horímetro");
                    }}
                    type="radio"
                    value="Horímetro"
                    name="Horímetro"
                    checked={periodicityType === "Horímetro"}
                    className="ml-4 mb-4"
                  />{" "}
                  Horimetro
                </div>
              </div>
              <div>
                <Autocomplete
                  id="outlined-basic"
                  label="Tipo"
                  variant="outlined"
                  freeSolo
                  value={periodicity}
                  style={{ marginTop: "-15px", width: "100%" }}
                  onChange={(e, v) => {
                    setPeriodicity(v);
                  }}
                  options={periodicidadeColuna}
                  renderInput={(params) => (
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      {...params}
                      placeholder="Ex.: Diário"
                      label={
                        periodicityType === "Horímetro"
                          ? "Em quanto tempo quer ser lembrado?"
                          : "Periodicidade"
                      }
                      margin="normal"
                      //variant="outlined"
                      onChange={(e) => {
                        console.log(e.target.value);
                      }}
                    />
                  )}
                />
                {periodicityType === "Horímetro" && (
                  <TextField
                    type="number"
                    id="outlined-basic"
                    label="Horímetro"
                    value={time}
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                    onChange={(e) => {
                      setTime(e.target.value);
                    }}
                    variant="outlined"
                    placeholder="Ex.: 1000 (horas)"
                  />
                )}
              </div>
            </div>
            <div>
              <h4>Detalhes da Ordem</h4>
              <TextField
                id="outlined-basic"
                label="Descrição"
                style={{ width: "100%", marginTop: "10px" }}
                variant="outlined"
                placeholder="Ex.: Plano semanal"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
              <Autocomplete
                id="outlined-basic"
                label="Tipo"
                variant="outlined"
                freeSolo
                style={{ width: "100%", marginTop: "0px" }}
                options={["Inspeção", "Preditiva", "Preventiva"]}
                value={maintenanceType}
                onChange={(e, v) => {
                  setMaintenanceType(v);
                }}
                renderInput={(params) => (
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    {...params}
                    label="Tipo de manutenção"
                    placeholder="Ex.: Inspeção"
                    margin="normal"
                    onChange={(e) => {
                      console.log(e.target.value);
                    }}
                  />
                )}
              />
            </div>
            {/* <div style={{ marginTop: "10px" }}>
              <h4>Material</h4>
              <Autocomplete
                id="outlined-basic"
                label="Tipo"
                key="alsfikasf"
                variant="outlined"
                freeSolo
                value={materialSelected}
                onChange={(e, v) => {
                  if (v) {
                    setMaterial(v.produto);
                    setPartNumber(v.part_number);
                    setMaterialSelected(v);
                  }
                }}
                getOptionLabel={(option) => {
                  if (option.produto) {
                    return option.produto + " - " + option.part_number;
                  }
                  if (option.part_number) {
                    return option.produto + " - " + option.part_number;
                  } else {
                    return "";
                  }
                }}
                style={{ width: "100%", marginTop: "-10px" }}
                options={spares}
                renderInput={(params) => (
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    {...params}
                    label="Material"
                    margin="normal"
                    onChange={(e) => {
                      console.log(e.target.value);
                    }}
                  />
                )}
              />
              <TextField
                type="number"
                id="outlined-basic"
                label="Quantidade"
                style={{ width: "100%", marginTop: "10px" }}
                variant="outlined"
                placeholder="Ex.: 10 (UND)"
                value={quantity}
                onChange={(e) => {
                  setQuantity(e.target.value);
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                  marginBottom: "10px",
                }}
              >
                <Button
                  onClick={() => {
                    if (material && quantity && partNumber) {
                      setStateMaterial([
                        ...stateMaterial,
                        { material, quantity, partNumber },
                      ]);
                      setQuantity("");
                      setMaterial("");
                      setPartNumber("");
                      setMaterialSelected({});
                    }
                  }}
                >
                  Adicionar material
                </Button>
              </div>
              {stateMaterial.length > 0 && (
                <>
                  <h4>Lista de materiais</h4>
                  <Card className="shadow-lg">
                    <CardBody>
                      <Table
                        className="align-items-center table-flush"
                        responsive
                        style={{ tableLayout: "fixed" }}
                      >
                        <thead className="thead-light">
                          <tr>
                            <th className="caractersTableSize" scope="col">
                              Material
                            </th>
                            <th className="caractersTableSize" scope="col">
                              Part Number
                            </th>
                            <th className="caractersTableSize" scope="col">
                              Quantidade
                            </th>
                            <th className="caractersTableSize" scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          {stateMaterial &&
                            stateMaterial.map((row, index) => (
                              <tr key={index}>
                                <th className="caractersTableSize" scope="row">
                                  <Media className="align-items-center">
                                    <Media>
                                      <span className="mb-0 text-sm">
                                        {row.material}
                                      </span>
                                    </Media>
                                  </Media>
                                </th>

                                <th className="caractersTableSize" scope="row">
                                  <Media className="align-items-center">
                                    <Media>
                                      <span className="mb-0 text-sm">
                                        {row.partNumber}
                                      </span>
                                    </Media>
                                  </Media>
                                </th>
                                <th className="caractersTableSize" scope="row">
                                  <Media className="align-items-center">
                                    <Media>
                                      <span className="mb-0 text-sm">
                                        {row.quantity}
                                      </span>
                                    </Media>
                                  </Media>
                                </th>
                                <th className="caractersTableSize" scope="row">
                                  <Media className="align-items-center">
                                    <Media>
                                      <IconButton
                                        onClick={() => {
                                          const removed = stateMaterial.filter(
                                            (material) => {
                                              if (material === row) {
                                                return false;
                                              } else {
                                                return true;
                                              }
                                            }
                                          );
                                          setStateMaterial(removed);
                                        }}
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </Media>
                                  </Media>
                                </th>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </>
              )}
            </div> */}
            <div>
              <Task />
            </div>
            <div>
              <h4>Responsável</h4>
              <Autocomplete
                id="outlined-basic"
                label="Tipo"
                variant="outlined"
                freeSolo
                value={username}
                style={{ marginTop: "-5px", width: "100%" }}
                onChange={(e, v) => {
                  if (v) {
                    setUsername({ first_name: v.first_name });
                    setResponsibleEmail(v.username);
                    setResponsibleName(v.first_name);
                  }
                }}
                getOptionLabel={(option) => {
                  if (option.first_name) {
                    return option.first_name;
                  } else {
                    // return option.codigo + option.denominacao;
                    return "";
                  }
                }}
                options={performers}
                renderInput={(params) => (
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    {...params}
                    label={"Responsável"}
                    margin="normal"
                    //variant="outlined"
                    onChange={(e) => {
                      console.log(e.target.value);
                    }}
                  />
                )}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <Button type="submit" color="success">
                Criar
              </Button>{" "}
              <Button
                color="secondary"
                onClick={() => {
                  toggle();
                  // setCodigo()
                }}
              >
                Cancelar
              </Button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default CreateStrategy;
