import styled from "styled-components";

const DatePickerComponent = styled.input`
  padding: 0.4em 0.5em;
  border-radius: 5px;
  border: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);

  &:focus {
    border-bottom: 2px solid #2b70d3;
  }
  &::placeholder {
    color: rgba(0, 0, 0, 0.2);
  }

  &::content {
    color: transparent;
  }
`;

export { DatePickerComponent };
