import React, { useState, useEffect, useContext, createRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  Card,
  CardBody,
  Table,
  Media,
  Badge,
} from "reactstrap";
import SpareService from "./SpareService";
//import SpareReserve from '../Strategy/SpareService'
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { EnterpriseNameContext } from "../../context/EnterpriseContext";
import OrderService from "../Orders/OrderService";
import { UserType } from "../../context/UserType";
import Spare_Reserve_Service from "../Orders/Spare_Reserve_Service";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import SpareMovimentService from "./SpareMoviment_Service";
import swal from "sweetalert";
import NotificationAlert from "react-notification-alert";
// import { useHistory } from "react-router-dom";
import { CartContext } from "../../context/Cart";

const notification = createRef();

function ExitSpare(props) {
  const {
    // buttonLabel,
    className,
  } = props;

  const { setSpare } = useContext(CartContext);

  // const history = useHistory();
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const { enterpriseName } = useContext(EnterpriseNameContext);
  const { userType } = useContext(UserType);

  const [order, setOrder] = useState([]);
  const [equipamento, setEquipamento] = useState([]);
  const [descricao, setDescricao] = useState([]);
  const [responsavel, setResponsavel] = useState([]);
  const [idOrder, setIdOrder] = useState();
  const [spareReserve, setSpareReserve] = useState([]);
  const [setorInstalado, setSetorInstalado] = useState();
  const [orderStatus, setOrderStatus] = useState(false);
  const [nameUser, setNameUser] = useState(() => {
    const userNameStoraged = window.localStorage.getItem("TOKEN_USERNAME");

    if (userNameStoraged) {
      return userNameStoraged;
    }
    return;
  });

  const notifyCode = (event, title, content) => {
    let options = {
      place: "tc",
      message: (
        <div>
          <h2
            style={{ color: "#fff", marginBottom: "0" }}
            className="alert-title"
            data-notify="title"
          >
            {" "}
            {title ? title : "Atenção!"}
            <br />
          </h2>
          <span data-notify="message">
            {content ? content : "Este código já foi cadastrado!"}
          </span>
        </div>
      ),
      type: event,
      // icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    notification.current.notificationAlert(options);
  };

  // const redirectLogin = () => {
  //   swal(
  //     "Hmm... vi que você não está logado. Clique em ok para fazer o login",
  //     "",
  //     "warning",
  //     {
  //       buttons: {
  //         // nope: {
  //         //   text: 'Me enganei!',
  //         //   value: 'nope',
  //         // },
  //         sure: {
  //           text: "Ok",
  //           value: "sure",
  //         },
  //       },
  //     }
  //   ).then((value) => {
  //     switch (value) {
  //       case "sure":
  //         swal("Operação realizada com sucesso!", "", "success").then((val) => {
  //           // history.pushState
  //           // return <Redirect to="/auth/login"/>
  //           history.push("/auth/login");
  //         });
  //         break;
  //       case "nope":
  //         swal("Operação cancelada", "", "success");
  //         break;
  //       default:
  //     }
  //   });
  // };

  useEffect(() => {
    OrderService.getOrders({ empresa: enterpriseName }).then((res) =>
      setOrder(res.data.data)
    );
  }, [enterpriseName]);

  const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      "&$checked": {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  function Order_selected(order_id) {
    if (order_id) {
      setIdOrder(order_id);
      console.log("id:", order_id);
      Spare_Reserve_Service.getSparesReserve({
        empresa: enterpriseName,
        ordem_id: order_id,
      }).then((response) => {
        const sparereserve = response.data;
        setSpareReserve(response.data);
        const verifyAllProvided = sparereserve.every(
          (spareReserve) => spareReserve.status === "Entregue"
        );
        if (verifyAllProvided) {
          const updateOrder = {
            stateMaterialProvided: true,
          };
          OrderService.updateOrder(updateOrder, order_id).then(
            async (response) => {
              const res = await SpareService.getSpares({
                empresa: enterpriseName,
              });
              setSpare(res?.data?.data);
            }
          );
        }
      });
      OrderService.getOrderById(order_id).then((res) => {
        let orders = res.data.data;
        setEquipamento(orders.equipamento);
        // setArea(orders.area)
        setSetorInstalado(orders.setor_equipamento);
        setDescricao(orders.equipamento_denominacao);
        setResponsavel(orders.responsavel);
      });
    }
  }
  const statusReserva = (reserva) => {
    console.log(reserva);

    if (reserva.status === "Reservado") {
      Spare_Reserve_Service.getSpareReserveById(reserva._id)
        .then((response) => {
          console.log(response.data);
          const resultSpareReserve = response.data;
          resultSpareReserve.status = "Entregue";
          swal(
            `Confirmar a entrega do material ${reserva.produto}?`,
            "",
            "warning",
            {
              buttons: {
                nope: {
                  text: "Me enganei!",
                  value: "nope",
                },
                sure: {
                  text: "Sim, claro.",
                  value: "sure",
                },
              },
            }
          ).then((value) => {
            switch (value) {
              case "sure":
                swal("Material entregue com sucesso!", "", "success").then(
                  (val) => {
                    SpareService.getSpareById(reserva.spare_id, {
                      // token: token,
                      // hash: hash,
                      // idUser: idUserStorage,
                    }).then((response) => {
                      let spare = response.data.data;
                      // localStorage.removeItem("TOKEN_KEY");
                      // localStorage.setItem("TOKEN_KEY", response.data.token);
                      spare.quantidade = spare.quantidade - reserva.quantidade;
                      spare.itens_reservados =
                        spare.itens_reservados - reserva.quantidade;
                      // spare.token = token;
                      // spare.hash = hash;
                      // spare.isUser = idUserStorage;

                      SpareService.updateSpare(spare, reserva.spare_id).then(
                        (response) => {
                          console.log(response.data);
                        }
                      );
                    });
                    Spare_Reserve_Service.updateSpareReserve(
                      resultSpareReserve,
                      reserva._id
                    )
                      .then((response) => {
                        console.log(response.data);

                        Spare_Reserve_Service.getSparesReserve({
                          empresa: enterpriseName,
                          ordem_id: idOrder,
                        }).then((response) => {
                          setSpareReserve(response.data);

                          if (
                            response.data.every(
                              (spareReserve) =>
                                spareReserve.status === "Entregue"
                            )
                          ) {
                            console.log("entrou aqui!");
                            const updateOrder = {
                              stateMaterialProvided: true,
                            };
                            OrderService.updateOrder(updateOrder, idOrder).then(
                              (response) => {
                                console.log("atualizou:", response.data);
                              }
                            );
                          } else {
                            const updateOrder = {
                              stateMaterialProvided: false,
                            };
                            OrderService.updateOrder(updateOrder, idOrder).then(
                              (response) => {
                                console.log("atualizou:", response.data);
                              }
                            );
                          }
                        });
                      })
                      .catch((error) => {
                        console.log(error);
                      });

                    const request = {
                      acao: "Saída", //Entrou ou saiu
                      responsavel: nameUser,
                      quantidade: reserva.quantidade,
                      empresa: enterpriseName,
                      ordemResponsavel: reserva.ordem_id,
                      codigoSpare: reserva.produto,
                      partNumberSpare: reserva.part_number,
                      idOrder: resultSpareReserve.orderNumber,
                      equipmentArea: resultSpareReserve.equipmentArea,
                      precoAtual: reserva.custoTotal,
                    };
                    SpareMovimentService.createSpareMoviment(request).then(
                      (response) => {
                        console.log(response.data);
                      }
                    );

                    SpareService.getSpares({
                      empresa: enterpriseName,
                    })
                      .then((response) => {
                        props.setSpare(response.data.data);
                        setSpare(response?.data?.data);
                      })
                      .catch((error) => {
                        if (error?.response?.data?.auth) {
                        } else {
                          // redirectLogin();
                        }
                      });
                  }
                );
                break;
              case "nope":
                swal("Operação cancelada", "", "success");
                break;
              default:
                swal("Operação cancelada!");
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
      // SpareService.getSpares({empresa: enterpriseName}).then((response=>{
      //   props.setSpare(response.data);
      // }))
    } else {
      swal(
        `Deseja desfazer a entrega do produto  ${reserva.produto}?`,
        "",
        "warning",
        {
          buttons: {
            nope: {
              text: "Me enganei!",
              value: "nope",
            },
            sure: {
              text: "Sim, claro.",
              value: "sure",
            },
          },
        }
      ).then((value) => {
        switch (value) {
          case "sure":
            swal("Entrega cancelada com sucesso!", "", "success").then(
              (val) => {
                Spare_Reserve_Service.getSpareReserveById(reserva._id).then(
                  (response) => {
                    const resultSpareReserve = response.data;
                    resultSpareReserve.status = "Reservado";

                    Spare_Reserve_Service.updateSpareReserve(
                      resultSpareReserve,
                      reserva._id
                    )
                      .then((response) => {
                        console.log(response.data);

                        SpareService.getSpareById(reserva.spare_id, {
                          // token: token,
                          // hash: hash,
                          // idUser: idUserStorage,
                        })
                          .then((response) => {
                            let spare = response.data.data;
                            // localStorage.removeItem("TOKEN_KEY");
                            // localStorage.setItem(
                            //   "TOKEN_KEY",
                            //   response.data.token
                            // );
                            spare.quantidade =
                              spare.quantidade + reserva.quantidade;
                            // spare.token = token;
                            // spare.hash = hash;
                            // spare.isUser = idUserStorage;

                            SpareService.updateSpare(
                              spare,
                              reserva.spare_id
                            ).then((response) => {
                              console.log(response.data);
                            });
                          })
                          .catch((error) => {
                            if (error?.response?.data?.auth) {
                            } else {
                              // redirectLogin();
                            }
                          });

                        Spare_Reserve_Service.getSparesReserve({
                          empresa: enterpriseName,
                          ordem_id: idOrder,
                        }).then((response) => {
                          setSpareReserve(response.data);
                          if (
                            response.data.every(
                              (spareReserve) =>
                                spareReserve.status === "Entregue"
                            )
                          ) {
                            const updateOrder = {
                              stateMaterialProvided: true,
                            };
                            OrderService.updateOrder(updateOrder, idOrder).then(
                              (response) => {
                                console.log("atualizou:", response.data);
                              }
                            );
                          } else {
                            const updateOrder = {
                              stateMaterialProvided: false,
                            };
                            OrderService.updateOrder(updateOrder, idOrder).then(
                              (response) => {
                                console.log("atualizou:", response.data);
                              }
                            );
                          }
                        });
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }
                );
                SpareService.getSpares({
                  empresa: enterpriseName,
                  // token: token,
                  // hash: hash,
                  // idUser: idUserStorage,
                }).then((response) => {
                  props.setSpare(response.data.data);
                });
              }
            );
            break;
          case "nope":
            swal("Operação cancelada", "", "success");
            break;
          default:
            swal("Operação cancelada!");
        }
      });
      // SpareService.getSpares({empresa: enterpriseName}).then((response=>{
      //   props.setSpare(response.data);
      // }))
    }
    SpareService.getSpares({
      empresa: enterpriseName,
      // token: token,
      // hash: hash,
      // idUser: idUserStorage,
    })
      .then((response) => {
        props.setSpare(response.data.data);
        // localStorage.removeItem("TOKEN_KEY");
        // localStorage.setItem("TOKEN_KEY", response.data.token);
      })
      .catch((error) => {
        if (error?.response?.data?.auth) {
        } else {
          // redirectLogin();
        }
      });
    console.log(props.spare);
  };

  return (
    <div>
      {userType !== "Executante" && userType !== "Requisitante" ? (
        <>
          <Button
            color="success"
            size="sm"
            className="position-absolute top-3 right-4"
            onClick={toggle}
          >
            {" "}
            Saída de material{" "}
          </Button>
        </>
      ) : null}
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={className}
        size="lg"
        className="mr-0 mt--1 mb--1 mt-5"
      >
        <NotificationAlert ref={notification} />
        <ModalHeader toggle={toggle} tag="h3" className="mt-3 mb--3">
          Saída de material
        </ModalHeader>
        <ModalBody>
          <div className="form-group">
            <Autocomplete
              id="free-solo-demo"
              freeSolo
              options={order}
              getOptionLabel={(option) => {
                return (
                  option.id?.toString() +
                  " - " +
                  option.equipamento_denominacao?.toString()
                );
              }}
              onChange={(event, order) => {
                if (order) {
                  if (order.status === "Concluída") {
                    Order_selected(order._id);
                    setOrderStatus(true);
                  } else {
                    Order_selected(order._id);
                    setOrderStatus(false);
                  }
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Ordem de Manutenção"
                  margin="normal"
                  //variant="outlined"
                  placeholder="Insira o número da ordem de manutenção"
                />
              )}
            />
          </div>
          <h4>Resumo da Ordem </h4>
          <Card className="shadow-lg">
            <CardBody>
              <Row>
                <Col>
                  <p>
                    <b style={{ fontWeight: "550" }}>Area: </b> {setorInstalado}
                  </p>

                  <p>
                    <b style={{ fontWeight: "550" }}>Equipamento: </b>
                    {equipamento + " - " + descricao}
                  </p>

                  <p>
                    <b style={{ fontWeight: "550" }}>Executante: </b>
                    {responsavel}
                  </p>
                </Col>

                <Col className="col-auto">
                  <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                    <i className="ni ni-settings" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <br></br>
          <h4> Materiais Reservados</h4>
          <Card className="shadow-lg">
            <CardBody>
              <Table className="align-items-center table-flush" responsive>
                <thead>
                  <tr>
                    <th scope="col">Material</th>
                    <th scope="col">Part Number</th>
                    <th scope="col">Quantidade</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {spareReserve &&
                    spareReserve.map((response, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {response.produto}
                                </span>
                              </Media>
                            </Media>
                          </th>

                          <th scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {response.part_number}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <th scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {response.quantidade}
                                </span>
                              </Media>
                            </Media>
                          </th>

                          <th scope="row">
                            {orderStatus ? (
                              <Badge color="" className="badge-dot mr-4">
                                <GreenCheckbox
                                  checked={false}
                                  onChange={() =>
                                    notifyCode(
                                      "danger",
                                      "⚠ Atenção ⚠",
                                      "Essa ordem já foi concluída!"
                                    )
                                  }
                                  name="checkedG"
                                />
                                {response.status}
                              </Badge>
                            ) : (
                              <>
                                {response.status === "Reservado" ? (
                                  <Badge color="" className="badge-dot mr-4">
                                    <GreenCheckbox
                                      checked={false}
                                      onChange={() => statusReserva(response)}
                                      name="checkedG"
                                    />
                                    {response.status}
                                  </Badge>
                                ) : (
                                  <Badge color="" className="badge-dot">
                                    <GreenCheckbox
                                      checked={true}
                                      onChange={() => statusReserva(response)}
                                      name="checkedG"
                                    />
                                    {response.status}
                                  </Badge>
                                )}
                              </>
                            )}
                            {/* {response.status === "Reservado" ?
                            <Badge color="" className="badge-dot mr-4">
                              <GreenCheckbox checked={false} onChange={() => statusReserva(response)} name="checkedG" />
                              {response.status}
                            </Badge>
                            : <Badge color="" className="badge-dot">
                              <GreenCheckbox checked={true} onChange={() => statusReserva(response)} name="checkedG" />
                              {response.status}
                            </Badge>
                          } */}
                          </th>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
          <Button style={{ marginTop: "1em" }} color="success" onClick={toggle}>
            Confirmar
          </Button>{" "}
          <Button
            style={{ marginTop: "1em" }}
            color="secondary"
            onClick={toggle}
          >
            Cancelar
          </Button>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ExitSpare;
