import axios from "axios";

const AREAS_API_BASE_URL = "https://api.darwinx.com.br/areas";

class OrderService {
  getAllAreas(enterprise) {
    return axios.get(AREAS_API_BASE_URL, { params: enterprise });
  }
}

export default new OrderService();
