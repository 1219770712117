// GESTÃO DE Fornecedores

import axios from "axios";

const PDF_API_BASE_URL = "https://api.darwinx.com.br/pdfs";

class PDFService {
  getPDFs(PDF) {
    return axios.get(PDF_API_BASE_URL, { params: PDF });
  }

  createPDFs(PDF) {
    return axios.post(PDF_API_BASE_URL, PDF);
  }

  getPDFsById(PDFId) {
    return axios.get(PDF_API_BASE_URL + "/" + PDFId);
  }

  updatePDFs(PDF, PDFId) {
    return axios.put(PDF_API_BASE_URL + "/" + PDFId, PDF);
  }

  deletePDFs(PDFId) {
    return axios.delete(PDF_API_BASE_URL + "/" + PDFId);
  }
}

export default new PDFService();
