import React, { useState, useEffect, useContext, memo, createRef } from "react";
import { EnterpriseNameContext } from "../../context/EnterpriseContext";
import SpareReserve from "../Orders/Spare_Reserve_Service";
import "./Performer.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PrintIcon from "@mui/icons-material/Print";
import {
  Container,
  Button,
  Modal,
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  FormGroup,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  Row,
  Col,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from "reactstrap";
import moment from "moment";
import swal from "sweetalert";
import AWS from "aws-sdk";
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Headers/Headerfor.js";
// import CreateRequest from './CreateRequest';
import CreateRequest from "../Requests/CreateRequest";
import OrderService from "../Orders/OrderService";
import PlanService from "../Strategy/PlanService";
import BadgeMaterial from "@material-ui/core/Badge";
import { yellow } from "@material-ui/core/colors";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import NotificationAlert from "react-notification-alert";
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import { Link } from "react-router-dom";
import ReactToPrint from "react-to-print";

import { UserType } from "../../context/UserType";

import {
  Checkbox,
  FormControlLabel,
  FormGroup as Form,
  TextField,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";

import UserService from "../Users/UserService";

import TextFieldMui from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "../DateTimePicker";
import { useHistory } from "react-router-dom";
// import { borderRadius } from '@mui/system';
import GetFilterOrders from "./services/GetCustomFilterOrder";

const notification = createRef();
const refToPrint = createRef();

function ListRequests(props) {
  const [materialAutomatic, setMaterialAutomatic] = useState(() => {
    const materialAutomaticStoraged = localStorage.getItem(
      "TOKEN_MATERIAL_AUTOMATIC"
    );
    if (materialAutomaticStoraged) {
      return materialAutomaticStoraged;
    }
    return "";
  });
  const history = useHistory();
  const { buttonLabel, className } = props;
  const { enterpriseName } = useContext(EnterpriseNameContext);
  const [modal, setModal] = useState(false);
  const { userType } = useContext(UserType);
  const [spareReserve, setSpareReserve] = useState();

  const [stopped, setStopped] = useState();
  const [started, setStarted] = useState(new Date());

  const [userName, setUserName] = useState(() => {
    const storagedUserName = localStorage.getItem("TOKEN_USERNAME");

    if (storagedUserName) {
      return storagedUserName;
    }
    return "";
  });
  const [token, setToken] = useState(() => {
    const tokenStoraged = localStorage.getItem("TOKEN_KEY");
    if (tokenStoraged) {
      return tokenStoraged;
    }
    return "";
  });
  const [hash, setHash] = useState(() => {
    const hashStoraged = localStorage.getItem("TOKEN_HASH");
    if (hashStoraged) {
      return hashStoraged;
    }
    return "";
  });
  const [idUserStorage, setIdUserStorage] = useState(() => {
    const idUserStoraged = localStorage.getItem("TOKEN_ID_USER");
    if (idUserStoraged) {
      return idUserStoraged;
    }
    return "";
  });

  const [initialService, setInitialService] = useState();
  const [finishedService, setFinishedService] = useState();

  const [orderExecutante, setOrderExecutante] = useState();
  const [orderExecutanteTemplate, setOrderExecutanteTemplate] = useState([]);
  const [filter, setFilter] = useState("Todos");

  const togglemodal = () => setModal(!modal);

  const [stateModal, setStateModal] = useState(false);
  // modal do radio button.

  const [structureCheckbox, setStructureCheckbox] = useState([]);

  const radiomodal = () => setStateModal(!stateModal);

  const [dropdownOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);

  const [dropdownOpen2, setOpen2] = useState(false);

  const toggle2 = () => setOpen2(!dropdownOpen2);

  const [dropdownOpen3, setOpen3] = useState(false);

  const [statePopup, setStatePopup] = useState();
  const [showImage, setShowImage] = useState();

  const [showBtnArea, setShowBtnArea] = useState();
  const [textArea, setTextArea] = useState("");
  const [textAreaSelectedNo, setTextAreaSelectedNo] = useState("");
  const [motivo, setMotivo] = useState(
    "Equipamento não liberado para operação"
  );
  const [visibleBtn, setVisibleBtn] = useState(false);

  const [user, setUser] = useState([]);
  const [users, setUsers] = useState([]);

  const [performer, setPerformer] = useState();

  const [dropdownOpen4, setOpen4] = useState(false);

  const toggle3 = () => setOpen3(!dropdownOpen3);

  const toggle4 = () => setOpen4(!dropdownOpen4);

  const [stateStartTimer, setStateStartTime] = useState(false);

  const alta = {
    borderLeft: "1.5em solid red",
    height: "110px",
  };

  const media = {
    borderLeft: "1.5em solid yellow",
    height: "110px",
  };

  const baixa = {
    borderLeft: "1.5em solid green",
    height: "110px",
  };

  const nenhuma = {
    borderLeft: "1.5em solid gray",
    height: "110px",
  };
  const useStyles = makeStyles((theme) => ({
    yellow: {
      backgroundColor: "#175",
      color: "#123",
    },
  }));
  const classes = useStyles();

  const notifyCode = (event, title, content) => {
    let options = {
      place: "tc",
      message: (
        <div>
          <h2
            style={{ color: "#fff", marginBottom: "0" }}
            className="alert-title"
            data-notify="title"
          >
            {" "}
            {title ? title : "Atenção!"}
            <br />
          </h2>
          <span data-notify="message">
            {content ? content : "Este código já foi cadastrado!"}
          </span>
        </div>
      ),
      type: event,
      // icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    notification.current.notificationAlert(options);
  };

  const deletar = (evt) => {
    SpareReserve.getSparesReserve({ ordem_id: statePopup._id }).then(
      (spareReserve) => {
        if (spareReserve.data.length > 0) {
          if (statePopup.stateMaterialProvided || materialAutomatic) {
            swal("A ordem foi executada com sucesso?", "", "warning", {
              buttons: {
                nope: {
                  text: "Me enganei!",
                  value: "nope",
                },
                sure: {
                  text: "Sim, claro.",
                  value: "sure",
                },
              },
            }).then((value) => {
              switch (value) {
                case "sure":
                  swal("Ordem finalizada", "", "success").then((val) => {
                    OrderService.getOrderById(statePopup._id)
                      .then((response) => {
                        let data = response.data.data;
                        data.status = "Concluída";
                        data.dates.dateOrderCompleted = `${Date.now()}`;
                        data.responsibles.OwnerOrderCompleted = userName;
                        data.statusOrder = "Concluída";
                        data.countCompleted = true;
                        data.descricoes = {
                          descricao: textArea,
                        };
                        data.token = token;
                        data.idUser = idUserStorage;
                        data.hash = hash;
                        data.descriptionCompleted = textArea;
                        data.performer = userName;
                        setShowBtnArea(false);
                        console.log(data);
                        OrderService.updateStatusOfOrder(
                          statePopup._id,
                          data
                        ).then((response) => {
                          const source = response.data.data;
                          console.log(source, "- source");
                          if (
                            source.status === "Concluída" &&
                            source.tipo === "Plano"
                          ) {
                            PlanService.getPlan({
                              enterprise: enterpriseName,
                              hashOrderCreated: data.hashOrderCreated,
                            }).then((sender) => {
                              const res = sender.data.data;
                              if (res.length > 0) {
                                let lastOrderCompleted;
                                switch (res[0]?.periodicity) {
                                  case "Diário":
                                    console.log("entrou aqui!");
                                    lastOrderCompleted.add(1, "day");
                                    break;
                                  case "Semanal":
                                    console.log("entrou no semanal!");
                                    lastOrderCompleted.add(7, "day");
                                    break;
                                  case "Quinzenal":
                                    lastOrderCompleted.add(14, "day");
                                    break;
                                  case "Mensal":
                                    lastOrderCompleted.add(30, "day");
                                    break;
                                  case "Bimestral":
                                    lastOrderCompleted.add(60, "day");
                                    break;
                                  case "Trimestral":
                                    lastOrderCompleted.add(90, "day");
                                    break;
                                  case "Semestral":
                                    lastOrderCompleted.add(180, "day");
                                    break;
                                  case "Anual":
                                    lastOrderCompleted.add(365, "day");
                                    break;
                                  case "Bianual":
                                    lastOrderCompleted.add(730, "day");
                                    break;
                                  default:
                                    break;
                                }
                                const updatePlan = {
                                  lastOrderCompleted:
                                    lastOrderCompleted ?? new Date(),
                                  hasOrderCreated: false,
                                };
                                console.log("id", res[0]._id);
                                PlanService.updatePlan(
                                  updatePlan,
                                  res[0]._id
                                ).then((plan) => {
                                  console.log("plano atualizado", plan.data);
                                });
                              }
                            });
                          }
                          OrderService.getOrders({
                            empresa: enterpriseName,
                            usernameResponsavel: userName,
                            token: token,
                            hash: hash,
                            idUser: idUserStorage,
                          }).then((response) => {
                            console.log("get na tabela");
                            setOrderExecutante(response.data.data);
                            setOrderExecutanteTemplate(response.data.data);
                            localStorage.removeItem("TOKEN_KEY");
                            localStorage.setItem(
                              "TOKEN_KEY",
                              response.data.token
                            );
                            togglemodal();
                          });
                        });
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  });
                  break;
                case "nope":
                  swal("Operação cancelada", "", "success");
                  break;
                default:
                  return;
              }
            });
          } else {
            notifyCode(
              "danger",
              "Atenção",
              "A ordem não pode ser concluída devido a pendência de entrega de material!"
            );
          }
        } else {
          console.log("entrou aqui!");
          swal("A ordem foi executada com sucesso?", "", "warning", {
            buttons: {
              nope: {
                text: "Me enganei!",
                value: "nope",
              },
              sure: {
                text: "Sim, claro.",
                value: "sure",
              },
            },
          }).then((value) => {
            switch (value) {
              case "sure":
                swal("Ordem finalizada", "", "success").then((val) => {
                  OrderService.getOrderById(statePopup._id)
                    .then((response) => {
                      let data = response.data.data;
                      data.status = "Concluída";
                      data.dates.dateOrderCompleted = `${Date.now()}`;
                      data.responsibles.OwnerOrderCompleted = userName;
                      data.statusOrder = "Concluída";
                      data.countCompleted = true;
                      data.descricoes = {
                        descricao: textArea,
                      };
                      data.token = token;
                      data.idUser = idUserStorage;
                      data.hash = hash;
                      data.descriptionCompleted = textArea;
                      data.performer = userName;
                      setShowBtnArea(false);
                      OrderService.updateStatusOfOrder(
                        statePopup._id,
                        data
                      ).then((response) => {
                        const source = response.data.data;
                        if (
                          source.status === "Concluída" &&
                          source.tipo === "Plano"
                        ) {
                          PlanService.getPlan({
                            enterprise: enterpriseName,
                            hashOrderCreated: data.hashOrderCreated,
                          }).then((sender) => {
                            const res = sender.data.data;
                            if (res.length > 0) {
                              const updatePlan = {
                                lastOrderCompleted: new Date(),
                                hasOrderCreated: false,
                              };

                              PlanService.updatePlan(
                                updatePlan,
                                res[0]._id
                              ).then((plan) => {});
                            }
                          });
                        }
                        OrderService.getOrders({
                          empresa: enterpriseName,
                          usernameResponsavel: userName,
                          token: token,
                          hash: hash,
                          idUser: idUserStorage,
                        }).then((response) => {
                          setOrderExecutante(response.data.data);
                          setOrderExecutanteTemplate(response.data.data);
                          localStorage.removeItem("TOKEN_KEY");
                          localStorage.setItem(
                            "TOKEN_KEY",
                            response.data.token
                          );
                          togglemodal();
                        });
                      });
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                });
                break;
              case "nope":
                swal("Operação cancelada", "", "success");
                break;
              default:
                return;
            }
          });
        }
      }
    );
  };

  const selectedNoExecute = () => {
    swal("Enviar informações?", "", "warning", {
      buttons: {
        nope: {
          text: "Me enganei!",
          value: "nope",
        },
        sure: {
          text: "Sim, claro.",
          value: "sure",
        },
      },
    }).then((value) => {
      switch (value) {
        case "sure":
          swal("Informações enviadas!", "", "success").then((val) => {
            console.log(statePopup);
            console.log(statePopup._id);
            OrderService.getOrderById(statePopup._id, {
              token: token,
              hash: hash,
              idUser: idUserStorage,
            })
              .then((response) => {
                let data = response.data.data;
                data.dates = {
                  dateOrderNotExecuted: `${Date.now()}`,
                  dateOrderOpen: `${Date.now()}`,
                };
                data.responsibles.OwnerOrderOpen = userName;
                data.usernameResponsavel = null;
                data.start = null;
                data.end = null;
                data.status = "Replanejar";
                if (!statePopup.initialService) {
                  data.initialService = initialService;
                }
                if (!statePopup.finishedService) {
                  data.finishedService = finishedService;
                }
                data.descricoes.descricao_aberta = textAreaSelectedNo;
                data.descricoes.descricao_motivo = motivo;
                data.token = token;
                data.idUser = idUserStorage;
                data.hash = hash;
                data.descriptionReplan = textAreaSelectedNo;
                data.reason = motivo;
                data.performer = userName;
                console.log("data", data);
                setShowBtnArea(false);
                OrderService.updateStatusOfOrder(statePopup._id, data)
                  .then((response) => {
                    console.log(response.data);
                    OrderService.getOrders({
                      empresa: enterpriseName,
                      usernameResponsavel: userName,
                      token: token,
                      hash: hash,
                      idUser: idUserStorage,
                    }).then((response) => {
                      setOrderExecutante(response.data.data);
                      setOrderExecutanteTemplate(response.data.data);
                      localStorage.removeItem("TOKEN_KEY");
                      localStorage.setItem("TOKEN_KEY", response.data.token);
                    });
                  })
                  .catch((error) => {
                    console.log(error);
                    // if(error?.response?.data?.auth){

                    // }else{
                    //   redirectLogin()
                    // }
                  });
                togglemodal();
                radiomodal();
              })
              .catch((error) => {
                console.log(error);

                // if(error?.response?.data?.auth){

                // }else{
                //   redirectLogin()
                // }
              });
          });
          break;
        case "nope":
          swal("Operação cancelada", "", "success");
          break;
        default:
          return;
      }
    });
  };

  const [ordersTotal, setOrdersTotal] = useState([]);

  useEffect(() => {
    UserService.getUserById(localStorage.getItem("TOKEN_ID_USER")).then(
      (response) => {
        setUser(response.data);
        setPerformer(response.data);
      }
    );

    UserService.getUsers({ empresa: enterpriseName }).then((response) => {
      console.log(response.data, "users");
      setUsers(response.data);
    });

    SpareReserve.getSparesReserve({ empresa: enterpriseName }).then(
      (response) => {
        setSpareReserve(response.data);
      }
    );
    console.log(enterpriseName);
    console.log(userName);
    setFilter(userName);
    OrderService.getOrders({
      empresa: enterpriseName,
      usernameResponsavel: userName,
      token: token,
      hash: hash,
      idUser: idUserStorage,
    })
      .then((response) => {
        console.log("ordens:", response.data.data);
        localStorage.removeItem("TOKEN_KEY");
        localStorage.setItem("TOKEN_KEY", response.data.token);
        setOrderExecutante(response.data.data);
        setOrderExecutanteTemplate(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    OrderService.getOrders({ empresa: enterpriseName }).then((response) => {
      setOrdersTotal(response.data.data);
    });
  }, [userName, enterpriseName, token, hash, idUserStorage, statePopup]);

  const completeOrderAdministrator = () => {
    SpareReserve.getSparesReserve({ ordem_id: statePopup._id }).then(
      (spareReserve) => {
        if (spareReserve.data.length > 0) {
          if (statePopup.stateMaterialProvided || materialAutomatic) {
            swal("A ordem foi executada com sucesso?", "", "warning", {
              buttons: {
                nope: {
                  text: "Me enganei!",
                  value: "nope",
                },
                sure: {
                  text: "Sim, claro.",
                  value: "sure",
                },
              },
            }).then((value) => {
              switch (value) {
                case "sure":
                  swal("Ordem finalizada", "", "success").then((val) => {
                    OrderService.getOrderById(statePopup._id)
                      .then((response) => {
                        let data = response.data.data;
                        data.status = "Concluída";
                        data.dates.dateOrderCompleted = `${Date.now()}`;
                        data.responsibles.OwnerOrderCompleted = userName;
                        data.statusOrder = "Concluída";
                        data.countCompleted = true;
                        data.descricoes = {
                          descricao: textArea,
                        };
                        if (!statePopup.initialService) {
                          data.initialService = initialService;
                        }
                        if (!statePopup.finishedService) {
                          data.finishedService = finishedService;
                        }
                        data.token = token;
                        data.idUser = idUserStorage;
                        data.hash = hash;
                        (data.initiateService = initialService),
                          (data.finishedService = finishedService),
                          (data.descriptionCompleted = textArea);
                        data.performer = userName;
                        setShowBtnArea(false);
                        console.log(data);
                        OrderService.updateStatusOfOrder(
                          statePopup._id,
                          data
                        ).then((response) => {
                          const source = response.data.data;
                          console.log(source, "- source");
                          if (
                            source.status === "Concluída" &&
                            source.tipo === "Plano"
                          ) {
                            PlanService.getPlan({
                              enterprise: enterpriseName,
                              hashOrderCreated: data.hashOrderCreated,
                            }).then((sender) => {
                              const res = sender.data.data;
                              if (res.length > 0) {
                                let lastOrderCompleted;
                                switch (res[0]?.periodicity) {
                                  case "Diário":
                                    console.log("entrou aqui!");
                                    lastOrderCompleted.add(1, "day");
                                    break;
                                  case "Semanal":
                                    console.log("entrou no semanal!");
                                    lastOrderCompleted.add(7, "day");
                                    break;
                                  case "Quinzenal":
                                    lastOrderCompleted.add(14, "day");
                                    break;
                                  case "Mensal":
                                    lastOrderCompleted.add(30, "day");
                                    break;
                                  case "Bimestral":
                                    lastOrderCompleted.add(60, "day");
                                    break;
                                  case "Trimestral":
                                    lastOrderCompleted.add(90, "day");
                                    break;
                                  case "Semestral":
                                    lastOrderCompleted.add(180, "day");
                                    break;
                                  case "Anual":
                                    lastOrderCompleted.add(365, "day");
                                    break;
                                  case "Bianual":
                                    lastOrderCompleted.add(730, "day");
                                    break;
                                  default:
                                    break;
                                }
                                const updatePlan = {
                                  lastOrderCompleted:
                                    lastOrderCompleted ?? new Date(),
                                  hasOrderCreated: false,
                                };
                                console.log("id", res[0]._id);
                                PlanService.updatePlan(
                                  updatePlan,
                                  res[0]._id
                                ).then((plan) => {
                                  console.log("plano atualizado", plan.data);
                                });
                              }
                            });
                          }
                          OrderService.getOrders({
                            empresa: enterpriseName,
                            usernameResponsavel: userName,
                            token: token,
                            hash: hash,
                            idUser: idUserStorage,
                          }).then((response) => {
                            console.log("get na tabela");
                            setOrderExecutante(response.data.data);
                            setOrderExecutanteTemplate(response.data.data);
                            localStorage.removeItem("TOKEN_KEY");
                            localStorage.setItem(
                              "TOKEN_KEY",
                              response.data.token
                            );
                            togglemodal();
                          });
                        });
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  });
                  break;
                case "nope":
                  swal("Operação cancelada", "", "success");
                  break;
                default:
                  return;
              }
            });
          } else {
            notifyCode(
              "danger",
              "Atenção",
              "A ordem não pode ser concluída devido a pendência de entrega de material!"
            );
          }
        } else {
          console.log("entrou aqui!");
          swal("A ordem foi executada com sucesso?", "", "warning", {
            buttons: {
              nope: {
                text: "Me enganei!",
                value: "nope",
              },
              sure: {
                text: "Sim, claro.",
                value: "sure",
              },
            },
          }).then((value) => {
            switch (value) {
              case "sure":
                swal("Ordem finalizada", "", "success").then((val) => {
                  OrderService.getOrderById(statePopup._id)
                    .then((response) => {
                      let data = response.data.data;
                      data.status = "Concluída";
                      data.dates.dateOrderCompleted = `${Date.now()}`;
                      data.responsibles.OwnerOrderCompleted = userName;
                      data.statusOrder = "Concluída";
                      data.countCompleted = true;
                      data.descricoes = {
                        descricao: textArea,
                      };
                      (data.initiateService = initialService),
                        (data.finishedService = finishedService),
                        (data.token = token);
                      data.idUser = idUserStorage;
                      data.hash = hash;
                      data.descriptionCompleted = textArea;
                      data.performer = userName;
                      setShowBtnArea(false);
                      OrderService.updateStatusOfOrder(
                        statePopup._id,
                        data
                      ).then((response) => {
                        const source = response.data.data;
                        if (
                          source.status === "Concluída" &&
                          source.tipo === "Plano"
                        ) {
                          PlanService.getPlan({
                            enterprise: enterpriseName,
                            hashOrderCreated: data.hashOrderCreated,
                          }).then((sender) => {
                            const res = sender.data.data;
                            if (res.length > 0) {
                              const updatePlan = {
                                lastOrderCompleted: new Date(),
                                hasOrderCreated: false,
                              };

                              PlanService.updatePlan(
                                updatePlan,
                                res[0]._id
                              ).then((plan) => {});
                            }
                          });
                        }
                        OrderService.getOrders({
                          empresa: enterpriseName,
                          usernameResponsavel: userName,
                          token: token,
                          hash: hash,
                          idUser: idUserStorage,
                        }).then((response) => {
                          setOrderExecutante(response.data.data);
                          setOrderExecutanteTemplate(response.data.data);
                          localStorage.removeItem("TOKEN_KEY");
                          localStorage.setItem(
                            "TOKEN_KEY",
                            response.data.token
                          );
                          togglemodal();
                        });
                      });
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                });
                break;
              case "nope":
                swal("Operação cancelada", "", "success");
                break;
              default:
                return;
            }
          });
        }
      }
    );
  };

  const updateService = () => {
    swal("Deseja alterar o horário de serviço?", "", "warning", {
      buttons: {
        nope: {
          text: "Me enganei!",
          value: "nope",
        },
        sure: {
          text: "Sim, claro.",
          value: "sure",
        },
      },
    }).then((response) => {
      switch (response) {
        case "sure":
          swal("Serviço atualizado com sucesso!", "", "success").then(
            (sender) => {
              let data;
              // calcular o downtime
              let downtime = 0;
              if (statePopup.quandoMaquinaParou) {
                let dayInitial = moment(statePopup.quandoMaquinaParou);
                let dayFinished = moment(finishedService);
                const compare = moment(dayFinished).diff(moment(dayInitial));
                downtime = moment.duration(compare).asHours();
              } else {
                let dayInitial = moment(statePopup.createdAt);
                let dayFinished = moment(finishedService);
                const compare = moment(dayFinished).diff(moment(dayInitial));
                downtime = moment.duration(compare).asHours();
              }
              if (downtime < 0) {
                downtime = 0;
              }

              // calcular as horas trabalhadas
              let initialWork = moment(initialService);
              let finishedWork = moment(finishedService);
              const comparedHoursWorked = moment(finishedWork).diff(
                moment(initialWork)
              );
              const totalHoursWoking = moment
                .duration(comparedHoursWorked)
                .asHours();
              if (initialService && finishedService) {
                data = {
                  initiateService: initialService,
                  finishedService: finishedService,
                  hoursWorking: totalHoursWoking,
                  token: token,
                  downtime: downtime,
                  hash: hash,
                  idUser: idUserStorage,
                };
              } else {
                data = {
                  initiateService: initialService,
                  finishedService: finishedService,
                  downtime: downtime,
                  token: token,
                  hash: hash,
                  idUser: idUserStorage,
                };
              }
              console.log("informações: ", data);
              OrderService.updateOrder(data, statePopup._id).then(
                (response) => {
                  OrderService.getOrderById(statePopup._id, {
                    token: token,
                    hash: hash,
                    idUser: idUserStorage,
                  }).then((event) => {
                    setStatePopup(event.data.data);
                    setFinishedService(
                      new Date(event.data?.datafinishedService)
                    );
                    setInitialService(
                      new Date(event.data?.data.initiateService)
                    );
                    localStorage.removeItem("TOKEN_KEY");
                    localStorage.setItem("TOKEN_KEY", event.data.token);
                  });
                  OrderService.getOrders({
                    empresa: enterpriseName,
                    usernameResponsavel: userName,
                    token: token,
                    hash: hash,
                    idUser: idUserStorage,
                  }).then((response) => {
                    setOrderExecutante(response.data.data);
                    setOrderExecutanteTemplate(response.data.data);
                    localStorage.removeItem("TOKEN_KEY");
                    localStorage.setItem("TOKEN_KEY", response.data.token);
                  });
                }
              );
            }
          );
          break;
        case "nope":
          swal("Operação cancelada", "", "success");
          break;
        default:
          return;
      }
    });
  };
  // const confirmOrder = () => {

  // }

  const CheckboxUI = memo(
    ({ check, description, state, setOrderExecutante }) => {
      const [checked, setChecked] = useState(check);
      return (
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={() => {
                setChecked(!checked);
                const checkbox = {
                  description: structureCheckbox[state].label
                    ? structureCheckbox[state].label
                    : structureCheckbox[state].description
                    ? structureCheckbox[state].description
                    : "",
                  state: !structureCheckbox[state].state,
                };
                structureCheckbox[state] = checkbox;
                OrderService.updateOrder(
                  { checkboxes: structureCheckbox },
                  statePopup._id
                ).then((response) => {
                  OrderService.getOrders({
                    empresa: enterpriseName,
                    usernameResponsavel: userName,
                  }).then((sender) => {
                    setOrderExecutante(sender.data.data);
                  });
                });
              }}
            />
          }
          style={{
            textDecoration: checked ? "line-through" : "none",
            width: "100%",
            // border: '2px solid red',
            backgroundColor: "#F8F9FA",
            borderRadius: "5px",
          }}
          label={description}
        />
      );
    }
  );

  const service = () => {
    if (statePopup.stateWork) {
      swal("Deseja iniciar o serviço?", "", "warning", {
        buttons: {
          nope: {
            text: "Me enganei!",
            value: "nope",
          },
          sure: {
            text: "Sim, claro.",
            value: "sure",
          },
        },
      }).then((value) => {
        switch (value) {
          case "sure":
            swal("Informações enviadas!", "", "success").then((val) => {
              OrderService.getOrderById(statePopup._id).then((response) => {
                let result = response.data.data;
                result.stateWork = false;
                result.initiateService = Date.now();

                OrderService.updateOrder(result, statePopup._id).then(
                  (sender) => {
                    console.log(sender.data);
                    OrderService.getOrderById(statePopup._id).then((event) => {
                      setStatePopup(event.data.data);
                      console.log(event.data?.finishedService);
                      console.log(event.data?.initiateService);
                      setFinishedService(
                        new Date(event.data?.data.finishedService)
                      );
                      setInitialService(
                        new Date(event.data?.data.initiateService)
                      );
                    });
                    OrderService.getOrders({
                      empresa: enterpriseName,
                      usernameResponsavel: userName,
                    }).then((response) => {
                      setOrderExecutante(response.data.data);
                      setOrderExecutanteTemplate(response.data.data);
                    });
                  }
                );
              });
            });
            break;
          case "nope":
            swal("Operação cancelada", "", "success");
            break;
          default:
            return;
        }
      });
    } else {
      swal("Deseja finalizar o serviço?", "", "warning", {
        buttons: {
          nope: {
            text: "Me enganei!",
            value: "nope",
          },
          sure: {
            text: "Sim, claro.",
            value: "sure",
          },
        },
      }).then((value) => {
        switch (value) {
          case "sure":
            swal("Serviço finalizado!", "", "success").then((val) => {
              OrderService.getOrderById(statePopup._id).then((response) => {
                let result = response.data.data;

                result.stateWork = true;
                result.finishedService = Date.now();
                result.finishedSeviceBtn = true;

                // calcular as horas trabalhadas
                let initialWork = moment(initialService);
                let finishedWork = moment(Date.now());
                const comparedHoursWorked = moment(finishedWork).diff(
                  moment(initialWork)
                );
                const totalHoursWoking = moment
                  .duration(comparedHoursWorked)
                  .asHours();
                result.hoursWorking = totalHoursWoking;
                // calcular o downtime
                let downtime = 0;
                if (statePopup.quandoMaquinaParou) {
                  let dayInitial = moment(statePopup.quandoMaquinaParou);
                  let dayFinished = moment(finishedService);
                  const compare = moment(dayFinished).diff(moment(dayInitial));
                  downtime = moment.duration(compare).asHours();
                } else {
                  let dayInitial = moment(statePopup.createdAt);
                  let dayFinished = moment(finishedService);
                  const compare = moment(dayFinished).diff(moment(dayInitial));
                  downtime = moment.duration(compare).asHours();
                }
                if (downtime < 0) {
                  downtime = 0;
                }
                result.downtime = downtime;
                OrderService.updateOrder(result, statePopup._id).then(
                  (sender) => {
                    console.log(sender.data);
                    OrderService.getOrderById(statePopup._id).then((event) => {
                      setStatePopup(event.data.data);
                      setFinishedService(
                        new Date(event.data?.data.finishedService)
                      );
                      setInitialService(
                        new Date(event.data?.data.initiateService)
                      );
                    });

                    OrderService.getOrders({
                      empresa: enterpriseName,
                      usernameResponsavel: userName,
                    }).then((response) => {
                      setOrderExecutante(response.data.data);
                      setOrderExecutanteTemplate(response.data.data);
                    });
                  }
                );
              });
            });
            break;
          case "nope":
            swal("Operação cancelada", "", "success");
            break;
          default:
            return;
        }
      });
    }
  };

  const reactToPrintContent = React.useCallback(() => {
    return refToPrint.current;
  }, [refToPrint.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
    // to the root node of the returned component as it will be overwritten.

    // Bad: the `onClick` here will be overwritten by `react-to-print`
    // return <button onClick={() => alert('This will not work')}>Print this out!</button>;

    // Good
    return <PrintIcon style={{ cursor: "pointer" }} />;
  }, []);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const verifyUser = () => {
    console.log("entrou na função");
    console.log(filter, "filter");
    if (filter !== "Todos") {
      GetFilterOrders({
        empresa: enterpriseName,
        responsavel: filter,
      }).then((response) => {
        console.log(response.filter, "respondeu");
        const orderFiltered =
          response &&
          response?.filter
            .filter((sender) => sender.statusOrder !== "Concluída")
            .filter(
              (order) =>
                order.status === "Programada" || order.status === "Atrasada"
            );
        console.log(orderFiltered, "orderFiltered");
        setOrderExecutante(orderFiltered ?? []);
      });
    }
  };

  return (
    <>
      <Header />
      <NotificationAlert ref={notification} />
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          maxWidth: "1300px",
        }}
        className="mt--7"
        fluid
      >
        <Card style={{ width: "100%" }}>
          <CardHeader className="border-0">
            <h3 className="mb-0">Ordens a serem executadas</h3>
            {userType === "Administrador" || userType === "Corpo Técnico" ? (
              <>
                <Button
                  color="success"
                  size="sm"
                  className="position-absolute top-3 right-9"
                >
                  <Link style={{ color: "white" }} to="/admin/team">
                    Equipe
                  </Link>
                </Button>
              </>
            ) : null}
            <CreateRequest />
          </CardHeader>
          <Row
            style={{ display: "flex", alignItems: "center" }}
            className="mt-3 mb-2"
          >
            <Col xs="auto" sm="auto">
              <ButtonDropdown
                size="sm"
                isOpen={dropdownOpen}
                className="ml-3 mr-2"
                toggle={toggle}
              >
                <DropdownToggle caret color="info">
                  Tipo da ordem
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    active={filter === "Todos"}
                    onClick={() => {
                      setFilter("Todos");
                      setOrderExecutante(orderExecutanteTemplate);
                    }}
                  >
                    Todas
                  </DropdownItem>
                  <DropdownItem
                    active={filter === "Preventiva"}
                    onClick={() => {
                      setFilter("Preventiva");
                      const filter = orderExecutanteTemplate.filter(
                        (response) => response.tipo === "Preventiva"
                      );
                      setOrderExecutante(filter);
                    }}
                  >
                    Preventiva
                  </DropdownItem>
                  <DropdownItem
                    active={filter === "Preditiva"}
                    onClick={() => {
                      setFilter("Preditiva");
                      const filter = orderExecutanteTemplate.filter(
                        (response) => response.tipo === "Preditiva"
                      );
                      setOrderExecutante(filter);
                    }}
                  >
                    Preditiva
                  </DropdownItem>
                  <DropdownItem
                    active={filter === "Corretiva"}
                    onClick={() => {
                      setFilter("Corretiva");
                      const filter = orderExecutanteTemplate.filter(
                        (response) => response.tipo === "Corretiva"
                      );
                      setOrderExecutante(filter);
                    }}
                  >
                    Corretiva
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>

              <ButtonDropdown
                size="sm"
                isOpen={dropdownOpen2}
                className="mx-2"
                toggle={toggle2}
              >
                <DropdownToggle caret color="info">
                  Prioridade
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    active={filter === "Todos"}
                    onClick={() => {
                      setFilter("Todos");
                      setOrderExecutante(orderExecutanteTemplate);
                    }}
                  >
                    Todas
                  </DropdownItem>
                  <DropdownItem
                    active={filter === "Alta"}
                    onClick={() => {
                      setFilter("Alta");
                      const filter = orderExecutanteTemplate.filter(
                        (response) => response.prioridade === "Alta"
                      );
                      setOrderExecutante(filter);
                    }}
                  >
                    Alta
                  </DropdownItem>
                  <DropdownItem
                    active={filter === "Média"}
                    onClick={() => {
                      setFilter("Média");
                      const filter = orderExecutanteTemplate.filter(
                        (response) => response.prioridade === "Média"
                      );
                      setOrderExecutante(filter);
                    }}
                  >
                    Média
                  </DropdownItem>
                  <DropdownItem
                    active={filter === "Baixa"}
                    onClick={() => {
                      setFilter("Baixa");
                      const filter = orderExecutanteTemplate.filter(
                        (response) => response.prioridade === "Baixa"
                      );
                      setOrderExecutante(filter);
                    }}
                  >
                    Baixa
                  </DropdownItem>
                  <DropdownItem
                    active={filter === "Nenhuma"}
                    onClick={() => {
                      setFilter("Nenhuma");
                      const filter = orderExecutanteTemplate.filter(
                        (response) => response.prioridade === "Nenhuma"
                      );
                      setOrderExecutante(filter);
                    }}
                  >
                    Nenhuma
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>

              <ButtonDropdown
                isOpen={dropdownOpen3}
                className="mx-2"
                size="sm"
                toggle={toggle3}
              >
                <DropdownToggle caret color="info">
                  Data
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    active={filter === "Todos"}
                    onClick={() => {
                      setFilter("Todos");
                      setOrderExecutante(orderExecutanteTemplate);
                    }}
                  >
                    Todas
                  </DropdownItem>
                  <DropdownItem
                    active={filter === "7"}
                    onClick={() => {
                      setFilter("7");
                      const dateNow = Date.now();
                      let dayInitial = moment(dateNow).format("YYYY-MM-DD");
                      const filter = orderExecutanteTemplate.filter(
                        (response) => {
                          let dayFinished = moment(response.start).format(
                            "YYYY-MM-DD"
                          );
                          const compare = moment(dayFinished).diff(
                            moment(dayInitial)
                          );
                          if (
                            moment.duration(compare).asDays() <= 7 &&
                            moment.duration(compare).asDays() >= 0
                          ) {
                            return true;
                          }
                          return false;
                        }
                      );
                      setOrderExecutante(filter);
                    }}
                  >
                    Próximos 7 dias
                  </DropdownItem>
                  <DropdownItem
                    active={filter === "15"}
                    onClick={() => {
                      setFilter("15");
                      const dateNow = Date.now();
                      let dayInitial = moment(dateNow).format("YYYY-MM-DD");
                      const filter = orderExecutanteTemplate.filter(
                        (response) => {
                          let dayFinished = moment(response.start).format(
                            "YYYY-MM-DD"
                          );
                          const compare = moment(dayFinished).diff(
                            moment(dayInitial)
                          );
                          if (
                            moment.duration(compare).asDays() <= 15 &&
                            moment.duration(compare).asDays() >= 0
                          ) {
                            return true;
                          }
                          return false;
                        }
                      );
                      setOrderExecutante(filter);
                    }}
                  >
                    Próximos 15 dias
                  </DropdownItem>
                  <DropdownItem
                    active={filter === "30"}
                    onClick={() => {
                      setFilter("30");
                      const dateNow = Date.now();
                      let dayInitial = moment(dateNow).format("YYYY-MM-DD");
                      const filter = orderExecutanteTemplate.filter(
                        (response) => {
                          let dayFinished = moment(response.start).format(
                            "YYYY-MM-DD"
                          );
                          const compare = moment(dayFinished).diff(
                            moment(dayInitial)
                          );
                          if (
                            moment.duration(compare).asDays() <= 30 &&
                            moment.duration(compare).asDays() >= 0
                          ) {
                            return true;
                          }
                          return false;
                        }
                      );
                      setOrderExecutante(filter);
                    }}
                  >
                    Próximos 30 dias
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>

              <ButtonDropdown
                isOpen={dropdownOpen4}
                className="mx-2"
                size="sm"
                toggle={toggle4}
              >
                <DropdownToggle caret color="info">
                  Usuários
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    active={filter === "Todos"}
                    onClick={() => {
                      setFilter("Todos");
                      setOrderExecutante(ordersTotal);
                    }}
                  >
                    Todos
                  </DropdownItem>
                  {users.map((response) => {
                    return (
                      <DropdownItem
                        active={filter === response.first_name}
                        onClick={() => {
                          setFilter(response.first_name);
                          OrderService.getOrders({
                            empresa: enterpriseName,
                            usernameResponsavel: response.username,
                            token: token,
                            hash: hash,
                            idUser: idUserStorage,
                          }).then((response) => {
                            setOrderExecutante(response.data.data);
                            setOrderExecutanteTemplate(response.data.data);
                          });
                        }}
                      >
                        {response.first_name}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </ButtonDropdown>
            </Col>
            <Col>
              {/* {userType === "Administrador" || userType === "Corpo Técnico" ? (
              <AutocompleteComponent performer={performer}/>
            ) : null} */}
            </Col>
          </Row>

          <div className="container-performer">
            {orderExecutante &&
              orderExecutante
                .filter((sender) => sender.statusOrder !== "Concluída")
                .filter(
                  (order) =>
                    order.status === "Programada" || order.status === "Atrasada"
                )
                .map((response, index) => {
                  return (
                    <>
                      {response.prioridade === "Alta" && (
                        <a
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            togglemodal();
                            setStructureCheckbox(response.checkboxes);
                            setStatePopup(response);
                            if (response.nomeFoto) {
                              try {
                                const myBucket = "darwinxperfil";
                                const myKey = response.data.nomeFoto;
                                const signedUrlExpireSeconds = 60 * 1;
                                const s3 = new AWS.S3({
                                  accessKeyId: "AKIAVSM7L4TZDZIH7KGO",
                                  region: "sa-east-1",
                                  secretAccessKey:
                                    "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",
                                });

                                const url = s3.getSignedUrl("getObject", {
                                  Bucket: myBucket,
                                  Key: myKey,
                                  Expires: signedUrlExpireSeconds,
                                });

                                setShowImage(url);
                              } catch (error) {
                                console.log(error);
                              }
                            } else {
                              setShowImage(null);
                            }
                            setFinishedService(
                              new Date(response.finishedService)
                            );
                            setInitialService(
                              new Date(response.initiateService)
                            );
                            if (
                              userType === "Administrador" ||
                              userType === "Corpo Técnico"
                            ) {
                              setInitialService(response.start ?? new Date());
                              setFinishedService(response.end ?? new Date());
                            }
                            verifyUser();
                          }}
                          href
                        >
                          <Card
                            style={alta}
                            className="shadow-lg mx-3 my-2 bl-8"
                          >
                            <Row className="mx-2 mt-1">
                              <Col className="cardContainerAlta">
                                <h2>{response.equipamento}</h2>
                                <h2 className="denominacaoAlta">
                                  {response.equipamento_denominacao}
                                </h2>
                                <h3 className="programadoAlta">
                                  Programado para:{" "}
                                  {!!response.descricao.includes(
                                    "Plano de manutenção diário"
                                  ) ? (
                                    <>
                                      {moment(
                                        new Date(
                                          new Date(response?.prazo).setDate(
                                            new Date(
                                              response?.prazo
                                            ).getDate() - 1
                                          )
                                        )
                                      ).format("DD/MM/YYYY")}
                                    </>
                                  ) : (
                                    <>
                                      {moment(response?.start).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </>
                                  )}
                                </h3>
                              </Col>

                              <Col className="col-auto">
                                <Row
                                  style={{
                                    justifyContent: "flex-end",
                                    marginBottom: "0.4em",
                                  }}
                                >
                                  <Badge size="lg" className="styleBadgeId">
                                    {response.id}
                                  </Badge>
                                </Row>
                                <Row>
                                  <Badge size="lg" color="danger">
                                    {response.prioridade}
                                  </Badge>
                                </Row>
                              </Col>
                            </Row>
                          </Card>
                        </a>
                      )}
                      {response.prioridade === "Média" && (
                        <Row className="" key={index}>
                          <Col className="">
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                togglemodal();
                                setStructureCheckbox(response.checkboxes);
                                setStatePopup(response);
                                if (response.nomeFoto) {
                                  try {
                                    const myBucket = "darwinxperfil";
                                    const myKey = response.data.nomeFoto;
                                    const signedUrlExpireSeconds = 60 * 1;
                                    const s3 = new AWS.S3({
                                      accessKeyId: "AKIAVSM7L4TZDZIH7KGO",
                                      region: "sa-east-1",
                                      secretAccessKey:
                                        "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",
                                    });

                                    const url = s3.getSignedUrl("getObject", {
                                      Bucket: myBucket,
                                      Key: myKey,
                                      Expires: signedUrlExpireSeconds,
                                    });

                                    setShowImage(url);
                                  } catch (error) {
                                    console.log(error);
                                  }
                                } else {
                                  setShowImage(null);
                                }
                                setFinishedService(
                                  new Date(response.finishedService)
                                );
                                setInitialService(
                                  new Date(response.initiateService)
                                );
                                if (
                                  userType === "Administrador" ||
                                  userType === "Corpo Técnico"
                                ) {
                                  setInitialService(
                                    response.start ?? new Date()
                                  );
                                  setFinishedService(
                                    response.end ?? new Date()
                                  );
                                }
                                verifyUser();
                              }}
                              href
                            >
                              <Card
                                style={media}
                                className="shadow-lg mx-3 my-2 bl-8"
                              >
                                <Row className="mx-2 mt-1">
                                  <Col className="cardContainerMedia">
                                    <h2>{response.equipamento}</h2>
                                    <h2 className="denominacaoMedia">
                                      {response.equipamento_denominacao}
                                    </h2>
                                    <h3 className="programadoMedia">
                                      Programado para:{" "}
                                      {!!response.descricao.includes(
                                        "Plano de manutenção diário"
                                      ) ? (
                                        <>
                                          {moment(
                                            new Date(
                                              new Date(response?.prazo).setDate(
                                                new Date(
                                                  response?.prazo
                                                ).getDate() - 1
                                              )
                                            )
                                          ).format("DD/MM/YYYY")}
                                        </>
                                      ) : (
                                        <>
                                          {moment(response?.start).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </>
                                      )}
                                    </h3>
                                  </Col>

                                  <Col className="col-auto">
                                    <Row
                                      style={{
                                        justifyContent: "flex-end",
                                        marginBottom: "0.4em",
                                      }}
                                    >
                                      <Badge size="lg" className="styleBadgeId">
                                        {response.id}
                                      </Badge>
                                    </Row>
                                    <Row>
                                      <Badge
                                        size="lg"
                                        style={{
                                          background: "#fdfd03",
                                          color: "#7F7F02",
                                        }}
                                        color="warning"
                                      >
                                        {response.prioridade}
                                      </Badge>
                                    </Row>
                                  </Col>
                                </Row>
                              </Card>
                            </a>
                          </Col>
                        </Row>
                      )}
                      {response.prioridade === "Baixa" && (
                        <Row className="" key={index}>
                          <Col className="">
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                togglemodal();
                                setStructureCheckbox(response.checkboxes);
                                setStatePopup(response);
                                if (response.nomeFoto) {
                                  try {
                                    const myBucket = "darwinxperfil";
                                    const myKey = response.data.nomeFoto;
                                    const signedUrlExpireSeconds = 60 * 1;
                                    const s3 = new AWS.S3({
                                      accessKeyId: "AKIAVSM7L4TZDZIH7KGO",
                                      region: "sa-east-1",
                                      secretAccessKey:
                                        "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",
                                    });

                                    const url = s3.getSignedUrl("getObject", {
                                      Bucket: myBucket,
                                      Key: myKey,
                                      Expires: signedUrlExpireSeconds,
                                    });

                                    setShowImage(url);
                                  } catch (error) {
                                    console.log(error);
                                  }
                                } else {
                                  setShowImage(null);
                                }
                                setFinishedService(
                                  new Date(response.finishedService)
                                );
                                setInitialService(
                                  new Date(response.initiateService)
                                );
                                if (
                                  userType === "Administrador" ||
                                  userType === "Corpo Técnico"
                                ) {
                                  setInitialService(
                                    response.start ?? new Date()
                                  );
                                  setFinishedService(
                                    response.end ?? new Date()
                                  );
                                }
                                verifyUser();
                              }}
                              href
                            >
                              <Card
                                style={baixa}
                                className="shadow-lg mx-3 my-2 bl-8"
                              >
                                <Row className="mx-2 mt-1">
                                  <Col className="cardContainer">
                                    <h2>{response.equipamento}</h2>
                                    <h2 className="denominacaoBaixa">
                                      {response.equipamento_denominacao}
                                    </h2>
                                    <h3 className="programacaoBaixa">
                                      Programado para:{" "}
                                      {!!response.descricao.includes(
                                        "Plano de manutenção diário"
                                      ) ? (
                                        <>
                                          {moment(
                                            new Date(
                                              new Date(response?.prazo).setDate(
                                                new Date(
                                                  response?.prazo
                                                ).getDate() - 1
                                              )
                                            )
                                          ).format("DD/MM/YYYY")}
                                        </>
                                      ) : (
                                        <>
                                          {moment(response?.start).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </>
                                      )}
                                    </h3>
                                  </Col>

                                  <Col className="col-auto">
                                    <Row
                                      style={{
                                        justifyContent: "flex-end",
                                        marginBottom: "0.4em",
                                      }}
                                    >
                                      <Badge size="lg" className="styleBadgeId">
                                        {response.id}
                                      </Badge>
                                    </Row>
                                    <Row>
                                      <Badge color="success" size="lg">
                                        {response.prioridade}
                                      </Badge>
                                    </Row>
                                  </Col>
                                </Row>
                              </Card>
                            </a>
                          </Col>
                        </Row>
                      )}
                      {response.prioridade === "Nenhuma" && (
                        <Row className="" key={index}>
                          <Col className="">
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                togglemodal();
                                setStructureCheckbox(response.checkboxes);
                                setStatePopup(response);
                                if (response.nomeFoto) {
                                  try {
                                    const myBucket = "darwinxperfil";
                                    const myKey = response.data.nomeFoto;
                                    const signedUrlExpireSeconds = 60 * 1;
                                    const s3 = new AWS.S3({
                                      accessKeyId: "AKIAVSM7L4TZDZIH7KGO",
                                      region: "sa-east-1",
                                      secretAccessKey:
                                        "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",
                                    });

                                    const url = s3.getSignedUrl("getObject", {
                                      Bucket: myBucket,
                                      Key: myKey,
                                      Expires: signedUrlExpireSeconds,
                                    });

                                    setShowImage(url);
                                  } catch (error) {
                                    console.log(error);
                                  }
                                } else {
                                  setShowImage(null);
                                }
                                setFinishedService(
                                  new Date(response.finishedService)
                                );
                                setInitialService(
                                  new Date(response.initiateService)
                                );
                                if (
                                  userType === "Administrador" ||
                                  userType === "Corpo Técnico"
                                ) {
                                  setInitialService(
                                    response.start ?? new Date()
                                  );
                                  setFinishedService(
                                    response.end ?? new Date()
                                  );
                                }
                                verifyUser();
                              }}
                              href
                            >
                              <Card
                                style={nenhuma}
                                className="shadow-lg mx-3 my-2 bl-8"
                              >
                                <Row className="mx-2 mt-1">
                                  <Col>
                                    <h2>{response.equipamento}</h2>
                                    <h2 className="denominacaoNenhuma">
                                      {response.equipamento_denominacao}
                                    </h2>
                                    <h3 className="programadoNenhuma">
                                      Programado para:{" "}
                                      {!!response.descricao.includes(
                                        "Plano de manutenção diário"
                                      ) ? (
                                        <>
                                          {moment(
                                            new Date(
                                              new Date(response?.prazo).setDate(
                                                new Date(
                                                  response?.prazo
                                                ).getDate() - 1
                                              )
                                            )
                                          ).format("DD/MM/YYYY")}
                                        </>
                                      ) : (
                                        <>
                                          {moment(response?.start).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </>
                                      )}
                                    </h3>
                                  </Col>

                                  <Col className="col-auto">
                                    <Row
                                      style={{
                                        justifyContent: "flex-end",
                                        marginBottom: "0.4em",
                                      }}
                                    >
                                      <Badge size="lg" className="styleBadgeId">
                                        {response.id}
                                      </Badge>
                                    </Row>
                                    <Badge size="lg" color="light">
                                      {response.prioridade}
                                    </Badge>
                                  </Col>
                                </Row>
                              </Card>
                            </a>
                          </Col>
                        </Row>
                      )}
                      {!response.prioridade && (
                        <Row className="" key={index}>
                          <Col className="">
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                togglemodal();
                                setStructureCheckbox(response.checkboxes);
                                setStatePopup(response);
                                if (response.nomeFoto) {
                                  try {
                                    const myBucket = "darwinxperfil";
                                    const myKey = response.data.nomeFoto;
                                    const signedUrlExpireSeconds = 60 * 1;
                                    const s3 = new AWS.S3({
                                      accessKeyId: "AKIAVSM7L4TZDZIH7KGO",
                                      region: "sa-east-1",
                                      secretAccessKey:
                                        "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",
                                    });

                                    const url = s3.getSignedUrl("getObject", {
                                      Bucket: myBucket,
                                      Key: myKey,
                                      Expires: signedUrlExpireSeconds,
                                    });

                                    setShowImage(url);
                                  } catch (error) {
                                    console.log(error);
                                  }
                                } else {
                                  setShowImage(null);
                                }
                                setFinishedService(
                                  new Date(response.finishedService)
                                );
                                setInitialService(
                                  new Date(response.initiateService)
                                );
                                if (
                                  userType === "Administrador" ||
                                  userType === "Corpo Técnico"
                                ) {
                                  setInitialService(
                                    response.start ?? new Date()
                                  );
                                  setFinishedService(
                                    response.end ?? new Date()
                                  );
                                }
                                verifyUser();
                              }}
                              href
                            >
                              <Card
                                style={nenhuma}
                                className="shadow-lg mx-3 my-2 bl-8"
                              >
                                <Row className="mx-2 mt-1">
                                  <Col>
                                    <h2>{response.equipamento}</h2>
                                    <h2 className="denominacaoNenhuma">
                                      {response.equipamento_denominacao}
                                    </h2>
                                    <h3 className="programadoNenhuma">
                                      Programado para:{" "}
                                      {!!response.descricao.includes(
                                        "Plano de manutenção diário"
                                      ) ? (
                                        <>
                                          {moment(
                                            new Date(
                                              new Date(response?.prazo).setDate(
                                                new Date(
                                                  response?.prazo
                                                ).getDate() - 1
                                              )
                                            )
                                          ).format("DD/MM/YYYY")}
                                        </>
                                      ) : (
                                        <>
                                          {moment(response?.start).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </>
                                      )}
                                    </h3>
                                  </Col>

                                  <Col className="col-auto">
                                    <Row
                                      style={{
                                        justifyContent: "flex-end",
                                        marginBottom: "0.4em",
                                      }}
                                    >
                                      <Badge size="lg" className="styleBadgeId">
                                        {response.id}
                                      </Badge>
                                    </Row>
                                    <Badge size="lg" color="light">
                                      {response.prioridade}
                                    </Badge>
                                  </Col>
                                </Row>
                              </Card>
                            </a>
                          </Col>
                        </Row>
                      )}
                    </>
                  );
                })}
            {orderExecutante?.length >= 1 ? (
              ""
            ) : (
              <p style={{ textAlign: "center" }}>
                Nenhuma informação encontrada :(
              </p>
            )}
          </div>
        </Card>
        {/* </div> */}
      </Container>

      <Modal
        size="lg"
        className="mr-0 mt--1 mb--1"
        isOpen={modal}
        toggle={togglemodal}
      >
        <div ref={refToPrint}>
          <ModalHeader className="mb--4" tag="h2" toggle={togglemodal}>
            Visão geral da ordem Nº{statePopup?.id}{" "}
            {statePopup?.tipo === "Plano" ? " - " + statePopup.descricao : null}
          </ModalHeader>

          {statePopup && (
            <ModalBody>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h4>Resumo do equipamento </h4>
                <ReactToPrint
                  content={reactToPrintContent}
                  documentTitle="AwesomeFileName"
                  onAfterPrint={handleAfterPrint}
                  onBeforePrint={handleBeforePrint}
                  removeAfterPrint
                  trigger={reactToPrintTrigger}
                />
              </div>
              <Card className="shadow-lg">
                <CardBody>
                  <Row>
                    <Col>
                      <h3>Código: {statePopup.equipamento}</h3>
                      <h4>
                        {" "}
                        Descrição:{" "}
                        {statePopup.descriptionPlan
                          ? statePopup.descriptionPlan
                          : statePopup.equipamento_denominacao}{" "}
                      </h4>
                      <h4> Setor instalado: {statePopup.setor_equipamento}</h4>
                      {/* <h4> Setor instalado: OM-0001 </h4> */}
                    </Col>

                    <Col className="col-auto">
                      {showImage && (
                        <img
                          style={{ maxWidth: "700px" }}
                          src={showImage}
                          alt=""
                        />
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <br></br>
              <div style={{ marginBottom: "1em" }}>
                <h4>Descrição do problema</h4>
                <Card className="shadow-lg">
                  <CardBody>
                    <Row>
                      <Col>
                        <h4>{statePopup.descricao}</h4>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>

              <h4> Materiais reservados </h4>
              <Card className="shadow-lg">
                <CardBody>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="">
                      <tr>
                        <th scope="col">Produto</th>
                        <th scope="col">Part Number</th>
                        <th scope="col">Quantidade</th>
                      </tr>
                    </thead>
                    <tbody>
                      {spareReserve
                        .filter(
                          (response) => response.ordem_id === statePopup._id
                        )
                        .map((response, index) => {
                          return (
                            <tr>
                              <th scope="row">
                                <Media className="align-items-center">
                                  <Media>
                                    <span className="mb-0 text-sm">
                                      {response.produto}
                                    </span>
                                  </Media>
                                </Media>
                              </th>
                              <th scope="row">
                                <Media className="align-items-center">
                                  <Media>
                                    <span className="mb-0 text-sm">
                                      {response.part_number}
                                    </span>
                                  </Media>
                                </Media>
                              </th>
                              <th scope="row">
                                <Media className="align-items-center">
                                  <Media>
                                    <span className="mb-0 text-sm">
                                      {response.quantidade}
                                    </span>
                                  </Media>
                                </Media>
                              </th>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>

              {statePopup.tipo === "Plano" && structureCheckbox.length > 0 ? (
                <div style={{ marginTop: "1.5em", marginBottom: "1.5rem" }}>
                  <h4>Tarefas a serem executadas</h4>
                  <Card className="shadow-lg bl-8" style={{ padding: "1em" }}>
                    <FormGroup style={{ marginBottom: "-0.5em" }}>
                      {console.log("checkbox", structureCheckbox)}
                      {structureCheckbox &&
                        structureCheckbox.map((response, index) => {
                          if (response.description) {
                            return (
                              <CheckboxUI
                                key={index}
                                state={index}
                                check={response.state}
                                description={response.description}
                                setOrderExecutante={setOrderExecutante}
                              />
                            );
                          } else if (response.label) {
                            return (
                              <CheckboxUI
                                key={index}
                                state={index}
                                check={response.state}
                                description={response.label}
                                setOrderExecutante={setOrderExecutante}
                              />
                            );
                          }
                        })}
                    </FormGroup>
                  </Card>
                </div>
              ) : null}
              {console.log(userType, "userType")}
              {userType === "Administrador" || userType === "Corpo Técnico" ? (
                <Card className="shadow-lg">
                  {console.log("entrou aqui")}
                  <CardBody>
                    <Row>
                      <Col>
                        <>
                          <p>Início do serviço</p>
                          <DateTimePicker
                            date={initialService}
                            setDate={setInitialService}
                            placeholder="Início do serviço"
                          />
                        </>
                      </Col>
                      <Col>
                        <>
                          <p>Fim do serviço</p>
                          <DateTimePicker
                            date={finishedService}
                            setDate={setFinishedService}
                            placeholder="Fim do serviço"
                          />
                        </>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "1em" }}>
                      <Col>
                        <h3> A ordem foi executada? </h3>
                        <input
                          type="radio"
                          value="Sim"
                          name="parou"
                          onClick={() => setShowBtnArea(true)}
                        />{" "}
                        Sim
                        <input
                          type="radio"
                          value="Não"
                          name="parou"
                          className="ml-4 mb-4"
                          onClick={() => {
                            setShowBtnArea(false);
                            radiomodal();
                          }}
                        />{" "}
                        Não
                      </Col>
                    </Row>

                    {showBtnArea ? (
                      <>
                        <Col>
                          <textarea
                            placeholder="Descreva o que foi executado"
                            name="descricao"
                            className="form-control"
                            //value={descricao}
                            onChange={(e) => setTextArea(e.target.value)}
                          />
                        </Col>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "0.5em",
                          }}
                        >
                          <Col className="col-auto">
                            <Button
                              color="success"
                              className="mr-2"
                              onClick={deletar}
                            >
                              Confirmar
                              {/* marcar data e hora do serviço */}
                            </Button>
                          </Col>
                        </div>
                      </>
                    ) : null}

                    {visibleBtn ? (
                      <Col
                        style={{ marginLeft: "-0.5em" }}
                        className="col-auto"
                      >
                        <Button
                          color="success"
                          className="mr-2"
                          onClick={completeOrderAdministrator}
                        >
                          Finalizar serviço
                          {/* marcar data e hora do serviço */}
                        </Button>
                      </Col>
                    ) : null}
                  </CardBody>
                </Card>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "1em",
                      marginBottom: "0.5em",
                    }}
                  >
                    <h4> Execução da tarefa </h4>

                    {statePopup.stateWork ? (
                      <>
                        {statePopup.finishedSeviceBtn ? null : (
                          <Button
                            color="success"
                            className="mr-2"
                            style={{ marginLeft: "-1em" }}
                            onClick={service}
                          >
                            <PlayCircleFilledWhiteIcon /> Iniciar serviço
                          </Button>
                        )}
                      </>
                    ) : (
                      <Button
                        color="danger"
                        className="mr-2"
                        style={{ marginRight: "-1em" }}
                        onClick={service}
                      >
                        <StopCircleIcon /> Finalizar serviço
                      </Button>
                    )}
                  </div>
                  {statePopup.finishedService && (
                    <Card className="shadow-lg">
                      <CardBody>
                        <Row>
                          <Col>
                            {statePopup.initiateService ? (
                              <>
                                <p>Início do serviço</p>
                                <DateTimePicker
                                  date={initialService}
                                  setDate={setInitialService}
                                  placeholder="Início do serviço"
                                  setStateAux={setVisibleBtn}
                                />
                              </>
                            ) : null}
                          </Col>
                          <Col>
                            {statePopup.finishedService ? (
                              <>
                                <p>Fim do serviço</p>
                                <DateTimePicker
                                  date={finishedService}
                                  setDate={setFinishedService}
                                  placeholder="Fim do serviço"
                                  setStateAux={setVisibleBtn}
                                />
                              </>
                            ) : null}
                          </Col>
                        </Row>
                        {statePopup.finishedService && (
                          <Row style={{ marginTop: "1em" }}>
                            <Col>
                              <h3> A ordem foi executada? </h3>
                              <input
                                type="radio"
                                value="Sim"
                                name="parou"
                                onClick={() => setShowBtnArea(true)}
                              />{" "}
                              Sim
                              <input
                                type="radio"
                                value="Não"
                                name="parou"
                                className="ml-4 mb-4"
                                onClick={() => {
                                  setShowBtnArea(false);
                                  radiomodal();
                                }}
                              />{" "}
                              Não
                            </Col>
                          </Row>
                        )}

                        {showBtnArea ? (
                          <>
                            <Col>
                              <textarea
                                placeholder="Descreva o que foi executado"
                                name="descricao"
                                className="form-control"
                                //value={descricao}
                                onChange={(e) => setTextArea(e.target.value)}
                              />
                            </Col>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginTop: "0.5em",
                              }}
                            >
                              <Col className="col-auto">
                                <Button
                                  color="success"
                                  className="mr-2"
                                  onClick={deletar}
                                >
                                  Confirmar
                                  {/* marcar data e hora do serviço */}
                                </Button>
                              </Col>
                            </div>
                          </>
                        ) : null}

                        {visibleBtn ? (
                          <Col
                            style={{ marginLeft: "-0.5em" }}
                            className="col-auto"
                          >
                            <Button
                              color="success"
                              className="mr-2"
                              onClick={updateService}
                            >
                              Atualizar serviço
                              {/* marcar data e hora do serviço */}
                            </Button>
                          </Col>
                        ) : null}
                      </CardBody>
                    </Card>
                  )}
                </>
              )}
            </ModalBody>
          )}
        </div>
      </Modal>

      {
        //so falta o modal do sim e quando eu clicar em ambos os radios buttons, ele precisa ocultar o anterior.
      }
      <Modal
        size="lg"
        className="mr-0 fixed-bottom"
        isOpen={stateModal}
        toggle={radiomodal}
      >
        <ModalHeader>
          {" "}
          <h2> Por que a ordem não foi executada? </h2>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <FormGroup>
                <h3>Motivo: </h3>
                <Input
                  type="select"
                  name="select"
                  value={motivo}
                  onChange={(e) => {
                    console.log(e.target.value, " - valor");
                    setMotivo(e.target.value);
                  }}
                  id="exampleSelect"
                >
                  <option>Equipamento não liberado para operação</option>
                  <option>Falta de material</option>
                  <option>Serviço será executado em outra oportunidade</option>
                  <option>Outros</option>
                </Input>
              </FormGroup>

              <textarea
                placeholder="Descreva o ocorrido"
                name="descricao"
                className="form-control"
                value={textAreaSelectedNo}
                onChange={(e) => setTextAreaSelectedNo(e.target.value)}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            className="mt--2 col-auto"
            onClick={selectedNoExecute}
          >
            Replanejar serviço
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ListRequests;
