// GESTÃO DE ORDENS DE MANUTENÇÂO

import axios from "axios";

const ORDER_API_BASE_URL = "https://api.darwinx.com.br/orders";

class OrderService {
  getOrders(order) {
    console.log(order);
    return axios.get(ORDER_API_BASE_URL, { params: order });
  }

  createOrder(order) {
    return axios.post(ORDER_API_BASE_URL, order);
  }

  getOrderById(orderId, token) {
    return axios.get(ORDER_API_BASE_URL + "/" + orderId, { params: token });
  }

  updateOrder(order, orderId) {
    return axios.put(ORDER_API_BASE_URL + "/" + orderId, order);
  }

  updateStatusOfOrder(orderId, order) {
    return axios.put(ORDER_API_BASE_URL + "/status/" + orderId, order);
  }

  deleteOrder(orderId, token) {
    return axios.delete(ORDER_API_BASE_URL + "/" + orderId, { params: token });
  }
}

export default new OrderService();
