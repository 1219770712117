import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  createRef,
  useMemo,
  useCallback,
} from "react";
import EquipmentService from "./EquipmentService";
// import DataTable, { createTheme } from 'react-data-table-component';
// import DataTableExtensions from 'react-data-table-component-extensions';
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";
import { UserType } from "../../context/UserType";
import swal from "sweetalert";
import SearchImage from "../../assets/img/icons/search.svg";
import FilterColumn from "../../assets/img/icons/filterColumn.svg";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
//Pacotes de import para os botoes de filtrar tabelas
import { purple } from "@material-ui/core/colors";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import Header from "components/Headers/Headerfor.js";
import CreateEquipment from "./CreateEquipment";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { EnterpriseNameContext } from "../../context/EnterpriseContext";
import SearchIcon from "@material-ui/icons/Search";
import AWS from "aws-sdk";
import Checkbox from "@material-ui/core/Checkbox";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
  useResizeColumns,
  useBlockLayout,
} from "react-table";
import "./ListEquipments.css";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
// reactstrap
import {
  Card,
  CardHeader,
  Container,
  Row,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Table,
  Badge,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import axios from "axios";

import ReactToPrint from "react-to-print";
import { yellow, deepOrange, green } from "@material-ui/core/colors";
import NotificationAlert from "react-notification-alert";
import PrintEquipment from "./PrintEquipment";
import Datagrid from "./Components/Datagrid";

const notification = createRef();
// import { useTable, usePagination } from 'react-table'

const useStylesCheck = makeStyles((theme) => ({
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    marginRight: "10px",
    marginLeft: "-5px",
    backgroundColor: deepOrange[500],
  },
  yellow: {
    color: "#fff",
    marginRight: "10px",
    marginLeft: "-5px",
    backgroundColor: yellow[500],
  },
  green: {
    color: "#fff",
    marginRight: "10px",
    marginLeft: "-5px",
    backgroundColor: green[500],
  },
}));
const createRefModal = createRef();
function ListEquipments(props) {
  const classesCheck = useStylesCheck();
  const qrCodeRef = useRef();
  const [equipment, setEquipment] = useState([]);
  // const [equipmentStoraged, setEquipamentStoraged] = useState([]);
  const [showImage, setShowImage] = useState();
  const [criticidade, setCriticidade] = useState();
  const { enterpriseName } = useContext(EnterpriseNameContext);
  const { userType } = useContext(UserType);
  const [image, setImage] = useState();
  const [none, setNone] = useState("none");
  const [token, setToken] = useState(() => {
    const tokenStoraged = localStorage.getItem("TOKEN_KEY");
    if (tokenStoraged) {
      return tokenStoraged;
    }
    return "";
  });
  const [hash, setHash] = useState(() => {
    const hashStoraged = localStorage.getItem("TOKEN_HASH");
    if (hashStoraged) {
      return hashStoraged;
    }
    return "";
  });
  const [idUserStorage, setIdUserStorage] = useState(() => {
    const idUserStoraged = localStorage.getItem("TOKEN_ID_USER");
    if (idUserStoraged) {
      return idUserStoraged;
    }
    return "";
  });
  const [qrcode, setQrcode] = useState("");

  const history = useHistory();
  const redirectLogin = () => {
    swal(
      "Hmm... vi que você não está logado. Clique em ok para fazer o login",
      "",
      "warning",
      {
        buttons: {
          // nope: {
          //   text: 'Me enganei!',
          //   value: 'nope',
          // },
          sure: {
            text: "Ok",
            value: "sure",
          },
        },
      }
    ).then((value) => {
      switch (value) {
        case "sure":
          swal("Operação realizada com sucesso!", "", "success").then((val) => {
            // history.pushState
            // return <Redirect to="/auth/login"/>
            history.push("/auth/login");
          });
          break;
        case "nope":
          swal("Operação cancelada", "", "success");
          break;
        default:
      }
    });
  };

  useEffect(() => {
    if (enterpriseName) {
      EquipmentService.getEquipment({
        empresa: enterpriseName,
        token: token,
        hash: hash,
        idUser: idUserStorage,
      })
        .then((res) => {
          setEquipment(res.data.data);
          // setEquipamentStoraged(res.data)
          localStorage.removeItem("TOKEN_KEY");
          localStorage.setItem("TOKEN_KEY", res.data.token);
        })
        .catch((error) => {
          if (error?.response?.data?.auth) {
          } else {
            // redirectLogin();
          }
        });
    }
  }, [enterpriseName, token, hash]);

  console.log(createRefModal);

  // const handlePrint = useReactToPrint({
  //   content: () => createRefModal.current,
  // });

  //Estilo dos botoes de filtrar colunas
  const PurpleSwitch = withStyles({
    switchBase: {
      color: "#58a3fb",
      "&$checked": {
        color: "#58a3fb",
      },
      "&$checked + $track": {
        backgroundColor: "#58a3fb",
      },
    },
    checked: {},
    track: {},
  })(Switch);

  // const [state, setState] = React.useState({
  //   ID: true,
  //   Codigo: false,
  //   Area: false,
  //   Setor: false,
  //   Sistema: false,
  //   Denominacao: false,
  //   Fabricante: false,
  //   Modelo: false,
  // });
  const [stateFilterList, setStateFilterList] = useState({
    Codigo: false,
    Area: false,
    Setor: false,
    Sistema: false,
    Denominacao: false,
    Fabricante: false,
    Modelo: false,
  });
  const [filterColumn, setFilterColumn] = useState({
    Codigo: true,
    Area: true,
    Setor: true,
    Sistema: true,
    Denominacao: true,
    Fabricante: true,
    Modelo: true,
  });

  const handleChange = (event) => {
    setStateFilterList({
      ...stateFilterList,
      [event.target.name]: event.target.checked,
    });
  };
  // FIM dos botoes

  // tema da tabela, preciso colocar em outline para aplicar a todas.
  const customStyles = {
    rows: {
      style: {
        backgroundColor: "",
        cursor: "pointer",
      },
    },
    headCells: {
      //titulo da tabela.
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        backgroundColor: "#f6f9fc",
        border: "1px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        borderLeft: "1px",
      },
    },
  };

  const cellcolor = {
    color: "#1193EF",
    marginRight: "0.2em",
    width: "19px",
  };

  const notifyGeneral = (event, title, text) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <h2
            style={{ color: "#fff", marginBottom: "0" }}
            className="alert-title"
            data-notify="title"
          >
            {title} <br />
          </h2>
          <span data-notify="message">{text}</span>
        </div>
      ),
      type: event,
      // icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    notification.current.notificationAlert(options);
  };

  // const columns = [
  //   {
  //     name: 'Código',
  //     selector: 'codigo',
  //     sortable: true,
  //     grow: 0.4, //aumentar e diminuir o tamanho da coluna.
  //     center: true, // centralizar a informação.stateFilterList
  //     omit: stateFilterList.Codigo,
  //   },
  //   {
  //     name: 'Área',
  //     selector: 'area',
  //     sortable: true,
  //     grow: 0.4,
  //     omit: stateFilterList.Area,
  //   },
  //   {
  //     name: 'Setor Instalado',
  //     selector: 'setor',
  //     sortable: true,
  //     grow: 0.7,
  //     omit: stateFilterList.Setor,
  //   },
  //   {
  //     name: 'Sistema',
  //     selector: 'sistema',
  //     sortable: true,
  //     omit: stateFilterList.Sistema,
  //   },
  //   {
  //     name: 'Equipamento',
  //     selector: 'denominacao',
  //     sortable: true,
  //     omit: stateFilterList.denominacao,
  //   },
  //   {
  //     name: 'Fabricante',
  //     selector: 'fabricante',
  //     sortable: true,
  //     omit: stateFilterList.Fabricante,
  //   },
  //   {
  //     name: 'Modelo de Equipamento',
  //     selector: 'modelo',
  //     sortable: true,
  //     omit: stateFilterList.Modelo,
  //   },
  // ];

  const TranslateOptions = {
    rowsPerPageText: "Equipamentos por Página:",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsText: "Todos",
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { buttonLabel, className, actual_id } = props;

  const [row_id, setRow_id] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [area, setArea] = useState("");
  const [denominacao, setDenominacao] = useState("");
  const [codigo, setCodigo] = useState("");
  const [fabricante, setFabricante] = useState("");
  const [modelo, setModelo] = useState("");
  const [sistema, setSistema] = useState("");
  const [setor_instalado, setSetor_instalado] = useState("");
  const [idUser, setIdUser] = useState("");
  const handleSubmit = (evt) => {
    evt.preventDefault();

    //alert(`Submitting Name ${firstName} ${lastName}`);
    //resetFirstName();
    //resetLastName();
  };
  // const columns = useMemo(() => COLUMNS, [])
  console.log(equipment);
  const informations = [
    // {
    //   Header: 'Id',
    //   Footer: 'Id',
    //   accessor: '_id',
    //   // disableFilters: true,
    //   sticky: 'left',
    //   disable: true
    // },
    {
      Header: "Código",
      Footer: "Código",
      accessor: "codigo",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Equipamento",
      Footer: "Equipamento",
      accessor: "denominacao",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Área",
      Footer: "Área",
      accessor: "area",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Setor Instalado",
      Footer: "Setor Instalado",
      accessor: "setor_instalado",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Sistema",
      Footer: "Sistema",
      accessor: "sistema",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Fabricante",
      Footer: "Fabricante",
      accessor: "fabricante",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Modelo",
      Footer: "Modelo",
      accessor: "modelo",
      // disableFilters: true,
      sticky: "left",
    },
  ];

  const columns = useMemo(() => informations, []);
  const data = useMemo(() => equipment, [equipment]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    allColumns,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
    // useResizeColumns,
    // useBlockLayout
  );
  console.log(page);
  const [value, setValue] = useState(state.globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value);
  });

  const notifyCode = (event, title, content) => {
    let options = {
      place: "tc",
      message: (
        <div>
          <h2
            style={{ color: "#fff", marginBottom: "0" }}
            className="alert-title"
            data-notify="title"
          >
            {" "}
            {title ? title : "Atenção!"}
            <br />
          </h2>
          <span data-notify="message">
            {content ? content : "Este código já foi cadastrado!"}
          </span>
        </div>
      ),
      type: event,
      // icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    notification.current.notificationAlert(options);
  };

  const update = (evt) => {
    let url, filename;
    if (image) {
      if (image[0].size > 1024 * 1024 * 4) {
        notifyCode(
          "warning",
          "Atenção!",
          "O tamanho da imagem é muito grande!"
        );
        return;
      }
      EquipmentService.getEquipmentById(row_id).then(async (response) => {
        const res = response.data;
        if (res.foto) {
          const s3 = new AWS.S3({
            accessKeyId: "AKIAVSM7L4TZDZIH7KGO",
            region: "sa-east-1",
            secretAccessKey: "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",
          });
          s3.deleteObject(
            { Key: response.data.nomeFoto, Bucket: "darwinxperfil" },
            function (err, data) {}
          );
          axios
            .delete("https://api.darwinx.com.br/images", {
              params: { url: response.data.foto },
            })
            .then(async (response) => {
              if (response.data) {
                const formData = new FormData();
                formData.append("image", image[0]);
                await axios({
                  method: "post",
                  url: "https://api.darwinx.com.br/images",
                  data: formData,
                  headers: { "Content-Type": "multipart/form-data" },
                }).then((response) => {
                  url = response.data.url;
                  filename = response.data.name;
                  console.log(url);
                });
              }
              if (url) {
                let equipments = {
                  area: area,
                  denominacao: denominacao,
                  codigo: codigo,
                  fabricante: fabricante,
                  modelo: modelo,
                  setor_instalado: setor_instalado,
                  sistema: sistema,
                  criticidade: criticidade,
                  nomeFoto: filename,
                  foto: url,
                  token: token,
                  hash: hash,
                  idUser: idUserStorage,
                };
                console.log(equipments);
                EquipmentService.updateEquipment(equipments, row_id)
                  .then((res) => {
                    //equipment._id no lugar do ID
                    console.log(res);
                    EquipmentService.getEquipment({
                      empresa: enterpriseName,
                      token: token,
                      hash: hash,
                      idUser: idUserStorage,
                    }).then((res) => {
                      setEquipment(res.data.data);
                      // setEquipamentStoraged(res.data)
                      localStorage.removeItem("TOKEN_KEY");
                      localStorage.setItem("TOKEN_KEY", res.data.token);
                    });
                    setModal(!modal);
                    notifyGeneral(
                      "success",
                      "Concluído",
                      `O equipamento com o código ${codigo} foi atualizado!`
                    );
                    //console.log("Etapa 4");
                  })
                  .catch((error) => {
                    if (error?.response?.data?.auth) {
                    } else {
                      // redirectLogin();
                    }
                  });
              }
            });
        } else {
          const formData = new FormData();
          formData.append("image", image[0]);
          await axios({
            method: "post",
            url: "https://api.darwinx.com.br/images",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then((response) => {
              url = response.data.url;
              filename = response.data.name;
              console.log(url);
            })
            .catch((error) => {
              console.log(error);
            });
        }
        if (url) {
          let equipments = {
            area: area,
            denominacao: denominacao,
            codigo: codigo,
            fabricante: fabricante,
            modelo: modelo,
            setor_instalado: setor_instalado,
            sistema: sistema,
            criticidade: criticidade,
            nomeFoto: filename,
            foto: url,
            token: token,
            hash: hash,
            idUser: idUserStorage,
          };
          console.log(equipments);
          EquipmentService.updateEquipment(equipments, row_id)
            .then((res) => {
              //equipment._id no lugar do ID
              console.log(res);
              EquipmentService.getEquipment({
                empresa: enterpriseName,
                token: token,
                hash: hash,
                idUser: idUserStorage,
              })
                .then((res) => {
                  setEquipment(res.data.data);
                  // setEquipamentStoraged(res.data)
                  localStorage.removeItem("TOKEN_KEY");
                  localStorage.setItem("TOKEN_KEY", res.data.token);
                })
                .catch((error) => {
                  console.log(error.message);
                });
              setModal(!modal);
              notifyGeneral(
                "success",
                "Concluído",
                `O equipamento com o código ${codigo} foi atualizado!`
              );
              //console.log("Etapa 4");
            })
            .catch((error) => {
              if (error?.response?.data?.auth) {
              } else {
                // redirectLogin();
              }
            });
        }
      });
    } else {
      let equipments = {
        area: area,
        denominacao: denominacao,
        codigo: codigo,
        fabricante: fabricante,
        modelo: modelo,
        setor_instalado: setor_instalado,
        sistema: sistema,
        criticidade: criticidade,
        token: token,
        hash: hash,
        idUser: idUserStorage,
      };
      console.log(equipments);
      EquipmentService.updateEquipment(equipments, row_id)
        .then((res) => {
          //equipment._id no lugar do ID
          console.log(res);
          EquipmentService.getEquipment({
            empresa: enterpriseName,
            token: token,
            hash: hash,
            idUser: idUserStorage,
          })
            .then((res) => {
              setEquipment(res.data.data);
              // setEquipamentStoraged(res.data)
              localStorage.removeItem("TOKEN_KEY");
              localStorage.setItem("TOKEN_KEY", res.data.token);
            })
            .catch((error) => {
              if (error?.response?.data?.auth) {
              } else {
                // redirectLogin();
              }
            });
          setModal(!modal);
          notifyGeneral(
            "success",
            "Concluído",
            `O equipamento com o código ${codigo} foi atualizado!`
          );
          //console.log("Etapa 4");
        })
        .catch((error) => {
          if (error?.response?.data?.auth) {
          } else {
            // redirectLogin();
          }
        });
    }
  };

  const deletar = (evt) => {
    swal("Tem certeza que deletar o equipamento?", "", "warning", {
      buttons: {
        nope: {
          text: "Me enganei!",
          value: "nope",
        },
        sure: {
          text: "Sim, claro.",
          value: "sure",
        },
      },
    }).then((value) => {
      switch (value) {
        case "sure":
          swal("Equipamento deletado com sucesso", "", "success").then(
            (val) => {
              EquipmentService.deleteEquipment(row_id)
                .then((res) => {
                  //console.log("Etapa 4");
                  // window.location.reload();
                  EquipmentService.getEquipment({
                    empresa: enterpriseName,
                    token: token,
                    hash: hash,
                    idUser: idUserStorage,
                  })
                    .then((res) => {
                      setEquipment(res.data.data);
                      notifyGeneral(
                        "error",
                        "Concluído",
                        "Equipamento apagado com sucesso!"
                      );
                      // setEquipamentStoraged(res.data)
                      localStorage.removeItem("TOKEN_KEY");
                      localStorage.setItem("TOKEN_KEY", res.data.token);
                      // setModal(false)
                    })
                    .catch((error) => {
                      if (error?.response?.data?.auth) {
                      } else {
                        // redirectLogin();
                      }
                    });
                })
                .catch((error) => {
                  if (error?.response?.data?.auth) {
                  } else {
                    // redirectLogin();
                  }
                });
            }
          );
          break;
        case "nope":
          swal("Operação cancelada", "", "success");
          break;
        default:
          swal("Equipamento excluído!");
      }
    });
  };

  function linhaClicada(source) {
    const row = source.data._id;
    console.log(source, "source");
    console.log(row);
    setRow_id(row);
    setModal(true);
    EquipmentService.getEquipmentById(row, {
      token: token,
      hash: hash,
      idUser: idUserStorage,
    })
      .then((res) => {
        setQrcode(
          `https://api.qrserver.com/v1/create-qr-code/?data=darwinx://qrcode/${row}&size=120x120&bgcolor=#fff%60`
        );
        let equipments = res.data.data;
        setArea(equipments.area); //equipments.tag
        setDenominacao(equipments.denominacao);
        setCodigo(equipments.codigo);
        setFabricante(equipments.fabricante);
        setSistema(equipments.sistema);
        setModelo(equipments.modelo);
        setSetor_instalado(equipments.setor_instalado);
        console.log(res.data.nomeFoto);
        setCriticidade(equipments.criticidade);
        console.log(equipments.nomeFoto);
        if (equipments.nomeFoto) {
          try {
            const myBucket = "darwinxperfil";
            const myKey = equipments.nomeFoto;
            const signedUrlExpireSeconds = 60 * 1;
            const s3 = new AWS.S3({
              accessKeyId: "AKIAVSM7L4TZDZIH7KGO",
              region: "sa-east-1",
              secretAccessKey: "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",
            });

            const url = s3.getSignedUrl("getObject", {
              Bucket: myBucket,
              Key: myKey,
              Expires: signedUrlExpireSeconds,
            });
            console.log(url);
            setShowImage(url);
          } catch (error) {
            console.log(error);
          }
        } else {
          setShowImage(null);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.auth) {
        } else {
          // redirectLogin();
        }
      });
  }

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));

  const classes = useStyles();
  const sort_by = (field, reverse, primer) => {
    const key = primer
      ? function (x) {
          return primer(x[field]);
        }
      : function (x) {
          return x[field];
        };

    reverse = !reverse ? 1 : -1;

    return function (a, b) {
      return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a));
    };
  };
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <NotificationAlert ref={notification} />
        <Card className="shadow">
          <CardHeader className="border-0">
            <CreateEquipment
              equipment={equipment}
              setEquipment={setEquipment}
            />
          </CardHeader>
          <CardBody>
            <div style={{ width: "100%" }}>
              <Datagrid lineClicked={linhaClicada} dataSource={equipment} />
            </div>
          </CardBody>
        </Card>
      </Container>

      <Modal
        isOpen={modal}
        toggle={toggle}
        size="lg"
        className="mr-0 mt--1 mb--1"
      >
        <ModalHeader tag="h1" toggle={toggle}>
          Editar Equipamento{" "}
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <TextField
                id="outlined-basic"
                label="Código do Equipamento"
                variant="outlined"
                value={codigo}
                onChange={(e) => setCodigo(e.target.value)}
                style={{ width: "100%" }}
              />
            </div>

            <div className="form-group">
              <TextField
                id="outlined-basic"
                label="Área de instalacão do equipamento"
                variant="outlined"
                value={area}
                onChange={(e) => setArea(e.target.value)}
                style={{ width: "100%" }}
              />
            </div>

            <div className="form-group">
              <TextField
                id="outlined-basic"
                label="Setor Instalado"
                variant="outlined"
                value={setor_instalado}
                onChange={(e) => setSetor_instalado(e.target.value)}
                style={{ width: "100%" }}
              />
            </div>
            <div className="form-group">
              <TextField
                id="outlined-basic"
                label="Sistema"
                variant="outlined"
                value={sistema}
                onChange={(e) => setSistema(e.target.value)}
                style={{ width: "100%" }}
              />
            </div>
            <div className="form-group">
              <TextField
                id="outlined-basic"
                label="Equipamento"
                variant="outlined"
                value={denominacao}
                onChange={(e) => setDenominacao(e.target.value)}
                style={{ width: "100%" }}
              />
            </div>

            <Row>
              <Col>
                <div className="form-group">
                  <TextField
                    id="outlined-basic"
                    label="Fabricante"
                    variant="outlined"
                    value={fabricante}
                    onChange={(e) => setFabricante(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <TextField
                    id="outlined-basic"
                    label="Modelo"
                    variant="outlined"
                    value={modelo}
                    onChange={(e) => setModelo(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </div>
              </Col>
            </Row>
            <div className="form-group">
              <label>Foto:</label>
              {showImage && (
                <>
                  <div style={{ width: "35%", marginBottom: "1em" }}>
                    <img
                      style={{ maxWidth: "300px", maxHeight: "400px" }}
                      src={showImage}
                      alt=""
                    />
                  </div>
                </>
              )}
              <input
                type="file"
                placeholder="Equipamento"
                name="file"
                className="form-control"
                accept="image/png, image/jpeg"
                onChange={(e) => {
                  setImage(e.target.files);
                }}
              />
            </div>
            <FormControl style={{ display: "block" }} component="fieldset">
              <FormLabel component="legend">
                Criticidade do Equipamento
              </FormLabel>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue="top"
              >
                <FormControlLabel
                  style={{ marginRight: "3em" }}
                  value="Alta"
                  checked={criticidade === "Alta"}
                  control={
                    <>
                      <Radio
                        onChange={() => {
                          setCriticidade("Alta");
                        }}
                        checked={criticidade === "Alta"}
                        color="primary"
                      />{" "}
                      <Avatar className={classesCheck.orange}>A</Avatar>
                    </>
                  }
                  label="Alta"
                />
                <FormControlLabel
                  style={{ marginRight: "3em" }}
                  value="Média"
                  control={
                    <>
                      <Radio
                        checked={criticidade === "Média"}
                        onChange={() => {
                          setCriticidade("Média");
                        }}
                        color="primary"
                      />{" "}
                      <Avatar
                        onChange={() => {
                          console.log("cliqui aqui");
                        }}
                        className={classesCheck.yellow}
                      >
                        B
                      </Avatar>
                    </>
                  }
                  label="Média"
                  checked={criticidade === "Média"}
                />
                <FormControlLabel
                  value="Baixa"
                  checked={criticidade === "Baixa"}
                  control={
                    <>
                      <Radio
                        onChange={() => {
                          setCriticidade("Baixa");
                        }}
                        checked={criticidade === "Baixa"}
                        color="primary"
                      />{" "}
                      <Avatar className={classesCheck.green}>C</Avatar>
                    </>
                  }
                  label="Baixa"
                  onChange={(e, v) => {
                    if (v) {
                      setCriticidade("Baixa");
                    }
                  }}
                />
              </RadioGroup>
            </FormControl>
          </form>
          <div
            ref={(el) => {
              console.log(el);
              return (qrCodeRef.current = el);
            }}
          >
            {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', border: '2px solid red', marginTop: "2em"}}>
                  <QRCode style={{width: '25%', height: '25%'}} position="" value={`http://18.228.45.138:3000/admin/qrcode?id=${row_id}`}/>
                  <div style={{textAlign: 'center', marginTop: '1em'}}>
                      <label style={{fontSize: '2em'}}> <b>Equipamento:</b> {denominacao}</label> <br></br>
                      <label style={{fontSize: '2em'}}> <b>Código:</b> {codigo}</label> <br></br>
                      <label style={{fontSize: '2em'}}> <b>Área:</b> {area} </label>
                  </div>
            </div> */}
          </div>
          {/* <PrintEquipment reff={qrCodeRef} ref={(el) => {
              console.log(el);
              qrCodeRef.current = el
              }} id={row_id} codigo={codigo} denominacao={denominacao} area={area}/> */}
          <div style={{ display: "none" }}>
            <PrintEquipment
              id={row_id}
              denominacao={denominacao}
              codigo={codigo}
              area={area}
              ref={(el) => (qrCodeRef.current = el)}
            />
          </div>

          <div id="qrCode" className="form-group">
            <Card className=" shadow-lg bg-default">
              <CardHeader className="">
                <Row>
                  <Col>
                    <h4> QR Code: </h4>
                  </Col>
                  <Col></Col>
                  <Col>
                    <ReactToPrint
                      trigger={() => (
                        <Button
                          color="default"
                          size="sm"
                          className="ml-9"
                          type="button"
                          onClick={() => {
                            console.log("clicou");
                            setNone("block");
                          }}
                        >
                          Imprimir
                        </Button>
                      )}
                      content={() => qrCodeRef.current}
                    />
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                <div>
                  <Row>
                    {row_id ? console.log(`darwinx://qrcode/${row_id}`) : null}
                    <Col className="">
                      {/* <QRCode
                        accessKey="darwinx"
                        key="darwinx"
                        position=""
                        value={`darwinx://qrcode/${row_id}`}
                      /> */}
                      <img alt="qrcode" src={qrcode} />
                    </Col>
                    <Col className="mb-5 mb-xl-0 text-white" xl="9">
                      <label> Código: {codigo}</label> <br></br>
                      <label> Equipamento: {denominacao}</label> <br></br>
                      <label> Área: {area} </label>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </div>

          <Row>
            <Col className="mb-5 mb-xl-0" xl="9">
              {userType !== "Executante" && userType !== "Requisitante" ? (
                <>
                  <Button color="success" onClick={update}>
                    Atualizar
                  </Button>{" "}
                  <Button color="secondary" onClick={toggle}>
                    Cancelar
                  </Button>
                </>
              ) : null}
            </Col>

            <Col className="">
              {userType !== "Executante" && userType !== "Requisitante" ? (
                <>
                  <Button color="secondary" onClick={deletar}>
                    <DeleteIcon /> Apagar
                  </Button>
                </>
              ) : null}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}

export default ListEquipments;
