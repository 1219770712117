import { Badge } from "reactstrap";
import ErrorOutlineRounded from "@material-ui/icons/ErrorOutlineRounded";
import WarningRounded from "@material-ui/icons/WarningRounded";

const quantityAvailable = (spare) => {
  console.log(spare, "quantityAvailable");
  const sumQuantityAvailable = () => {
    return (spare?.quantidade - spare?.itens_reservados ?? 0) < 0
      ? 0
      : spare?.quantidade - spare?.itens_reservados ?? 0;
  };

  if (spare?.quantidade === spare?.ressuprimento) {
    return (
      <Badge
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        color=""
        className="badge-dot mr-4"
      >
        <ErrorOutlineRounded
          style={{
            color: "#ffcc00",
            marginRight: "3px",
          }}
          color=""
        />
        {sumQuantityAvailable()}
      </Badge>
    );
  }

  if (spare.quantidade < spare.ressuprimento) {
    return (
      <Badge
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        color=""
        className="badge-dot mr-4"
      >
        <WarningRounded color="error" style={{ marginRight: "3px" }} />
        {sumQuantityAvailable()}
      </Badge>
    );
  }

  return <>{sumQuantityAvailable()}</>;
};

export default quantityAvailable;
