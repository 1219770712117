import ReactSelect from "react-select";
import { SelectContainer } from "./styles";

const Select = ({ options, value, onChange, label, ...rest }) => {
  return (
    <SelectContainer>
      <h4>{label}</h4>
      <ReactSelect
        {...rest}
        options={options}
        value={value}
        onChange={onChange}
      />
    </SelectContainer>
  );
};

export default Select;
