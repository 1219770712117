/* eslint-disable no-restricted-globals */
// AWS Amplify
//import { graphql, Link } from 'gatsby'
//import { withAuthenticator, Connect } from 'aws-amplify-react'
//import Amplify, { graphqlOperation } from 'aws-amplify'
//import { listPostLikes } from '../graphql/queries'
//import awsConfig from './aws-exports.js'
//Amplify.configure(awsConfig)

import React, { useContext, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { Button, Input } from "reactstrap";
import { EnterpriseNameContext } from "../../context/EnterpriseContext";
import VerifyStrategyService from "./VerifyStrategyService";
import verifyOrder from "./VerifyOrderService";

import { Row, Col } from "reactstrap";

const LoginSchema = Yup.object().shape({
  username: Yup.string()
    .min(2, "username is Too Short!")
    .max(50, "username is Too Long!")
    .required("Username is Required"),
  password: Yup.string().required("Password is required"),
});

const Login = () => {
  const { enterpriseName, setEnterpriseName } = useContext(
    EnterpriseNameContext
  );

  useEffect(() => {
    if (localStorage.getItem("TOKEN_KEY") != null) {
      console.log(window.history);

      console.log(localStorage.getItem("TOKEN_KEY"));
      // setEnterpriseName(localStorage.getItem("TOKEN_EMPRESA"))
      if (
        localStorage.getItem("TOKEN_USERTYPE") === "Requisitante" ||
        localStorage.getItem("TOKEN_USERTYPE") === "Executante"
      ) {
        return (document.location.href = "/admin/requests");
      }
      // return document.location.href = "/admin";
    }
  }, [setEnterpriseName]);

  const submitForm = (values, history) => {
    console.log(values);
    axios
      .post("https://api.darwinx.com.br/login", values)
      .then((res) => {
        if (res.data.result === "success") {
          console.log(res.data);
          VerifyStrategyService.verify();
          verifyOrder.verify();
          localStorage.setItem("TOKEN_EMPRESA", res.data.empresa);
          localStorage.setItem("TOKEN_KEY", res.data.token);
          localStorage.setItem("TOKEN_USERTYPE", res.data.level);
          localStorage.setItem("TOKEN_ID_USER", res.data.id);
          localStorage.setItem("TOKEN_USERNAME", res.data.username);
          localStorage.setItem("TOKEN_HASH", res.data.hash);
          localStorage.setItem(
            "TOKEN_MATERIAL_AUTOMATIC",
            res.data.materialAutomatic
          );
          swal("Success!", res.data.message, "success").then((value) => {
            // history.push("/admin/index");
            if (
              localStorage.getItem("TOKEN_USERTYPE") === "Requisitante" ||
              localStorage.getItem("TOKEN_USERTYPE") === "Executante"
            ) {
              return (document.location.href = "/admin/requests");
            } else {
              // consok
              document.location.href = "/admin/dashboard";
            }
          });
        } else if (res.data.result === "error") {
          swal("Error!", res.data.message, "error");
        }
      })
      .catch((error) => {
        console.log(error);
        return swal("Error!", error.message, "error");
      });
  };
  const showForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  }) => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="form-group input-group has-feedback">
          <input
            type="text"
            name="username"
            onChange={handleChange}
            value={values.username}
            className="form-control"
            placeholder="Username"
            className={
              errors.username && touched.username
                ? "form-control is-invalid"
                : "form-control"
            }
          />
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-user"></span>
            </div>
          </div>
          {errors.username && touched.username ? (
            <small id="passwordHelp" class="text-danger">
              {errors.username}
            </small>
          ) : null}
        </div>
        <div className="form-group input-group mb-3 has-feedback">
          <input
            type="password"
            name="password"
            onChange={handleChange}
            value={values.password}
            className="form-control"
            placeholder="Password"
            className={
              errors.password && touched.password
                ? "form-control is-invalid"
                : "form-control"
            }
          />
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-lock"></span>
            </div>
          </div>
          {errors.password && touched.password ? (
            <small id="passwordHelp" class="text-danger">
              {errors.password}
            </small>
          ) : null}
        </div>
        <div class="row">
          <div class="col-8">
            <div class="icheck-primary">
              <Row>
                <Col>
                  <Input type="checkbox" id="remember" className="bl-2" />
                  <label for="remember">Salvar senha</label>
                </Col>
                <Col></Col>
              </Row>
            </div>
          </div>
          <div class="col-4">
            <Button
              type="submit"
              color="danger"
              disabled={isSubmitting}
              class="btn btn-primary btn-block"
            >
              Entrar
            </Button>
          </div>
        </div>
      </form>
    );
  };

  return (
    <>
      <Row className="">
        <Col></Col>
        <Col className="mb-5 mb-xl-0" xl="6">
          <div class="login-page">
            <div className="register-box">
              <div className="register-logo"></div>
              <div className="card">
                <div className="card-body register-card-body">
                  <p className="login-box-msg">
                    Efetue o Login para iniciar a sessão
                  </p>

                  <Formik
                    initialValues={{
                      username: "",
                      password: "",
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      submitForm(values, history);
                      setSubmitting(false);
                    }}
                    validationSchema={LoginSchema}
                  >
                    {/* {this.showForm()}            */}
                    {(props) => showForm(props)}
                  </Formik>

                  <p class="mb-1">
                    <Link to="password/forgot">Esqueci minha senha</Link>
                  </p>

                  {/* <p class="mb-0">
                <Link to="/register">Realize seu cadastro</Link>
              </p> */}
                </div>
                {/* /.form-box */}
              </div>
              {/* /.card */}
            </div>
          </div>
        </Col>
        <Col></Col>
      </Row>
    </>
  );
};

export default Login;
