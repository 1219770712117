import styled from "styled-components";

export const Container = styled.main`
  /* background-color: #eee; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 50vh;
  /* border: 2px solid rgba(0,0,0,0.5) */
  /* align-items: center; */
`;

export const CtnInputs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 50%; */
  /* border: 2px solid red; */
  & + & {
    margin-top: 2em;
  }
`;

export const Title = styled.h1`
  /* font-size: small; */
  font-size: 1em;
  margin-bottom: 1em;
`;
