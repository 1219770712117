// AWS Amplify
//import { graphql, Link } from 'gatsby'
//import { withAuthenticator, Connect } from 'aws-amplify-react'
//import Amplify, { graphqlOperation } from 'aws-amplify'
//import { listPostLikes } from '../graphql/queries'
//import awsConfig from './aws-exports.js'
//Amplify.configure(awsConfig)

import React, { useState, useEffect, useContext, useMemo } from "react";
import { EnterpriseNameContext } from "../../context/EnterpriseContext";
import DashboardService from "./DashboardService";
// node.js library that concatenates classes (strings)
// javascipt plugin for creating charts
import Chart from "chart.js";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
// react plugin used to create charts
import { Line, Doughnut, Pie } from "react-chartjs-2";
// reactstrap components
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine } from "@nivo/line";

import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

import { Link } from "react-router-dom";
import PlumbingIcon from "@mui/icons-material/Plumbing";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/Info";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Table,
  Row,
  Col,
  CardTitle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Badge,
  CardFooter,
  Progress,
} from "reactstrap";

import DatepickerArgon from "./components/DatepickerArgon.jsx";

import moment from "moment";

// core components
import {
  chartOptions,
  parseOptions,
  chartOrdens_abertas_encerradas,
  chartExample2,
} from "variables/charts.js";

// import { ResponsiveLine } from '@nivo/line'
import { ResponsivePie } from "@nivo/pie";

import "./styles/styles.order.css";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const Orders = (props) => {
  const { enterpriseName } = useContext(EnterpriseNameContext);
  const [activeNav, setActiveNav] = useState(1);
  const [
    chartOrdens_abertas_encerradasData,
    setchartOrdens_abertas_encerradasData,
  ] = useState("data1");
  const [cardsOrder, setCardsOrder] = useState();
  const [totalOrderType, setTotalOrderType] = useState();
  const [octoberOrders, setOctoberOrders] = useState();
  const [deadlineTable, setDeadlineTable] = useState([]);
  const [sector, setSector] = useState([]);
  const [ordersOpen, setOrdersOpen] = useState([]);
  const [ordersCompleted, setOrdersCompleted] = useState([]);

  const Bad_Actors_Bar = [
    {
      country: "BC-01",
      "Bad Actors": 10000,
      donutColor: "hsl(148, 70%, 50%)",
    },
    {
      country: "ME-003",
      "Bad Actors": 12000,
      donutColor: "hsl(95, 70%, 50%)",
    },
    {
      country: "PLC-005",
      "Bad Actors": 15000,
      donutColor: "hsl(162, 70%, 50%)",
    },
    {
      country: "EST-002",
      "Bad Actors": 19000,
      donutColor: "hsl(103, 70%, 50%)",
    },

    {
      country: "RL-006",
      "Bad Actors": 25000,
      donutColor: "hsl(157, 70%, 50%)",
    },
  ];

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setchartOrdens_abertas_encerradasData("data" + index);
  };

  useEffect(() => {
    DashboardService.getOrder({ empresa: enterpriseName }).then((response) => {
      const data = response.data;
      console.log(data, "orders");
      setCardsOrder(data);
      if (data.equipmentArea) {
        setEquipmentArea(data.equipmentArea[0] ? data.equipmentArea[0] : []);
      }
      setTotalOrderType(data?.totalOrderType);
      setOrdersCompleted(data?.OrderOpenAndCompleted.completed);
      setOrdersOpen(data?.OrderOpenAndCompleted.open);
      setDeadlineTable(data?.deadlineArray);
      const sortSector = data?.sectorCount?.sector.sort((a, b) => {
        if (a?.quantity < b?.quantity) {
          return 1;
        }
        if (a?.quantity > b?.quantity) {
          return -1;
        }
      });
      const filterAvailability = sortSector?.filter((sender, index) => {
        if (index < 6) {
          return sender;
        }
      });
      if (filterAvailability) {
        setSector(filterAvailability);
      }
    });
  }, [enterpriseName]);
  const DataPie_Tipo_Ordens = [
    {
      id: "Corretivas",
      label: "Corretivas",
      value: `${totalOrderType ? totalOrderType?.corrective : 0}`,
      // "backgroundColor": "hsl(210, 70%, 50%)",
      // color: "hsl(210, 70%, 50%)",
    },
    {
      id: "Preditivas",
      label: "Preditivas",
      value: `${totalOrderType ? totalOrderType?.predictive : 0}`,
      color: "hsl(103, 70%, 50%)",
    },
    {
      id: "Preventivas",
      label: "Preventivas",
      value: `${totalOrderType ? totalOrderType?.preventive : 0}`,
      color: "hsl(72, 70%, 50%)",
    },
    {
      id: "Melhoria",
      label: "Melhoria",
      value: `${totalOrderType ? totalOrderType?.improvement : 0}`,
      color: "hsl(0, 100%, 49%)",
    },
  ];

  const DataLine_Abertas_Concluídas = [
    {
      id: "Ordens Abertas",
      color: "hsl(281, 70%, 50%)",
      data: ordersOpen,
    },
    {
      id: "Ordens Concluídas",
      color: "hsl(281, 70%, 50%)",
      data: ordersCompleted,
    },
  ];

  const columnHeader = [
    {
      Header: "Ordem",
      Footer: "Ordem",
      accessor: "id",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Descrição da Ordem",
      Footer: "Descrição da Ordem",
      accessor: "descricao",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: "status",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Prioridade",
      Footer: "Prioridade",
      accessor: "prioridade",
      // disableFilters: true,
      sticky: "left",
    },
  ];

  const columns = useMemo(() => columnHeader, []);
  const data = useMemo(() => deadlineTable, [deadlineTable]);

  const {
    getTableProps,
    getTableBodyProps,
    minRows,
    maxRows,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    allColumns,
    // state:{pageSize: 10},
    state: { pageIndex, pageSize },
    defaultPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { maxRows: 10, pageSize: 10, defaultPageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  console.log(page);
  const [value, setValue] = useState(state.globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value);
  });

  const [startDate, setStartDate] = useState(
    new Date(new Date().setFullYear(new Date().getFullYear() - 1))
  );
  const [endDate, setEndDate] = useState(new Date());

  const [equipmentArea, setEquipmentArea] = useState([]);
  const [selectedArea, setSelectedArea] = useState([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedArea(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <>
      <Row>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-checkbox-label">Área</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={selectedArea}
              onChange={handleChange}
              input={<OutlinedInput label="Área" />}
              MenuProps={MenuProps}
            >
              {equipmentArea.map((name) => (
                <MenuItem key={name} value={name}>
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ display: "flex", width: "80%", marginTop: "1.5em" }}>
            <DatepickerArgon
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
            <div>
              <Button
                style={{ marginLeft: "10px", marginRight: "20px" }}
                type="button"
                color="success"
                onClick={() => {
                  DashboardService.getOrder({
                    empresa: enterpriseName,
                    start: new Date(startDate),
                    end: new Date(endDate),
                    area: selectedArea[0],
                  }).then((response) => {
                    const data = response.data;
                    console.log(data, "data");
                    setCardsOrder(data);
                    setTotalOrderType(data?.totalOrderType);
                    setOrdersCompleted(data?.OrderOpenAndCompleted.completed);
                    setOrdersOpen(data?.OrderOpenAndCompleted.open);
                    setDeadlineTable(data?.deadlineArray);
                    const sortSector = data?.sectorCount?.sector.sort(
                      (a, b) => {
                        if (a?.quantity < b?.quantity) {
                          return 1;
                        }
                        if (a?.quantity > b?.quantity) {
                          return -1;
                        }
                      }
                    );
                    if (sortSector) {
                      setSector(sortSector);
                    }
                  });
                }}
              >
                Filtrar
              </Button>
            </div>
          </div>
        </div>

        <Col className="mb-5 mb-xl-0" xl="12">
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="3">
                {/*  <Card className="card-stats mb-4 mb-xl-0"> */}
                <Card>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Abertas
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {cardsOrder?.totalOrderOpen
                            ? cardsOrder?.totalOrderOpen
                            : 0}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <Tooltip
                          style={{ cursor: "pointer" }}
                          title={
                            "Ordens Abertas entre " +
                            `${moment(startDate).format(
                              "DD/MM/YYYY"
                            )} e ${moment(endDate).format("DD/MM/YYYY")}`
                          }
                        >
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-chart-bar" />
                          </div>
                        </Tooltip>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      {/* <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 0%
                      </span>{" "} */}
                      <span className="text-nowrap">
                        Entre {moment(startDate).format("DD/MM/YYYY")} e{" "}
                        {moment(endDate).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Concluídas
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {cardsOrder?.totalOrderCompleted
                            ? cardsOrder?.totalOrderCompleted
                            : 0}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <Tooltip
                          style={{ cursor: "pointer" }}
                          title={
                            "Ordens concluídas entre " +
                            `${moment(startDate).format(
                              "DD/MM/YYYY"
                            )} e ${moment(endDate).format("DD/MM/YYYY")}`
                          }
                        >
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-chart-pie" />
                          </div>
                        </Tooltip>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      {/* <span className="text-danger mr-2">
                        <i className="fas fa-arrow-down" /> 0%
                      </span>{" "} */}
                      <span className="text-nowrap">
                        Entre {moment(startDate).format("DD/MM/YYYY")} e{" "}
                        {moment(endDate).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Atrasadas
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {cardsOrder?.deadlineLate
                            ? cardsOrder?.deadlineLate
                            : 0}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <Tooltip
                          style={{ cursor: "pointer" }}
                          title={
                            "Ordens em atraso entre " +
                            `${moment(startDate).format(
                              "DD/MM/YYYY"
                            )} e ${moment(endDate).format("DD/MM/YYYY")}`
                          }
                        >
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Tooltip>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      {/* <span className="text-warning mr-2">
                        <i className="fas fa-arrow-down" /> 0%
                      </span>{" "} */}
                      <span className="text-nowrap">
                        Entre {moment(startDate).format("DD/MM/YYYY")} e{" "}
                        {moment(endDate).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Tempo médio de atendimento
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {cardsOrder?.sumOfAttendance
                            ? cardsOrder.sumOfAttendance <= 1
                              ? "< 1 dia"
                              : parseFloat(cardsOrder.sumOfAttendance).toFixed(
                                  2
                                ) + " dias"
                            : 0 + " dia"}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <Tooltip
                          style={{ cursor: "pointer" }}
                          title={
                            "Tempo médio de atendimento entre " +
                            `${moment(startDate).format(
                              "DD/MM/YYYY"
                            )} e ${moment(endDate).format("DD/MM/YYYY")}`
                          }
                        >
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <PlumbingIcon />
                          </div>
                        </Tooltip>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      {/* <span className="text-success mr-2">
                        <i className="fas fa-arrow-up" /> 0%
                      </span>{" "} */}
                      <span className="text-nowrap">
                        Entre {moment(startDate).format("DD/MM/YYYY")} e{" "}
                        {moment(endDate).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {/* <div style={{border: "2px solid green", position: "absolute", zIndex: "9999", height: "300px", width: "80%"}}> */}
      {/* <Fab style={{position: "absolute", bottom:"50px", right:"50px", float: "right", border: "2px solid red"}} color="primary" aria-label="add"> */}
      {/* <AddIcon /> */}
      {/* </Fab> */}
      {/* </div> */}

      {/* <div style={{position: "fixed",zIndex:"9999", border: "2px solid red", width:"100px", height:"100px", bottom: "10px"}}>
        <p>Teste</p>
      </div> */}

      <Row className="mt-5">
        <Col className="mb-5 mb-xl-0" xl="12">
          <Card>
            <CardHeader className="bg-transparent">
              <Row className="align-items-center">
                <Col>
                  <h6 className="text-uppercase ls-1 mb-1">Visão Geral</h6>
                  <h2 className=" mb-0">Ordens Abertas vs Concluídas</h2>
                </Col>
                {/* <Col className="col-auto">
                  <Tooltip
                    title={
                      <React.Fragment>
                        <Typography color="inherit">Como funciona ?</Typography>
                        {"Em breve!"}
                      </React.Fragment>
                    }
                  >
                    <InfoIcon> </InfoIcon>
                  </Tooltip>
                </Col> */}
              </Row>
            </CardHeader>
            <CardBody>
              <div style={{ height: 350 }}>
                <ResponsiveLine
                  data={DataLine_Abertas_Concluídas}
                  margin={{ top: 20, right: 110, bottom: 20, left: 60 }}
                  xScale={{ type: "point" }}
                  yScale={{
                    type: "linear",
                    min: "auto",
                    max: "auto",
                    stacked: false,
                    reverse: false,
                  }}
                  yFormat=" >-.0f"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    orient: "bottom",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Mês",
                    legendOffset: 30,
                    legendPosition: "middle",
                  }}
                  axisLeft={{
                    orient: "left",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Quantidade",
                    legendOffset: -40,
                    legendPosition: "middle",
                    tickValues: 8,
                  }}
                  enableGridX={false}
                  enableGridY={false}
                  colors={{ scheme: "set1" }}
                  pointSize={7}
                  pointColor={{ theme: "background" }}
                  pointBorderWidth={2}
                  pointBorderColor={{ from: "serieColor", modifiers: [] }}
                  pointLabelYOffset={-12}
                  useMesh={true}
                  enableArea={false}
                  enableSlices="x"
                  legends={[]}
                  animate={true}
                  //onClick= {(point, event) => {console.log(point)}}
                />
              </div>
              {/*
                <div className="chart">
                  <Line
                    data={chart_Historico_Spare[chart_Historico_SpareData]}
                    options={chart_Historico_Spare.options}
                    getDatasetAtEvent={(e) => console.log(e)}
                  />
                  
                </div>
                */}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col className="mb-5 mb-xl-0" xl="6">
          <Card>
            <CardHeader className="bg-transparent">
              <Row className="align-items-center">
                <div className="col">
                  <h6 className="text-uppercase ls-1 mb-1">Overview</h6>
                  <h2 className=" mb-0">Tipos de Ordem</h2>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <div style={{ height: 350 }}>
                <ResponsivePie
                  data={DataPie_Tipo_Ordens}
                  margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                  startAngle={-6}
                  innerRadius={0.55}
                  padAngle={2}
                  cornerRadius={2}
                  activeOuterRadiusOffset={8}
                  colors={{ scheme: "nivo" }}
                  borderWidth={1}
                  borderColor={{
                    from: "color",
                    modifiers: [["darker", "0.2"]],
                  }}
                  enableArcLinkLabels={false}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#333333"
                  arcLinkLabelsThickness={4}
                  arcLinkLabelsColor={{ from: "color", modifiers: [] }}
                  arcLabelsSkipAngle={10}
                  arcLabelsTextColor={{
                    from: "color",
                    modifiers: [["darker", 2]],
                  }}
                  defs={[
                    {
                      id: "dots",
                      type: "patternDots",
                      background: "inherit",
                      color: "rgba(255, 255, 255, 0.3)",
                      size: 4,
                      padding: 1,
                      stagger: true,
                    },
                    {
                      id: "lines",
                      type: "patternLines",
                      background: "inherit",
                      color: "rgba(255, 255, 255, 0.3)",
                      rotation: -45,
                      lineWidth: 6,
                      spacing: 10,
                    },
                  ]}
                  legends={[]}
                  /*legends={[
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 35,
                        translateY: 56,
                        itemsSpacing: 0,
                        itemWidth: 100,
                        itemHeight: 18,
                        itemTextColor: '#999',
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 18,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ]
                    }
                ]}*/
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col className="mb-5 mb-xl-0" xl="6">
          <Card>
            <CardHeader className="bg-transparent">
              <Row className="align-items-center">
                <div className="col">
                  <h6 className="text-uppercase ls-1 mb-1">Ranking</h6>
                  <h2 className=" mb-0">Setores com maiores demandas</h2>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              {/* Chart */}
              {console.log("setor primeiro", sector[0]?.quantity)}
              <div>
                {sector.map((response, index) => {
                  return (
                    <div className="progress-wrapper">
                      <div className="progress-secondary">
                        <div className="progress-label">
                          <span>{response.equipment}</span>
                        </div>
                        <div className="progress-percentage">
                          <span>QTDD: {response.quantity}</span>
                        </div>
                      </div>
                      <Progress
                        max={sector[0].quantity}
                        value={response.quantity}
                      />
                    </div>
                  );
                })}
                {/* <ResponsiveBar
                  data={sector}
                  keys={["Bad Actors"]}
                  indexBy="country"
                  margin={{ top: 20, right: 40, bottom: 20, left: 200 }}
                  padding={0.2}
                  layout="horizontal"
                  valueScale={{ type: "linear" }}
                  indexScale={{ type: "band", round: true }}
                  colors={{ scheme: "nivo" }}
                  defs={[
                    {
                      id: "dots",
                      type: "patternDots",
                      background: "inherit",
                      color: "#38bcb2",
                      size: 4,
                      padding: 1,
                      stagger: true,
                    },
                    {
                      id: "lines",
                      type: "patternLines",
                      background: "inherit",
                      color: "#eed312",
                      rotation: -45,
                      lineWidth: 6,
                      spacing: 10,
                    },
                  ]}
                  borderRadius={7}
                  borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "country",
                    legendPosition: "middle",
                    legendOffset: 32,
                    tickValues: 4,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    //legend: 'food',
                    legendPosition: "middle",
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor="black"
                  legends={[]}
                  animate={true}
                  motionStiffness={90}
                  motionDamping={15}
                /> */}
              </div>
            </CardBody>
          </Card>
        </Col>
        {/* 
          <Col className="mb-5 mb-xl-0" xl="6">
          </Col> */}
      </Row>

      <Row className="mt-5">
        <Col className="mb-5 mb-xl-0" xl="12">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col">
                  <h3 className="mb-0">Lista de Ordens Atrasadas</h3>
                </div>
                <div className="col text-right">
                  <Button
                    color="primary"
                    style={{ color: "#ffff" }}
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    <Link style={{ color: "#ffff" }} to="/admin/orders">
                      Ver todas
                    </Link>
                  </Button>
                </div>
              </Row>
            </CardHeader>
            <Table
              {...getTableProps()}
              className="align-items-center table-flush"
              responsive
            >
              <thead className="thead-light">
                {headerGroups.map((headerGroup) => (
                  <tr
                    style={{ textAlign: "center" }}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => {
                      return (
                        <th
                          className="text-wrap"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          scope="col"
                        >
                          {column.render("Header")}{" "}
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <ArrowDropDownIcon />
                            ) : (
                              <ArrowDropUpIcon />
                            )
                          ) : (
                            ""
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr style={{ cursor: "pointer" }} {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        if (cell.column.Header === "Status") {
                          if (cell.row.values?.status === "Aberta") {
                            return (
                              <td
                                style={{ textAlign: "center" }}
                                className="caractersTableSize"
                              >
                                <Badge color="" className="badge-dot mr-4">
                                  <i
                                    style={{
                                      background: "#1474EE",
                                      borderRadius: "50%",
                                    }}
                                  />
                                  Aberta
                                </Badge>
                              </td>
                            );
                          }
                          if (cell.row.values?.status === "Atrasada") {
                            return (
                              <td
                                style={{ textAlign: "center" }}
                                className="caractersTableSize"
                              >
                                <Badge color="" className="badge-dot mr-4">
                                  <i
                                    style={{
                                      background: "#F5365C",
                                      borderRadius: "50%",
                                    }}
                                  />
                                  Atrasada
                                </Badge>
                              </td>
                            );
                          }
                          if (cell.row.values?.status === "Programada") {
                            return (
                              <td
                                style={{ textAlign: "center" }}
                                className="caractersTableSize"
                              >
                                <Badge color="" className="badge-dot mr-4">
                                  <i
                                    style={{
                                      background: "#78E3FD",
                                      borderRadius: "50%",
                                    }}
                                  />
                                  Programada
                                </Badge>
                              </td>
                            );
                          }
                          if (
                            cell.row.values?.status === "Material Reservado"
                          ) {
                            return (
                              <td style={{ textAlign: "center" }}>
                                <Badge color="" className="badge-dot mr-4">
                                  <i
                                    style={{
                                      background: "#44D978",
                                      borderRadius: "50%",
                                    }}
                                  />
                                  Material Reservado
                                </Badge>
                              </td>
                            );
                          }
                          if (
                            cell.row.values?.status === "Aguardando Material"
                          ) {
                            return (
                              <td
                                style={{ textAlign: "center" }}
                                className="caractersTableSize"
                              >
                                <Badge color="" className="badge-dot mr-4">
                                  <i
                                    style={{
                                      background: "#FFD600",
                                      borderRadius: "50%",
                                    }}
                                  />
                                  Aguardando Material
                                </Badge>
                              </td>
                            );
                          }

                          if (cell.row.values?.status === "Concluída") {
                            return (
                              <td
                                style={{ textAlign: "center" }}
                                className="caractersTableSize"
                              >
                                <Badge
                                  className="caractersTableSize badge-dot mr-4"
                                  color=""
                                >
                                  <i
                                    style={{
                                      background: "#44D978",
                                      borderRadius: "50%",
                                    }}
                                  />
                                  Concluída
                                </Badge>
                              </td>
                            );
                          }
                        }
                        if (cell.column.Header === "Ordem") {
                          return (
                            <td
                              style={{ textAlign: "center" }}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        }
                        if (cell.column.Header === "Prioridade") {
                          if (cell.row.values?.prioridade === "Baixa") {
                            return (
                              <td style={{ textAlign: "center" }}>
                                <Badge color="success">
                                  {cell.render("Cell")}
                                </Badge>
                              </td>
                            );
                          }
                          if (cell.row.values?.prioridade === "Alta") {
                            return (
                              <td style={{ textAlign: "center" }}>
                                <Badge color="danger">
                                  {cell.render("Cell")}
                                </Badge>
                              </td>
                            );
                          }
                          if (cell.row.values?.prioridade === "Nenhuma") {
                            return (
                              <td style={{ textAlign: "center" }}>
                                <Badge color="info">
                                  {cell.render("Cell")}
                                </Badge>
                              </td>
                            );
                          }
                          if (cell.row.values?.prioridade === "Média") {
                            return (
                              <td style={{ textAlign: "center" }}>
                                <Badge
                                  style={{
                                    background: "#FFD600",
                                    color: "rgba(29, 27, 27, 0.844)",
                                    fontWeight: "600",
                                  }}
                                  color="warning"
                                >
                                  {cell.render("Cell")}
                                </Badge>
                              </td>
                            );
                          }
                        }

                        // if(cell.column.Header === "Descrição da Ordem"){
                        //   return <td className="caractersTableSizeOrder" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        // }
                        return (
                          <td>
                            <p className="caractersTableSizeOrder">
                              {cell.row.values?.descricao}
                            </p>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card>
          <CardFooter>
            <nav aria-label="...">
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem disabled={!canPreviousPage}>
                  <PaginationLink onClick={(e) => gotoPage(0)} tabIndex="-1">
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                {state.pageIndex > 0 ? (
                  <>
                    <PaginationItem disabled={!canPreviousPage}>
                      <PaginationLink onClick={(e) => previousPage()}>
                        {state.pageIndex}
                      </PaginationLink>
                    </PaginationItem>
                  </>
                ) : null}
                <PaginationItem className="active">
                  <PaginationLink>{state.pageIndex + 1}</PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={!canNextPage}>
                  <PaginationLink onClick={(e) => nextPage()}>
                    {state.pageIndex + 2}
                    <span className="sr-only">(current)</span>
                  </PaginationLink>
                </PaginationItem>

                <PaginationItem disabled={!canNextPage}>
                  <PaginationLink onClick={(e) => gotoPage(pageCount - 1)}>
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </CardFooter>
        </Col>
      </Row>
    </>
  );
};

export default Orders;
