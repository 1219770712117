import { L10n, setCulture, registerLicense } from "@syncfusion/ej2-base";
import * as EJ2_LOCALE_pt from "@syncfusion/ej2-locale/src/pt.json";

import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "./layouts/Auth";
import { EnterpriseNameContext } from "./context/EnterpriseContext";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { useState, useEffect } from "react";
import { UserType } from "./context/UserType";
import { IdUserContext } from "./context/IdUser";
import { CartContext } from "./context/Cart";
// import { StateModalContext } from './context/StateModal';
import { StructureEditContext } from "./context/StructureEdit";

import SpareReserveService from "components/Orders/Spare_Reserve_Service.js";
import SpareService from "./components/Spares/SpareService.js";
// import swal from 'sweetalert';
// import { useHistory } from 'react-router-dom';
// import LoginAdministrator from "pages/LoginAdministrator/index.js";

registerLicense(
  "Mgo+DSMBaFt/QHJqVVhjWlpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF9jQHxadEdiXX1cdHRTQw==;Mgo+DSMBPh8sVXJ0S0R+XE9HcFRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS3xTf0dqW31fdHZXQmJdVA==;ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkxhUH1ccXBXRGRfVUE=;NzI2OTIxQDMyMzAyZTMyMmUzMFVlSXRPR2dvR1cxdFJCQzVVdkFvWlFhNGRCd1R6ZXBsWkZtMWk1aEMxVkE9;NzI2OTIyQDMyMzAyZTMyMmUzMFdTRk05dGQyM1JzNFRuUWZtSWJJcS9CbXBZdllIbS9GWFF3ZG5DZzcwaHM9;NRAiBiAaIQQuGjN/V0Z+Xk9EaFxEVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdEVqW3ZccnVRRGJZUERz;NzI2OTI0QDMyMzAyZTMyMmUzMEZKSjVjazRob0dvU0FNWG9FLzNtNVRPOE1HRFh6MUJYZHhoQUxWRTBsZlE9;NzI2OTI1QDMyMzAyZTMyMmUzMGFVYWdKUG83WDA3UjVNNHFGUGJLN3hkN1RPWGFoelNMQjBvZEc4SFQ0T0E9;Mgo+DSMBMAY9C3t2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkxhUH1ccXBXRGVbVUE=;NzI2OTI3QDMyMzAyZTMyMmUzMEJYTnJMMUNvKzVNNTFRcFljb0REMUh2SnpXcXAzTGZZZzZacm5oL0ZiTWc9;NzI2OTI4QDMyMzAyZTMyMmUzMEJiSU5oZWd2MG9rczZnQ1ZpcHlOUGVSK0gyN042QzNNYnN3N3hldVcvOXc9;NzI2OTI5QDMyMzAyZTMyMmUzMEZKSjVjazRob0dvU0FNWG9FLzNtNVRPOE1HRFh6MUJYZHhoQUxWRTBsZlE9"
);

const App = () => {
  const isLoggedIn = () => {
    return localStorage.getItem("TOKEN_KEY") != null;
  };

  const [enterpriseName, setEnterpriseName] = useState(() => {
    const storagedEnterpriseName = localStorage.getItem("TOKEN_EMPRESA");

    if (storagedEnterpriseName) {
      return storagedEnterpriseName;
    }

    return "";
  });

  const [userType, setUserType] = useState(() => {
    const storagedUserType = localStorage.getItem("TOKEN_USERTYPE");

    if (storagedUserType) {
      return storagedUserType;
    }

    return "";
  });

  const [idUser, setIdUser] = useState(() => {
    const storagedIdUser = localStorage.getItem("TOKEN_ID_USER");

    if (storagedIdUser) {
      return storagedIdUser;
    }

    return "";
  });
  const [token, setToken] = useState(() => {
    const tokenStoraged = localStorage.getItem("TOKEN_KEY");
    if (tokenStoraged) {
      return tokenStoraged;
    }
    return "";
  });
  const [hash, setHash] = useState(() => {
    const hashStoraged = localStorage.getItem("TOKEN_HASH");
    if (hashStoraged) {
      return hashStoraged;
    }
    return "";
  });
  const [idUserStorage, setIdUserStorage] = useState(() => {
    const idUserStoraged = localStorage.getItem("TOKEN_ID_USER");
    if (idUserStoraged) {
      return idUserStoraged;
    }
    return "";
  });
  const [structureModal, setStructureModal] = useState();

  const [cart, setCart] = useState();
  const [spare, setSpare] = useState();
  // const redirectLogin = () => {
  //   swal('Hmm... vi que você não está logado. Clique em ok para fazer o login', '', 'warning', {
  //     buttons: {
  //       // nope: {
  //       //   text: 'Me enganei!',
  //       //   value: 'nope',
  //       // },
  //       sure: {
  //         text: 'Ok',
  //         value: 'sure',
  //       },
  //     },
  //   }).then((value) => {
  //     switch (value) {
  //       case 'sure':
  //         swal('Operação realizada com sucesso!', '', 'success').then(
  //           (val) => {
  //             // history.pushState
  //             // return <Redirect to="/auth/login"/>
  //             history.push('/auth/login')
  //           }
  //         );
  //         break;
  //       case 'nope':
  //         swal('Operação cancelada', '', 'success');
  //         break;
  //       default:
  //     }
  //   });
  // }

  useEffect(() => {
    SpareService.getSpares({
      empresa: enterpriseName,
      // token: token,
      // hash: hash,
      // idUser: idUserStorage,
    }).then((res) => {
      setSpare(res.data.data);
    });
    SpareReserveService.getSparesReserve({ empresa: enterpriseName })
      .then((res) => {
        setCart(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [enterpriseName, token, hash, idUserStorage]);

  return (
    <EnterpriseNameContext.Provider
      value={{ enterpriseName, setEnterpriseName }}
    >
      <UserType.Provider value={{ userType, setUserType }}>
        <IdUserContext.Provider value={{ idUser, setIdUser }}>
          <CartContext.Provider value={{ cart, setCart, spare, setSpare }}>
            <StructureEditContext.Provider
              value={{ structureModal, setStructureModal }}
            >
              <Router>
                <Switch>
                  <Route
                    path="/admin"
                    render={(props) =>
                      isLoggedIn() === true ? (
                        <AdminLayout {...props} />
                      ) : (
                        <Redirect from="*" to="/auth/login" />
                      )
                    }
                  />
                  <Route
                    path="/auth"
                    render={(props) => <AuthLayout {...props} />}
                  />
                  <Redirect from="/" to="/auth/login" />
                </Switch>
              </Router>
            </StructureEditContext.Provider>
          </CartContext.Provider>
        </IdUserContext.Provider>
      </UserType.Provider>
    </EnterpriseNameContext.Provider>
  );
};
export default App;
