import { useState, useContext, useEffect, useMemo, createRef } from "react";
import { EnterpriseNameContext } from "../../context/EnterpriseContext";
import ToolsService from "./service";
import {
  Button,
  Modal,
  Col,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Badge,
  CardBody,
} from "reactstrap";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
  useResizeColumns,
  useBlockLayout,
} from "react-table";
import SearchIcon from "@material-ui/icons/Search";

import TextField from "@material-ui/core/TextField";

import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
// import { StructureEditContext } from '../../../context/StructureEdit';

import CreateTool from "./components/CreateTool";
import EditTool from "./components/EditTool";
import InputTool from "./components/InputTool";
import OutputTool from "./components/OutputTool";

import { Link } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import Header from "components/Headers/Headerfor.js";
import Datagrid from "./components/Datagrid";

const notification = createRef();

const Tools = (props) => {
  // const { structureModal, setStructureModal } = useContext(StructureEditContext);
  const { enterpriseName } = useContext(EnterpriseNameContext);
  const [tools, setTools] = useState([]);
  const [value, setValue] = useState();
  const [modal, setModal] = useState(false);
  const [structureModal, setStructureModal] = useState();

  useEffect(() => {
    ToolsService.getTools({ empresa: enterpriseName }).then((response) => {
      console.log(response.data);
      setTools(response.data.data);
    });
    setPageSize(12);
  }, [enterpriseName]);

  const columnHeader = [
    {
      Header: "Tipo de ferramenta",
      Footer: "Tipo de ferramenta",
      accessor: "tipoFerramenta",
      // disableFilters: true,
      width: 290,
      sticky: "left",
    },
    {
      Header: "Descrição",
      Footer: "Descrição",
      accessor: "descricao",
      // disableFilters: true,
      width: 290,
      sticky: "left",
    },
    {
      Header: "Quantidade",
      Footer: "Quantidade",
      accessor: "quantidade",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Valor",
      Footer: "Valor",
      width: 200,
      accessor: (originalRow, rowIndex) => {
        return (
          "R$ " + originalRow.preco?.toFixed(2)?.toString()?.replace(".", ",")
        );
      },
      // disableFilters: true,
      sticky: "left",
    },
  ];

  const columns = useMemo(() => columnHeader, []);
  const data = useMemo(() => tools, [tools]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    allColumns,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
    // useResizeColumns,
    // useBlockLayout
  );

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value);
  });

  /* A function that is not being used. */
  const lineClicked = (e) => {
    setStructureModal(e.data);
    setModal(true);
  };

  return (
    <>
      <Header />
      {/* <NotificationAlert ref={notification} /> */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader
                style={{ display: "flex", justifyContent: "flex-end" }}
                className="border-0"
              >
                <div
                  style={{
                    width: "40%",
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <CreateTool tools={tools} setTools={setTools} />
                  <OutputTool
                    visible={true}
                    notification={props.notification}
                    tools={tools}
                    setTools={setTools}
                  />
                  <InputTool
                    tools={tools}
                    notification={props.notification}
                    setTools={setTools}
                  />
                  {/* <Button> */}
                  <Link style={{ marginLeft: "10px" }} to="/admin/report">
                    <Button color="success" size="lg">
                      Relatório
                    </Button>
                  </Link>
                  {/* </Button> */}
                </div>
              </CardHeader>
              <CardBody>
                <Datagrid lineClicked={lineClicked} dataSource={tools} />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      {/* <div style={{flexWrap: 'wrap', display: 'flex'}}> */}
      {/* Para fazer com cards em breve! */}
      {/* {page.map(row => {
                    prepareRow(row)
                    console.log(row)
                    return (
                        <Card sx={{ maxWidth: 345 }}>
                            <CardMedia
                                component="img"
                                height="260"
                                image={row.original.imagem ? row.original.imagem:'https://drive.google.com/file/d/1KhUbYv-Sl8yusVYPNVs04sc7fuHSz4iv/view?usp=sharing'}
                                alt="Ferramenta"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                {row.original.id}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                {row.original.descricao}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small">Share</Button>
                                <Button size="small">Learn More</Button>
                            </CardActions>
                        </Card>
                    )
                })} 
            </div>  
              */}
      <EditTool
        setModal={setModal}
        tools={tools}
        setTools={setTools}
        modal={modal}
        structureModal={structureModal}
      />
      {page.length >= 1 ? null : (
        <div style={{ textAlign: "center" }}>
          <p>Sem ferramentas cadastradas!</p>
        </div>
      )}
    </>
  );
};

export default Tools;
