import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Spares from "./components/Spares/ListSpares";
import Equipments from "./components/Equipments/ListEquipments";
import Requests from "./components/Requests/ListRequests";
import Orders from "./components/Orders/ListOrders";
import Management from "./components/Management";
import Strategy from "./components/Strategy/ListStrategy";
import Performer from "./components/Performer/Performer";
import qrcode from "./components/qrcode/Qrcode";
// import Calendar from "./components/FullCalendar";
import Fornecedores from "./components/Fornecedores/ListFornecedores";
import ReportTool from "./components/Management/tools/pages/ReportTool";
import Timeline from "./components/Strategy/pages/Timeline";
import Team from "./components/Performer/pages/Team";
import Users from "./components/Users/ListUsers";
import Tools from "./components/tools";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-compass-04 text-red",
    component: Index,
    layout: "/admin",
  },
  // {
  //   path: "/users",
  //   name: "Usuários",
  //   icon: "ni ni-circle-08 text-red",
  //   component: Users,
  //   layout: "/admin",
  // },
  {
    path: "/equipments",
    name: "Equipamentos",
    icon: "ni ni-settings text-red",
    component: Equipments,
    layout: "/admin",
  },
  {
    path: "/spares",
    name: "Estoque",
    icon: "ni ni-building text-red",
    component: Spares,
    layout: "/admin",
  },
  {
    path: "/requests",
    name: "Requisições",
    icon: "ni ni-mobile-button text-red",
    component: Requests,
    layout: "/admin",
  },
  {
    path: "/orders",
    name: "Ordens",
    icon: "ni ni-single-copy-04 text-red",
    component: Orders,
    layout: "/admin",
  },
  {
    path: "/strategy",
    name: "Plano de Manutenção",
    icon: "ni ni-calendar-grid-58 text-red",
    component: Strategy,
    layout: "/admin",
  },
  {
    path: "/performer",
    name: "Agenda de execução",
    icon: "ni ni-spaceship text-red",
    component: Performer,
    layout: "/admin",
  },
  {
    //qrcode
    path: "/qrcode",
    name: "QR-Code",
    icon: "ni ni-settings-gear-65 text-red",
    component: qrcode,
    layout: "/admin",
  },
  {
    path: "/fornecedores",
    name: "Fornecedores",
    icon: "Fornecedor",
    component: Fornecedores,
    layout: "/admin",
  },
  {
    path: "/tools",
    name: "Ferramentas",
    icon: "ni ni-settings-gear-65 text-red",
    component: Tools,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Usuários",
    icon: "ni ni-badge text-red",
    component: Users,
    layout: "/admin",
  },
  // { //visao em calendário
  //   path: "/calendarstrategy",
  //   component: CalendarStrategy,
  //   layout: "/admin",
  // },
  {
    //profile
    path: "/profile",
    component: Profile,
    layout: "/admin",
  },
  {
    //profile
    path: "/report",
    component: ReportTool,
    layout: "/admin",
  },
  {
    path: "/timeline",
    component: Timeline,
    layout: "/admin",
  },
  {
    path: "/team",
    component: Team,
    layout: "/admin",
  },
];

export default routes;
