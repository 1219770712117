import { Badge } from "reactstrap";

const templateStatus = (props) => {
  switch (props?.status) {
    case "Aberta":
      return (
        <Badge color="" className="badge-dot mr-4">
          <i
            style={{
              background: "#1474EE",
              borderRadius: "50%",
            }}
          />
          Aberta
        </Badge>
      );
    case "Aprovada":
      return (
        <Badge color="" className="badge-dot mr-4">
          <i
            style={{
              background: "#44D978",
              borderRadius: "50%",
            }}
          />
          Aprovada
        </Badge>
      );
    case "Reprovada":
      return (
        <Badge color="" className="badge-dot mr-4">
          <i
            style={{
              background: "#F5365C",
              borderRadius: "50%",
            }}
          />
          Reprovada
        </Badge>
      );
  }
};

export default templateStatus;
