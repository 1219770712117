// GESTÃO DE USUARIOS

import axios from "axios";

const TOOLS_API_BASE_URL = "https://api.darwinx.com.br/tools";

class ToolsService {
  getTools(params) {
    return axios.get(TOOLS_API_BASE_URL, { params: params });
  }

  createTool(params) {
    return axios.post(TOOLS_API_BASE_URL, params);
  }

  getToolById(params) {
    return axios.get(TOOLS_API_BASE_URL + "/" + params);
  }

  updateTool(user, userId) {
    return axios.put(TOOLS_API_BASE_URL + "/" + userId, user);
  }

  deleteTool(params) {
    return axios.delete(TOOLS_API_BASE_URL + "/" + params);
  }
}

export default new ToolsService();
