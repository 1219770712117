import axios from "axios";

const REQUEST = "https://api.darwinx.com.br/orders/custom/filter";

export default async function GetCustomFilterOrder(filter) {
  const response = await axios.get(REQUEST, {
    params: filter,
  });
  return response.data;
}
