import styled from "styled-components";

export const SelectContainer = styled.div`
  z-index: 99999;
  position: relative;
  .css-1s2u09g-control,
  .css-1pahdxg-control {
    min-height: 60px;
  }
  margin-top: 0.5rem;
  margin-bottom: 2rem;
`;
