import React, { useState, useEffect, useContext, useMemo } from "react";
import { EnterpriseNameContext } from "../../context/EnterpriseContext";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
import { Bar } from "react-chartjs-2";
import DashboardService from "./DashboardService";

import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";

import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import moment from "moment";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavLink,
  Table,
  Row,
  Col,
  Nav,
  NavItem,
  CardTitle,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
} from "reactstrap";

import DatepickerArgon from "./components/DatepickerArgon.jsx";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

// core components
import {
  chartOptions,
  parseOptions,
  // Pareto_Consumo_Spare,
  // chart_Historico_Spare,
} from "variables/charts.js";

//import {DataLine} from "variables/charts_nivo";
import { ResponsiveLine } from "@nivo/line";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
// import Header from "components/Headers/Header copy";
import Tooltip from "@mui/material/Tooltip";
const Spares = (props) => {
  var colors = {
    gray: {
      100: "#f6f9fc",
      200: "#e9ecef",
      300: "#dee2e6",
      400: "#ced4da",
      500: "#adb5bd",
      600: "#8898aa",
      700: "#525f7f",
      800: "#32325d",
      900: "#212529",
    },
    theme: {
      default: "#172b4d",
      primary: "#5e72e4",
      secondary: "#f4f5f7",
      info: "#11cdef",
      success: "#2dce89",
      danger: "#f5365c",
      warning: "#fb6340",
    },
    black: "#12263F",
    white: "#FFFFFF",
    transparent: "transparent",
  };
  // const optionsChart = {
  //   scales: {
  //     yAxes: [
  //       {
  //         gridLines: {
  //           color: colors.gray[900],
  //           zeroLineColor: colors.gray[900],
  //         },
  //         ticks: {
  //           callback: function (value) {
  //             if (!(value % 10)) {
  //               //return "$" + value + "k";
  //               return value;
  //             }
  //           },
  //         },
  //       },
  //     ],
  //   },
  //   tooltips: {
  //     callbacks: {
  //       label: function (item, data) {
  //         var label = data.datasets[item.datasetIndex].label || "";
  //         var yLabel = item.yLabel;
  //         var content = "";

  //         if (data.datasets.length > 1) {
  //           content += label;
  //         }

  //         //content += "$" + yLabel + "k";
  //         content += yLabel;
  //         return content;
  //       },
  //     },
  //   },
  // }

  const Pareto_Consumo_Spare = {
    optionsCost: {
      responsive: true,
      title: {
        display: true,
        text: "Custo",
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              color: colors.gray[900],
              zeroLineColor: colors.gray[900],
            },
            ticks: {
              callback: function (value, index, length) {
                return "R$ " + value;
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (item, data) {
            var label = data.datasets[item.datasetIndex].label || "";
            var yLabel = item.yLabel;
            var content = "";

            if (data.datasets.length > 1) {
              content += label;
            }

            //content += "$" + yLabel + "k";
            content += yLabel;
            return content;
          },
        },
      },
    },
    optionsQuantity: {
      responsive: true,
      title: {
        display: true,
        text: "Quantidade",
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              color: colors.gray[900],
              zeroLineColor: colors.gray[900],
            },
            ticks: {
              callback: function (value, index, length) {
                return value;
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (item, data) {
            var label = data.datasets[item.datasetIndex].label || "";
            var yLabel = item.yLabel;
            var content = "";

            if (data.datasets.length > 1) {
              content += label;
            }

            //content += "$" + yLabel + "k";
            content += yLabel;
            return content;
          },
        },
      },
    },
    data1: (canvas) => {
      return {
        labels: paretoGraphFirst.names,
        datasets: [
          {
            label: "Performance",
            data: paretoGraphFirst.quantities,
          },
        ],
      };
    },
    data2: (canvas) => {
      return {
        labels: paretoGraphSecond.names,
        datasets: [
          {
            label: "Performance",
            data: paretoGraphSecond?.quantities,
          },
        ],
      };
    },
  };
  const { enterpriseName } = useContext(EnterpriseNameContext);
  const [activeNav, setActiveNav] = useState(1);
  const [Pareto_Consumo_SpareData, setPareto_Consumo_SpareData] =
    useState("data1");
  // const [chart_Historico_SpareData, setchart_Historico_SpareData] = useState("data1");
  const [cardsSpare, setCardsSpare] = useState([]);
  const [paretoGraphFirst, setParetoGraphFirst] = useState({
    names: [],
    quantities: [],
  });
  const [paretoGraphSecond, setParetoGraphSecond] = useState({
    names: [],
    quantities: [],
  });
  const [tableSpare, setTableSpare] = useState([]);
  const [graphHistoric, setGraphHistoric] = useState([]);
  const [stateButtonTable, setStateButtonTable] = useState(1);
  const [tablePending, setTablePending] = useState([]);
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  useEffect(() => {
    DashboardService.getSpare({ empresa: enterpriseName }).then((response) => {
      const spare = response.data;
      setCardsSpare(spare);
      if (spare.local_deposito) {
        setEquipmentArea(spare.local_deposito[0]);
      }
      setParetoGraphFirst(spare.paretoGraphFirst);
      setParetoGraphSecond(spare.paretoGraphSecond);
      setTableSpare(spare?.tableSpare.reverse() ?? []);
      setGraphHistoric(spare?.graphHistoric);
      setTablePending(spare?.tablePending);
    });
  }, [enterpriseName]);

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setPareto_Consumo_SpareData("data" + index);
  };

  const toggleNavsTable = (e, index) => {
    e.preventDefault();
    setStateButtonTable(index);
    // if
  };
  const DataLine = [
    {
      id: "Consumo de Materiais",
      color: "hsl(281, 70%, 50%)",
      data: graphHistoric,
    },
  ];
  const columnHeader = [
    {
      Header: "Material",
      Footer: "Material",
      accessor: "codigoSpare",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Descrição do Material",
      Footer: "Descrição do Material",
      accessor: "partNumberSpare",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Número da ordem",
      Footer: "Número da ordem",
      accessor: "idOrder",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Quantidade",
      Footer: "Quantidade",
      accessor: "quantidade",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Área",
      Footer: "Área",
      accessor: "area",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Ação",
      Footer: "Ação",
      accessor: "acao",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Data",
      Footer: "Data",
      accessor: "createdAt",
      // disableFilters: true,
      sticky: "left",
    },
  ];

  const columnHeaderPending = [
    {
      Header: "Material",
      Footer: "Material",
      accessor: "produto",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Número da ordem",
      Footer: "Número da ordem",
      accessor: "orderNumber",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Part Number",
      Footer: "Part Number",
      accessor: "part_number",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Quantidade",
      Footer: "Quantidade",
      accessor: "qtdWaitMaterial",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Data",
      Footer: "Data",
      accessor: "createdAt",
      // disableFilters: true,
      sticky: "left",
    },
  ];

  const columns = useMemo(() => {
    return stateButtonTable === 1 ? columnHeader : columnHeaderPending;
  }, [stateButtonTable]);
  const data = useMemo(() => {
    return stateButtonTable === 1 ? tableSpare : tablePending;
  }, [stateButtonTable, tablePending, tableSpare]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    allColumns,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const [startDate, setStartDate] = useState(
    new Date(new Date().setFullYear(new Date().getFullYear() - 1))
  );
  const [endDate, setEndDate] = useState(new Date());
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [equipmentArea, setEquipmentArea] = useState([]);
  const [selectedArea, setSelectedArea] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    console.log(value, "value");
    setSelectedArea(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [value, setValue] = useState(state.globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value);
  });

  console.log(selectedArea, "selectedArea");

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "flex-end",
        }}
      >
        {/* <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-checkbox-label">Área</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            value={selectedArea}
            onChange={handleChange}
            input={<OutlinedInput label="Área" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {equipmentArea.map((name) => (
              <MenuItem key={name} value={name}>
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
        <div style={{ display: "flex", width: "80%", marginTop: "1.5em" }}>
          {/* <div style={{display: "flex", justifyContent:"flex-end"}}> */}
          <DatepickerArgon
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
          <div>
            <Button
              style={{ marginLeft: "10px", marginRight: "20px" }}
              type="button"
              color="success"
              onClick={() => {
                DashboardService.getSpare({
                  empresa: enterpriseName,
                  start: new Date(startDate),
                  end: new Date(endDate),
                  area: selectedArea,
                }).then((response) => {
                  console.log("source?", response.data);
                  setCardsSpare(response.data);
                  setParetoGraphFirst(response.data.paretoGraphFirst);
                  setParetoGraphSecond(response.data.paretoGraphSecond);
                  setTableSpare(response.data?.tableSpare);
                  setTablePending(response.data?.tablePending);
                });
              }}
            >
              Filtrar
            </Button>
            {/* </div> */}
          </div>
        </div>
      </div>
      <Row>
        <Col className="mb-5 mb-xl-0" xl="12">
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="3">
                {/*  <Card className="card-stats mb-4 mb-xl-0"> */}
                <Card>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Itens
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {cardsSpare?.itemsSpare
                            ? cardsSpare?.itemsSpare?.totalSpare +
                              " (" +
                              cardsSpare?.itemsSpare?.itemsUnique +
                              " distintos)"
                            : 0}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <Tooltip
                          style={{ cursor: "pointer" }}
                          title="Total de itens em Estoque."
                        >
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-chart-bar" />
                          </div>
                        </Tooltip>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      {/* <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 0%
                      </span>{" "} */}
                      <span className="text-nowrap">
                        Entre{" "}
                        {moment(
                          new Date(
                            new Date().setFullYear(new Date().getFullYear() - 1)
                          )
                        ).format("DD/MM/YYYY")}{" "}
                        e {moment(new Date()).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Custo do estoque
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {cardsSpare?.priceTotalSpare
                            ? "R$ " +
                              cardsSpare?.priceTotalSpare?.toLocaleString(
                                "pt-br"
                              )
                            : "R$ 0,00"}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <Tooltip
                          style={{ cursor: "pointer" }}
                          title="Custo total em Estoque"
                        >
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-chart-pie" />
                          </div>
                        </Tooltip>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      {/* <span className="text-danger mr-2">
                        <i className="fas fa-arrow-down" /> 0%
                      </span>{" "} */}
                      <span className="text-nowrap">
                        Entre{" "}
                        {moment(
                          new Date(
                            new Date().setFullYear(new Date().getFullYear() - 1)
                          )
                        ).format("DD/MM/YYYY")}{" "}
                        e {moment(new Date()).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Materiais Adquiridos
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {cardsSpare.quantityOfSpares
                            ? cardsSpare.quantityOfSpares
                            : 0}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <Tooltip
                          style={{ cursor: "pointer" }}
                          title="Quantidade de materiais Adquiridos"
                        >
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-search-dollar" />
                          </div>
                        </Tooltip>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      {/* <span className="text-warning mr-2">
                        <i className="fas fa-arrow-down" /> 0%
                      </span>{" "} */}
                      <span className="text-nowrap">
                        Entre {moment(startDate).format("DD/MM/YYYY")} e{" "}
                        {moment(endDate).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Materiais Utilizados
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {cardsSpare.quantityOfMaterialsUsed
                            ? cardsSpare.quantityOfMaterialsUsed
                            : 0}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <Tooltip
                          style={{ cursor: "pointer" }}
                          title="Quantidade de materiais Utilizados."
                        >
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <ShoppingCartCheckoutIcon />
                          </div>
                        </Tooltip>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      {/* <span className="text-success mr-2">
                        <i className="fas fa-arrow-up" /> 0%
                      </span>{" "} */}
                      <span className="text-nowrap">
                        Entre {moment(startDate).format("DD/MM/YYYY")} e{" "}
                        {moment(endDate).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col className="mb-5 mb-xl-0" xl="12">
          <Card>
            <CardHeader className="bg-transparent">
              <Row className="align-items-center">
                <div className="col">
                  <h6 className="text-uppercase ls-1 mb-1">Visão Geral</h6>
                  <h2 className=" mb-0">Histórico de Consumo</h2>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <div style={{ height: 350 }}>
                <ResponsiveLine
                  data={DataLine}
                  margin={{ top: 20, right: 110, bottom: 20, left: 60 }}
                  xScale={{ type: "point" }}
                  yScale={{
                    type: "linear",
                    min: 0,
                    max: "auto",
                    stacked: true,
                    reverse: false,
                  }}
                  yFormat=" >-.2f"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    orient: "bottom",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Mês",
                    legendOffset: 30,
                    legendPosition: "middle",
                  }}
                  axisLeft={{
                    orient: "left",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Quantidade",
                    legendOffset: -40,
                    legendPosition: "middle",
                    tickValues: 4,
                  }}
                  enableGridX={false}
                  enableGridY={false}
                  colors={{ scheme: "set1" }}
                  pointSize={7}
                  pointColor={{ theme: "background" }}
                  pointBorderWidth={2}
                  pointBorderColor={{ from: "serieColor", modifiers: [] }}
                  pointLabelYOffset={-12}
                  useMesh={true}
                  enableArea={true}
                  enableSlices="x"
                  //areaOpacity:{0.3}

                  /*
                  legends={[
                      {
                          anchor: 'top',
                          direction: 'row',
                          justify: false,
                          translateX: 30,
                          translateY: -17,
                          itemsSpacing: 0,
                          itemDirection: 'left-to-right',
                          itemWidth: 80,
                          itemHeight: 20,
                          itemOpacity: 0.75,
                          symbolSize: 12,
                          symbolShape: 'circle',
                          symbolBorderColor: 'rgba(0, 0, 0, .5)',
                          effects: [
                              {
                                  on: 'hover',
                                  style: {
                                      itemBackground: 'rgba(0, 0, 0, .03)',
                                      itemOpacity: 1
                                  }
                              }
                          ]
                      }
                  ]}
                      */
                  legends={[]}
                  animate={true}
                  // getDatasetAtEvent={(e) => console.log(e)}
                  // onClick= {(point, event) => {console.log(point)
                  //   console.log("entrou aqui")
                  //   console.log(event)
                  // }}

                  onClick={() => {
                    console.log("clicou");
                  }}
                />
              </div>
              {/*
                <div className="chart">
                  <Line
                    data={chart_Historico_Spare[chart_Historico_SpareData]}
                    options={chart_Historico_Spare.options}
                    getDatasetAtEvent={(e) => console.log(e)}
                  />
                  
                </div>
                */}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col className="mb-5 mb-xl-0" xl="12">
          <Card>
            <CardHeader className="bg-transparent">
              <Row className="align-items-center">
                <div className="col">
                  <h6 className="text-uppercase ls-1 mb-1">Pareto</h6>
                  <h2 className="mb-0">Maiores consumos no último mês</h2>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              {/* Charts */}
              <Row>
                <Col lg="6" xl="6">
                  <div className="chart">
                    <Bar
                      data={Pareto_Consumo_Spare["data1"]}
                      options={Pareto_Consumo_Spare.optionsQuantity}
                      getDatasetAtEvent={(e) => console.log(e)}
                    />
                  </div>
                </Col>
                <Col lg="6" xl="6">
                  <div className="chart">
                    <Bar
                      data={Pareto_Consumo_Spare["data2"]}
                      options={Pareto_Consumo_Spare.optionsCost}
                      getDatasetAtEvent={(e) => console.log(e)}
                    />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col className="mb-5 mb-xl-0" xl="12">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col">
                  <h3 className="mb-0">Tabela de movimentações</h3>
                </div>
                <div className="col">
                  <Nav className="justify-content-end" pills>
                    {/* <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames("py-2 px-3", {
                          active: stateButtonTable === 1,
                        })}
                        onClick={(e) => toggleNavsTable(e, 1)}
                      >
                        <span className="d-none d-md-block">Movimentações</span>
                        <span className="d-md-none">M</span>
                      </NavLink>
                    </NavItem> */}
                    {/* Voltar à tabela do dashboard de estoque e variar as pendencias */}
                    {/* <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames("py-2 px-3", {
                          active: stateButtonTable === 2,
                        })}
                        data-toggle="tab"
                        onClick={(e) => toggleNavsTable(e, 2)}
                      >
                        <span className="d-none d-md-block">Pendências</span>
                        <span className="d-md-none">P</span>
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                </div>
              </Row>
              <Row>
                <div style={{ display: "flex", width: "50%" }}>
                  <div
                    style={{
                      width: "85%",
                      display: "flex",
                      alignItems: "center",
                      margin: "0.5em",
                    }}
                  >
                    <SearchIcon
                      style={{ marginTop: "12px", marginRight: "5px" }}
                    />
                    <TextField
                      id="input-with-icon-grid"
                      value={value || ""}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setValue(e.target.value);
                        onChange(e.target.value);
                      }}
                      style={{ width: "100%" }}
                      label="Filtrar tabela"
                    />
                    {/* <ButtonMui si color="inherit" onClick={handleClick}  variant="contained" style={{ maxHeight: '25px'}}><FilterListOutlinedIcon style={cellcolor}/>Filtar</ButtonMui> */}
                  </div>
                </div>
              </Row>
            </CardHeader>
            <Table
              {...getTableProps()}
              className="align-items-center table-flush"
              responsive
            >
              <thead className="thead-light">
                {/* <tr>
                    <th scope="col">Código</th>
                    <th scope="col">Nome Material</th>
                    <th scope="col">Descrição</th>
                    <th scope="col">Preço</th>
                    <th scope="col">Estoque Atual</th>
                    <th scope="col">Part Number (Número do Item)</th>
                    <th scope="col">Local (Almoxarifado)</th>
                    <th scope="col">Categoria</th>
                    <th scope="col">Equipamento que o item é usado</th>
                    <th scope="col">Área que o item é usado</th>
                    <th scope="col">Fornecedor</th>
                  </tr> */}

                {headerGroups.map((headerGroup) => (
                  <tr
                    style={{ textAlign: "center" }}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => {
                      return (
                        <th
                          className="text-wrap"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          scope="col"
                        >
                          {column.render("Header")}{" "}
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <ArrowDropDownIcon />
                            ) : (
                              <ArrowDropUpIcon />
                            )
                          ) : (
                            ""
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  console.log(row);
                  prepareRow(row);
                  return (
                    <tr
                      style={{ cursor: "pointer", textAlign: "center" }}
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell) => {
                        if (cell.column.Header === "Data") {
                          return (
                            <td
                              className="caractersTableSize"
                              {...cell.getCellProps()}
                            >
                              {moment(cell.row.values?.createdAt).format(
                                "DD/MM/YYYY"
                              )}
                            </td>
                          );
                        }
                        if (cell.column.Header === "Ação") {
                          return (
                            <td
                              className="caractersTableSize"
                              {...cell.getCellProps()}
                            >
                              {cell.row.original.acao === "Entrada" ? (
                                <>
                                  <LoginIcon style={{ marginRight: "0.2em" }} />
                                  {cell.render("Cell")}
                                </>
                              ) : (
                                <>
                                  <LogoutIcon
                                    style={{ marginRight: "0.2em" }}
                                  />
                                  {cell.render("Cell")}
                                </>
                              )}
                            </td>
                          );
                        }
                        if (cell.column.Header === "Valor") {
                          return (
                            <td
                              className="caractersTableSize"
                              {...cell.getCellProps()}
                            >
                              R${" "}
                              {parseInt(cell.row.original.precoAtual, 10)
                                .toFixed(2)
                                .toString()
                                .replace(".", ",")}
                            </td>
                          );
                        }
                        return (
                          <td
                            className="caractersTableSize"
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card>
          <CardFooter className="py-4">
            <nav aria-label="...">
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem disabled={!canPreviousPage}>
                  <PaginationLink onClick={(e) => gotoPage(0)} tabIndex="-1">
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                {state.pageIndex > 0 ? (
                  <>
                    <PaginationItem disabled={!canPreviousPage}>
                      <PaginationLink onClick={(e) => previousPage()}>
                        {state.pageIndex}
                      </PaginationLink>
                    </PaginationItem>
                  </>
                ) : null}
                <PaginationItem className="active">
                  <PaginationLink>{state.pageIndex + 1}</PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={!canNextPage}>
                  <PaginationLink onClick={(e) => nextPage()}>
                    {state.pageIndex + 2}
                    <span className="sr-only">(current)</span>
                  </PaginationLink>
                </PaginationItem>

                <PaginationItem disabled={!canNextPage}>
                  <PaginationLink onClick={(e) => gotoPage(pageCount - 1)}>
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </CardFooter>
        </Col>{" "}
      </Row>
    </>
  );
};

export default Spares;
