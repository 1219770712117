import { createRef } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Toolbar,
  Inject,
  Filter,
  Sort,
  Resize,
  PdfExport,
  ExcelExport,
  ColumnChooser,
} from "@syncfusion/ej2-react-grids";

import { convertPrice, quantityAvailable } from "./templates";

import "./style.css";

const ref = createRef();

const Datagrid = ({ lineClicked, dataSource }) => {
  const toolbarClick = (args) => {
    if (
      args.item.text === "PDF Export" ||
      args.item.text === "Exportação de PDF"
    ) {
      ref.current.pdfExport();
    } else if (
      args.item.text === "Excel Export" ||
      args.item.text === "Exportação Excel"
    ) {
      ref.current.excelExport();
    }
  };

  const groupOptions = { showGroupedColumn: true };

  return (
    <GridComponent
      ref={ref}
      dataSource={dataSource ?? []}
      allowPaging={true}
      allowFiltering={true}
      filterSettings={{ type: "CheckBox" }}
      allowSorting={true}
      allowTextWrap={true}
      allowResizing={true}
      allowPdfExport={true}
      allowExcelExport={true}
      enableHeaderFocus={true}
      hierarchyPrintMode={"All"}
      allowReordering={true}
      showColumnChooser={true}
      groupSettings={groupOptions}
      toolbarClick={toolbarClick}
      toolbar={["PdfExport", "ExcelExport", "Print", "Search", "ColumnChooser"]}
      rowSelected={lineClicked}
    >
      <ColumnsDirective>
        <ColumnDirective field="produto" headerText="Material" />
        <ColumnDirective field="descricao" headerText="Descrição" width={400} />
        <ColumnDirective field="part_number" headerText="Part Number" />
        <ColumnDirective field="quantidade" headerText="Total do estoque" />
        <ColumnDirective
          field="preco"
          headerText="Valor Unitário"
          template={convertPrice}
        />
        <ColumnDirective
          field="quantidade"
          headerText="Quantidade disponível"
          template={quantityAvailable}
          width={200}
        />
        <ColumnDirective
          field="local_deposito"
          headerText="Local de Depósito"
        />
        <ColumnDirective field="fornecedores" headerText="Fornecedor" />
        <ColumnDirective field="categoria" headerText="Categoria" />
      </ColumnsDirective>
      <Inject
        services={[
          Page,
          Toolbar,
          Filter,
          Sort,
          ColumnChooser,
          Resize,
          PdfExport,
          ExcelExport,
        ]}
      />
    </GridComponent>
  );
};

export default Datagrid;
