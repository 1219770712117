import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from "react";

import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";

import classnames from "classnames";

import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import moment from "moment";
import "./Request.css";
// node.js library that concatenates classes (strings)
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Bar, Pie } from "react-chartjs-2";
// reactstrap components
import DashboardService from "./DashboardService";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { EnterpriseNameContext } from "../../context/EnterpriseContext";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
  Table,
  Row,
  Col,
  CardTitle,
} from "reactstrap";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

// core components
import {
  chartOptions,
  parseOptions,
  // chartHistorico_Requests,
} from "variables/charts.js";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import DatepickerArgon from "./components/DatepickerArgon.jsx";

const Requests = (props) => {
  var colors = {
    gray: {
      100: "#f6f9fc",
      200: "#e9ecef",
      300: "#dee2e6",
      400: "#ced4da",
      500: "#adb5bd",
      600: "#8898aa",
      700: "#525f7f",
      800: "#32325d",
      900: "#212529",
    },
    theme: {
      default: "#172b4d",
      primary: "#5e72e4",
      secondary: "#f4f5f7",
      info: "#11cdef",
      success: "#2dce89",
      danger: "#f5365c",
      warning: "#fb6340",
    },
    black: "#12263F",
    white: "#FFFFFF",
    transparent: "transparent",
  };

  const { enterpriseName } = useContext(EnterpriseNameContext);
  const [activeNav, setActiveNav] = useState(1);
  const [chartHistorico_RequestsData, setchartHistorico_RequestsData] =
    useState("data1");
  const [cardsRequest, setCardsRequest] = useState({});
  const [tableRequest, setTableRequest] = useState([]);
  const [dataSource1, setDataSource1] = useState([]);
  const [dataSource2, setDataSource2] = useState([]);
  const [label1, setLabel1] = useState([]);
  const [label2, setLabel2] = useState([]);
  const optionsChart = {
    scales: {
      yAxes: [
        {
          gridLines: {
            color: colors.gray[900],
            zeroLineColor: colors.gray[900],
          },
          ticks: {
            callback: function (value) {
              if (!(value % 10)) {
                //return "$" + value + "k";
                return value;
              }
            },
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (item, data) {
          var label = data.datasets[item.datasetIndex].label || "";
          var yLabel = item.yLabel;
          var content = "";

          if (data.datasets.length > 1) {
            content += label;
          }

          //content += "$" + yLabel + "k";
          content += yLabel;
          return content;
        },
      },
    },
  };

  const contentChart = {
    data1: useCallback(
      (canvas) => {
        return {
          labels: label1,
          datasets: [
            {
              label: "Performance",
              data: dataSource1,
              backgroundColor: "#059BFF",
            },
          ],
        };
      },
      [dataSource1, label1]
    ),
    data2: (canvas) => {
      return {
        labels: label2,
        datasets: [
          {
            label: "Performance",
            data: dataSource2,
            backgroundColor: "#FB6340",
          },
        ],
      };
    },
  };
  // const [historicGenereted, setHistoricGenereted] = useState({
  //   data1: useCallback((canvas) => {
  //     return {
  //       labels: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out"],
  //       datasets: [
  //         {
  //           label: "Performance",
  //           data: teste,
  //         },
  //       ],
  //     };
  //   },[teste]),
  //   data2: (canvas) => {
  //     return {
  //       labels: ["Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
  //       datasets: [
  //         {
  //           label: "Performance",
  //           data: [],
  //         },
  //       ],
  //     };
  //   },
  // });
  useEffect(() => {
    DashboardService.getRequest({ empresa: enterpriseName }).then(
      (response) => {
        const data = response.data;
        if (data.equipmentArea) {
          setEquipmentArea(data.equipmentArea[0] ? data.equipmentArea[0] : []);
        }
        setCardsRequest(data);
        setTableRequest(data?.table);
        setDataSource1(data.historicGenerated?.data);
        setDataSource2(data?.historicTreated?.data);
        setLabel1(data?.historicGenerated?.label);
        setLabel2(data?.historicTreated?.label);
      }
    );

    setPageSize(5);
  }, [enterpriseName]);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  // let chartHistorico_Requests = {
  //   options: {
  //     scales: {
  //       yAxes: [
  //         {
  //           gridLines: {
  //             color: colors.gray[900],
  //             zeroLineColor: colors.gray[900],
  //           },
  //           ticks: {
  //             callback: function (value) {
  //               if (!(value % 10)) {
  //                 //return "$" + value + "k";
  //                 return value;
  //               }
  //             },
  //           },
  //         },
  //       ],
  //     },
  //     tooltips: {
  //       callbacks: {
  //         label: function (item, data) {
  //           var label = data.datasets[item.datasetIndex].label || "";
  //           var yLabel = item.yLabel;
  //           var content = "";

  //           if (data.datasets.length > 1) {
  //             content += label;
  //           }

  //           //content += "$" + yLabel + "k";
  //           content += yLabel;
  //           return content;
  //         },
  //       },
  //     },
  //   },
  //   data1: (canvas) => {
  //     return {
  //       labels: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out"],
  //       datasets: [
  //         {
  //           label: "Performance",
  //           data: historicGenereted,
  //         },
  //       ],
  //     };
  //   },
  //   data2: (canvas) => {
  //     return {
  //       labels: ["Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
  //       datasets: [
  //         {
  //           label: "Performance",
  //           data: [50, 20, 5, 25, 10, 30, 15, 40, 40],
  //         },
  //       ],
  //     };
  //   },
  // };
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setchartHistorico_RequestsData("data" + index);
  };

  const columnHeader = [
    {
      Header: "Equipamento",
      Footer: "Equipamento",
      accessor: "equipamento",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Data de criação",
      Footer: "Data de criação",
      accessor: "createdAt",
      // disableFilters: true,
      sticky: "left",
    },
    // {
    //   Header: 'Função',
    //   Footer: 'Função',
    //   accessor: 'level',
    //   // disableFilters: true,
    //   sticky: 'left'
    // },
    {
      Header: "Prioridade",
      Footer: "Prioridade",
      accessor: "prioridade",
      // disableFilters: true,
      sticky: "left",
    },
  ];

  const columns = useMemo(() => columnHeader, []);
  const data = useMemo(() => tableRequest, [tableRequest]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    allColumns,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const [startDate, setStartDate] = useState(
    new Date(new Date().setFullYear(new Date().getFullYear() - 1))
  );
  const [endDate, setEndDate] = useState(new Date());

  const [equipmentArea, setEquipmentArea] = useState([]);
  const [selectedArea, setSelectedArea] = useState([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedArea(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <>
      <Row>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-checkbox-label">Área</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={selectedArea}
              onChange={handleChange}
              input={<OutlinedInput label="Área" />}
              MenuProps={MenuProps}
            >
              {equipmentArea.map((name) => (
                <MenuItem key={name} value={name}>
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ display: "flex", width: "80%", marginTop: "1.5em" }}>
            <DatepickerArgon
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
            <div>
              <Button
                style={{ marginLeft: "10px", marginRight: "20px" }}
                type="button"
                color="success"
                onClick={() => {
                  DashboardService.getRequest({
                    empresa: enterpriseName,
                    start: new Date(startDate),
                    end: new Date(endDate),
                    area: selectedArea[0],
                  }).then((response) => {
                    setCardsRequest(response.data);
                    setTableRequest(response.data?.table);
                  });
                }}
              >
                Filtrar
              </Button>
            </div>
          </div>
        </div>
        <Col className="mb-5 mb-xl-0" xl="12">
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="3">
                {/*   <Card className="card-stats mb-4 mb-xl-0 "> */}
                <Card>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Aprovadas
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {cardsRequest.approved ? cardsRequest.approved : 0}
                        </span>
                      </div>

                      <Col className="col-auto">
                        <Tooltip
                          style={{ cursor: "pointer" }}
                          title={
                            "Requisições Aprovadas entre " +
                            `${moment(startDate).format(
                              "DD/MM/YYYY"
                            )} e ${moment(endDate).format("DD/MM/YYYY")}`
                          }
                        >
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-chart-bar" />
                          </div>
                        </Tooltip>
                      </Col>
                    </Row>

                    <p className="mt-3 mb-0 text-muted text-sm">
                      {/* <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 0%
                        </span>{" "} */}
                      <span className="text-nowrap">
                        Entre {moment(startDate).format("DD/MM/YYYY")} e{" "}
                        {moment(endDate).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Reprovadas
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {cardsRequest.failed ? cardsRequest.failed : 0}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <Tooltip
                          style={{ cursor: "pointer" }}
                          title={
                            "Requisições Reprovadas entre " +
                            `${moment(startDate).format(
                              "DD/MM/YYYY"
                            )} e ${moment(endDate).format("DD/MM/YYYY")}`
                          }
                        >
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-chart-pie" />
                          </div>
                        </Tooltip>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      {/* <span className="text-danger mr-2">
                        <i className="fas fa-arrow-down" /> 0%
                      </span>{" "} */}
                      <span className="text-nowrap">
                        Entre {moment(startDate).format("DD/MM/YYYY")} e{" "}
                        {moment(endDate).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Em Aberto
                        </CardTitle>

                        <span className="h2 font-weight-bold mb-0">
                          {cardsRequest.open ? cardsRequest.open : 0}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <Tooltip
                          style={{ cursor: "pointer" }}
                          title="Requisições abertas aguardando avaliação."
                        >
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Tooltip>
                      </Col>
                    </Row>

                    <p className="mt-3 mb-0 text-muted text-sm">
                      {/* <span className="text-warning mr-2">
                        <i className="fas fa-arrow-down" /> 0%
                      </span>{" "} */}
                      <span className="text-nowrap">
                        Entre {moment(startDate).format("DD/MM/YYYY")} e{" "}
                        {moment(endDate).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Atendimento
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {cardsRequest.resultAverage === 1 &&
                            cardsRequest.resultAverage + " dia"}
                          {cardsRequest.resultAverage > 1 &&
                            cardsRequest.resultAverage + " dias"}
                          {cardsRequest.resultAverage <= 0 ? "< 1 dia" : null}
                          {/* {cardsRequest.resultAverage ? "" :
                          "0"} */}
                          {/* {cardsRequest.resultAverage ? null: 0 } */}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <Tooltip
                          style={{ cursor: "pointer" }}
                          title="Tempo médio de atendimento."
                        >
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <AccessTimeFilledIcon />
                          </div>
                        </Tooltip>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      {/* <span className="text-success mr-2">
                        <i className="fas fa-arrow-up" /> 0%
                      </span>{" "} */}
                      <span className="text-nowrap">
                        Entre {moment(startDate).format("DD/MM/YYYY")} e{" "}
                        {moment(endDate).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col className="mb-5 mb-xl-0" xl="6">
          <Card>
            <CardHeader className="bg-transparent">
              <Row className="align-items-center">
                <div className="col">
                  <h6 className="text-uppercase ls-1 mb-1">Histórico</h6>
                  <h2 className="mb-0">Requisições</h2>
                </div>
                <div className="col">
                  <Nav className="justify-content-end" pills>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames("py-2 px-3", {
                          active: activeNav === 1,
                        })}
                        onClick={(e) => toggleNavs(e, 1)}
                      >
                        <span className="d-none d-md-block">Geradas</span>
                        <span className="d-md-none">M</span>
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames("py-2 px-3", {
                          active: activeNav === 2,
                        })}
                        data-toggle="tab"
                        onClick={(e) => toggleNavs(e, 2)}
                      >
                        <span className="d-none d-md-block">Não tratadas</span>
                        <span className="d-md-none">W</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              {/* Chart */}
              <div className="chart">
                {/* <Bar
                    data={historicGenereted[chartHistorico_RequestsData]}
                    options={historicGenereted.options}
                    getDatasetAtEvent={(e) => console.log(e)}
                  /> */}
                <Bar
                  data={contentChart[chartHistorico_RequestsData]}
                  options={optionsChart}
                  getDatasetAtEvent={(e) => console.log(e)}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col className="mb-5 mb-xl-0" xl="6">
          {/*
            <Card>
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase ls-1 mb-1">
                      Overview
                    </h6>
                    <h2 className=" mb-0">Requisições por prioridade</h2>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 1,
                          })}
                           
                          onClick={(e) => toggleNavs(e, 1)}
                        >
                          <span className="d-none d-md-block">Month</span>
                          <span className="d-md-none">M</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 2,
                          })}
                          data-toggle="tab"
                           
                          onClick={(e) => toggleNavs(e, 2)}
                        >
                          <span className="d-none d-md-block">Week</span>
                          <span className="d-md-none">W</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                
                <div className="chart">
                  <Pie
                    data={chartExample1[chartExample1Data]}
                    options={chartExample1.options}
                    getDatasetAtEvent={(e) => console.log(e)}
                  />
                </div>
              </CardBody>
            </Card>
          */}

          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col">
                  <h6 className="text-uppercase ls-1 mb-1">Solicitações</h6>
                  <h3 className="mb-0">Não tratadas</h3>
                </div>
                <div className="col text-right">
                  <Link to="/admin/requests">
                    <Button
                      color="primary"
                      //onClick={redirect("/admin/requests")}
                      size="sm"
                    >
                      Maiores informações
                    </Button>
                  </Link>
                </div>
              </Row>
            </CardHeader>
            <Table
              {...getTableProps()}
              className="align-items-center table-flush"
              responsive
            >
              <thead className="thead-light">
                {headerGroups.map((headerGroup) => (
                  <tr
                    style={{ textAlign: "center" }}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => {
                      return (
                        <th
                          className="text-wrap"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          scope="col"
                        >
                          {column.render("Header")}{" "}
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <ArrowDropDownIcon />
                            ) : (
                              <ArrowDropUpIcon />
                            )
                          ) : (
                            ""
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  console.log(row);
                  prepareRow(row);
                  return (
                    <tr
                      style={{ cursor: "pointer", textAlign: "center" }}
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell) => {
                        if (cell.column.Header === "Equipamento") {
                          console.log(cell.row.original);

                          return (
                            <td
                              className="caractersTableSize centerContent"
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")} -{" "}
                              {cell.row.original?.descricao_equipamento}
                            </td>
                          );
                        }

                        if (cell.column.Header === "Data de criação") {
                          return (
                            <td
                              className="caractersTableSize centerContent"
                              {...cell.getCellProps()}
                            >
                              {moment(cell.row.values?.createdAt).format(
                                "DD/MM/YYYY"
                              )}
                            </td>
                          );
                        }
                        if (cell.column.Header === "Prioridade") {
                          if (cell.row.values?.prioridade === "Alta") {
                            return (
                              <td
                                className="caractersTableSize centerContent"
                                {...cell.getCellProps()}
                              >
                                <span class="badge-md badge-pill badge-warning">
                                  Alta
                                </span>
                              </td>
                            );
                          }
                          if (cell.row.values?.prioridade === "Média") {
                            return (
                              <td
                                className="caractersTableSize centerContent"
                                {...cell.getCellProps()}
                              >
                                <span class="badge-md badge-pill badge-success">
                                  Média
                                </span>
                              </td>
                            );
                          }
                          if (cell.row.values?.prioridade === "Baixa") {
                            return (
                              <td
                                className="caractersTableSize centerContent"
                                {...cell.getCellProps()}
                              >
                                <span class="badge-md badge-pill badge-primary">
                                  Baixa
                                </span>
                              </td>
                            );
                          }
                          if (cell.row.values?.prioridade === "Nenhuma") {
                            return (
                              <td
                                className="caractersTableSize centerContent"
                                {...cell.getCellProps()}
                              >
                                <span
                                  style={{ border: "1px solid #0001" }}
                                  class="badge-md badge-pill badgeNothing"
                                  className="badgeNothing"
                                >
                                  Nenhuma
                                </span>
                              </td>
                            );
                          }
                        }
                        return (
                          <td
                            className="caractersTableSize"
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card>

          <CardFooter className="py-4">
            <nav aria-label="...">
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem disabled={!canPreviousPage}>
                  <PaginationLink onClick={(e) => gotoPage(0)} tabIndex="-1">
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                {state.pageIndex > 0 ? (
                  <>
                    <PaginationItem disabled={!canPreviousPage}>
                      <PaginationLink onClick={(e) => previousPage()}>
                        {state.pageIndex}
                      </PaginationLink>
                    </PaginationItem>
                  </>
                ) : null}
                <PaginationItem className="active">
                  <PaginationLink>{state.pageIndex + 1}</PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={!canNextPage}>
                  <PaginationLink onClick={(e) => nextPage()}>
                    {state.pageIndex + 2}
                    <span className="sr-only">(current)</span>
                  </PaginationLink>
                </PaginationItem>

                <PaginationItem disabled={!canNextPage}>
                  <PaginationLink onClick={(e) => gotoPage(pageCount - 1)}>
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </CardFooter>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col className="mb-5 mb-xl-0" xl="8">
          {/*
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Page visits</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                       
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      See all
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Page name</th>
                    <th scope="col">Visitors</th>
                    <th scope="col">Unique users</th>
                    <th scope="col">Bounce rate</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">/argon/</th>
                    <td>4,569</td>
                    <td>340</td>
                    <td>
                      <i className="fas fa-arrow-up text-success mr-3" /> 46,53%
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">/argon/index.html</th>
                    <td>3,985</td>
                    <td>319</td>
                    <td>
                      <i className="fas fa-arrow-down text-warning mr-3" />{" "}
                      46,53%
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">/argon/charts.html</th>
                    <td>3,513</td>
                    <td>294</td>
                    <td>
                      <i className="fas fa-arrow-down text-warning mr-3" />{" "}
                      36,49%
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">/argon/tables.html</th>
                    <td>2,050</td>
                    <td>147</td>
                    <td>
                      <i className="fas fa-arrow-up text-success mr-3" /> 50,87%
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">/argon/profile.html</th>
                    <td>1,795</td>
                    <td>190</td>
                    <td>
                      <i className="fas fa-arrow-down text-danger mr-3" />{" "}
                      46,53%
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          
            </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Social traffic</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                       
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      See all
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Referral</th>
                    <th scope="col">Visitors</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Facebook</th>
                    <td>1,480</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">60%</span>
                        <div>
                          <Progress
                            max="100"
                            value="60"
                            barClassName="bg-gradient-danger"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Facebook</th>
                    <td>5,480</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">70%</span>
                        <div>
                          <Progress
                            max="100"
                            value="70"
                            barClassName="bg-gradient-success"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Google</th>
                    <td>4,807</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">80%</span>
                        <div>
                          <Progress max="100" value="80" />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Instagram</th>
                    <td>3,678</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">75%</span>
                        <div>
                          <Progress
                            max="100"
                            value="75"
                            barClassName="bg-gradient-info"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">twitter</th>
                    <td>2,645</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">30%</span>
                        <div>
                          <Progress
                            max="100"
                            value="30"
                            barClassName="bg-gradient-warning"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
            */}
        </Col>
      </Row>
    </>
  );
};

export default Requests;
