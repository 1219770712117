import { memo } from "react";
import { Checkbox as MuiCheckbox, FormControlLabel } from "@mui/material";

const Checkbox = memo(({ check, description }) => {
  return (
    <FormControlLabel
      control={<MuiCheckbox checked={(check ||= false)} />}
      style={{
        textDecoration: check ? "line-through" : "none",
        width: "100%",
        backgroundColor: "#F8F9FA",
        borderRadius: "5px",
      }}
      label={description}
    />
  );
});

export default Checkbox;
