import axios from "axios";

const GET_CUSTOM_EQUIPMENTS =
  "https://api.darwinx.com.br/getAllEquipmentsByCustomFilter";

class GetAllEquipmentsByCustomFilter {
  getEquipments(filter) {
    return axios.get(GET_CUSTOM_EQUIPMENTS, { params: filter });
  }
}

export default new GetAllEquipmentsByCustomFilter();
