// GESTÃO DE Estoque DE MANUTENÇÂO

import axios from "axios";

const SPAREMOVIMENT_SERVICE = "https://api.darwinx.com.br/sparesmoviment";

class SpareMovimentService {
  getSparesMoviment(spare) {
    return axios.get(SPAREMOVIMENT_SERVICE, { params: spare });
  }

  createSpareMoviment(spare) {
    return axios.post(SPAREMOVIMENT_SERVICE, spare);
  }

  getSpareMovimentById(spareId) {
    return axios.get(SPAREMOVIMENT_SERVICE + "/" + spareId);
  }

  updateSpareMoviment(spare, spareId) {
    return axios.put(SPAREMOVIMENT_SERVICE + "/" + spareId, spare);
  }

  deleteSpareMoviment(spareId) {
    return axios.delete(SPAREMOVIMENT_SERVICE + "/" + spareId);
  }
}

export default new SpareMovimentService();
