import axios from "axios";

const DASHBOARD_API_BASE_URL = "https://api.darwinx.com.br/dashboard/admin";

class DashboardService {
  getData(filter) {
    return axios.get(DASHBOARD_API_BASE_URL, { params: filter });
  }
}

export default new DashboardService();
