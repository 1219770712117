/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import UserService from "../Users/UserService";
import swal from "sweetalert";
import axios from "axios";

import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";

import AWS from "aws-sdk";

// import { DropzoneArea } from 'material-ui-dropzone';
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
  ModalHeader,
  Row,
  Col,
  ModalFooter,
  Button,
  ModalBody,
  Modal,
  Input,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import TextField from "@material-ui/core/TextField";

function AdminNavbar(props) {
  const [userType] = useState(() => {
    const userTypeStoraged = localStorage.getItem("TOKEN_USERTYPE");
    if (userTypeStoraged) {
      return userTypeStoraged;
    }
    return "";
  });

  const [enterpriseName] = useState(() => {
    const enterpriseStoraged = localStorage.getItem("TOKEN_EMPRESA");
    if (enterpriseStoraged) {
      return enterpriseStoraged;
    }
    return "";
  });

  const [materialAutomatic, setMaterialAutomatic] = useState(false);
  const handleSubmit = (evt) => {
    evt.preventDefault();

    //alert(`Submitting Name ${firstName} ${lastName}`);
    //resetFirstName();
    //resetLastName();
  };

  let token = localStorage.getItem("TOKEN_KEY");
  const tokenParts = token?.split(".");
  const encodedPayload = tokenParts[1];
  const rawPayload = atob(encodedPayload);
  const [user] = useState(() => {
    const idStoraged = localStorage.getItem("TOKEN_ID_USER");
    if (idStoraged) {
      return idStoraged;
    }
    return "";
  });

  const [userUpdate, setUserUpdate] = useState([]);
  const [first_name, setFirst_name] = useState("");
  const [avatars, setAvatars] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [level, setLevel] = useState("");
  const [custohh, setCustohh] = useState("");

  useEffect(() => {
    UserService.getUserById(user)
      .then((res) => {
        let users = res.data;
        console.log("user", users);
        setUserUpdate(users._id);
        setFirst_name(users.first_name);
        setAvatars(users.avatars);
        setEmail(users.email);
        setPhone(users.phone);
        setLevel(users.level);
        setCustohh(users.custohh);
        setMaterialAutomatic(users.materialAutomatic);
        try {
          const myBucket = "darwinxperfil";
          const myKey = res.data.nomeFoto;
          const signedUrlExpireSeconds = 60 * 1;
          const s3 = new AWS.S3({
            accessKeyId: "AKIAVSM7L4TZDZIH7KGO",
            region: "sa-east-1",
            secretAccessKey: "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",
          });

          const url = s3.getSignedUrl("getObject", {
            Bucket: myBucket,
            Key: myKey,
            Expires: signedUrlExpireSeconds,
          });

          console.log(url);
          setShowImage(url);
        } catch (error) {
          console.log(error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [user.id]);
  function Logout() {
    swal({
      title: "Tem certeza que deseja sair?",
      //text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Logout concluído!", {
          icon: "success",
        }).then((val) => {
          localStorage.removeItem("TOKEN_EMPRESA");
          localStorage.removeItem("TOKEN_KEY");
          localStorage.removeItem("TOKEN_USERTYPE");
          localStorage.removeItem("TOKEN_ID_USER");
          localStorage.removeItem("TOKEN_USERNAME");
          localStorage.removeItem("TOKEN_HASH");
          localStorage.removeItem("TOKEN_MATERIAL_AUTOMATIC");
          return window.location.reload();
        });
      } else {
        swal("Logout cancelado!");
      }
    });
  }

  const [modal, setModal] = useState(false);
  const [image, setImage] = useState();
  const [showImage, setShowImage] = useState();

  const toggle = () => setModal(!modal);

  const update = async (evt) => {
    console.log("entrou aqui");
    let url;
    let filename;
    const formData = new FormData();
    if (image) {
      formData.append("image", image[0]);
      console.log(userUpdate);
      UserService.getUserById(userUpdate).then((response) => {
        const resultRequest = response.data;
        if (resultRequest.foto) {
          try {
            const s3 = new AWS.S3({
              accessKeyId: "AKIAVSM7L4TZDZIH7KGO",
              region: "sa-east-1",
              secretAccessKey: "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",
            });

            s3.deleteObject(
              { Key: response.data.nomeFoto, Bucket: "darwinxperfil" },
              function (err, data) {}
            );
            axios
              .delete("https://api.darwinx.com.br/images", {
                params: { url: response.data.foto },
              })
              .then((response) => {
                console.log(response.data);
                if (response.data) {
                  axios({
                    method: "post",
                    url: "https://api.darwinx.com.br/images",
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                  })
                    .then((response) => {
                      console.log(response);
                      url = response.data.url;
                      filename = response.data.name;
                      if (url) {
                        let users = {
                          first_name: first_name,
                          email: email,
                          phone: phone,
                          nomeFoto: filename,
                          foto: url,
                          materialAutomatic,
                        };
                        console.log(users);
                        UserService.updateUser(users, userUpdate).then(
                          (res) => {
                            setModal(!modal);
                            UserService.getUserById(userUpdate).then(
                              (response) => {
                                console.log(response.data);
                                let users = res.data;
                                setUserUpdate(users._id);
                                setFirst_name(users.first_name);
                                setAvatars(users.avatars);
                                setEmail(users.email);
                                setPhone(users.phone);
                                setLevel(users.level);
                                setCustohh(users.custohh);
                                try {
                                  const myBucket = "darwinxperfil";
                                  const myKey = response.data.nomeFoto;
                                  const signedUrlExpireSeconds = 60 * 1;
                                  const s3 = new AWS.S3({
                                    accessKeyId: "AKIAVSM7L4TZDZIH7KGO",
                                    region: "sa-east-1",
                                    secretAccessKey:
                                      "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",
                                  });

                                  const url = s3.getSignedUrl("getObject", {
                                    Bucket: myBucket,
                                    Key: myKey,
                                    Expires: signedUrlExpireSeconds,
                                  });

                                  setShowImage(url);
                                } catch (error) {
                                  console.log(error);
                                }
                              }
                            );
                          }
                        );
                        console.log(url);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              });
          } catch (error) {
            console.log(error);
          }
        } else {
          console.log(formData);
          axios({
            method: "post",
            url: "https://api.darwinx.com.br/images",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then((response) => {
              console.log(response);
              url = response.data.url;
              filename = response.data.name;
              if (url) {
                let users = {
                  first_name: first_name,
                  email: email,
                  phone: phone,
                  nomeFoto: filename,
                  foto: url,
                  materialAutomatic,
                };
                console.log(users);
                UserService.updateUser(users, userUpdate).then((res) => {
                  setModal(!modal);
                  UserService.getUserById(userUpdate).then((response) => {
                    console.log(response.data);
                    let users = res.data;
                    setUserUpdate(users._id);
                    setFirst_name(users.first_name);
                    setAvatars(users.avatars);
                    setEmail(users.email);
                    setPhone(users.phone);
                    setLevel(users.level);
                    setCustohh(users.custohh);
                    try {
                      const myBucket = "darwinxperfil";
                      const myKey = response.data.nomeFoto;
                      const signedUrlExpireSeconds = 60 * 1;
                      const s3 = new AWS.S3({
                        accessKeyId: "AKIAVSM7L4TZDZIH7KGO",
                        region: "sa-east-1",
                        secretAccessKey:
                          "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",
                      });

                      const url = s3.getSignedUrl("getObject", {
                        Bucket: myBucket,
                        Key: myKey,
                        Expires: signedUrlExpireSeconds,
                      });

                      setShowImage(url);
                    } catch (error) {
                      console.log(error);
                    }
                  });
                });
                console.log(url);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    } else {
      const data = {
        materialAutomatic,
      };
      UserService.getUsers({ empresa: enterpriseName }).then((users) => {
        const usersData = users.data;
        console.log("usersData", usersData);
        for (let i = 0; i < usersData.length; i++) {
          UserService.updateUser(data, usersData[i]._id).then((response) => {
            console.log("atualizou", response.data);
          });
        }
        localStorage.setItem("TOKEN_MATERIAL_AUTOMATIC", materialAutomatic);
        setModal(false);
      });
    }
  };

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="#"
          >
            {props.brandText}
          </Link>

          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={showImage}
                      class="profile-user-img img-fluid img-circle"
                      width={100}
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {first_name}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem to="/admin/profile" onClick={toggle}>
                  <i className="ni ni-single-02" />
                  <span>Meu Perfil</span>
                </DropdownItem>
                {/* <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Configurações</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-calendar-grid-58" />
                  <span>Atividades</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-support-16" />
                    <span>Suporte</span> 
                    </DropdownItem>*/}
                <DropdownItem divider />
                <DropdownItem href="" onClick={(e) => Logout()}>
                  <i className="ni ni-user-run" />
                  <span>Sair</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>

      <div>
        <Modal isOpen={modal} toggle={toggle} className="mr-0 mt--1 mb--1 mt-5">
          <ModalHeader toggle={toggle} className="mt-3" tag="h2">
            Editar perfil
          </ModalHeader>
          <ModalBody className="mb-1 mt--2 mb-2">
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <Card className="mb-3 shadow-lg">
                <CardHeader className=" bg-gradient-red"></CardHeader>
                <CardBody>
                  <Row className="justify-content-center mb-7">
                    <Col className="order-lg-2" lg="3">
                      <div className="card-profile-image mt--4">
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={showImage}
                        />
                      </div>
                    </Col>
                  </Row>
                  <input
                    type="file"
                    onChange={(e) => setImage(e.target.files)}
                  />

                  <br />
                  <Row>
                    <Col>
                      {/*
              <DropzoneArea
                acceptedFiles={['image/*']}
                filesLimit={1}
                dropzoneText={"Drag and drop an image here or click"}
                onChange={(files) => console.log('Files:', files)}
              />*/}
                      <br></br>
                      <div className="form-group">
                        <TextField
                          id="outlined-basic"
                          label="Nome Completo"
                          //variant="outlined"
                          name="nome completo"
                          style={{ width: "100%" }}
                          defaultValue={first_name}
                          disabled
                          //onChange={(e) => setFirst_name(e.target.value)}
                        />
                      </div>

                      <div className="form-group">
                        <TextField
                          id="outlined-basic"
                          label="E-mail"
                          //variant="outlined"
                          name="nome completo"
                          style={{ width: "100%" }}
                          value={email}
                          disabled
                          //onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>

                      <div className="form-group">
                        <TextField
                          id="outlined-basic"
                          label="Perfil"
                          //variant="outlined"
                          name="perfil"
                          style={{ width: "100%" }}
                          value={level}
                          disabled
                          //onChange={(e) => setLevel(e.target.value)}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </form>

            <Button color="success" onClick={update}>
              Atualizar
            </Button>
            <Button color="secondary" onClick={toggle}>
              Cancelar
            </Button>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}

export default AdminNavbar;
