//listagem de usuarios sem search e paginação.
import React, { useState, useEffect, useContext, useMemo } from "react";
import UserService from "./UserService";

// import DataTable from 'react-data-table-component';
// import DataTableExtensions from 'react-data-table-component-extensions';
import { EnterpriseNameContext } from "../../context/EnterpriseContext";
import swal from "sweetalert";
import Autocomplete from "@material-ui/lab/Autocomplete";

import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
  useResizeColumns,
  useBlockLayout,
} from "react-table";
import SearchIcon from "@material-ui/icons/Search";

import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import {
  Button,
  Modal,
  Col,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Badge,
  CardBody,
} from "reactstrap";

import Header from "components/Headers/Headerfor.js";
import CreateUser from "./CreateUser";
import "./ListUsers.js";
import Datagrid from "./components/Datagrid";

function ListUsers(props) {
  const levelForm = [
    "Administrador",
    "Corpo Técnico",
    "Executante",
    "Requisitante",
    "Amoxarife",
    "Ferramenteiro",
    // "Amoxarife e Ferramenteiro",
  ];
  const { enterpriseName } = useContext(EnterpriseNameContext);
  console.log(enterpriseName);

  const {
    // buttonLabel,
    className,
    actual_id,
  } = props;

  const [row_id, setRow_id] = useState("");

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [filterColumn, setFilterColumn] = useState({
    Nome: false,
    Email: false,
    Funcao: false,
    AreaAtuacao: false,
    Custo: false,
    Phone: false,
  });

  // const columns = [
  //   {
  //     name: 'Nome',
  //     selector: 'Nome',
  //     sortable: true,
  //   },
  //   {
  //     name: 'E-mail',
  //     selector: 'Email',
  //     sortable: true,
  //   },
  //   {
  //     name: 'Função',
  //     selector: 'Funcao',
  //     sortable: true,
  //   },
  //   {
  //     name: 'Área de atuação',
  //     selector: 'area',
  //     sortable: true,
  // },
  //   {
  //     name: 'Custo hh',
  //     selector: 'custohh',
  //     sortable: true,
  //   },
  //   {
  //     name: 'phone',
  //     selector: 'phone',
  //     sortable: true,
  //   },
  // ];

  const TranslateOptions = {
    rowsPerPageText: "Usuários por Página:",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsText: "Todos",
  };

  const deletar = (evt) => {
    swal("Tem certeza que deletar o usuário?", "", "warning", {
      buttons: {
        nope: {
          text: "Me enganei!",
          value: "nope",
        },
        sure: {
          text: "Sim, claro.",
          value: "sure",
        },
      },
    }).then((value) => {
      switch (value) {
        case "sure":
          swal("Usuário deletado com sucesso", "", "success").then((val) => {
            UserService.deleteUser(row_id)
              .then((res) => {
                UserService.getUsers({ empresa: enterpriseName }).then(
                  (response) => {
                    console.log(response.data);
                    setUser(response.data);
                    toggle();
                  }
                );
              })
              .catch((error) => console.log(error.response));
          });
          break;
        case "nope":
          swal("Operação cancelada", "", "success");
          break;
        default:
          swal("Equipamento excluído!");
      }
    });
  };

  useEffect(() => {
    UserService.getUsers({ empresa: enterpriseName }).then((res) => {
      setUser(res.data);
      setUserStoraged(res.data);
    });
  }, [enterpriseName, row_id]);

  const [user, setUser] = useState([]);
  const [userStoraged, setUserStoraged] = useState();
  const [area, setArea] = useState("");
  const [first_name, setFirst_name] = useState("");
  //const [last_name, setLast_name] = useState('');
  const [email, setEmail] = useState("");
  const [level, setLevel] = useState("");
  const [custohh, setCustohh] = useState("");
  const [phone, setPhone] = useState("");

  const handleSubmit = (evt) => {
    evt.preventDefault();

    //alert(`Submitting Name ${firstName} ${lastName}`);
    //resetFirstName();
    //resetLastName();
  };
  const update = (evt) => {
    const removeDot = custohh?.replace(".", "");
    const changeComma = removeDot?.replace(",", ".");
    const converted = parseFloat(changeComma).toFixed(2);
    let users = {
      first_name: first_name,
      area: area,
      email: email,
      level: level,
      phone: phone,
    };
    console.log(users);
    UserService.updateUser(users, row_id)
      .then((res) => {
        console.log(res);
        setModal(!modal);
        UserService.getUsers({ empresa: enterpriseName }).then((res) => {
          setUser(res.data);
          setUserStoraged(res.data);
        });
      })
      .catch((error) => console.log(error.response));
  };

  function linhaClicada(source) {
    const row = source.data._id;

    setRow_id(row);
    setModal(true);
    UserService.getUserById(row).then((res) => {
      let users = res.data;
      setFirst_name(users.first_name);
      setArea(users.area);
      //setLast_name(users.last_name);
      setEmail(users.email);
      setLevel(users.level);
      console.log("users.custohh", users.custohh);

      if (users.custohh) {
        console.log("users.custohh", users.custohh);
        const decimal = users.custohh.toFixed(2);
        console.log("decimal: ", decimal);
        console.log(decimal.toString().replace(".", ","), "convertida");
        setCustohh(decimal.toString().replace(".", ","));
      }
      setPhone(users.phone);
    });
  }

  const customStyles = {
    rows: {
      style: {
        backgroundColor: "",
        cursor: "pointer",
      },
    },
    headCells: {
      //titulo da tabela.
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        backgroundColor: "#f6f9fc",
        border: "1px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        borderLeft: "1px",
      },
    },
  };
  const columnHeader = [];
  const columns = useMemo(() => columnHeader, []);
  const data = useMemo(() => user, [user]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    allColumns,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
    // useResizeColumns,
    // useBlockLayout
  );
  const [value, setValue] = useState(state.globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value);
  });

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <CreateUser setUser={setUser} />
              </CardHeader>
              <CardBody>
                <Datagrid lineClicked={linhaClicada} dataSource={user} />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

      <Modal
        isOpen={modal}
        toggle={toggle}
        size="lg"
        className="mr-0 mt--1 mb--1 mt-5"
      >
        <ModalHeader className="mt-3" toggle={toggle} tag="h2">
          Editar Usuário{" "}
        </ModalHeader>
        <ModalBody className="mb-1 mt--2 mb-2">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label> Nome: </label>
              <input
                name="name"
                className="form-control"
                value={first_name}
                onChange={(e) => setFirst_name(e.target.value)}
              />
            </div>
            {/*
            <div className="form-group">
              <label> Sobrenome: </label>
              <input
                name="last_name"
                className="form-control"
                value={last_name}
                onChange={(e) => setLast_name(e.target.value)}
              />
            </div>
            */}
            <div className="form-group">
              <label> E-mail: </label>
              <input
                name="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="form-group">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={levelForm}
                // {...bindlevel}
                onChange={(e, v) => {
                  setLevel(v);
                }}
                value={level}
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} label="Função" />
                )}
              />
            </div>

            {/* <div className="form-group">
              <label> Área de atuação: </label>
              <input
                name="area"
                className="form-control"
                value={area}
                onChange={(e) => setArea(e.target.value)}
              />
            </div> */}

            {/* <div className="form-group">
              <label> Custo Hh: </label>
              <input
                placeholder="Custo medio da hora"
                name="custo"
                className="form-control"
                value={custohh}
                onChange={(e) => setCustohh(e.target.value)}
              />
            </div> */}

            <div className="form-group">
              <label> Telefone: </label>
              <input
                placeholder="telefone para contato"
                name="phone"
                className="form-control"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </form>

          <Row>
            <Col className="mb-5 mb-xl-0" xl="9">
              <Button color="success" onClick={update}>
                Atualizar
              </Button>{" "}
              <Button color="secondary" onClick={toggle}>
                Cancelar
              </Button>
            </Col>

            <Col className="">
              <Button color="secondary" onClick={deletar}>
                <DeleteIcon /> Apagar
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}

export default ListUsers;
