import React, { Component } from "react";

import {
  Container,
  Row,
  Card,
  CardHeader,
  Table,
  Col,
  Input,
  FormGroup,
  Form,
  CardBody,
  Button,
  CardImg,
} from "reactstrap";

import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import swal from "sweetalert";
import Header from "components/Headers/Headerfor";

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      response: {},
      error_message: null,
      avatar: "",
    };
  }

  parseJwt() {
    let token = localStorage.getItem("TOKEN_KEY");
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  componentDidMount() {
    let { id } = this.parseJwt();
    this.getData(id);
  }

  showPreviewImage = (values) => {
    console.log(this.state.response.avatar);
    return (
      <Row style={{ width: "12rem" }}>
        <Col className="center">
          <div className="card-footer">
            <CardImg
              id="avatars"
              src={
                values.file_obj != null
                  ? values.file_obj
                  : "https://api.darwinx.com.br/images/user.png"
              }
              class="img-circle "
            />
          </div>
        </Col>
      </Row>
    );
  };
  getData = async (id) => {
    await axios
      .get("https://api.darwinx.com.br/profile/id/" + id)
      .then((response) => {
        console.log(response.data);
        document.getElementById("avatars").src =
          "https://api.darwinx.com.br/images/" + response.data.avatars;
        // profile.setAttribute("src",);
        this.setState({ response: response.data });
      })
      .catch((error) => {
        this.setState({ error_message: error.message });
      });
  };
  submitForm = async (formData) => {
    await axios
      .put("https://api.darwinx.com.br/profile", formData)
      .then((res) => {
        console.log(res.data.result);
        if (res.data.result === "success") {
          swal("Success!", res.data.message, "success").then((value) => {
            //s window.location.reload();
          });
        } else if (res.data.result === "error") {
          swal("Error!", res.data.message, "error");
        }
      })
      .catch((error) => {
        console.log(error);
        swal("Error!", "Unexpected error", "error");
      });
  };
  showForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    onSubmit,
    isSubmitting,
    setFieldValue,
  }) => {
    return (
      <form role="form" onSubmit={handleSubmit}>
        {this.showPreviewImage(values)}
        <div className="card-body">
          <div className="form-group">
            <label htmlFor="exampleInputFile">Foto</label>
            <div className="input-group">
              <div className="custom-file">
                <Input
                  type="file"
                  onChange={(e) => {
                    e.preventDefault();
                    setFieldValue("avatars", e.target.files[0]); // for upload
                    setFieldValue(
                      "file_obj",
                      URL.createObjectURL(e.target.files[0])
                    ); // for preview image
                  }}
                  name="avatars"
                  className={
                    errors.email && touched.email
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  accept="image/*"
                  id="avatars"
                  className="custom-file-input"
                  id="exampleInputFile"
                />
                <label className="custom-file-label" htmlFor="exampleInputFile">
                  Insira sua foto
                </label>
              </div>
            </div>
          </div>

          <input type="hidden" name="id" value={values._id} />
          <div className="form-group  has-feedback">
            <Row>
              <Col lg="8">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-first-name"
                  >
                    Nome Completo
                  </label>
                  <Input
                    onChange={handleChange}
                    className={
                      errors.first_name && touched.first_name
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    defaultValue="Primeiro Nome"
                    id="first_name"
                    placeholder="First name"
                    value={values.first_name}
                    type="text"
                  />
                  {errors.first_name && touched.first_name ? (
                    <small id="passwordHelp" class="text-danger">
                      {errors.first_name}
                    </small>
                  ) : null}
                </FormGroup>
              </Col>

              <Col>
                <div className="form-group has-feedback">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-last-name"
                    >
                      Telefone
                    </label>
                    <Input
                      onChange={handleChange}
                      value={values.phone}
                      type="text"
                      className={
                        errors.phone && touched.phone
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      id="phone"
                      placeholder="Insira seu telefone"
                    />
                  </FormGroup>

                  {errors.phone && touched.phone ? (
                    <small id="passwordHelp" class="text-danger">
                      {errors.phone}
                    </small>
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
          <Row className="mt--4">
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="input-email">
                  E-mail
                </label>
                <Input
                  className={
                    errors.email && touched.email
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  id="email"
                  placeholder="jesse@example.com"
                  type="email"
                  onChange={handleChange}
                  value={values.email}
                />
              </FormGroup>
              <div className="form-group has-feedback">
                {errors.email && touched.email ? (
                  <small id="passwordHelp" class="text-danger">
                    {errors.email}
                  </small>
                ) : null}
              </div>
            </Col>
          </Row>

          {/*<div className="form-group has-feedback">
            <FormGroup>
            <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >Nome da Empresa</label>
            <Input
              onChange={handleChange}
              value={values.address}
              className={
                errors.address && touched.address
                  ? "form-control is-invalid"
                  : "form-control"
              }
              id="address"
              placeholder="Address"
            />
            </FormGroup>
            
            {errors.address && touched.address ? (
              <small id="passwordHelp" class="text-danger">
                {errors.address}
              </small>
            ) : null}
          </div>
          */}
        </div>

        <div className="card-footer mt--4">
          <Button
            type="submit"
            disabled={isSubmitting}
            color="success"
            className="btn btn-block btn-primary"
          >
            Salvar
          </Button>
        </div>
      </form>
    );
  };

  render() {
    let result = this.state.response;
    console.log(result);
    return (
      <>
        <Header />

        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Formik
                    enableReinitialize={true}
                    initialValues={
                      result
                        ? result
                        : {
                            id: "",
                            username: "",
                            email: "",
                            first_name: "",
                            last_name: "",
                            phone: "",
                            address: "",
                          }
                    }
                    onSubmit={(values, { setSubmitting }) => {
                      let formData = new FormData();
                      formData.append("id", values._id);
                      formData.append("username", values.username);
                      formData.append("first_name", values.first_name);
                      formData.append("last_name", values.last_name);
                      formData.append("phone", values.phone);
                      formData.append("address", values.address);
                      formData.append("email", values.email);
                      if (values.avatars) {
                        formData.append("avatars", values.avatars);
                      }
                      console.log(values.avatars);
                      this.submitForm(formData, this.props.history);
                      setSubmitting(false);
                    }}
                    // validationSchema={ProfileSchema}
                  >
                    {(props) => this.showForm(props)}
                  </Formik>
                </CardHeader>
                <CardBody></CardBody>
              </Card>
            </div>
          </Row>

          <Col className="order-xl-1" xl="8"></Col>
        </Container>
      </>
    );
  }
}

export default Profile;
