import React, { useState, useContext, useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import swal from "sweetalert";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useInput } from "../../../components/Users/useInput";
import UserService from "../../../components/Users/UserService";

import { EnterpriseNameContext } from "../../../context/EnterpriseContext";

// import { FormHandles } from '@unform/core';
// import { Form } from '@unform/web';

function CreateUser(props) {
  const levelForm = [
    "Administrador",
    "Corpo Técnico",
    "Executante",
    "Requisitante",
    "Amoxarife",
    "Ferramenteiro",
    // "Amoxarife e Ferramenteiro",
  ];
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const {
    value: first_name,
    bind: bindfirst_name,
    setValue: setFisrt_name,
  } = useInput();
  const { value: last_name } = useInput("");
  const { value: area } = useInput("");
  const { value: level, bind: bindlevel, setValue: setLevel } = useInput();
  const { value: email, bind: bindemail, setValue: setEmail } = useInput();
  const {
    value: custohh,
    bind: bindcustohh,
    setValue: setCustohh,
  } = useInput();
  const { value: phone, bind: bindphone, setValue: setPhone } = useInput("");

  //YUP IN HAND
  const [requireName, setRequireName] = useState(false);
  const [messageRequireName, setMessageRequireName] = useState("");

  const [requireFunction, setRequireFunction] = useState(false);
  const [messageRequireFunction, setMessageRequireFunction] = useState("");

  const [requireEmail, setRequireEmail] = useState(false);
  const [messageRequireEmail, setMessageRequireEmail] = useState("");

  const formRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    UserService.getUsers({ email: email })
      .then((response) => {
        if (response.data.length > 0) {
          swal("Erro", "Email já cadastrado", "error");
        } else {
          if (first_name && email && level) {
            let users = {
              first_name: first_name,
              last_name: last_name,
              area: area,
              level: level,
              email: email,
              phone: phone,
              empresa: props.enterprise,
            };

            console.log(users);
            UserService.createUser(users)
              .then((res) => {
                console.log(res);
                swal(
                  "Success!",
                  "E-mail enviado para o novo usuário contendo as instruções de acesso.",
                  "success"
                ).then((value) => {
                  UserService.getUsers({ empresa: props.enterprise }).then(
                    (res) => {
                      props.setEnterpriseData(res.data);
                      setModal(false);
                      setLevel(null);
                      setPhone(null);
                      setCustohh(null);
                      setEmail(null);
                      setFisrt_name(null);
                    }
                  );
                });
              })
              .catch((error) => console.log(error.response));
          }
          if (!first_name) {
            setRequireName(true);
            setMessageRequireName("O campo Nome Completo é obrigatório!");
          } else {
            setRequireName(false);
            setMessageRequireName("");
          }
          if (!level) {
            setRequireFunction(true);
            setMessageRequireFunction("O campo Função é obrigatório!");
          } else {
            setRequireFunction(false);
            setMessageRequireFunction("");
          }
          if (!email) {
            setRequireEmail(true);
            setMessageRequireEmail("O campo Email é obrigatório!");
          } else {
            setRequireEmail(false);
            setMessageRequireEmail("");
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // const removeDot = custohh?.toString()?.replace(".", "");
    // const changeComma = removeDot?.replace(",", ".");
    // const converted = parseFloat(changeComma).toFixed(2);
  };

  return (
    <div>
      <Button
        color="success"
        size="sm"
        style={{ marginRight: "2rem" }}
        // className=""
        onClick={toggle}
      >
        {" "}
        Novo Usuário{" "}
      </Button>
      <Modal isOpen={modal} toggle={toggle} className="mr-0 mt--1 mb--1 mt-5">
        <ModalHeader className="mt-3" toggle={toggle} tag="h2">
          Novo Usuário
        </ModalHeader>
        <ModalBody className="mb-1 mt--2 mb-2">
          <form ref={formRef} onSubmit={handleSubmit}>
            <div className="form-group">
              <TextField
                // required={true}
                {...bindfirst_name}
                label="Nome Completo"
                margin="normal"
                fullWidth
                variant="outlined"
                error={requireName}
                helperText={messageRequireName}
              />
            </div>

            <div className="form-group">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={levelForm}
                // {...bindlevel}
                onChange={(e, v) => {
                  setLevel(v);
                }}
                renderInput={(params) => (
                  <TextField
                    {...bindlevel}
                    error={requireFunction}
                    variant="outlined"
                    {...params}
                    label="Função"
                    helperText={messageRequireFunction}
                  />
                )}
              />
            </div>

            <div className="form-group">
              <TextField
                {...bindemail}
                label="Email"
                margin="normal"
                fullWidth
                variant="outlined"
                error={requireEmail}
                helperText={messageRequireEmail}
              />
            </div>

            <div className="form-group">
              <TextField
                {...bindphone}
                label="Telefone"
                placeholder="(00) 0 0000-0000"
                margin="normal"
                fullWidth
                variant="outlined"
              />
            </div>
            <ModalFooter>
              <Button type="submit" color="success">
                Criar
              </Button>{" "}
              <Button color="secondary" onClick={toggle}>
                Cancelar
              </Button>
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default CreateUser;
