import React, { useState, useEffect, useContext, createRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  Col,
  Row,
} from "reactstrap";
import { useInput } from "../Spares/useInput";
import OrderService from "./OrderService";
import EquipmentService from "../Equipments/EquipmentService";
import UserService from "../Users/UserService";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { EnterpriseNameContext } from "../../context/EnterpriseContext";
import { UserType } from "../../context/UserType";
import axios from "axios";
import NotificationAlert from "react-notification-alert";
import Stack from "@mui/material/Stack";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TextFieldMUI from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from "moment";
import DatePickerMui from "./DatePickerMui";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import brLocale from "date-fns/locale/pt-BR";
import DateTimePicker from "../DateTimePicker";

import GetAllAreasByEnterprise from "../../components/Orders/services/GetAllAreasByEnterprise";
import GetAllEquipmentsByArea from "../../components/Orders/services/GetAllEquipmentsByArea";
import GetAllEquipmentsByCustomFilter from "../Strategy/services/GetAllEquipmentsByCustomFilter";
import GetAllSectorsByEnterprise from "../Strategy/services/GetAllSectorsByEnterprise";
import FilterSectorsByArea from "./services/FilterSectorsByArea";

import DatePicker from "../DatePicker";

const notification = createRef();

function CreateOrder(props) {
  const history = useHistory();

  const typeOrder = ["Preventiva", "Corretiva", "Preditiva", "Melhoria"];

  const { enterpriseName } = useContext(EnterpriseNameContext);
  const { userType } = useContext(UserType);
  const [rSelected, setRSelected] = useState("Nenhuma");
  const [color, setColor] = useState("#7BF7CD");
  const [maquina, setMaquina] = useState("Não");
  const [machineStop, setMachineStop] = useState(null);

  const [stateDatePicker, setStateDatePicker] = useState(false);

  const [nameUser] = useState(() => {
    const userNameStoraged = window.localStorage.getItem("TOKEN_USERNAME");

    if (userNameStoraged) {
      return userNameStoraged;
    }
    return "";
  });

  const [modal, setModal] = useState(false);
  const [image, setImage] = useState();
  const [setor, setSetor] = useState("");

  const [equipamento, setEquipamento] = useState();
  const [denominacao, setDenominacao] = useState();
  const [responsavel, setResponsavel] = useState("");
  const [nameEquipment, setNameEquipment] = useState({
    codigo: null,
    denominacao: null,
  });
  const [tipo, setTipo] = useState("Corretiva");
  const {
    value: descricao,
    bind: binddescricao,
    setValue: setDescricao,
  } = useInput();
  const { value: prazo, setValue: setPrazo } = useInput(new Date());
  const [equipmentArea, setEquipmentArea] = useState();

  //YUP IN HAND
  const [requireEquipment, setRequireEquipment] = useState(false);
  const [messageRequireEquipment, setMessageRequireEquipment] = useState("");

  const [requireDescription, setRequireDescription] = useState(false);
  const [messageRequireDescription, setMessageRequireDescription] =
    useState("");

  const [requirePeriod, setRequirePeriod] = useState(false);
  const [messageRequirePeriod, setMessageRequirePeriod] = useState("");

  const [requireTypeOrder, setRequireTypeOrder] = useState(false);
  const [messageRequireTypeOrder, setMessageRequireTypeOrder] = useState("");

  const [token, setToken] = useState(() => {
    const tokenStoraged = localStorage.getItem("TOKEN_KEY");
    if (tokenStoraged) {
      return tokenStoraged;
    }
    return "";
  });
  const [hash, setHash] = useState(() => {
    const hashStoraged = localStorage.getItem("TOKEN_HASH");
    if (hashStoraged) {
      return hashStoraged;
    }
    return "";
  });
  const [idUserStorage, setIdUserStorage] = useState(() => {
    const idUserStoraged = localStorage.getItem("TOKEN_ID_USER");
    if (idUserStoraged) {
      return idUserStoraged;
    }
    return "";
  });
  const redirectLogin = () => {
    swal(
      "Hmm... vi que você não está logado. Clique em ok para fazer o login",
      "",
      "warning",
      {
        buttons: {
          // nope: {
          //   text: 'Me enganei!',
          //   value: 'nope',
          // },
          sure: {
            text: "Ok",
            value: "sure",
          },
        },
      }
    ).then((value) => {
      switch (value) {
        case "sure":
          swal("Operação realizada com sucesso!", "", "success").then((val) => {
            // history.pushState
            // return <Redirect to="/auth/login"/>
            history.push("/auth/login");
          });
          break;
        case "nope":
          swal("Operação cancelada", "", "success");
          break;
        default:
      }
    });
  };

  const filterEquipments = (area, sector) => {
    if (area && !sector) {
      FilterSectorsByArea.getSectors({ area, empresa: enterpriseName }).then(
        (response) => {
          setSectors(response.data?.filter ?? []);
        }
      );
      GetAllEquipmentsByCustomFilter.getEquipments({
        area: area,
        empresa: enterpriseName,
      }).then((response) => {
        setEquipment(response.data.equipments);
      });
    } else if (sector && area) {
      GetAllEquipmentsByCustomFilter.getEquipments({
        area: area,
        setor_instalado: sector,
        empresa: enterpriseName,
      }).then((response) => {
        setEquipment(response.data.equipments);
      });
    } else if (!area && sector) {
      GetAllEquipmentsByCustomFilter.getEquipments({
        setor_instalado: sector,
        empresa: enterpriseName,
      }).then((response) => {
        setEquipment(response.data.equipments);
      });
    } else {
      EquipmentService.getEquipment({
        empresa: enterpriseName,
      })
        .then((res) => {
          if (res.data.data) {
            const sorted = res.data.data?.sort((compareA, compareB) => {
              if (compareA.denominacao > compareB.denominacao) {
                return 1;
              }
              if (compareA.denominacao < compareB.denominacao) {
                return -1;
              }
              return 0;
            });
            setEquipment(sorted);
          }
        })
        .catch((error) => {});
    }
    // } else {
    //   if (area && !sector) {
    //     GetAllEquipmentsByCustomFilter.getEquipments({
    //       area: area,
    //       empresa: enterpriseName,
    //     }).then((response) => {
    //       setEquipment(response.data.equipments);
    //     });
    //   } else if (sector && area) {
    //     GetAllEquipmentsByCustomFilter.getEquipments({
    //       area: area,
    //       setor_instalado: sector,
    //       empresa: enterpriseName,
    //     }).then((response) => {
    //       setEquipment(response.data.equipments);
    //     });
    //   } else if (!area && sector) {
    //     GetAllEquipmentsByCustomFilter.getEquipments({
    //       setor_instalado: sector,
    //       empresa: enterpriseName,
    //     }).then((response) => {
    //       setEquipment(response.data.equipments);
    //     });
    //   } else {
    //     EquipmentService.getEquipment({
    //       empresa: enterpriseName,
    //     })
    //       .then((res) => {
    //         if (res.data.data) {
    //           const sorted = res.data.data?.sort((compareA, compareB) => {
    //             if (compareA.denominacao > compareB.denominacao) {
    //               return 1;
    //             }
    //             if (compareA.denominacao < compareB.denominacao) {
    //               return -1;
    //             }
    //             return 0;
    //           });
    //           setEquipment(sorted);
    //         }
    //       })
    //       .catch((error) => {});
    //   }
    // }
  };

  const notifyCode = (event, title, content) => {
    let options = {
      place: "tc",
      message: (
        <div>
          <h2
            style={{ color: "#fff", marginBottom: "0" }}
            className="alert-title"
            data-notify="title"
          >
            {" "}
            {title ? title : "Atenção!"}
            <br />
          </h2>
          <span data-notify="message">
            {content ? content : "Este código já foi cadastrado!"}
          </span>
        </div>
      ),
      type: event,
      // icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    notification.current.notificationAlert(options);
  };

  const toggle = () => {
    setModal(!modal);
    setSector(null);
    setAreaSelected(null);
    filterEquipments();
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (equipamento && descricao && tipo && prazo) {
      let url;
      let filename;
      if (image) {
        if (image[0].size > 1024 * 1024 * 4) {
          notifyCode(
            "warning",
            "Atenção!",
            "O tamanho da imagem é muito grande!"
          );
          return;
        }
        const formData = new FormData();
        formData.append("image", image[0]);
        await axios({
          method: "post",
          url: "https://api.darwinx.com.br/images",
          data: formData,
          params: { empresa: enterpriseName },
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((response) => {
            url = response.data.url;
            filename = response.data.name;
          })
          .catch((error) => {
            console.error(error);
          });
      }
      if (url) {
        let orders = {
          setor_equipamento: setor,
          equipamento: equipamento,
          descricao: descricao,
          responsavel: responsavel,
          prazo: prazo,
          equipmentArea,
          empresa: enterpriseName,
          tipo: tipo,
          prioridade: rSelected,
          title: equipamento,
          color: color,
          start: null,
          end: null,
          maquina_parou: maquina,
          quandoMaquinaParou: machineStop,
          equipamento_denominacao: denominacao,
          foto: url,
          nomeFoto: filename,
          status: "Aberta",
          performer: nameUser,
          dates: { dateOrderOpen: `${Date.now()}` },
          responsibles: {
            OwnerOrderOpen: nameUser,
          },
          token: token,
          hash: hash,
          idUser: idUserStorage,
        };

        OrderService.createOrder(orders)
          .then((res) => {
            if (props.setOrder) {
              OrderService.getOrders({
                empresa: enterpriseName,
                token: token,
                hash: hash,
                idUser: idUserStorage,
              }).then((res) => {
                props?.setOrder(res.data.data);
                localStorage.removeItem("TOKEN_KEY");
                localStorage.setItem("TOKEN_KEY", res.data.token);
              });
            }
            setTipo(null);
            setRSelected(null);
            setEquipamento(null);
            setDescricao("");
            setPrazo(new Date());
            setImage(null);
            setNameEquipment({ codigo: null, denominacao: null });
            notifyGeneral("success");
            setModal(false);
          })
          .catch((error) => {
            // if(error?.response?.data?.auth){
            // }else{
            //   redirectLogin()
            // }
          });
      } else {
        let orders = {
          setor_equipamento: setor,
          equipamento: equipamento,
          descricao: descricao,
          responsavel: responsavel,
          prazo: prazo,
          equipmentArea,
          empresa: enterpriseName,
          tipo: tipo,
          prioridade: rSelected,
          title: equipamento,
          color: color,
          start: null,
          end: null,
          maquina_parou: maquina,
          quandoMaquinaParou: machineStop,
          equipamento_denominacao: denominacao,
          status: "Aberta",
          performer: nameUser,
          dates: { dateOrderOpen: `${Date.now()}` },
          responsibles: {
            OwnerOrderOpen: nameUser,
          },
          token: token,
          hash: hash,
          idUser: idUserStorage,
        };
        OrderService.createOrder(orders)
          .then((res) => {
            if (props.setOrder) {
              OrderService.getOrders({
                empresa: enterpriseName,
                token: token,
                hash: hash,
                idUser: idUserStorage,
              })
                .then((res) => {
                  props?.setOrder(res.data.data);
                })
                .catch((error) => {
                  if (error?.response?.data?.auth) {
                  } else {
                    // redirectLogin();
                  }
                });
            }
            setTipo(null);
            setRSelected(null);
            setEquipamento(null);
            setDescricao("");
            setPrazo(new Date());
            setImage(null);
            setNameEquipment({ codigo: null, denominacao: null });
            notifyGeneral("success");
            setModal(false);
          })
          .catch((error) => {
            // if(error?.response?.data?.auth){
            // }else{
            //   redirectLogin()
            // }
          });
      }
    }

    if (!equipamento) {
      notificationDynamic(
        "warning",
        "Atenção",
        "O campo Equipamento é obrigatório!"
      );
      setRequireEquipment(true);
      setMessageRequireEquipment("O campo Equipamento é obrigatório!");
    } else {
      setRequireEquipment(false);
      setMessageRequireEquipment("");
    }

    if (!descricao) {
      notificationDynamic(
        "warning",
        "Atenção",
        "O campo Descrição é obrigatório!"
      );
      setRequireDescription(true);
      setMessageRequireDescription("O campo Descrição é obrigatório!");
    } else {
      setRequireDescription(false);
      setMessageRequireDescription("");
    }

    if (!prazo) {
      notificationDynamic("warning", "Atenção", "O campo Prazo é obrigatório!");
      setRequirePeriod(true);
      setMessageRequirePeriod("o campo Prazo é obrigatório!");
    } else {
      setRequirePeriod(false);
      setMessageRequirePeriod("");
    }

    if (!tipo) {
      notificationDynamic(
        "warning",
        "Atenção",
        "O campo Tipo de Ordem é obrigatório!"
      );
      setRequireTypeOrder(true);
      setMessageRequireTypeOrder("o campo Tipo da Ordem é obrigatório!");
    } else {
      setRequireTypeOrder(false);
      setMessageRequireTypeOrder("");
    }
  };

  const notifyGeneral = (event) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <h2
            style={{ color: "#fff", marginBottom: "0" }}
            className="alert-title"
            data-notify="title"
          >
            {" "}
            Concluído!
            <br />
          </h2>
          <span data-notify="message">A ordem foi criada com sucesso!</span>
        </div>
      ),
      type: event,
      // icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    notification.current.notificationAlert(options);
  };

  const notificationDynamic = (event, title, description) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <h2
            style={{ color: "#fff", marginBottom: "0" }}
            className="alert-title"
            data-notify="title"
          >
            {" "}
            {title}
            <br />
          </h2>
          <span data-notify="message">{description}</span>
        </div>
      ),
      type: event,
      // icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    notification.current.notificationAlert(options);
  };

  const [equipment, setEquipment] = useState([]);
  const [users, setUsers] = useState([]);
  const [areas, setAreas] = useState([]);
  const [areaSelected, setAreaSelected] = useState("");
  const [sector, setSector] = useState("");
  const [sectors, setSectors] = useState([]);

  useEffect(() => {
    GetAllSectorsByEnterprise.getSectors({
      enterprise: enterpriseName,
    })
      .then((res) => {
        setSectors(res.data.equipmentArea);
      })
      .catch((error) => {
        console.error(error);
      });
    EquipmentService.getEquipment({
      empresa: enterpriseName,
      token: token,
      hash: hash,
      idUser: idUserStorage,
    })
      .then((res) => {
        if (res.data.data) {
          const sorted = res.data.data?.sort((compareA, compareB) => {
            if (compareA.denominacao > compareB.denominacao) {
              return 1;
            }
            if (compareA.denominacao < compareB.denominacao) {
              return -1;
            }
            return 0;
          });
          setEquipment(sorted);
        }
        localStorage.removeItem("TOKEN_KEY");
        localStorage.setItem("TOKEN_KEY", res.data.token);
      })
      .catch((error) => {
        if (error?.response?.data?.auth) {
        } else {
          // redirectLogin();
        }
      });

    GetAllAreasByEnterprise.getAllAreas({
      enterprise: enterpriseName,
    })
      .then((res) => {
        setAreas(res.data.equipmentArea);
      })
      .catch((error) => {
        console.error(error);
      });

    UserService.getUsers({
      empresa: enterpriseName,
      token: token,
      hash: hash,
      idUser: idUserStorage,
    })
      .then((res) => setUsers(res.data))
      .catch((error) => {
        if (error?.response?.data?.auth) {
        } else {
          // redirectLogin();
        }
      });
  }, [enterpriseName, token, hash, idUserStorage]);

  return (
    <div>
      {userType !== "Executante" && userType !== "Requisitante" ? (
        <>
          <Button
            color="success"
            size="sm"
            className="position-absolute top-3 right-4"
            onClick={toggle}
          >
            {" "}
            Nova Ordem{" "}
          </Button>
        </>
      ) : null}
      <NotificationAlert ref={notification} />
      <Modal isOpen={modal} toggle={toggle} size="lg" className="mr-0">
        <ModalHeader className="mb--4" tag="h2" toggle={toggle}>
          Nova Ordem{" "}
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                onChange={(e, v) => {
                  if (v) {
                    setAreaSelected(v);
                    filterEquipments(v, sector);
                  } else {
                    filterEquipments(null, sector);
                    setAreaSelected("");
                  }
                }}
                value={areaSelected}
                options={areas}
                renderInput={(params) => (
                  <TextField
                    value={areaSelected}
                    {...params}
                    variant="outlined"
                    label="Filtre seus equipamentos pela área"
                    margin="normal"
                    placeholder="Área 1"
                  />
                )}
              />
            </div>

            <div className="form-group">
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                onChange={(e, v) => {
                  if (v) {
                    setSector(v);
                    filterEquipments(areaSelected, v);
                  } else {
                    filterEquipments(areaSelected, null);
                    setSector("");
                  }
                }}
                value={sector}
                options={sectors}
                renderInput={(params) => (
                  <TextField
                    value={sector}
                    {...params}
                    variant="outlined"
                    placeholder="Ex.: Utilidades"
                    label="Filtrar por Setor Instalado"
                    margin="normal"
                  />
                )}
              />
            </div>
            <div className="form-group">
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                onChange={(e, v) => {
                  if (v) {
                    setEquipmentArea(v?.area);
                    setEquipamento(v?.codigo);
                    setDenominacao(v?.denominacao);
                    setSetor(v?.setor_instalado);
                    setNameEquipment({
                      codigo: v?.codigo,
                      denominacao: v?.denominacao,
                    });
                  } else {
                    setEquipamento(null);
                    setDenominacao(null);
                    setSetor(null);
                    setNameEquipment({ codigo: null, denominacao: null });
                  }
                }}
                value={nameEquipment}
                options={equipment}
                getOptionLabel={(option) => {
                  if (option.codigo) {
                    return option.codigo + " - " + option.denominacao;
                  }
                  if (option.denominacao) {
                    return option.codigo + " - " + option.denominacao;
                  } else {
                    // return option.codigo + option.denominacao;
                    return "";
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    value={nameEquipment}
                    {...params}
                    error={requireEquipment}
                    helperText={messageRequireEquipment}
                    variant="outlined"
                    label="Equipamento"
                    margin="normal"
                  />
                )}
              />
            </div>

            <div className="form-group">
              <TextField
                error={requireDescription}
                helperText={messageRequireDescription}
                id="outlined-basic"
                label="Descrição"
                variant="outlined"
                rows={4}
                //name="descrição"
                {...binddescricao}
                multiline="true"
                style={{ width: "100%" }}
              />
            </div>
            <Row>
              <Col>
                <DatePicker
                  title={"Prazo"}
                  setDate={setPrazo}
                  date={prazo}
                  placeholder="Prazo"
                />
              </Col>
              <Col>
                <div className="form-group">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={typeOrder}
                    // {...bindlevel}
                    onChange={(e, v) => {
                      setTipo(v);
                    }}
                    value={tipo}
                    renderInput={(params) => (
                      <TextField
                        // error={requireTypeOrder}
                        variant="outlined"
                        {...params}
                        label="Tipo de Ordem"
                        // helperText={messageRequireTypeOrder}
                      />
                    )}
                  />
                </div>
              </Col>
            </Row>

            <div className="form-group">
              <label> Prioridade: </label> <br></br>
              <ButtonGroup className="mb-4">
                <Button
                  color="default"
                  className=""
                  outline
                  onClick={() => {
                    setRSelected("Nenhuma");
                    setColor("#7BF7CD");
                  }}
                  active={rSelected === "Nenhuma"}
                >
                  Nenhuma
                </Button>
                <Button
                  color="default"
                  outline
                  onClick={() => {
                    setRSelected("Baixa");
                    setColor("#0091F7");
                  }}
                  active={rSelected === "Baixa"}
                >
                  {" "}
                  &nbsp; &nbsp; Baixa &nbsp; &nbsp;{" "}
                </Button>
                <Button
                  color="default"
                  outline
                  onClick={() => {
                    setRSelected("Média");
                    setColor("#ffc400");
                  }}
                  active={rSelected === "Média"}
                >
                  {" "}
                  &nbsp; &nbsp; Média &nbsp; &nbsp;
                </Button>
                <Button
                  color="default"
                  outline
                  onClick={() => {
                    setRSelected("Alta");
                    setColor("#dc4664");
                  }}
                  active={rSelected === "Alta"}
                >
                  {" "}
                  &nbsp; &nbsp; Alta &nbsp; &nbsp;{" "}
                </Button>
              </ButtonGroup>
            </div>

            <FormControl style={{ display: "block" }} component="fieldset">
              <FormLabel component="legend">O equipamento parou?</FormLabel>
              <Row>
                <Col>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="top"
                  >
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="Sim"
                      checked={maquina === "Sim"}
                      onChange={(e, v) => {
                        if (v) {
                          setMaquina("Sim");
                        }
                      }}
                    />
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="Não"
                      checked={maquina === "Não"}
                      onChange={(e, v) => {
                        if (v) {
                          setMaquina("Não");
                        }
                      }}
                    />
                  </RadioGroup>
                </Col>
                <Col>
                  {maquina === "Sim" && (
                    <DateTimePicker
                      placeholder="Quando parou?"
                      date={machineStop}
                      setDate={setMachineStop}
                    />
                  )}
                </Col>
              </Row>
            </FormControl>

            <div className="form-group">
              <label> Foto: </label>
              <input
                type="file"
                placeholder="Equipamento"
                name="file"
                className="form-control"
                accept="image/png, image/jpeg"
                onChange={(e) => setImage(e.target.files)}
              />
            </div>
            <ModalFooter>
              <Button type="submit" color="success">
                Criar
              </Button>{" "}
              <Button
                color="secondary"
                onClick={() => {
                  toggle();
                  setTipo(null);
                  setRSelected("Nenhuma");
                  setEquipamento(null);
                  setDescricao("");
                  setPrazo(new Date());
                  setImage(null);
                  setNameEquipment({ codigo: null, denominacao: null });
                }}
              >
                Cancelar
              </Button>
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default CreateOrder;
