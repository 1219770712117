import { useState, useEffect, useContext, createRef, memo } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import { TextField, Autocomplete } from '@mui/material'
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { EnterpriseNameContext } from "../../../context/EnterpriseContext";
import axios from "axios";
import ToolsService from "../service";
import UserService from "../../Users/UserService";
import SignatureCanvas from "react-signature-canvas";
import ReportToolService from "../service/ReportToolService";
import NotificationAlert from "react-notification-alert";

const signatures = createRef();
const notification = createRef();
const notificationOut = createRef();

const OutputTool = (props) => {
  const { enterpriseName } = useContext(EnterpriseNameContext);
  const [modal, setModal] = useState(false);
  // const [image, setImage] = useState();
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [typeTool, setTypeTool] = useState("");
  const [comments, setComments] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [tools, setTools] = useState([]);
  const [users, setUsers] = useState([]);
  const [signature, setSignature] = useState();
  const [deliveryUser, setDeliveryUser] = useState("");
  const [reserveUser, setReserveUser] = useState("");
  const [tool, setTool] = useState();
  const [toolmaker, setToolmaker] = useState([]);
  const [toolId, setToolId] = useState("");
  const [userReceived, setUserReceived] = useState();
  const [userDelivery, setUserDelivery] = useState();

  const user = async () => {
    const array = [];
    await UserService.getUsers({
      empresa: enterpriseName,
      level: "Ferramenteiro",
    }).then((response) => {
      if (response.data.length > 0) {
        array.push(response.data[0]);
      }
    });
    await UserService.getUsers({
      empresa: enterpriseName,
      level: "Amoxarife e Ferramenteiro",
    }).then((response) => {
      if (response.data.length > 0) {
        array.push(response.data[0]);
      }
    });
    return array;
  };

  useEffect(() => {
    ToolsService.getTools({ empresa: enterpriseName }).then((response) => {
      setTools(response.data.data);
    });

    const result = user();
    if (result) {
      result.then((response) => {
        if (response) {
          console.log(response, "response");
          setToolmaker(response);
        }
      });
    }

    UserService.getUsers({ empresa: enterpriseName }).then((response) => {
      setUsers(response.data);
    });
  }, []);
  const toggle = () => {
    if (props.modalOut) {
      props.setModalOut(!props.modalOut);
    } else {
      setModal(!modal);
    }
  };
  const notifyCode = (event, title, content) => {
    let options = {
      place: "tc",
      message: (
        <div>
          <h2
            style={{ color: "#fff", marginBottom: "0" }}
            className="alert-title"
            data-notify="title"
          >
            {" "}
            {title ? title : "Atenção!"}
            <br />
          </h2>
          <span data-notify="message">
            {content ? content : "Este código já foi cadastrado!"}
          </span>
        </div>
      ),
      type: event,
      // icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    notification.current.notificationAlert(options);
  };
  const notifyCodeOut = (event, title, content) => {
    let options = {
      place: "tc",
      message: (
        <div>
          <h2
            style={{ color: "#fff", marginBottom: "0" }}
            className="alert-title"
            data-notify="title"
          >
            {" "}
            {title ? title : "Atenção!"}
            <br />
          </h2>
          <span data-notify="message">
            {content ? content : "Este código já foi cadastrado!"}
          </span>
        </div>
      ),
      type: event,
      // icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    props.notification.current.notificationAlert(options);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let url;
    let filename;
    const formData = new FormData();
    // ReportToolService.getReserveTools({empresa: enterpriseName, typeTool: typeTool, descriptionTool: tool}).then((response)=>{
    //     console.log(response.data);
    // })
    if (signature) {
      ToolsService.getToolById(toolId).then((res) => {
        const result = res.data.data;
        if (result.quantidade >= quantity) {
          result.quantidade = result.quantidade - quantity;
          ToolsService.updateTool(result, toolId).then(async (e) => {
            formData.append("image", signature);
            // props.tools()
            await axios({
              method: "post",
              url: "https://api.darwinx.com.br/images/signatures",
              data: formData,
              params: { empresa: enterpriseName },
              headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
              url = response.data.url;
              filename = response.data.name;
              if (url) {
                const data = {
                  quantityReverse: quantity,
                  reserveOwner1: userReceived,
                  idTool: toolId,
                  deliveryOwner1: userDelivery,
                  empresa: enterpriseName,
                  dateInitial: new Date(),
                  typeTool: typeTool,
                  descriptionTool: tool,
                  comments: comments,
                  status: "Saída",
                  photograph1: url,
                  namePhoto1: filename,
                };
                console.log(data);
                ReportToolService.createReserveTool(data).then((sender) => {
                  console.log("criou");
                  ToolsService.getTools({ empresa: enterpriseName }).then(
                    (res) => {
                      props.setTools(res.data.data);
                      toggle();
                      setQuantity("");
                      setComments("");
                      setTypeTool("");
                      // setImage(null)
                      setTool("");
                      setToolId("");
                      setToolmaker([]);
                      setPrice(0);
                      setDescription("");
                      ToolsService.getTools({ empresa: enterpriseName }).then(
                        (event) => {
                          props.setTools(event.data.data);
                        }
                      );
                    }
                  );
                  notifyCodeOut(
                    "success",
                    "Saída com sucesso",
                    "Saída de ferramenta realizada com sucesso!"
                  );
                });
              }
            });
          });
        } else {
          notifyCode("danger", "Erro", "Não existem ferramentas suficientes!");
        }
      });
    } else {
      notifyCode(
        "warning",
        "Atenção!",
        "A assinatura é obrigatória para a saída de ferramenta!"
      );
    }

    // formData.append("image", signature[0])
    //     axios({
    //       method: "post",
    //       url: "https://api.darwinx.com.br/images",
    //       data: formData,
    //       params: {empresa: enterpriseName},
    //       headers: { "Content-Type": "multipart/form-data" },
    //     }).then((response)=>{
    //         console.log(response.data);
    //       url = response.data.url
    //       filename = response.data.name
    //       if(url){
    //         const data = {
    //             quantityReverse: quantity,
    //             reserveOwner1: reserveUser,
    //             deliveryOwner1: deliveryUser,
    //             empresa: enterpriseName,
    //             dateInitial: new Date(),
    //             typeTool: typeTool,
    //             descriptionTool: description,
    //             comments:comments,
    //             status: "Saída",
    //             photograph1: url,
    //             namePhoto1: filename,
    //             // namePhoto2:
    //         }
    //         ToolsService.createTool(data).then((sender)=>{
    //             console.log("criou")
    //             ToolsService.getTools({empresa: enterpriseName}).then((res)=>{
    //                 props.setTools(res.data.data);
    //                 setModal(false)
    //                 setQuantity('');
    //                 setComments('');
    //                 setTypeTool('');
    //                 // setImage(null)
    //                 setPrice(0)
    //                 setDescription('')
    //             })
    //             console.log(sender.data.data);
    //         })
    //       }
    //     })
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const clearSignatures = () => {
    if (signatures.current) {
      return signatures.current.clear();
    }
  };

  const formatIntoPng = () => {
    if (signatures.current) {
      const dataURL = signatures.current.toDataURL();
      return dataURL;
    }
  };
  return (
    <>
      {props.visible ? (
        // <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button color="success" size="sm" onClick={toggle}>
          {" "}
          Saída de ferramenta{" "}
        </Button>
      ) : // </div>
      null}
      <NotificationAlert ref={notificationOut} />

      <Modal
        isOpen={props.modalOut ? props.modalOut : modal}
        toggle={toggle}
        // className="mr-0 mt--1 mb--1 mt-5"
        style={{ maxWidth: "1000px" }}
      >
        <NotificationAlert ref={notification} />
        <ModalHeader className="mt-3" toggle={toggle} tag="h2">
          Saída de ferramenta
        </ModalHeader>
        <ModalBody className="mb-1 mt--2 mb-2">
          <form onSubmit={handleSubmit}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Autocomplete
                style={{ flex: 0.5 }}
                // id="free-solo-demo"
                freeSolo
                options={props.tools ? props.tools : []}
                getOptionLabel={(option) => {
                  return option.tipoFerramenta;
                  //   if(option.codigo){
                  //     return option.codigo + " - " + option.denominacao
                  //   }
                  //   if(option.denominacao){
                  //     return option.codigo + " - " + option.denominacao
                  //   }else{
                  //     // return option.codigo + option.denominacao;
                  //     return ''
                  //   }
                }}
                // value={nameEquipment}

                onChange={(e, value) => {
                  if (value) {
                    setTypeTool(value.tipoFerramenta);
                    // setToolmaker(value.)
                    // EquipmentService.getEquipmentById(value._id, { token: token, hash: hash, idUser: idUserStorage}).then((res) => {
                    //   let equipments = res.data.data;
                    //   setArea(equipments.area);
                    //   setCodigo(equipments.codigo)
                    //   setNameEquipment({codigo: equipments.codigo, denominacao: equipments.denominacao  });
                    //   setDenominacao(equipments.denominacao);
                    //   setSetor(equipments.setor_instalado);
                    //   localStorage.removeItem("TOKEN_KEY")
                    //   localStorage.setItem("TOKEN_KEY", res.data.token);
                    // });
                  } else {
                    setTypeTool("");
                    // setArea(null);
                    // setDenominacao(null);
                    // setSetor(null);
                    // setNameEquipment(null)
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    // error={requireEquipment}
                    // helperText={messageRequireEquipment}
                    {...params}
                    // value={nameEquipment}
                    label="Tipo de ferramenta"
                    margin="normal"
                    variant="outlined"
                  />
                )}
              />
              <Autocomplete
                style={{ flex: 0.5 }}
                freeSolo
                options={props.tools ? props.tools : []}
                getOptionLabel={(option) => {
                  return option.descricao;
                }}
                onChange={(e, value) => {
                  if (value) {
                    setTool(value.descricao);
                    setToolId(value._id);
                  } else {
                    setTool("");
                    setToolId("");
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Ferramenta"
                    margin="normal"
                    variant="outlined"
                  />
                )}
              />
              {/* <div className="form-group">  */}
              {/* </div> */}
            </div>

            {/* <div className="form-group">
                        <TextField
                            id="outlined-basic"
                            placeholder="50 MHz a 200MHz, Display colorido 7 polegadas, Carregador 220V..."
                            label="Descrição"
                            variant="outlined"
                            rows={4}
                            name="descrição"
                            value={description}
                            onChange={(e)=>{
                                setDescription(e.target.value)
                            }}
                            multiline="true"
                            style={{ width: "100%" }}
                        />
                    </div> */}
            <div className="form-group">
              <TextField
                id="outlined-basic"
                label="Quantidade"
                margin="normal"
                //variant="outlined"
                // InputProps={{
                // startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                // }}
                placeholder="1"
                name="Quantidade"
                value={quantity}
                // error={requirePrice}
                // helperText={messageRequirePrice}
                onChange={(e) => {
                  setQuantity(e.target.value);
                }}
                style={{ width: "100%" }}
              />
            </div>
            {/* <div className="form-group">
                        <TextField
                            id="outlined-basic"
                            label="Valor"
                            margin="normal"
                            //variant="outlined"
                            InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                            placeholder="00,00"
                            name="preco"
                            value={price}
                            // error={requirePrice}
                            // helperText={messageRequirePrice}
                            onChange={(e)=>{
                                setPrice(e.target.value)
                            }}
                            style={{ width: '100%' }}
                        />
                    </div> */}
            <div style={{ display: "flex" }}>
              <Autocomplete
                style={{ flex: 0.5 }}
                // id="free-solo-demo"
                freeSolo
                options={users}
                getOptionLabel={(option) => {
                  return option.first_name;
                  //   if(option.codigo){
                  //     return option.codigo + " - " + option.denominacao
                  //   }
                  //   if(option.denominacao){
                  //     return option.codigo + " - " + option.denominacao
                  //   }else{
                  //     // return option.codigo + option.denominacao;
                  //     return ''
                  //   }
                }}
                // value={nameEquipment}

                onChange={(e, value) => {
                  if (value) {
                    setUserReceived(value.first_name);
                    // EquipmentService.getEquipmentById(value._id, { token: token, hash: hash, idUser: idUserStorage}).then((res) => {
                    //   let equipments = res.data.data;
                    //   setArea(equipments.area);
                    //   setCodigo(equipments.codigo)
                    //   setNameEquipment({codigo: equipments.codigo, denominacao: equipments.denominacao  });
                    //   setDenominacao(equipments.denominacao);
                    //   setSetor(equipments.setor_instalado);
                    //   localStorage.removeItem("TOKEN_KEY")
                    //   localStorage.setItem("TOKEN_KEY", res.data.token);
                    // });
                  } else {
                    setUserReceived("");
                    // setArea(null);
                    // setDenominacao(null);
                    // setSetor(null);
                    // setNameEquipment(null)
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    // error={requireEquipment}
                    // helperText={messageRequireEquipment}
                    {...params}
                    // value={nameEquipment}
                    label="Retirado por: "
                    margin="normal"
                    variant="outlined"
                  />
                )}
              />

              <Autocomplete
                style={{ flex: 0.5 }}
                // id="free-solo-demo"
                freeSolo
                options={toolmaker}
                getOptionLabel={(option) => {
                  return option.first_name;
                  //   if(option.codigo){
                  //     return option.codigo + " - " + option.denominacao
                  //   }
                  //   if(option.denominacao){
                  //     return option.codigo + " - " + option.denominacao
                  //   }else{
                  //     // return option.codigo + option.denominacao;
                  //     return ''
                  //   }
                }}
                // value={nameEquipment}

                onChange={(e, value) => {
                  if (value) {
                    setUserDelivery(value.first_name);
                    // EquipmentService.getEquipmentById(value._id, { token: token, hash: hash, idUser: idUserStorage}).then((res) => {
                    //   let equipments = res.data.data;
                    //   setArea(equipments.area);
                    //   setCodigo(equipments.codigo)
                    //   setNameEquipment({codigo: equipments.codigo, denominacao: equipments.denominacao  });
                    //   setDenominacao(equipments.denominacao);
                    //   setSetor(equipments.setor_instalado);
                    //   localStorage.removeItem("TOKEN_KEY")
                    //   localStorage.setItem("TOKEN_KEY", res.data.token);
                    // });
                  } else {
                    setUserDelivery("");
                    // setArea(null);
                    // setDenominacao(null);
                    // setSetor(null);
                    // setNameEquipment(null)
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    // error={requireEquipment}
                    // helperText={messageRequireEquipment}
                    {...params}
                    // value={nameEquipment}
                    label="Entregue por: "
                    margin="normal"
                    variant="outlined"
                  />
                )}
              />
            </div>

            <div className="form-group">
              <TextField
                style={{ width: "100%" }}
                id="outlined-basic"
                rows={2}
                label="Observações"
                placeholder="Observações pertinentes para sua empresa"
                variant="outlined"
                multiline="true"
                onChange={(e) => {
                  setComments(e.target.value);
                }}
                value={comments}
              />
            </div>

            <div className="form-group">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label> Assinatura: </label>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    onClick={() => {
                      clearSignatures();
                    }}
                  >
                    Limpar
                  </Button>
                </div>
              </div>
              <div>
                <SignatureCanvas
                  ref={signatures}
                  penColor="black"
                  canvasProps={{
                    width: 500,
                    height: 200,
                    className: "sigCanvas",
                  }}
                  onEnd={(e) => {
                    const result = formatIntoPng();
                    const file = dataURLtoFile(result, "image.png");
                    setSignature(file);
                  }}
                />
              </div>
              {/* <input
                        type="file"
                        placeholder="Equipamento"
                        name="file"
                        className="form-control"
                        accept="image/png, image/jpeg"
                        onChange={(e)=>{setImage(e.target.files)
                            // setNameImage()
                        console.log(e.target.files)}}
                        /> */}
            </div>

            <ModalFooter>
              <Button type="submit" color="success">
                Reservar
              </Button>{" "}
              <Button
                color="secondary"
                onClick={() => {
                  toggle();
                  setQuantity("");
                  setComments("");
                  setTypeTool("");
                  // setImage(null)
                  setTool("");
                  setToolId("");
                  setToolmaker([]);
                  setPrice(0);
                  setDescription("");
                  setSignature(null);
                }}
              >
                Cancelar
              </Button>
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};
export default memo(OutputTool);
