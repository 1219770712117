import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
  memo,
} from "react";
import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Autocomplete from "@material-ui/lab/Autocomplete";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import ptLocale from "date-fns/locale/pt-BR";

import CloseIcon from "@mui/icons-material/Close";

import InputAdornment from "@material-ui/core/InputAdornment";

import swal from "sweetalert";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateStrategy from "./CreateStrategy";
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";
import { Checkbox } from "@mui/material";

import PlanService from "./PlanService";
import UserService from "../Users/UserService";
import EquipmentService from "../Equipments/EquipmentService";
import SpareService from "../Spares/SpareService";

import { Link } from "react-router-dom";

import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import SearchIcon from "@material-ui/icons/Search";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
  useBlockLayout,
  useResizeColumns,
} from "react-table";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import "./ListStrategy.css";
import "./styles.css";

import Toggle from "react-toggle";
import Datagrid from "./components/Datagrid";
import Select from "../Select";

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  CardBody,
  Col,
  ModalFooter,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Badge,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Media,
} from "reactstrap";
// core components
import Header from "components/Headers/Headerfor.js";
import { useHistory } from "react-router-dom";
import { EnterpriseNameContext } from "context/EnterpriseContext";

import DateTimePicker from "@mui/lab/DateTimePicker";
import TextFieldMui from "@mui/material/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function ListStrategy(props) {
  const history = useHistory();

  const { enterpriseName } = useContext(EnterpriseNameContext);
  const [activeTab, setActiveTab] = useState("1");
  const [modalEdit, setModalEdit] = useState(false);
  const [token] = useState(() => {
    const tokenStoraged = localStorage.getItem("TOKEN_KEY");
    if (tokenStoraged) {
      return tokenStoraged;
    }
    return "";
  });
  const [hash] = useState(() => {
    const hashStoraged = localStorage.getItem("TOKEN_HASH");
    if (hashStoraged) {
      return hashStoraged;
    }
    return "";
  });
  const [idUserStorage] = useState(() => {
    const idUserStoraged = localStorage.getItem("TOKEN_ID_USER");
    if (idUserStoraged) {
      return idUserStoraged;
    }
    return "";
  });

  const classes = useStyles();

  const PurpleSwitch = withStyles({
    switchBase: {
      color: "#58a3fb",
      "&$checked": {
        color: "#58a3fb",
      },
      "&$checked + $track": {
        backgroundColor: "#58a3fb",
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const [plan, setPlan] = useState([]);

  const deletePlan = () => {
    PlanService.deletePlan(row_id).then((response) => {
      console.log("plan deleted:", response.data);
      PlanService.getPlan({ enterprise: enterpriseName }).then((plans) => {
        setPlan(plans.data.data);
      });
      toggleEdit();
    });
  };

  // const redirectLogin = () => {
  //   swal(
  //     "Hmm... vi que você não está logado. Clique em ok para fazer o login",
  //     "",
  //     "warning",
  //     {
  //       buttons: {
  //         // nope: {
  //         //   text: 'Me enganei!',
  //         //   value: 'nope',
  //         // },
  //         sure: {
  //           text: "Ok",
  //           value: "sure",
  //         },
  //       },
  //     }
  //   ).then((value) => {
  //     switch (value) {
  //       case "sure":
  //         swal("Operação realizada com sucesso!", "", "success").then((val) => {
  //           // history.pushState
  //           // return <Redirect to="/auth/login"/>
  //           history.push("/auth/login");
  //         });
  //         break;
  //       case "nope":
  //         swal("Operação cancelada", "", "success");
  //         break;
  //       default:
  //     }
  //   });
  // };
  useEffect(() => {
    PlanService.getPlan({
      enterprise: enterpriseName,
      // token: token,
      // hash: hash,
      // idUser: idUserStorage,
    })
      .then((response) => {
        console.log("plan:", response.data);
        setPlan(response.data.data);
        // localStorage.removeItem("TOKEN_KEY");
        // localStorage.setItem("TOKEN_KEY", response.data.token);
      })
      .catch((error) => {});
    SpareService.getSpares({ empresa: enterpriseName }).then((spare) => {
      console.log("spare:", spare.data.data);
      setSpares(spare.data.data);
    });
    UserService.getUsers({
      empresa: enterpriseName,
      level: "Executante",
    }).then((response) => {
      console.log("usuários", response.data);
      setPerformers(response.data);
    });
    EquipmentService.getEquipment({ empresa: enterpriseName }).then(
      (equipment) => {
        setEquipments(equipment.data.data);
      }
    );
  }, [enterpriseName, hash, token, idUserStorage]);

  const columnHeader = [
    {
      Header: "Código do plano",
      Footer: "Código do plano",
      accessor: "name",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Peridicidade",
      Footer: "Peridicidade",
      accessor: "periodicity",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Conclusão da última",
      Footer: "Última ordem",
      accessor: (originalRow, rowIndex) => {
        if (originalRow.lastOrderCompleted) {
          return moment(originalRow.lastOrderCompleted).format("DD/MM/YYYY");
        } else {
          return " - ";
        }
      },
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Prazo da próxima",
      Footer: "Próxima ordem",
      accessor: (originalRow, rowIndex) => {
        if (originalRow.deadlineCurrentOrder) {
          return moment(originalRow.deadlineCurrentOrder).format("DD/MM/YYYY");
        } else {
          return " - ";
        }
      },
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Responsável",
      Footer: "Responsável",
      accessor: "responsibleName",
      sticky: "left",
    },
    {
      Header: "Equipamento",
      Footer: "Equipamento",
      accessor: (originalRow, rowIndex) => {
        if (originalRow.hierarchyType === "Equipamento") {
          return originalRow.hierarchy;
        } else {
          return " - ";
        }
      },
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Setor",
      Footer: "Setor",
      accessor: (originalRow, rowIndex) => {
        if (originalRow.hierarchyType === "Setor") {
          return originalRow.hierarchy;
        } else {
          return " - ";
        }
      },
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Tipo",
      Footer: "Tipo",
      accessor: "maintenanceType",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: "status",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Editar plano",
      Footer: "",
      accessor: "asdgagas",
      // disableFilters: true,
      sticky: "left",
    },
  ];

  const columns = useMemo(() => columnHeader, []);
  const data = useMemo(() => plan, [plan]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    allColumns,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
    // useBlockLayout,
    // useResizeColumns
  );
  console.log(page);
  // const [value, setValue] = useState(state.globalFilter)
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value);
  });
  const [value, setValue] = useState(state.globalFilter);
  const [valueTrello, setValueTrello] = useState("one");

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [descriptionTotal, setDescriptionTotal] = useState([]);
  const [taskItem, setTaskItem] = useState([]);

  const [row_id, setRow_id] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [area, setArea] = useState("");
  const [sector, setSector] = useState("");

  let [count, setCount] = useState(0);
  const [spares, setSpares] = useState([]);
  const [name, setName] = useState("");
  const [periodicity, setPeriodicity] = useState();
  const [periodicityType, setPeriodicityType] = useState("Tempo");
  const [time, setTime] = useState(0);
  const [description, setDescription] = useState("");
  const [maintenanceType, setMaintenanceType] = useState("");
  const [hierarchy, setHierarchy] = useState("");
  const [equipmentId, setEquipmentId] = useState("");
  const [hierarchyType, setHierarchyType] = useState("Equipamento");
  const [periodicidadeColuna] = useState([
    "Diário",
    "Semanal",
    "Quinzenal",
    "Mensal",
    "Bimestral",
    "Trimestral",
    "Semestral",
    "Anual",
    "Bianual",
  ]);
  const [hierarchyInput, setHierarchyInput] = useState(
    hierarchyType === "Equipamento"
      ? { denominacao: "", codigo: "" }
      : { setor_instalado: "" }
  );

  const [status, setStatus] = useState(false);
  const [stateMaterial, setStateMaterial] = useState([]);
  const [material, setMaterial] = useState("");
  const [partNumber, setPartNumber] = useState("");
  const [quantity, setQuantity] = useState("");
  const [performers, setPerformers] = useState([]);
  const [responsibleEmail, setResponsibleEmail] = useState("");
  const [responsibleName, setResponsibleName] = useState("");
  const [username, setUsername] = useState({ first_name: "" });

  const [equipments, setEquipments] = useState([]);

  const [materialSelected, setMaterialSelected] = useState({});

  const [sourceEdit, setSourceEdit] = useState({});

  const [equipmentsSelect, setEquipmentsSelect] = useState([]);

  const CheckboxUI = memo(({ task, label, id }) => {
    const [text, setText] = useState(label);
    const onChange = (e) => {
      descriptionTotal[id].description = e.target.value;
      setText(e.target.value);
    };

    return (
      <Row style={{ marginTop: "15px" }} key={id}>
        <Checkbox checked={false} onChange={() => {}} />
        <TextField
          style={{ width: "90%" }}
          placeholder="Digite aqui sua tarefa"
          onChange={onChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    console.log("task:", task);
                    const filter = taskItem.filter((tasks) => {
                      console.log("verify: ", tasks.id === task.id);
                      console.log(tasks.id);
                      if (tasks.id === task.id) {
                        return false;
                      }
                      return true;
                    });
                    console.log("filter: ", filter);
                    setTaskItem(filter);
                    const filtered = descriptionTotal.filter((description) => {
                      if (description.id === task.id) {
                        return false;
                      }
                      return true;
                    });

                    console.log("filtered: ", filtered);
                    setDescriptionTotal(filtered);
                    setCount((count = count - 1));
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={
            text
              ? text
              : descriptionTotal[id]?.description
              ? descriptionTotal[id]?.description
              : ""
          }
        />
      </Row>
    );
  });

  const Task = memo((props) => {
    return (
      <>
        <div
          style={{ width: "100%", marginLeft: "10px", marginTop: "25px" }}
          className="form-group"
        >
          <h4> Lista de tarefas: </h4>
          {taskItem.map((task, index) => {
            return <CheckboxUI task={task} id={index} key={index} />;
          })}
          {taskItem.length === 0 && (
            <p style={{ textAlign: "center" }}>Nenhuma tarefa adicionada!</p>
          )}
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            color="primary"
            onClick={() => {
              setTaskItem([
                ...taskItem,
                {
                  description: "",
                  id: count,
                },
              ]);
              setDescriptionTotal([
                ...descriptionTotal,
                {
                  description: "",
                  id: count,
                  state: false,
                },
              ]);
              setCount((count = count + 1));
            }}
          >
            Nova tarefa
          </Button>
        </div>
      </>
    );
  });

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const source = {
      name,
      periodicity,
      periodicityType,
      time,
      area,
      status,
      sector,
      description,
      maintenanceType,
      hierarchy,
      hierarchyType,
      enterprise: enterpriseName,
      materials: stateMaterial,
      start,
      end,
      taskList: descriptionTotal,
      responsibleEmail,
      responsibleName,
      equipmentId,
    };

    PlanService.updatePlan(source, row_id).then((response) => {
      toggleEdit();
      PlanService.getPlan({ enterprise: enterpriseName }).then((res) => {
        setPlan(res.data.data);
      });
    });
  };

  const adapterEquipmentSource = useCallback(() => {
    return equipments.map((equipment) => {
      return {
        value: equipment?._id,
        label: equipment?.codigo + " - " + equipment?.denominacao,
      };
    });
  }, [equipments]);

  useEffect(() => {
    setEquipmentsOptions(adapterEquipmentSource());
  }, [equipments]);

  const [equipmentsOptions, setEquipmentsOptions] = useState([]);

  function linhaClicada(source) {
    const row = source.data._id;
    toggleEdit();
    setRow_id(row);
    PlanService.getPlanById(row, {
      token: token,
      hash: hash,
      idUser: idUserStorage,
    }).then((res) => {
      const content = res.data.data;
      console.log(content, "content");
      setEquipmentsSelect(content.equipments);
      setStatus(content.status);
      setSourceEdit(content);
      setArea(content?.area);
      setSector(content?.sector);
      setName(content?.name);
      setDescription(content?.description);
      setResponsibleEmail(content?.responsibleEmail);
      setResponsibleName(content?.responsibleName);
      setStateMaterial(content?.materials);
      setPeriodicity(content?.periodicity);
      setStart(content?.start);
      setEnd(content?.end);
      setPeriodicityType(content?.periodicityType);
      setTaskItem(content?.taskList);
      setDescriptionTotal(content?.taskList);
      setHierarchy(content?.hierarchy);
      if (content?.equipmentId) {
        EquipmentService.getEquipmentById(content?.equipmentId).then(
          (response) => {
            setEquipmentsSelect([
              {
                value: response.data?.data?._id,
                label:
                  response.data?.data?.codigo +
                  " - " +
                  response.data?.data?.denominacao,
              },
            ]);
          }
        );
      }
      setHierarchyType(content?.hierarchyType);
      setMaintenanceType(content?.maintenanceType);
      setUsername({ first_name: content?.responsibleName });
      setTime(content?.time);
      setCount(content?.taskList.length);
    });
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <CreateStrategy plan={plan} setPlan={setPlan} />
                <Link to="/admin/timeline">
                  <Button
                    color="success"
                    size="sm"
                    className="position-absolute top-3 right-5"
                  >
                    Calendário
                  </Button>
                </Link>
              </CardHeader>
              <CardBody>
                <Datagrid dataSource={plan} lineClicked={linhaClicada} />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader tag="h3" toggle={toggle} className="mb--4">
          Detalhes
        </ModalHeader>
        <ModalBody className="mb-2">
          <h3>Ordem Preventiva - PDM - PV-01 </h3>
          <label>
            Observar desgaste excessivo nos componentes do acoplamento.
          </label>
          <h1>
            <Badge color="success">ABERTA</Badge>{" "}
          </h1>
          <label>
            {" "}
            <h3> Data a ser executada: </h3>
          </label>{" "}
          <label>02/03/2021 08:13 a.m</label>
          <br />
          <label>
            {" "}
            <h3> Equipamento: </h3>
          </label>{" "}
          <label>Acoplamento</label>
          <br />
          <label>
            {" "}
            <h3> Plano: </h3>
          </label>{" "}
          <label>Preventiva</label>
          {/* <DropzoneArea
  onChange={(files) => console.log('Files:', files)}
/> */}
          <Row>
            <Col className="mb-5 mb-xl-0 mt-3" xl="9">
              <Button color="success">Atualizar</Button>{" "}
              <Button color="secondary" onClick={toggle}>
                Cancelar
              </Button>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </Modal>

      <Modal
        isOpen={modalEdit}
        toggle={toggleEdit}
        size="lg"
        style={{ maxWidth: "1300px" }}
      >
        <ModalHeader tag="h3" toggle={toggleEdit} className="mb--4">
          Editar plano
        </ModalHeader>
        <ModalBody className="mb-2">
          <div className={classes.root}>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <TextField
                  id="outlined-basic"
                  label="Código do plano"
                  style={{ width: "100%" }}
                  variant="outlined"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  placeholder="Ex.: PMP-01"
                />
              </div>
              <div className="form-group">
                {/* <h4>Início do plano</h4> */}
                <LocalizationProvider
                  locale={ptLocale}
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    label="Início do plano"
                    disabled
                    value={new Date(sourceEdit.startDate)}
                    onChange={(newValue) => {
                      setInitialPlan(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        style={{ width: "100%" }}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="form-group">
                <TextField
                  id="outlined-basic"
                  label="Área do plano"
                  style={{ width: "100%" }}
                  variant="outlined"
                  value={area}
                  onChange={(e) => {
                    setArea(e.target.value);
                  }}
                  placeholder="Ex.: PMP-01"
                />
              </div>
              <div className="form-group">
                <TextField
                  id="outlined-basic"
                  label="Setor instalado"
                  style={{ width: "100%" }}
                  variant="outlined"
                  value={sector}
                  onChange={(e) => {
                    setSector(e.target.value);
                  }}
                  placeholder="Ex.: PMP-01"
                />
              </div>
              <div>
                <div>
                  <h4>Status do plano</h4>
                  <Toggle
                    checked={status}
                    onChange={() => setStatus(!status)}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    marginTop: "-10px",
                    alignItems: "center",
                  }}
                >
                  <h4>Periodicidade</h4>
                  <div className="mt-3">
                    <input
                      onClick={() => {
                        setPeriodicityType("Tempo");
                      }}
                      checked={periodicityType === "Tempo"}
                      type="radio"
                      value="Tempo"
                      name="Tempo"
                    />{" "}
                    Tempo
                    <input
                      onClick={() => {
                        setPeriodicityType("Horímetro");
                      }}
                      type="radio"
                      value="Horímetro"
                      name="Horímetro"
                      checked={periodicityType === "Horímetro"}
                      className="ml-4 mb-4"
                    />{" "}
                    Horimetro
                  </div>
                </div>
                <div>
                  <Autocomplete
                    id="outlined-basic"
                    label="Tipo"
                    variant="outlined"
                    freeSolo
                    value={periodicity}
                    style={{ marginTop: "-15px", width: "100%" }}
                    onChange={(e, v) => {
                      setPeriodicity(v);
                    }}
                    options={periodicidadeColuna}
                    renderInput={(params) => (
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        {...params}
                        placeholder="Ex.: Diário"
                        label={
                          periodicityType === "Horímetro"
                            ? "Em quanto tempo quer ser lembrado?"
                            : "Periodicidade"
                        }
                        margin="normal"
                        //variant="outlined"
                        onChange={(e) => {
                          console.log(e.target.value);
                        }}
                      />
                    )}
                  />
                  {periodicityType === "Horímetro" && (
                    <TextField
                      type="number"
                      id="outlined-basic"
                      label="Horímetro"
                      value={time}
                      style={{
                        width: "100%",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                      onChange={(e) => {
                        setTime(e.target.value);
                      }}
                      variant="outlined"
                      placeholder="Ex.: 1000 (horas)"
                    />
                  )}
                </div>
              </div>
              <div>
                <h4>Detalhes da Ordem</h4>
                <TextField
                  id="outlined-basic"
                  label="Descrição"
                  style={{ width: "100%", marginTop: "10px" }}
                  variant="outlined"
                  placeholder="Ex.: Plano semanal"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
                <Autocomplete
                  id="outlined-basic"
                  label="Tipo"
                  variant="outlined"
                  freeSolo
                  style={{ width: "100%", marginTop: "0px" }}
                  options={["Inspeção", "Preditiva", "Preventiva"]}
                  value={maintenanceType}
                  onChange={(e, v) => {
                    setMaintenanceType(v);
                  }}
                  renderInput={(params) => (
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      {...params}
                      label="Tipo de manutenção"
                      placeholder="Ex.: Inspeção"
                      margin="normal"
                      onChange={(e) => {
                        console.log(e.target.value);
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <Select
                  isMulti
                  label={"Equipamentos"}
                  placeholder="Selecione os equipamentos"
                  value={equipmentsSelect}
                  options={equipmentsOptions}
                  onChange={(e) => {
                    setEquipmentsSelect(e);
                  }}
                />
                {/* <div>
                  <Autocomplete
                    id="outlined-basic"
                    label="Tipo"
                    variant="outlined"
                    freeSolo
                    value={hierarchyInput}
                    style={{ marginTop: "-15px", width: "100%" }}
                    onChange={(e, v) => {
                      if (v) {
                        if (hierarchyType === "Equipamento") {
                          setHierarchy(v.denominacao);
                          setEquipmentId(v._id);
                        } else if (hierarchyType === "Setor") {
                          setHierarchy(v.setor_instalado);
                          setEquipmentId(v._id);
                        }
                      }
                    }}
                    options={equipments}
                    getOptionLabel={(option) => {
                      if (hierarchyType === "Setor" && option.setor_instalado) {
                        return option.setor_instalado;
                      }
                      if (
                        hierarchyType === "Equipamento" &&
                        option.denominacao
                      ) {
                        return option.codigo + " - " + option.denominacao;
                      } else {
                        return "";
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        {...params}
                        label={
                          hierarchyType === "Setor" ? "Setor" : "Equipamento"
                        }
                        placeholder={
                          hierarchyType === "Setor"
                            ? "Ex.: Utilidades"
                            : "Ex.: Bomba Centrífuga"
                        }
                        margin="normal"
                        //variant="outlined"
                        onChange={(e) => {
                          console.log(e.target.value);
                        }}
                      />
                    )}
                  />
                </div> */}
              </div>
              {/* <div style={{ marginTop: "10px" }}>
                <h4>Material</h4>
                <Autocomplete
                  id="outlined-basic"
                  label="Tipo"
                  key="alsfikasf"
                  variant="outlined"
                  freeSolo
                  value={materialSelected}
                  onChange={(e, v) => {
                    if (v) {
                      setMaterial(v.produto);
                      setPartNumber(v.part_number);
                      setMaterialSelected(v);
                    }
                  }}
                  getOptionLabel={(option) => {
                    if (option.produto) {
                      return option.produto + " - " + option.part_number;
                    }
                    if (option.part_number) {
                      return option.produto + " - " + option.part_number;
                    } else {
                      return "";
                    }
                  }}
                  style={{ width: "100%", marginTop: "-10px" }}
                  options={spares}
                  renderInput={(params) => (
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      {...params}
                      label="Material"
                      margin="normal"
                      onChange={(e) => {
                        console.log(e.target.value);
                      }}
                    />
                  )}
                />
                <TextField
                  type="number"
                  id="outlined-basic"
                  label="Quantidade"
                  style={{ width: "100%", marginTop: "10px" }}
                  variant="outlined"
                  placeholder="Ex.: 10 (UND)"
                  value={quantity}
                  onChange={(e) => {
                    setQuantity(e.target.value);
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                    marginBottom: "10px",
                  }}
                >
                  <Button
                    onClick={() => {
                      if (material && quantity && partNumber) {
                        setStateMaterial([
                          ...stateMaterial,
                          { material, quantity, partNumber },
                        ]);
                        setQuantity("");
                        setMaterial("");
                        setPartNumber("");
                        setMaterialSelected({});
                      }
                    }}
                  >
                    Adicionar material
                  </Button>
                </div>
                {stateMaterial.length > 0 && (
                  <>
                    <h4>Lista de materiais</h4>
                    <Card className="shadow-lg">
                      <CardBody>
                        <Table
                          className="align-items-center table-flush"
                          responsive
                          style={{ tableLayout: "fixed" }}
                        >
                          <thead className="thead-light">
                            <tr>
                              <th className="caractersTableSize" scope="col">
                                Material
                              </th>
                              <th className="caractersTableSize" scope="col">
                                Part Number
                              </th>
                              <th className="caractersTableSize" scope="col">
                                Quantidade
                              </th>
                              <th className="caractersTableSize" scope="col" />
                            </tr>
                          </thead>
                          <tbody>
                            {stateMaterial &&
                              stateMaterial.map((row, index) => (
                                <tr key={index}>
                                  <th
                                    className="caractersTableSize"
                                    scope="row"
                                  >
                                    <Media className="align-items-center">
                                      <Media>
                                        <span className="mb-0 text-sm">
                                          {row.material}
                                        </span>
                                      </Media>
                                    </Media>
                                  </th>

                                  <th
                                    className="caractersTableSize"
                                    scope="row"
                                  >
                                    <Media className="align-items-center">
                                      <Media>
                                        <span className="mb-0 text-sm">
                                          {row.partNumber}
                                        </span>
                                      </Media>
                                    </Media>
                                  </th>
                                  <th
                                    className="caractersTableSize"
                                    scope="row"
                                  >
                                    <Media className="align-items-center">
                                      <Media>
                                        <span className="mb-0 text-sm">
                                          {row.quantity}
                                        </span>
                                      </Media>
                                    </Media>
                                  </th>
                                  <th
                                    className="caractersTableSize"
                                    scope="row"
                                  >
                                    <Media className="align-items-center">
                                      <Media>
                                        <IconButton
                                          onClick={() => {
                                            const removed =
                                              stateMaterial.filter(
                                                (material) => {
                                                  if (material === row) {
                                                    return false;
                                                  } else {
                                                    return true;
                                                  }
                                                }
                                              );
                                            setStateMaterial(removed);
                                          }}
                                        >
                                          <CloseIcon />
                                        </IconButton>
                                      </Media>
                                    </Media>
                                  </th>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  </>
                )}
              </div> */}
              <div>
                <Task />
              </div>
              <div>
                <h4>Responsável</h4>
                <Autocomplete
                  id="outlined-basic"
                  label="Tipo"
                  variant="outlined"
                  freeSolo
                  value={username}
                  style={{ marginTop: "-5px", width: "100%" }}
                  onChange={(e, v) => {
                    if (v) {
                      setUsername({ first_name: v.first_name });
                      setResponsibleEmail(v.username);
                      setResponsibleName(v.first_name);
                    }
                  }}
                  getOptionLabel={(option) => {
                    if (option.first_name) {
                      return option.first_name;
                    } else {
                      // return option.codigo + option.denominacao;
                      return "";
                    }
                  }}
                  options={performers}
                  renderInput={(params) => (
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      {...params}
                      label={"Responsável"}
                      margin="normal"
                      //variant="outlined"
                      onChange={(e) => {
                        console.log(e.target.value);
                      }}
                    />
                  )}
                />
              </div>
              {periodicity === "Diário" && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <h4> Início programado:</h4>
                    <LocalizationProvider
                      locale={ptLocale}
                      dateAdapter={AdapterDateFns}
                    >
                      <DateTimePicker
                        value={start}
                        onChange={(newValue) => {
                          setStart(newValue);
                        }}
                        renderInput={(params) => <TextFieldMui {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                  <div style={{ marginLeft: "5em" }}>
                    <h4> Fim programado:</h4>
                    <LocalizationProvider
                      locale={ptLocale}
                      dateAdapter={AdapterDateFns}
                    >
                      <DateTimePicker
                        value={end}
                        onChange={(newValue) => {
                          setEnd(newValue);
                        }}
                        renderInput={(params) => (
                          <TextFieldMui error {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button type="submit" color="success">
                    Atualizar
                  </Button>{" "}
                  <Button
                    color="secondary"
                    onClick={() => {
                      toggleEdit();
                    }}
                  >
                    Cancelar
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button type="submit" onClick={deletePlan} color="danger">
                    <DeleteIcon />
                    Apagar
                  </Button>{" "}
                </div>
              </div>
            </form>
          </div>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </Modal>
    </>
  );
}

export default ListStrategy;
