import React, { useState, useEffect, useContext, createRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  CardHeader,
  CardBody,
  Card,
} from "reactstrap";
import { useInput } from "../Spares/useInput";
import EquipmentService from "./EquipmentService";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { EnterpriseNameContext } from "../../context/EnterpriseContext";
import { IdUserContext } from "../../context/IdUser";
import { UserType } from "../../context/UserType";
import axios from "axios";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Avatar from "@material-ui/core/Avatar";
//var React = require('react');
import { makeStyles } from "@material-ui/core/styles";
import { yellow, deepOrange, green } from "@material-ui/core/colors";
import NotificationAlert from "react-notification-alert";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    marginRight: "10px",
    marginLeft: "-5px",
    backgroundColor: deepOrange[500],
  },
  yellow: {
    color: "#fff",
    marginRight: "10px",
    marginLeft: "-5px",
    backgroundColor: yellow[500],
  },
  green: {
    color: "#fff",
    marginRight: "10px",
    marginLeft: "-5px",
    backgroundColor: green[500],
  },
}));

const notification = createRef();

function CreateEquipment(props) {
  const classes = useStyles();
  const history = useHistory();

  const { enterpriseName } = useContext(EnterpriseNameContext);
  const { idUser } = useContext(IdUserContext);
  const { userType } = useContext(UserType);
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [selectedDenomination, setSelectedDenomination] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedSystem, setSelectedSystem] = useState("");
  const [selectedManufacturer, setSelectedManufacturer] = useState("");
  const [selectedModel, setSelectedModel] = useState("");

  const [criticidade, setCriticidade] = useState("Baixa");
  const [area, setArea] = useState("");
  const [setor_instalado, setSetor_instalado] = useState("");
  const [sistema, setSistema] = useState("");
  const [denominacao, setDenominacao] = useState("");
  const [fabricante, setFabricante] = useState("");
  const [modelo, setModelo] = useState("");
  const [image, setImage] = useState(null);
  const [nameImage, setNameImage] = useState("");
  const [token, setToken] = useState(() => {
    const tokenStoraged = localStorage.getItem("TOKEN_KEY");
    if (tokenStoraged) {
      return tokenStoraged;
    }
    return "";
  });
  const [hash, setHash] = useState(() => {
    const hashStoraged = localStorage.getItem("TOKEN_HASH");
    if (hashStoraged) {
      return hashStoraged;
    }
    return "";
  });
  const [idUserStorage, setIdUserStorage] = useState(() => {
    const idUserStoraged = localStorage.getItem("TOKEN_ID_USER");
    if (idUserStoraged) {
      return idUserStoraged;
    }
    return "";
  });
  // const { value: area, bind: bindarea} = useInput('');
  // const { value: setor_instalado, bind: bindsetor_instalado } = useInput('');
  // const { value: sistema, bind: bindsistema } = useInput('');
  // const { value: denominacao, bind: binddenominacao} = useInput('');
  const { value: codigo, bind: bindcodigo, setValue: setCodigo } = useInput("");
  // const { value: fabricante, bind: bindfabricante} = useInput('');
  // const { value: modelo, bind: bindmodelo} = useInput('');

  const [equipment, setEquipment] = useState([]);
  const redirectLogin = () => {
    swal(
      "Hmm... vi que você não está logado. Clique em ok para fazer o login",
      "",
      "warning",
      {
        buttons: {
          // nope: {
          //   text: 'Me enganei!',
          //   value: 'nope',
          // },
          sure: {
            text: "Ok",
            value: "sure",
          },
        },
      }
    ).then((value) => {
      switch (value) {
        case "sure":
          swal("Operação realizada com sucesso!", "", "success").then((val) => {
            // history.pushState
            // return <Redirect to="/auth/login"/>
            history.push("/auth/login");
          });
          break;
        case "nope":
          swal("Operação cancelada", "", "success");
          break;
        default:
      }
    });
  };

  useEffect(() => {
    EquipmentService.getEquipment({
      empresa: enterpriseName,
      token: token,
      hash: hash,
      idUser: idUserStorage,
    })
      .then((res) => setEquipment(res.data.data))
      .catch((error) => {
        if (error?.response?.data?.auth) {
        } else {
          // redirectLogin();
        }
      });
  }, [enterpriseName, token, hash, idUserStorage]);

  //YUP IN HAND
  const [requireCode, setRequireCode] = useState(false);
  const [messageRequireCode, setMessageRequireCode] = useState("");

  const [requireDenomination, setRequireDenomination] = useState(false);
  const [messageRequireDenomination, setMessageRequireDenomination] =
    useState("");

  const [requireArea, setRequireArea] = useState(false);
  const [messageRequireArea, setMessageRequireArea] = useState("");

  const [requireSector, setRequireSector] = useState(false);
  const [messageRequireSector, setMessageRequireSector] = useState("");

  const notifyCode = (event, title, content) => {
    let options = {
      place: "tc",
      message: (
        <div>
          <h2
            style={{ color: "#fff", marginBottom: "0" }}
            className="alert-title"
            data-notify="title"
          >
            {" "}
            {title ? title : "Atenção!"}
            <br />
          </h2>
          <span data-notify="message">
            {content ? content : "Este código já foi cadastrado!"}
          </span>
        </div>
      ),
      type: event,
      // icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    notification.current.notificationAlert(options);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    if (codigo) {
      EquipmentService.getEquipment({
        codigo: codigo,
        empresa: enterpriseName,
        token: token,
        hash: hash,
        idUser: idUserStorage,
      })
        .then(async (response) => {
          const result = response.data.data;
          console.log(result);
          console.log(enterpriseName);
          if (result.length > 0) {
            notifyCode("warning");
            setRequireCode(true);
          } else if (denominacao && area && setor_instalado) {
            let url;
            let filename;
            const formData = new FormData();
            if (image) {
              if (image[0].size > 1024 * 1024 * 4) {
                notifyCode(
                  "warning",
                  "Atenção!",
                  "O tamanho da imagem é muito grande!"
                );
                return;
              }
              formData.append("image", image[0]);
              await axios({
                method: "post",
                url: "https://api.darwinx.com.br/images",
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
              }).then((response) => {
                url = response.data.url;
                filename = response.data.name;
                console.log(url);
              });
            }
            if (url) {
              let equipments = {
                area: area.trim(),
                denominacao: denominacao.trim(),
                codigo: codigo.trim(),
                fabricante: fabricante.trim(),
                modelo: modelo.trim(),
                setor_instalado: setor_instalado.trim(),
                sistema: sistema.trim(),
                empresa: enterpriseName,
                id: idUser,
                foto: url,
                nomeFoto: filename,
                criticidade: criticidade,
                token: token,
                hash: hash,
                idUser: idUserStorage,
              };
              EquipmentService.createEquipment(equipments)
                .then((res) => {
                  EquipmentService.getEquipment({
                    empresa: enterpriseName,
                    token: token,
                    hash: hash,
                    idUser: idUserStorage,
                  })
                    .then(async (res) => {
                      props.setEquipment(res.data.data);
                      localStorage.removeItem("TOKEN_KEY");
                      localStorage.setItem("TOKEN_KEY", res.data.token);
                    })
                    .catch((error) => {
                      if (error?.response?.data?.auth) {
                      } else {
                        // redirectLogin();
                      }
                    });
                  setImage("");
                  setCriticidade("Baixa");
                  setModelo(null);
                  setFabricante(null);
                  setSistema(null);
                  setSetor_instalado(null);
                  setArea(null);
                  setDenominacao(null);
                  setCodigo(null);
                  setModal(false);
                  setSelectedArea("");
                  setSelectedDenomination("");
                  setSelectedArea("");
                  setSelectedDenomination("");
                  setSelectedManufacturer("");
                  setSelectedModel("");
                  setSelectedSector("");
                  setSelectedSystem("");
                  notifyCode(
                    "success",
                    "Concluído!",
                    "O equipamento foi criado com sucesso!"
                  );
                })
                .catch((error) => {
                  if (error?.response?.data?.auth) {
                  } else {
                    // redirectLogin();
                  }
                });
            } else {
              let equipments = {
                area: area.trim(),
                denominacao: denominacao.trim(),
                codigo: codigo.trim(),
                fabricante: fabricante.trim(),
                modelo: modelo.trim(),
                setor_instalado: setor_instalado.trim(),
                sistema: sistema.trim(),
                empresa: enterpriseName,
                id: idUser,
                foto: url,
                nomeFoto: filename,
                criticidade: criticidade,
                token: token,
                hash: hash,
                idUser: idUserStorage,
              };
              EquipmentService.createEquipment(equipments)
                .then((res) => {
                  EquipmentService.getEquipment({
                    empresa: enterpriseName,
                    token: token,
                    hash: hash,
                    idUser: idUserStorage,
                  }).then((res) => {
                    props.setEquipment(res.data.data);
                    localStorage.removeItem("TOKEN_KEY");
                    localStorage.setItem("TOKEN_KEY", res.data.token);
                    setImage(null);
                    setCriticidade("Baixa");
                    setModelo(null);
                    setFabricante(null);
                    setSistema(null);
                    setSetor_instalado(null);
                    setArea(null);
                    setDenominacao(null);
                    setCodigo("");
                    setModal(false);
                    setSelectedArea("");
                    setSelectedDenomination("");
                    setSelectedArea("");
                    setSelectedDenomination("");
                    setSelectedManufacturer("");
                    setSelectedModel("");
                    setSelectedSector("");
                    setSelectedSystem("");
                    notifyCode(
                      "success",
                      "Concluído!",
                      "O equipamento foi criado com sucesso!"
                    );
                  });
                })
                .catch((error) => {
                  if (error?.response?.data?.auth) {
                  } else {
                    // redirectLogin();
                  }
                });
            }
          }
        })
        .catch((error) => {
          if (error?.response?.data?.auth) {
          } else {
            // redirectLogin();
          }
        });
    }

    if (!codigo) {
      setRequireCode(true);
      setMessageRequireCode("O campo Código do Equipamento é obrigatório!");
    } else {
      setRequireCode(false);
      setMessageRequireCode("");
    }

    if (!denominacao) {
      setRequireDenomination(true);
      setMessageRequireDenomination("O campo Equipamento é obrigatório!");
    } else {
      setRequireDenomination(false);
      setMessageRequireDenomination("");
    }

    if (!area) {
      setRequireArea(true);
      setMessageRequireArea("O campo Área de instalação é obrigatório!");
    } else {
      setRequireArea(false);
      setMessageRequireArea("");
    }

    if (!setor_instalado) {
      setRequireSector(true);
      setMessageRequireSector("O campo Setor Instalado é obrigatório!");
    } else {
      setRequireSector(false);
      setMessageRequireSector("");
    }
  };

  //checar qual usuário está logado

  // const notifyGeneral = (event) => {
  //   let options = {
  //     place: "tc",
  //     message: (
  //       <div className="alert-text">
  //         <h2 style={{color: '#fff', marginBottom:'0'}} className="alert-title" data-notify="title">
  //           {" "}
  //           Concluído!
  //           <br />
  //         </h2>
  //         <span data-notify="message">
  //           O material foi criado com sucesso!
  //         </span>
  //       </div>
  //     ),
  //     type: event,
  //     // icon: "ni ni-bell-55",
  //     autoDismiss: 4
  //   };
  //   notification.current.notificationAlert(options);
  // }

  return (
    <div>
      {userType !== "Executante" && userType !== "Requisitante" ? (
        <>
          <Button
            color="success"
            size="sm"
            className="position-absolute top-3 right-4"
            onClick={toggle}
          >
            {" "}
            Novo Equipamento{" "}
          </Button>
        </>
      ) : null}
      <NotificationAlert ref={notification} />
      <Modal
        isOpen={modal}
        toggle={toggle}
        size="lg"
        className="mr-0 mt--1 mb--1"
      >
        <ModalBody className="">
          <h2> Novo Equipamento </h2> <label></label>
          <form onSubmit={handleSubmit}>
            <div className="form-group"></div>
            <Row>
              <Col>
                <div className="form-group">
                  {/* <label> Descrição: </label>*/}
                  <TextField
                    id="outlined-basic"
                    label="Código do Equipamento"
                    style={{ width: "100%" }}
                    placeholder="Ex: BTQ-003"
                    onChange={(e) => setCodigo(e.target.value)}
                    // variant="outlined"
                    error={requireCode}
                    helperText={messageRequireCode}
                    value={codigo}
                  />
                </div>
              </Col>
            </Row>
            <div className="form-group">
              {/* <label> Equipamento: </label>*/}
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                onChange={(e, v) => {
                  setDenominacao(v);
                  setSelectedDenomination(v);
                  // if(v){
                  //   if (v.denominacao) {
                  //     setDenominacao(v.denominacao);
                  //     setSelectedDenomination({denominacao: v.denominacao})
                  //   } else {
                  //     setSelectedDenomination({denominacao: ""});
                  //     setDenominacao({denominacao: ""});
                  //   }
                  // }
                }}
                // getOptionLabel={(option) => {
                //   if(option){
                //     return option.denominacao;
                //   }else{
                //     return "";
                //   }
                // }}
                value={selectedDenomination}
                options={[
                  ...new Set(
                    equipment.map((equipmentSource) => {
                      if (equipmentSource.denominacao) {
                        return equipmentSource.denominacao;
                      } else {
                        return "";
                      }
                    })
                  ),
                ]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Equipamento"
                    margin="normal"
                    // variant="outlined"
                    error={requireDenomination}
                    helperText={messageRequireDenomination}
                    placeholder="Ex: Bomba de circulação de produto"
                    value={denominacao}
                    onChange={(e) => {
                      setDenominacao(e.target.value);
                    }}
                  />
                )}
              />
            </div>
            <div className="form-group">
              {/* <label> Área: </label>*/}
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                onChange={(e, v) => {
                  setArea(v);
                  setSelectedArea(v);
                  // if(v){
                  //   if (v.area) {
                  //     setArea(v.area);
                  //   } else {
                  //     setArea("");
                  //   }
                  // }
                }}
                // getOptionLabel={(option) => {
                //   if(option){
                //     return option.area;
                //   }else{
                //     return "";
                //   }
                // }}
                value={selectedArea}
                options={[
                  ...new Set(
                    equipment.map((equipmentSource) => {
                      if (equipmentSource.area) {
                        return equipmentSource.area;
                      } else {
                        return "";
                      }
                    })
                  ),
                ]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Área de instalacão do equipamento"
                    margin="normal"
                    placeholder="Ex: Lacenor"
                    value={area}
                    error={requireArea}
                    helperText={messageRequireArea}
                    onChange={(e) => {
                      setArea(e.target.value);
                    }}
                  />
                )}
              />
            </div>
            <div className="form-group">
              {/* <label> Setor Instalado: </label>*/}
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                onChange={(e, v) => {
                  setSelectedSector(v);
                  setSetor_instalado(v);
                  // if(v){
                  //   if (v.setor_instalado) {
                  //     setSelectedSector({setor_instalado: v.setor_instalado})
                  //     setSetor_instalado(v.setor_instalado);
                  //   } else {
                  //     setSelectedSector({setor_instalado:""});
                  //     setSetor_instalado("");
                  //   }
                  // }
                }}
                // getOptionLabel={(option) => {
                //   if(option){
                //     return option.setor_instalado;
                //   }else{
                //     return "";
                //   }
                // }}
                options={[
                  ...new Set(
                    equipment.map((equipmentSource) => {
                      if (equipmentSource.setor_instalado) {
                        return equipmentSource.setor_instalado;
                      } else {
                        return "";
                      }
                    })
                  ),
                ]}
                value={selectedSector}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Setor Instalado"
                    margin="normal"
                    //variant="outlined"
                    error={requireSector}
                    helperText={messageRequireSector}
                    value={setor_instalado}
                    placeholder="Ex: Tancagem"
                    onChange={(e) => {
                      setSetor_instalado(e.target.value);
                    }}
                  />
                )}
              />
            </div>
            <div className="form-group">
              {/* <label> Sistema: </label>*/}
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                onChange={(e, v) => {
                  if (v) {
                    setSelectedSystem(v);
                    setSistema(v);
                  } else {
                    setSistema("");
                  }
                }}
                options={[
                  ...new Set(
                    equipment.map((option) => {
                      if (option?.sistema) {
                        return option?.sistema;
                      } else {
                        return "";
                      }
                    })
                  ),
                ]}
                value={selectedSystem}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Sistema"
                    margin="normal"
                    //variant="outlined"
                    value={sistema}
                    placeholder="Ex: Transferência"
                    onChange={(e) => {
                      setSistema(e.target.value);
                    }}
                  />
                )}
              />
            </div>
            <Row>
              <Col>
                <div className="form-group">
                  {/* <label> Fabricante: </label>*/}
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    onChange={(e, v) => {
                      setSelectedManufacturer(v);
                      setFabricante(v);
                    }}
                    options={[
                      ...new Set(
                        equipment.map((option) => {
                          if (option?.fabricante) {
                            return option?.fabricante;
                          } else {
                            return "";
                          }
                        })
                      ),
                    ]}
                    value={selectedManufacturer}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Fabricante"
                        margin="normal"
                        //variant="outlined"
                        value={fabricante}
                        placeholder="Nome do Fabricante"
                        onChange={(e) => {
                          setFabricante(e.target.value);
                        }}
                      />
                    )}
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  {/* <label> Modelo: </label>*/}
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    onChange={(e, v) => {
                      setSelectedModel(v);
                      setModelo(v);
                    }}
                    options={[
                      ...new Set(
                        equipment.map((option) => {
                          if (option.modelo) {
                            return option.modelo;
                          }
                          return "";
                        })
                      ),
                    ]}
                    value={selectedModel}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Modelo do Equipamento"
                        margin="normal"
                        //variant="outlined"
                        placeholder="Modelo do Equipamento"
                        value={modelo}
                        onChange={(e) => {
                          setModelo(e.target.value);
                        }}
                      />
                    )}
                  />
                </div>
              </Col>
            </Row>
            <FormControl style={{ display: "block" }} component="fieldset">
              <FormLabel component="legend">
                Criticidade do Equipamento
              </FormLabel>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue="top"
              >
                <FormControlLabel
                  style={{ marginRight: "3em" }}
                  value="Alta"
                  checked={criticidade === "Alta"}
                  control={
                    <>
                      <Radio
                        onChange={() => {
                          setCriticidade("Alta");
                        }}
                        checked={criticidade === "Alta"}
                        color="primary"
                      />{" "}
                      <Avatar className={classes.orange}>A</Avatar>
                    </>
                  }
                  label="Alta"
                />
                <FormControlLabel
                  style={{ marginRight: "3em" }}
                  value="Média"
                  control={
                    <>
                      <Radio
                        checked={criticidade === "Média"}
                        onChange={() => {
                          setCriticidade("Média");
                        }}
                        color="primary"
                      />{" "}
                      <Avatar
                        onChange={() => {
                          console.log("cliqui aqui");
                        }}
                        className={classes.yellow}
                      >
                        B
                      </Avatar>
                    </>
                  }
                  label="Média"
                  checked={criticidade === "Média"}
                />
                <FormControlLabel
                  value="Baixa"
                  checked={criticidade === "Baixa"}
                  control={
                    <>
                      <Radio
                        onChange={() => {
                          setCriticidade("Baixa");
                        }}
                        checked={criticidade === "Baixa"}
                        color="primary"
                      />{" "}
                      <Avatar className={classes.green}>C</Avatar>
                    </>
                  }
                  label="Baixa"
                  onChange={(e, v) => {
                    if (v) {
                      setCriticidade("Baixa");
                    }
                  }}
                />
              </RadioGroup>
            </FormControl>
            <div className="form-group">
              <label> Foto: </label>
              <input
                type="file"
                placeholder="Equipamento"
                name="file"
                className="form-control"
                accept="image/png, image/jpeg"
                onChange={(e) => {
                  setImage(e.target.files);
                  // setNameImage()
                  console.log(e.target.files);
                }}
              />
            </div>
            <Button type="submit" color="success">
              Criar
            </Button>{" "}
            <Button
              color="secondary"
              onClick={() => {
                toggle();
                // setCodigo()
              }}
            >
              Cancelar
            </Button>
          </form>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </div>
  );
}

export default CreateEquipment;
