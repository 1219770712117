import React from "react";
import { Row, Col } from "reactstrap";
// import QRCode from "qrcode.react";
// ESSE DE IMPRESSÃO SÓ FUNCIONA COM CLASS COMPONENT
class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qrcode: "",
    };
  }
  componentDidMount() {
    this.setState({
      qrcode: `https://api.qrserver.com/v1/create-qr-code/?data=darwinx://qrcode/${this.props.id}&size=120x120&bgcolor=#fff%60`,
    });
  }
  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "2em",
        }}
      >
        {/* <QRCode
          style={{ width: "25%", height: "25%" }}
          position=""
          value={`http://18.228.45.138:3000/admin/qrcode?id=${this.props.id}`}
        /> */}
        <img
          style={{ width: "25%", height: "25%" }}
          alt="qrcode"
          src={this.state.qrcode}
        />
        <div style={{ textAlign: "center", marginTop: "1em" }}>
          <label style={{ fontSize: "2em" }}>
            {" "}
            <b>Equipamento:</b> {this.props.denominacao}
          </label>{" "}
          <br></br>
          <label style={{ fontSize: "2em" }}>
            {" "}
            <b>Código:</b> {this.props.codigo}
          </label>{" "}
          <br></br>
          <label style={{ fontSize: "2em" }}>
            {" "}
            <b>Área:</b> {this.props.area}{" "}
          </label>
        </div>
      </div>
    );
  }
}
export default ComponentToPrint;
