import { useState, useEffect, useContext, useMemo } from "react";
import Header from "components/Headers/Headerfor.js";
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
} from "reactstrap";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import SearchIcon from "@material-ui/icons/Search";

import TextField from "@material-ui/core/TextField";

import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { Link } from "react-router-dom";

import { EnterpriseNameContext } from "../../../../context/EnterpriseContext";
import ReserveToolsService from "../service/ReportToolService";
import moment from "moment";
import OutputTool from "../components/OutputTool";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
const ReportTool = () => {
  const { enterpriseName } = useContext(EnterpriseNameContext);
  const [value, setValue] = useState();
  const [toolsReserve, setToolsReserve] = useState([]);
  const [modalOut, setModalOut] = useState(false);
  const [structure] = useState();
  useEffect(() => {
    ReserveToolsService.getReserveTools({ empresa: enterpriseName }).then(
      (response) => {
        setToolsReserve(response.data);
      }
    );
  }, []);

  const columnHeader = [
    {
      Header: "Número da Reserva",
      Footer: "Número da Reserva",
      accessor: "numberTool",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: (originalRow, rowIndex) => {
        if (originalRow.status === "Entrada") {
          return (
            <>
              <LoginIcon style={{ marginRight: "0.2em" }} />
              {originalRow.status}
            </>
          );
        } else {
          return (
            <>
              <LogoutIcon style={{ marginRight: "0.2em" }} />
              {originalRow.status}
            </>
          );
        }
      },
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Descrição da ferramenta",
      Footer: "Descrição da ferramenta",
      accessor: "descriptionTool",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Quantidade de saída",
      Footer: "Quantidade de saída",
      accessor: "quantityReverse",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Observação da saída",
      Footer: "Observação da saída",
      accessor: "comments",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Data da saída",
      Footer: "Data da saída",
      accessor: (originalRow, rowIndex) => {
        return moment(originalRow?.dateInitial).format("DD/MM/YYYY");
      },
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Quantidade de entrada",
      Footer: "Quantidade de entrada",
      accessor: "quantityDelivery",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Observação da entrada",
      Footer: "Observação da entrada",
      accessor: "comments2",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Data da entrada",
      Footer: "Data da entrada",
      accessor: (originalRow, rowIndex) => {
        return moment(originalRow?.dateFinished).format("DD/MM/YYYY");
      },
      // disableFilters: true,
      sticky: "left",
    },
  ];

  const columns = useMemo(() => columnHeader, []);
  const data = useMemo(() => toolsReserve, [toolsReserve]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    allColumns,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value);
  });

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader
                style={{ display: "flex", flexDirection: "column" }}
                className="border-0"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <Link to="/admin/tools">
                    <Button>Voltar</Button>
                  </Link>
                </div>
                <div style={{ textAlign: "center", width: "100%" }}>
                  <h2 style={{ textAlign: "center" }}>
                    Relatório de ferramentas
                  </h2>
                </div>
              </CardHeader>
              <Card className="shadow-lg mx-4 mb-4 mt-3">
                <div
                  style={{
                    width: "80%",
                    display: "flex",
                    alignItems: "center",
                    margin: "0.5em",
                  }}
                >
                  <SearchIcon
                    style={{ marginTop: "12px", marginRight: "5px" }}
                  />
                  <TextField
                    id="input-with-icon-grid"
                    value={value || ""}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setValue(e.target.value);
                      onChange(e.target.value);
                    }}
                    style={{ width: "100%" }}
                    label="Buscar relatório"
                  />
                </div>
                <Table
                  {...getTableProps()}
                  className="align-items-center table-flush"
                  responsive
                  hover
                  style={{ tableLayout: "fixed" }}
                >
                  <thead className="thead-light">
                    {headerGroups.map((headerGroup) => (
                      <tr
                        style={{ textAlign: "center" }}
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column) => {
                          return (
                            <th
                              className="text-wrap"
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                              scope="col"
                            >
                              {column.render("Header")}{" "}
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <ArrowDropDownIcon />
                                ) : (
                                  <ArrowDropUpIcon />
                                )
                              ) : (
                                ""
                              )}
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      console.log(row);
                      prepareRow(row);
                      return (
                        <tr
                          style={{ cursor: "pointer", textAlign: "center" }}
                          {...row.getRowProps()}
                        >
                          {row.cells.map((cell) => {
                            // if(cell.column.Header === "Custo hh"){
                            //   return(
                            //     <td className="caractersTableSize centerContent" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            //   );
                            // }
                            return (
                              <td
                                className="caractersTableSize"
                                {...cell.getCellProps()}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <div>
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                        <PaginationItem disabled={!canPreviousPage}>
                          <PaginationLink
                            onClick={(e) => gotoPage(0)}
                            tabIndex="-1"
                          >
                            <i className="fas fa-angle-left" />
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>
                        {state.pageIndex > 0 ? (
                          <>
                            <PaginationItem disabled={!canPreviousPage}>
                              <PaginationLink onClick={(e) => previousPage()}>
                                {state.pageIndex}
                              </PaginationLink>
                            </PaginationItem>
                          </>
                        ) : null}
                        <PaginationItem className="active">
                          <PaginationLink>{state.pageIndex + 1}</PaginationLink>
                        </PaginationItem>
                        <PaginationItem disabled={!canNextPage}>
                          <PaginationLink onClick={(e) => nextPage()}>
                            {state.pageIndex + 2}
                            <span className="sr-only">(current)</span>
                          </PaginationLink>
                        </PaginationItem>

                        <PaginationItem disabled={!canNextPage}>
                          <PaginationLink
                            onClick={(e) => gotoPage(pageCount - 1)}
                          >
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </nav>
                  </CardFooter>
                </div>
              </Card>
            </Card>
          </div>
        </Row>
      </Container>

      {/* <InputTool structure={structure} modal={modal} setModal={setModal}/> */}
      <OutputTool
        structure={structure}
        modalOut={modalOut}
        setModalOut={setModalOut}
      />
    </>
  );
};
export default ReportTool;
