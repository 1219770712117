// GESTÃO DE USUARIOS

import axios from "axios";

const DASHBOARD_REQUEST = "https://api.darwinx.com.br/dashboard";

class DashboardService {
  getRequest(request) {
    return axios.get(DASHBOARD_REQUEST + "/request", { params: request });
  }

  getSpare(spare) {
    return axios.get(DASHBOARD_REQUEST + "/spare", { params: spare });
  }

  getOrder(order) {
    return axios.get(DASHBOARD_REQUEST + "/order", { params: order });
  }

  getUsers(enterprise) {
    return axios.get(DASHBOARD_REQUEST + "/user", { params: enterprise });
  }

  getActives(enterprise) {
    return axios.get(DASHBOARD_REQUEST + "/active", { params: enterprise });
  }
  getActivesRAV(equipment) {
    return axios.get(DASHBOARD_REQUEST + "/active/rav", { params: equipment });
  }
  getActivesPrice(price) {
    return axios.get(DASHBOARD_REQUEST + "/active/price", { params: price });
  }
  getAverageTime(object) {
    return axios.get(DASHBOARD_REQUEST + "/active/averageTime", {
      params: object,
    });
  }
}

export default new DashboardService();
