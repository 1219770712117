import React from "react";

import {} from "reactstrap";

const Header = () => {
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-6"></div>
    </>
  );
};

export default Header;
