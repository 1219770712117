import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Button } from "reactstrap";
import file from "../../assets/file.pdf";
import "./PDF.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export default function PDF({ url }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  return (
    <>
      <div className="main">
        <Document
          className="content"
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={console.error}
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            active={pageNumber === 1}
            onClick={() => {
              if (pageNumber > 1) {
                setPageNumber(pageNumber - 1);
              }
            }}
          >
            Anterior
          </Button>
          <Button
            active={pageNumber === numPages}
            onClick={() => {
              if (pageNumber < numPages) {
                setPageNumber(pageNumber + 1);
              }
            }}
          >
            Próxima
          </Button>
        </div>

        <p style={{ textAlign: "center", marginTop: "0.5em" }}>
          Página {pageNumber} das {numPages}
        </p>
      </div>
    </>
  );
}
