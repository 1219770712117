// GESTÃO DE Fornecedores

import axios from "axios";

const Fornecedor_API_BASE_URL = "https://api.darwinx.com.br/fornecedores";

class FornecedoresService {
  getFornecedores(fornecedor) {
    return axios.get(Fornecedor_API_BASE_URL, { params: fornecedor });
  }

  createFornecedores(fornecedor) {
    return axios.post(Fornecedor_API_BASE_URL, fornecedor);
  }

  getFornecedoresById(fornecedorId, token) {
    return axios.get(Fornecedor_API_BASE_URL + "/" + fornecedorId, {
      params: token,
    });
  }

  updateFornecedores(fornecedor, fornecedorId) {
    return axios.put(Fornecedor_API_BASE_URL + "/" + fornecedorId, fornecedor);
  }

  deleteFornecedores(fornecedorId, token) {
    return axios.delete(Fornecedor_API_BASE_URL + "/" + fornecedorId, {
      params: token,
    });
  }
}

export default new FornecedoresService();
