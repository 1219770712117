import React, { useState, useEffect, useContext, createRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  CardHeader,
  CardBody,
  Card,
} from "reactstrap";
import { useInput } from "../Spares/useInput";
import FornecedoresService from "./FornecedoresService";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { EnterpriseNameContext } from "../../context/EnterpriseContext";
import { IdUserContext } from "../../context/IdUser";
import { UserType } from "../../context/UserType";
import axios from "axios";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Avatar from "@material-ui/core/Avatar";
//var React = require('react');
import PDFService from "./PDFService.js";
import { makeStyles } from "@material-ui/core/styles";
import { yellow, deepOrange, green } from "@material-ui/core/colors";
import NotificationAlert from "react-notification-alert";

const useStyles = makeStyles((theme) => ({
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    marginRight: "10px",
    marginLeft: "-5px",
    backgroundColor: deepOrange[500],
  },
  yellow: {
    color: "#fff",
    marginRight: "10px",
    marginLeft: "-5px",
    backgroundColor: yellow[500],
  },
  green: {
    color: "#fff",
    marginRight: "10px",
    marginLeft: "-5px",
    backgroundColor: green[500],
  },
}));

const notification = createRef();

function CreateFornecedor(props) {
  const classes = useStyles();

  const { enterpriseName } = useContext(EnterpriseNameContext);
  const { idUser } = useContext(IdUserContext);
  const { userType } = useContext(UserType);
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [website, setWebsite] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [responsavel_fornecedor, setResponsavel_fornecedor] = useState("");
  const [telefone, setTelefone] = useState("");
  const [PDF, setPDF] = useState(null);
  const [contact, setContact] = useState("");
  const [anexo, setAnexo] = useState("");
  const [descricao, setDescricao] = useState("");

  const { value: codigo, bind: bindcodigo, setValue: setCodigo } = useInput("");

  const [fornecedor, setFornecedor] = useState([]);

  useEffect(() => {
    FornecedoresService.getFornecedores({ empresa: enterpriseName }).then(
      (res) => setFornecedor(res.data.data)
    );
  }, [enterpriseName]);

  const handleSubmit = (evt) => {
    evt.preventDefault();

    //alert(`Submitting Name ${firstName} ${lastName}`);
    //resetFirstName();
    //resetLastName();
  };

  //checar qual usuário está logado
  const notifyGeneral = (event) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Concluído!
            <br />
          </span>
          <span data-notify="message">
            O fornecedor foi criado com sucesso!
          </span>
        </div>
      ),
      type: event,
      // icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    notification.current.notificationAlert(options);
  };

  const create = async (evt) => {
    let url;
    let filename;
    const formData = new FormData();
    if (PDF) {
      formData.append("pdf", PDF[0]);
      await axios({
        method: "post",
        url: "https://api.darwinx.com.br/pdfs",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          url = response.data.url;
          filename = response.data.name;
          console.log(url);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (url) {
      let fornecedores = {
        codigo: codigo,
        responsavel_fornecedor: responsavel_fornecedor,
        telefone: telefone,
        contato: contact,
        website: website,
        empresa: enterpriseName,
        id: idUser,
        PDF: url,
        nomePDF: filename,
      };
      FornecedoresService.createFornecedores(fornecedores)
        .then((res) => {
          FornecedoresService.getFornecedores({ empresa: enterpriseName }).then(
            (res) => {
              props.setFornecedor(res.data.data);
            }
          );
          setPDF("");
          setTelefone(null);
          setResponsavel_fornecedor(null);
          setWebsite(null);
          setEmpresa(null);
          setCodigo(null);
          setContact("");
          notifyGeneral("success");
          setModal(false);
        })
        .catch((error) => {
          console.log(error.response);
        });
    } else {
      let fornecedores = {
        codigo: codigo,
        responsavel_fornecedor: responsavel_fornecedor,
        telefone: telefone,
        contato: contact,
        website: website,
        empresa: enterpriseName,
        id: idUser,
        PDF: url,
        nomePDF: filename,
      };
      console.log(fornecedores);
      FornecedoresService.createFornecedores(fornecedores)
        .then((res) => {
          FornecedoresService.getFornecedores({ empresa: enterpriseName }).then(
            (res) => {
              props.setFornecedor(res.data.data);
            }
          );
          setPDF(null);
          setTelefone(null);
          setResponsavel_fornecedor(null);
          setWebsite(null);
          setEmpresa(null);
          setCodigo("");
          setContact("");
          notifyGeneral("success");
          setModal(false);
        })
        .catch((error) => console.log(error.response));
    }
  };

  return (
    <div>
      {userType !== "Executante" && userType !== "Requisitante" ? (
        <>
          <Button
            color="success"
            size="sm"
            className="position-absolute top-3 right-4"
            onClick={toggle}
          >
            {" "}
            Novo Fornecedor{" "}
          </Button>
        </>
      ) : null}
      <NotificationAlert ref={notification} />
      <Modal
        isOpen={modal}
        toggle={toggle}
        size="lg"
        className="mr-0 mt--1 mb--1"
      >
        <ModalBody className="">
          <h2> Novo Fornecedor </h2> <label></label>
          <form onSubmit={handleSubmit}>
            <div className="form-group"></div>
            <Row>
              <Col>
                <div className="form-group">
                  {/* <label> Descrição: </label>*/}
                  <TextField
                    id="outlined-basic"
                    label="Código do Fornecedor"
                    style={{ width: "100%" }}
                    placeholder="Ex: BTQ-003"
                    onChange={(e) => setCodigo(e.target.value)}
                    value={codigo}
                  />
                </div>
              </Col>
            </Row>

            <div className="form-group">
              {/* <label> Equipamento: </label>*/}
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                onChange={(e, v) => {
                  setResponsavel_fornecedor(v);
                }}
                value={responsavel_fornecedor}
                options={[
                  ...new Set(
                    fornecedor.map((option) => {
                      if (option.responsavel_fornecedor) {
                        return option.responsavel_fornecedor;
                      }
                      return "";
                    })
                  ),
                ]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Fornecedor"
                    margin="normal"
                    placeholder="Ex.: John Doe"
                    value={empresa}
                    onChange={(e) => {
                      setResponsavel_fornecedor(e.target.value);
                    }}
                  />
                )}
              />
            </div>

            <div className="form-group">
              {/* <label> Descrição: </label>*/}
              <TextField
                id="outlined-basic"
                label="Descrição"
                style={{ width: "100%" }}
                placeholder="Ex: Fornecedor da bomba de água"
                onChange={(e) => setDescricao(e.target.value)}
                value={descricao}
              />
            </div>

            <div className="form-group">
              {/* <label> Website: </label>*/}
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                onChange={(e, v) => {
                  setWebsite(v);
                }}
                options={[
                  ...new Set(
                    fornecedor.map((option) => {
                      if (option.website) {
                        return option.website;
                      }
                      return "";
                    })
                  ),
                ]}
                value={website}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Website"
                    margin="normal"
                    //variant="outlined"
                    value={website}
                    placeholder="Ex.: http://www.darwinx.com.br/"
                    onChange={(e) => {
                      setWebsite(e.target.value);
                    }}
                  />
                )}
              />
            </div>

            <Row>
              <Col>
                <div className="form-group">
                  {/* <label> Responsavel: </label>*/}
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    onChange={(e, v) => {
                      setContact(v);
                    }}
                    options={[
                      ...new Set(
                        fornecedor.map((option) => {
                          if (option.contato) {
                            return option.contato;
                          }
                          return "";
                        })
                      ),
                    ]}
                    value={contact}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Contato"
                        margin="normal"
                        //variant="outlined"
                        value={contact}
                        placeholder="Pessoa para Contato"
                        onChange={(e) => {
                          setContact(e.target.value);
                        }}
                      />
                    )}
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  {/* <label> Telefone: </label>*/}
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    onChange={(e, v) => {
                      setTelefone(v);
                    }}
                    options={[
                      ...new Set(
                        fornecedor.map((option) => {
                          if (option.telefone) {
                            return option.telefone;
                          }
                          return "";
                        })
                      ),
                    ]}
                    value={telefone}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Telefone"
                        margin="normal"
                        //variant="outlined"
                        placeholder="(XX) X XXXX-XXXX"
                        value={telefone}
                        onChange={(e) => {
                          setTelefone(e.target.value);
                        }}
                      />
                    )}
                  />
                </div>
              </Col>
            </Row>
            <div className="form-group">
              <label> Anexo: </label>
              <input
                type="file"
                placeholder="Anexo"
                name="file"
                className="form-control"
                accept="application/pdf"
                onChange={(e) => {
                  setPDF(e.target.files);
                  console.log(e.target.files);
                }}
              />
            </div>
          </form>
          <Button color="success" onClick={create}>
            Criar
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => {
              toggle();
              setCodigo();
            }}
          >
            Cancelar
          </Button>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </div>
  );
}

export default CreateFornecedor;
