// GESTÃO DE PLANOS DE MANUTENÇÂO

import axios from "axios";

const VERIFY_API_BASE_URL = "https://api.darwinx.com.br/ordersVerify";

class VerifyStrategyService {
  verify() {
    return axios.get(VERIFY_API_BASE_URL);
  }
}

export default new VerifyStrategyService();
