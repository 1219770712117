import TextField from "@material-ui/core/TextField";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Row,
} from "reactstrap";

const ModalCompleteOrder = ({
  description,
  setDescription,
  modal,
  toggle,
  update,
}) => {
  return (
    <Modal size="lg" isOpen={modal}>
      <ModalHeader className="mb--4 ml-2" tag="h2" toggle={toggle}>
        <Row>Fechar Ordem</Row>
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="form-group">
            <TextField
              id="outlined-basic"
              label="Descrição da conclusão da ordem"
              variant="outlined"
              name="Descrição"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              style={{ width: "100%" }}
            />
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          onClick={() => {
            console.log(description, "description");
            update();
          }}
        >
          Concluir
        </Button>{" "}
        <Button
          color="secondary"
          onClick={() => {
            toggle();
          }}
        >
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalCompleteOrder;
