import { useState, useEffect, useContext, createRef, memo } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Card,
  CardBody,
  Col,
} from "reactstrap";
// import { TextField, Autocomplete } from '@mui/material'
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { EnterpriseNameContext } from "../../../context/EnterpriseContext";
import axios from "axios";
import ToolsService from "../service";
import UserService from "../../Users/UserService";
import SignatureCanvas from "react-signature-canvas";
import ReportToolService from "../service/ReportToolService";
// import { RemoveShoppingCartOutlined } from '@material-ui/icons';
import NotificationAlert from "react-notification-alert";
const signatures = createRef();
const notification = createRef();

const InputTool = (props) => {
  const { enterpriseName } = useContext(EnterpriseNameContext);
  const [modal, setModal] = useState(false);
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [typeTool, setTypeTool] = useState("");
  const [comments, setComments] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [tools, setTools] = useState([]);
  const [users, setUsers] = useState([]);
  const [signature, setSignature] = useState();
  const [deliveryUser, setDeliveryUser] = useState("");
  const [reserveUser, setReserveUser] = useState("");
  const [tool, setTool] = useState();
  const [toolmaker, setToolmaker] = useState([]);
  const [toolId, setToolId] = useState("");
  const [report, setReport] = useState([]);
  const [quantityReserved, setQuantityReserved] = useState(0);
  const [structureCard, setStructureCard] = useState();
  // const structure = () => {
  //     setTool(props.structure?.descriptionTool);
  //     setTypeTool(props.structure?.typeTool);
  // }
  const user = async () => {
    const array = [];
    await UserService.getUsers({
      empresa: enterpriseName,
      level: "Ferramenteiro",
    }).then((response) => {
      if (response.data.length > 0) {
        array.push(response.data[0]);
      }
      // if()
    });
    await UserService.getUsers({
      empresa: enterpriseName,
      level: "Amoxarife e Ferramenteiro",
    }).then((response) => {
      if (response.data.length > 0) {
        array.push(response.data[0]);
      }
    });
    return array;
  };

  useEffect(() => {
    ToolsService.getTools({ empresa: enterpriseName }).then((response) => {
      setTools(response.data.data);
    });
    UserService.getUsers({
      empresa: enterpriseName,
      level: "Ferramenteiro",
    }).then((response) => {
      setToolmaker(response.data);
    });
    ReportToolService.getReserveTools({
      empresa: enterpriseName,
      status: "Saída",
    }).then((response) => {
      // setToolmaker(...toolmaker, response.data[0]);
      setReport(response.data);
    });
    UserService.getUsers({ empresa: enterpriseName }).then((response) => {
      setUsers(response.data);
    });
    const result = user();
    if (result) {
      result.then((response) => {
        if (response) {
          setToolmaker(response);
        }
      });
    }
  }, []);
  // const data = {
  //     photograph2: signature,
  //     quantityDelivery: quantity,
  //     reserveOwner2: reserveUser,
  //     deliveryOwner2: deliveryUser,
  //     comments2: comments,
  //     empresa: enterpriseName,
  //     dateFinished: new Date(),
  // }
  const toggle = () => setModal(!modal);
  const notifyCodeOut = (event, title, content) => {
    let options = {
      place: "tc",
      message: (
        <div>
          <h2
            style={{ color: "#fff", marginBottom: "0" }}
            className="alert-title"
            data-notify="title"
          >
            {" "}
            {title ? title : "Atenção!"}
            <br />
          </h2>
          <span data-notify="message">
            {content ? content : "Este código já foi cadastrado!"}
          </span>
        </div>
      ),
      type: event,
      // icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    props.notification.current.notificationAlert(options);
  };
  const notifyCode = (event, title, content) => {
    let options = {
      place: "tc",
      message: (
        <div>
          <h2
            style={{ color: "#fff", marginBottom: "0" }}
            className="alert-title"
            data-notify="title"
          >
            {" "}
            {title ? title : "Atenção!"}
            <br />
          </h2>
          <span data-notify="message">
            {content ? content : "Este código já foi cadastrado!"}
          </span>
        </div>
      ),
      type: event,
      // icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    notification.current.notificationAlert(options);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let url;
    let filename;
    const formData = new FormData();
    // ReportToolService.getReserveTools({empresa: enterpriseName, typeTool: typeTool, descriptionTool: tool}).then((response)=>{
    //     console.log(response.data);
    // })
    if (signature) {
      ReportToolService.getReserveToolById(toolId).then((res) => {
        const result = res.data;
        if (result.quantityReverse == quantity) {
          ToolsService.getToolById(result.idTool).then((sender) => {
            const tool = sender.data.data;
            tool.quantidade = tool.quantidade + parseInt(quantity);
            ToolsService.updateTool(tool, result.idTool)
              .then(async (e) => {
                formData.append("image", signature);
                // props.tools()
                await axios({
                  method: "post",
                  url: "https://api.darwinx.com.br/images/signatures",
                  data: formData,
                  params: { empresa: enterpriseName },
                  headers: { "Content-Type": "multipart/form-data" },
                }).then((response) => {
                  url = response.data.url;
                  filename = response.data.name;
                  if (url) {
                    const data = {
                      quantityDelivery: quantity,
                      reserveOwner2: reserveUser,
                      deliveryOwner2: deliveryUser,
                      comments2: comments,
                      dateFinished: new Date(),
                      photograph2: url,
                      namePhoto2: filename,
                      status: "Entrada",
                      // namePhoto2:
                    };
                    ReportToolService.updateReserveTool(data, toolId).then(
                      (sender) => {
                        toggle();
                        setQuantity("");
                        setComments("");
                        setTypeTool("");
                        // setImage(null)
                        setTool("");
                        setToolId("");
                        setToolmaker("");
                        setPrice(0);
                        setDescription("");
                        ToolsService.getTools({ empresa: enterpriseName }).then(
                          (event) => {
                            props.setTools(event.data.data);
                          }
                        );
                        ReportToolService.getReserveTools({
                          empresa: enterpriseName,
                          status: "Saída",
                        }).then((response) => {
                          // setToolmaker(...toolmaker, response.data[0]);
                          setReport(response.data);
                        });
                        notifyCodeOut(
                          "success",
                          "Entrada com sucesso!",
                          "Entrada de ferramenta realizada com sucesso!"
                        );
                      }
                    );
                  }
                });
              })
              .catch((error) => {
                console.error(error);
              });
          });
        } else {
          notifyCode(
            "danger",
            "Erro",
            "Quantidade de entrega diferente da quantidade retirada!"
          );
        }
      });
    } else {
      notifyCode(
        "warning",
        "Atenção!",
        "A assinatura é obrigatória para a entrada de ferramenta!"
      );
    }
  };
  function dataURLToBlob(dataURL) {
    // Code taken from https://github.com/ebidel/filer.js
    var parts = dataURL.split(";base64,");
    var contentType = parts[0].split(":")[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;
    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
  }
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  // const download = (dataURL, filename) => {
  //     if (navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf("Chrome") === -1) {
  //         window.open(dataURL);
  //     } else {
  //     var blob = dataURLToBlob(dataURL);
  //     var url = window.URL?.createObjectURL(blob);

  //     var a = document.createElement("a");
  //     a.style = "display: none";
  //     a.href = url;
  //     a.download = filename;

  //     document.body.appendChild(a);
  //     a.click();

  //     window.URL.revokeObjectURL(url);
  //     }
  // }

  const formatIntoPng = () => {
    if (signatures.current) {
      const dataURL = signatures.current.toDataURL();
      return dataURL;
    }
  };

  const clearSignatures = () => {
    if (signatures.current) {
      return signatures.current.clear();
    }
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button color="success" size="sm" onClick={toggle}>
          {" "}
          Entrada de ferramenta{" "}
        </Button>
      </div>

      <Modal
        isOpen={modal}
        toggle={() => {
          setStructureCard(null);
          toggle();
        }}
        // className="mr-0 mt--1 mb--1 mt-5"
        style={{ maxWidth: "1000px" }}
      >
        <NotificationAlert ref={notification} />
        <ModalHeader className="mt-3" toggle={toggle} tag="h2">
          Entrada de ferramenta
        </ModalHeader>
        <ModalBody className="mb-1 mt--2 mb-2">
          <div>
            <div>
              <Autocomplete
                style={{ flex: 0.5 }}
                // id="free-solo-demo"
                freeSolo
                options={report}
                getOptionLabel={(option) => {
                  return (
                    option.numberTool +
                    " - " +
                    option.descriptionTool +
                    " - " +
                    option.typeTool +
                    " - " +
                    option.reserveOwner1
                  );
                  //   if(option.codigo){
                  //     return option.codigo + " - " + option.denominacao
                  //   }
                  //   if(option.denominacao){
                  //     return option.codigo + " - " + option.denominacao
                  //   }else{
                  //     // return option.codigo + option.denominacao;
                  //     return ''
                  //   }
                }}
                // value={nameEquipment}

                onChange={(e, value) => {
                  if (value) {
                    setTool(value.descricao);
                    setToolId(value._id);
                    setQuantityReserved(value.quantityReverse);
                    setStructureCard(value);
                    // EquipmentService.getEquipmentById(value._id, { token: token, hash: hash, idUser: idUserStorage}).then((res) => {
                    //   let equipments = res.data.data;
                    //   setArea(equipments.area);
                    //   setCodigo(equipments.codigo)
                    //   setNameEquipment({codigo: equipments.codigo, denominacao: equipments.denominacao  });
                    //   setDenominacao(equipments.denominacao);
                    //   setSetor(equipments.setor_instalado);
                    //   localStorage.removeItem("TOKEN_KEY")
                    //   localStorage.setItem("TOKEN_KEY", res.data.token);
                    // });
                  } else {
                    setTool("");
                    setToolId("");
                    setStructureCard(null);
                    // setArea(null);
                    // setDenominacao(null);
                    // setSetor(null);
                    // setNameEquipment(null)
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    // error={requireEquipment}
                    // helperText={messageRequireEquipment}
                    {...params}
                    // value={nameEquipment}
                    label="Reserva de ferramenta"
                    margin="normal"
                    variant="outlined"
                  />
                )}
              />
            </div>

            <div>
              <h4>Resumo da reserva de ferramenta </h4>
              <Card className="shadow-lg">
                <CardBody>
                  <Row>
                    <Col>
                      <p>
                        <b style={{ fontWeight: "550" }}>Ferramenta: </b> Nº
                        {structureCard?.numberTool} -{" "}
                        {structureCard?.descriptionTool}
                      </p>

                      <p>
                        <b style={{ fontWeight: "550" }}>
                          Quantidade reservada:{" "}
                        </b>
                        {structureCard?.quantityReverse}
                      </p>

                      <p>
                        <b style={{ fontWeight: "550" }}>Reservardo por: </b>
                        {structureCard?.reserveOwner1} | <b>Entregue por:</b>{" "}
                        {structureCard?.deliveryOwner1}
                      </p>

                      <p>
                        <b style={{ fontWeight: "550" }}>Descrição: </b>
                        {structureCard?.comments}
                      </p>
                    </Col>

                    <Col className="col-auto">
                      <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                        <i className="ni ni-settings" />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
            <form onSubmit={handleSubmit}>
              {/* <div style={{display: 'flex', alignItems: 'center'}}>
                            <TextField
                                disabled
                                style={{width:"50%"}}
                                placeholder="Ferramenta"
                                margin="normal"
                                variant="outlined"
                                value={structureCard?.descriptionTool ? structureCard?.descriptionTool: ''}
                            />
                            <TextField
                                disabled
                                style={{width:"50%"}}
                                value={structureCard?.typeTool ? structureCard?.typeTool : ''}
                                placeholder="Tipo de ferramenta"
                                margin="normal"
                                variant="outlined"
                            />
                        </div> */}
              <div className="form-group">
                <TextField
                  id="outlined-basic"
                  label="Quantidade de entrada"
                  margin="normal"
                  //variant="outlined"
                  // InputProps={{
                  // startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                  // }}
                  placeholder="0"
                  name="entrada"
                  value={quantity}
                  // error={requirePrice}
                  // helperText={messageRequirePrice}
                  onChange={(e) => {
                    setQuantity(e.target.value);
                  }}
                  style={{ width: "100%" }}
                />
              </div>
              <div style={{ display: "flex" }}>
                <Autocomplete
                  style={{ flex: 0.5 }}
                  // id="free-solo-demo"
                  freeSolo
                  options={users}
                  getOptionLabel={(option) => {
                    return option.first_name;
                    //   if(option.codigo){
                    //     return option.codigo + " - " + option.denominacao
                    //   }
                    //   if(option.denominacao){
                    //     return option.codigo + " - " + option.denominacao
                    //   }else{
                    //     // return option.codigo + option.denominacao;
                    //     return ''
                    //   }
                  }}
                  // value={nameEquipment}

                  onChange={(e, value) => {
                    if (value) {
                      setReserveUser(value.first_name);
                      // EquipmentService.getEquipmentById(value._id, { token: token, hash: hash, idUser: idUserStorage}).then((res) => {
                      //   let equipments = res.data.data;
                      //   setArea(equipments.area);
                      //   setCodigo(equipments.codigo)
                      //   setNameEquipment({codigo: equipments.codigo, denominacao: equipments.denominacao  });
                      //   setDenominacao(equipments.denominacao);
                      //   setSetor(equipments.setor_instalado);
                      //   localStorage.removeItem("TOKEN_KEY")
                      //   localStorage.setItem("TOKEN_KEY", res.data.token);
                      // });
                    } else {
                      setReserveUser();
                      // setArea(null);
                      // setDenominacao(null);
                      // setSetor(null);
                      // setNameEquipment(null)
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      // error={requireEquipment}
                      // helperText={messageRequireEquipment}
                      {...params}
                      // value={nameEquipment}
                      label="Devolvido por: "
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                />

                <Autocomplete
                  style={{ flex: 0.5 }}
                  // id="free-solo-demo"
                  freeSolo
                  options={toolmaker}
                  getOptionLabel={(option) => {
                    return option.first_name;
                    //   if(option.codigo){
                    //     return option.codigo + " - " + option.denominacao
                    //   }
                    //   if(option.denominacao){
                    //     return option.codigo + " - " + option.denominacao
                    //   }else{
                    //     // return option.codigo + option.denominacao;
                    //     return ''
                    //   }
                  }}
                  // value={nameEquipment}

                  onChange={(e, value) => {
                    if (value) {
                      setDeliveryUser(value.first_name);
                      // EquipmentService.getEquipmentById(value._id, { token: token, hash: hash, idUser: idUserStorage}).then((res) => {
                      //   let equipments = res.data.data;
                      //   setArea(equipments.area);
                      //   setCodigo(equipments.codigo)
                      //   setNameEquipment({codigo: equipments.codigo, denominacao: equipments.denominacao  });
                      //   setDenominacao(equipments.denominacao);
                      //   setSetor(equipments.setor_instalado);
                      //   localStorage.removeItem("TOKEN_KEY")
                      //   localStorage.setItem("TOKEN_KEY", res.data.token);
                      // });
                    } else {
                      setDeliveryUser();
                      // setArea(null);
                      // setDenominacao(null);
                      // setSetor(null);
                      // setNameEquipment(null)
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      // error={requireEquipment}
                      // helperText={messageRequireEquipment}
                      {...params}
                      // value={nameEquipment}
                      label="Recebido por: "
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                />
              </div>

              <div className="form-group">
                <TextField
                  style={{ width: "100%" }}
                  id="outlined-basic"
                  rows={2}
                  label="Observações"
                  placeholder="Observações pertinentes para sua empresa"
                  variant="outlined"
                  multiline="true"
                  onChange={(e) => {
                    setComments(e.target.value);
                  }}
                  value={comments}
                />
              </div>

              <div className="form-group">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <label> Assinatura: </label>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      onClick={() => {
                        clearSignatures();
                      }}
                    >
                      Limpar
                    </Button>
                  </div>
                </div>
                <div>
                  <SignatureCanvas
                    penColor="black"
                    // clearOnResize={true}
                    ref={signatures}
                    canvasProps={{
                      width: 500,
                      height: 200,
                      className: "sigCanvas",
                    }}
                    onEnd={(e) => {
                      // console.log(signatures);
                      // console.log(signatures.current.toDataURL())
                      // console.log(e);
                      // setPhotograph(signatures.current.toDataURL("image/jpeg"));
                      // console.log(signatures.current.getCanvas())
                      // var dataURL = signatures.current.toDataURL();
                      // download(dataURL, "signature.png");
                      const result = formatIntoPng();
                      const file = dataURLtoFile(result, "image.png");
                      setSignature(file);
                    }}
                    // onBegin={(e)=>{
                    //     setClearSignature(false);
                    // }}
                  />
                </div>
                {/* <input
                            type="file"
                            placeholder="Equipamento"
                            name="file"
                            className="form-control"
                            accept="image/png, image/jpeg"
                            onChange={(e)=>{setImage(e.target.files)
                                // setNameImage()
                            console.log(e.target.files)}}
                            /> */}
              </div>

              <ModalFooter>
                <Button type="submit" color="success">
                  Entregar
                </Button>{" "}
                <Button
                  color="secondary"
                  onClick={() => {
                    toggle();
                    setQuantity("");
                    setComments("");
                    setTypeTool("");
                    setPrice(0);
                    setDescription("");
                    setStructureCard(null);
                  }}
                >
                  Cancelar
                </Button>
              </ModalFooter>
            </form>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default memo(InputTool);
