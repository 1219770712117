import React, { useState, useEffect, useContext, useMemo } from "react";
import { EnterpriseNameContext } from "../../context/EnterpriseContext";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Bar } from "react-chartjs-2";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

import moment from "moment";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Progress,
  Table,
  Row,
  Col,
  CardTitle,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
  Nav,
  NavItem,
  NavLink,
  Button,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  // chartExample1,
  Chart_Ocupacao_Equipe,
} from "variables/charts.js";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DashboardService from "./DashboardService";
import Tooltip from "@mui/material/Tooltip";
import TextFieldMui from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import brLocale from "date-fns/locale/pt-BR";

import DatepickerArgon from "./components/DatepickerArgon.jsx";

const Team = (props) => {
  const { enterpriseName } = useContext(EnterpriseNameContext);
  const [cardsUsers, setCardsUsers] = useState();
  const [activeNav, setActiveNav] = useState(1);
  const [stateTable, setStateTable] = useState("Programado");
  const [tableUsers, setTableUsers] = useState([]);
  const [tableUsersExecuted, setTableUsersExecuted] = useState([]);
  const [filteredOrder, setFilteredOrder] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [labelGraph, setLabelGraph] = useState([]);
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());

  useEffect(() => {
    DashboardService.getUsers({ empresa: enterpriseName }).then((response) => {
      const data = response.data;
      console.log("result:", data);
      setCardsUsers(data);
      if (data.equipmentArea) {
        setTableUsers(data.equipmentArea, "entrou aqui");
        setEquipmentArea(data.equipmentArea[0] ? data.equipmentArea[0] : []);
      }
      if (data.graphHistoric) {
        if (data.graphHistoric.data1) {
          setData1(data?.graphHistoric?.data1);
        }
        if (data.graphHistoric.data2) {
          setData2(data?.graphHistoric?.data2);
        }
        if (data.graphHistoric.namesMouths) {
          setLabelGraph(data.graphHistoric.namesMouths);
        }
      }
      if (data.tableUser) {
        console.log("tableUser:", data.tableUser);
        setTableUsers(data?.tableUser);
      }
      if (data?.filteredOrder) {
        setFilteredOrder(data.filteredOrder);
      }
      setPageSize(5);
    });
  }, [enterpriseName]);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (e, index) => {
    e.preventDefault();
    console.log(index);
    if (index === 1) {
      setActiveNav(1);
      console.log("programado");
      setStateTable("Programado");
    } else {
      console.log("Execução");
      setActiveNav(2);
      setStateTable("Execução");
    }
  };

  const dataGraph = {
    labels: labelGraph,
    datasets: [
      {
        label: "Somatória de Ordens Concluídas",
        data: data2,
        backgroundColor: "rgb(255, 99, 132)",
        stack: "Stack 0",
      },
      {
        label: "Somatória de Ordens Programadas",
        data: data1,

        backgroundColor: "rgb(54, 162, 235)",
        stack: "Stack 1",
      },
    ],
  };
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const columnHeader = [
    {
      Header: "Foto",
      Footer: "Foto",
      accessor: (originalRow, rowIndex) => {
        if (originalRow.image) {
          return (
            <div>
              <img
                style={{ width: "150%", borderRadius: "50%" }}
                src={originalRow.image}
                alt="foto"
              ></img>
            </div>
          );
        } else {
          return " - ";
        }
      },
      sticky: "left",
    },
    {
      Header: "Nome",
      Footer: "Nome",
      accessor: "username",
      sticky: "left",
    },
    {
      Header: "Abertas",
      Footer: "Abertas",
      accessor: "quantityOrdersOpen",
      sticky: "left",
    },
    {
      Header: "Concluídas",
      Footer: "Concluídas",
      accessor: "quantityOrdersCompleted",
      // accessor: (originalRow, rowIndex) => {
      //   return (originalRow.duration / 180).toFixed(2);
      // },
      sticky: "left",
    },
    {
      Header: "Atrasadas",
      Footer: "Atrasadas",
      accessor: "quantityOrdersPending",
      // accessor: (originalRow, rowIndex) => {
      //   return (originalRow.duration / 180).toFixed(2);
      // },
      sticky: "left",
    },
    {
      Header: "Taxa de Execução",
      Footer: "Taxa de Execução",
      // accessor: 'execution',
      accessor: (originalRow, rowIndex) => {
        return new Intl.NumberFormat("pt-br", {
          style: "percent",
          maximumFractionDigits: 2,
        }).format(originalRow.execution);
      },
      // disableFilters: true,
      sticky: "left",
    },
  ];

  const columns = useMemo(() => {
    return columnHeader;
  }, [stateTable]);
  const data = useMemo(() => {
    return tableUsers;
  }, [stateTable, tableUsers]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    allColumns,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const [startDate, setStartDate] = useState(
    new Date(new Date().setFullYear(new Date().getFullYear() - 1))
  );
  const [endDate, setEndDate] = useState(new Date());

  const [equipmentArea, setEquipmentArea] = useState([]);
  const [selectedArea, setSelectedArea] = useState([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedArea(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  return (
    <>
      <Row>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-checkbox-label">Área</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={selectedArea}
              onChange={handleChange}
              input={<OutlinedInput label="Área" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {equipmentArea.map((name) => (
                <MenuItem key={name} value={name}>
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ display: "flex", width: "80%", marginTop: "1.5em" }}>
            <DatepickerArgon
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
            <div>
              <Button
                style={{ marginLeft: "10px", marginRight: "20px" }}
                type="button"
                color="success"
                onClick={() => {
                  DashboardService.getUsers({
                    empresa: enterpriseName,
                    start: new Date(startDate),
                    end: new Date(endDate),
                    area: selectedArea[0],
                  }).then((response) => {
                    const data = response.data;
                    console.log(data.backlog, "backlog");
                    console.log("result:", data);
                    setCardsUsers(data);
                    if (data.tableUser) {
                      setTableUsers(data?.tableUser);
                    } else {
                      setTableUsers([]);
                    }
                    if (data.tableUserExecuted) {
                      setTableUsersExecuted(data?.tableUserExecuted);
                    } else {
                      setTableUsersExecuted([]);
                    }
                    if (data?.filteredOrder) {
                      setFilteredOrder(data.filteredOrder);
                    } else {
                      setFilteredOrder([]);
                    }
                  });
                }}
              >
                Filtrar
              </Button>
            </div>
          </div>
        </div>
        <Col className="mb-5 mb-xl-0" xl="12">
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="3">
                {/* <Card className="card-stats mb-4 mb-xl-0"> */}
                <Card>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Número de OS Atendidas
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {cardsUsers?.quantityOrderCompleted
                            ? cardsUsers?.quantityOrderCompleted
                            : 0}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <Tooltip
                          style={{ cursor: "pointer" }}
                          title="Número de Ordens de Serviço atendidas"
                        >
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Tooltip>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      {/* <span className="text-warning mr-2">
                        <i className="fas fa-arrow-down" /> 0%
                      </span>{" "} */}
                      <span className="text-nowrap">
                        Entre {moment(startDate).format("DD/MM/YYYY")} e{" "}
                        {moment(endDate).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          BACKLOG
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {cardsUsers?.backlog && cardsUsers?.backlog != "NaN"
                            ? cardsUsers?.backlog
                            : "0,00"}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <Tooltip
                          style={{ cursor: "pointer" }}
                          title="Tempo para concluir todas as ordens de manutenção atrasadas usando toda a equipe."
                        >
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-chart-pie" />
                          </div>
                        </Tooltip>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      {/* <span className="text-danger mr-2">
                        <i className="fas fa-arrow-down" /> 0%
                      </span>{" "} */}
                      <span className="text-nowrap">
                        Entre {moment(startDate).format("DD/MM/YYYY")} e{" "}
                        {moment(endDate).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Time Executantes
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {cardsUsers?.quantityExecutantCard
                            ? cardsUsers?.quantityExecutantCard
                            : 0}
                        </span>
                      </div>

                      <Col className="col-auto">
                        <Tooltip
                          style={{ cursor: "pointer" }}
                          title="Quantidade de executantes"
                        >
                          <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Tooltip>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      {/* <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 0%
                      </span>{" "} */}
                      <span className="text-nowrap">
                        Entre {moment(startDate).format("DD/MM/YYYY")} e{" "}
                        {moment(endDate).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Taxa de Execução
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {cardsUsers?.rateExecution
                            ? new Intl.NumberFormat("pt-br", {
                                style: "percent",
                                maximumFractionDigits: 2,
                              }).format(cardsUsers?.rateExecution)
                            : "0%"}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <Tooltip
                          style={{ cursor: "pointer" }}
                          title={
                            "% de ordens de manutenção concluídas dentro do prazo dentre as que venciam entre " +
                            `${moment(startDate).format(
                              "DD/MM/YYYY"
                            )} e ${moment(endDate).format("DD/MM/YYYY")}`
                          }
                        >
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="fas fa-percent" />
                          </div>
                        </Tooltip>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      {/* <span className="text-success mr-2">
                        <i className="fas fa-arrow-up" /> 0%
                      </span>{" "} */}
                      <span className="text-nowrap">
                        Entre {moment(startDate).format("DD/MM/YYYY")} e{" "}
                        {moment(endDate).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col className="mb-5 mb-xl-0" xl="6">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col">
                  <h6 className="text-uppercase text-muted ls-1 mb-1">
                    Desempenho da equipe
                  </h6>
                  <h3 className="mb-0">Equipe</h3>
                </div>
                <div className="col">
                  {/* <Nav className="justify-content-end" pills>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames("py-2 px-3", {
                          active: activeNav === 1,
                        })}
                        onClick={(e) => toggleNavs(e, 1)}
                      >
                        <span className="d-none d-md-block">Programado</span>
                        <span className="d-md-none">M</span>
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames("py-2 px-3", {
                          active: activeNav === 2,
                        })}
                        data-toggle="tab"
                        onClick={(e) => toggleNavs(e, 2)}
                      >
                        <span className="d-none d-md-block">Execução</span>
                        <span className="d-md-none">W</span>
                      </NavLink>
                    </NavItem>
                  </Nav> */}
                </div>
              </Row>
            </CardHeader>
            {}
            <Table
              {...getTableProps()}
              className="align-items-center table-flush"
              responsive
            >
              <thead className="thead-light">
                {headerGroups.map((headerGroup) => (
                  <tr
                    style={{ textAlign: "center" }}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => {
                      return (
                        <>
                          <th
                            className="text-wrap"
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            scope="col"
                          >
                            {column.render("Header")}{" "}
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <ArrowDropDownIcon />
                              ) : (
                                <ArrowDropUpIcon />
                              )
                            ) : (
                              ""
                            )}
                          </th>
                        </>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      style={{ cursor: "pointer", textAlign: "center" }}
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell) => {
                        if (
                          cell.column.Header === "Taxa de ocupação programada"
                        ) {
                          return (
                            <td
                              className="caractersTableSize centerContent"
                              {...cell.getCellProps()}
                            >
                              <div className="d-flex align-items-center justify-content-center">
                                <span className="mr-2">
                                  {(
                                    (cell.row.original.duration / 180) *
                                    100
                                  ).toFixed(2)}
                                  %
                                </span>
                                <div>
                                  <Progress
                                    max="100"
                                    value={(
                                      (cell.row.original.duration / 180) *
                                      100
                                    ).toFixed(2)}
                                    barClassName="bg-gradient-info"
                                  />
                                </div>
                              </div>
                            </td>
                          );
                        }

                        if (cell.column.Header === "Taxa de execução") {
                          return (
                            <td
                              className="caractersTableSize centerContent"
                              {...cell.getCellProps()}
                            >
                              <div className="d-flex align-items-center justify-content-center">
                                <span className="mr-2">
                                  {cell.render("Cell")}%
                                </span>
                                <div>
                                  <Progress
                                    max="100"
                                    value={(
                                      (cell.row.original?.quantity /
                                        cell.row.original?.quantityOrderTotal) *
                                      100
                                    ).toFixed(2)}
                                    barClassName="bg-gradient-danger"
                                  />
                                </div>
                              </div>
                            </td>
                          );
                        }

                        return (
                          <td
                            className="caractersTableSize"
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card>
          <CardFooter className="py-4">
            <nav aria-label="...">
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem disabled={!canPreviousPage}>
                  <PaginationLink onClick={(e) => gotoPage(0)} tabIndex="-1">
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                {state.pageIndex > 0 ? (
                  <>
                    <PaginationItem disabled={!canPreviousPage}>
                      <PaginationLink onClick={(e) => previousPage()}>
                        {state.pageIndex}
                      </PaginationLink>
                    </PaginationItem>
                  </>
                ) : null}
                <PaginationItem className="active">
                  <PaginationLink>{state.pageIndex + 1}</PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={!canNextPage}>
                  <PaginationLink onClick={(e) => nextPage()}>
                    {state.pageIndex + 2}
                    <span className="sr-only">(current)</span>
                  </PaginationLink>
                </PaginationItem>

                <PaginationItem disabled={!canNextPage}>
                  <PaginationLink onClick={(e) => gotoPage(pageCount - 1)}>
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </CardFooter>
        </Col>

        <Col xl="6">
          <Card className="shadow">
            <CardHeader className="bg-transparent">
              <Row className="align-items-center">
                <div className="col">
                  <h6 className="text-uppercase text-muted ls-1 mb-1">
                    Performance
                  </h6>
                  <h2 className="mb-0">Desempenho de programação</h2>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              {/* Chart */}
              <div className="chart">
                <Bar data={dataGraph} options={options} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Team;
