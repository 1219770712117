import { useState, createRef, memo } from "react";
import { Button, Input } from "reactstrap";
import { Container, CtnInputs, Title } from "./styles";
import TextField from "@mui/material/TextField";
import axios from "axios";
import NotificationAlert from "react-notification-alert";
import { useHistory } from "react-router-dom";

const notification = createRef();
const LoginAdministrator = () => {
  const history = useHistory();
  let [email, setEmail] = useState();
  const [password, setPassword] = useState("");
  const [yupEmailMessage, setYupEmailMessage] = useState("");
  const [yupEmailError, setYupEmailError] = useState(false);
  const [yupPasswordMessage, setYupPasswordMessage] = useState("");
  const [yupPasswordError, setYupPasswordError] = useState(false);

  const login = async (e) => {
    e.preventDefault();
    if (email && password) {
      const data = {
        email: email,
        password: password,
      };
      try {
        console.log("entrou");
        const response = await axios.post(
          "https://api.darwinx.com.br/administrator",
          data
        );
        localStorage.setItem("tokenAdministrator", response.data.token);
        localStorage.setItem("nameAdministrator", email);
        notifyCode(
          "success",
          "Usuário logado com sucesso!",
          "O usuário foi logado com sucesso!"
        );
        setYupPasswordMessage("");
        setYupPasswordError(false);
        setYupEmailMessage("");
        setYupEmailError(false);
        history.push("/auth/management");
      } catch (error) {
        notifyCode(
          "danger",
          "Usuário ou senha incorretos!",
          "O usuário ou senha que digitou está incorreto!"
        );
        console.log(error.message);
      }
    } else if (password && !email) {
      setYupPasswordMessage("");
      setYupPasswordError(false);
      console.log("entrou no yup email");
      setYupEmailMessage("O email é obrigatório");
      setYupEmailError(true);
    } else if (email && !password) {
      setYupEmailMessage("");
      setYupEmailError(false);
      console.log("entrou no password");
      setYupPasswordMessage("A senha é obrigatória");
      setYupPasswordError(true);
    } else {
      setYupPasswordMessage("A senha é obrigatória");
      setYupPasswordError(true);
      setYupEmailMessage("O email é obrigatório");
      setYupEmailError(true);
    }
  };
  const notifyCode = (event, title, content) => {
    let options = {
      place: "tc",
      message: (
        <div>
          <h2
            style={{ color: "#fff", marginBottom: "0" }}
            className="alert-title"
            data-notify="title"
          >
            {" "}
            {title ? title : "Atenção!"}
            <br />
          </h2>
          <span data-notify="message">
            {content ? content : "Este código já foi cadastrado!"}
          </span>
        </div>
      ),
      type: event,
      // icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    notification.current.notificationAlert(options);
  };

  return (
    <>
      <NotificationAlert ref={notification} />
      <Container>
        <form onSubmit={login}>
          <Title>Só para administradores da darwinX</Title>
          <CtnInputs>
            <TextField
              error={yupEmailError}
              value={email}
              style={{ width: "50%" }}
              id="outlined-basic"
              label="Email"
              variant="outlined"
              onChange={(e) => setEmail(e.target.value)}
              helperText={yupEmailMessage}
            />
          </CtnInputs>
          <CtnInputs>
            <TextField
              error={yupPasswordError}
              helperText={yupPasswordMessage}
              type="password"
              style={{ width: "50%" }}
              id="outlined-basic"
              label="Senha"
              variant="outlined"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </CtnInputs>
          <CtnInputs>
            <Button type="submit" color="danger">
              Login
            </Button>
          </CtnInputs>
        </form>
      </Container>
    </>
  );
};

export default memo(LoginAdministrator);
