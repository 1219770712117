import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";

import { Container, Row, Col } from "reactstrap";
import Login from "../views/examples/Login";
import PasswordForgot from "../components/passwordforgot";
import LoginAdministrator from "../pages/LoginAdministrator";
import PasswordReset from "../components/passwordreset";
import AdministratorManagement from "../pages/AdministratorManagement";
const Auth = () => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  return (
    <>
      <div
        className={
          location.pathname === "/auth/management" ? "" : "main-content"
        }
        ref={mainContent}
      >
        <div
          className={
            location.pathname === "/auth/management"
              ? ""
              : "header bg-white py-7 py-lg-8"
          }
        >
          {location.pathname === "/auth/management" ? (
            <Switch>
              <Route path="/auth/login" component={Login} />
              <Route path="/auth/password/forgot" component={PasswordForgot} />
              <Route path="/auth/password/reset" component={PasswordReset} />
              <Route
                path="/auth/administrator"
                component={LoginAdministrator}
              />
              <Route
                path="/auth/management"
                component={AdministratorManagement}
              />
              <Redirect from="*" path="/auth/login" />
            </Switch>
          ) : (
            <Container>
              <div
                className={
                  location.pathname === "/auth/management"
                    ? ""
                    : "header-body text-center mb-7"
                }
              >
                <Row>
                  <Col>
                    {location.pathname !== "/auth/management" && (
                      <img
                        alt="..."
                        src={require("../assets/img/brand/logo.svg").default}
                      />
                    )}
                    <Switch>
                      <Route path="/auth/login" component={Login} />
                      <Route
                        path="/auth/password/forgot"
                        component={PasswordForgot}
                      />
                      <Route
                        path="/auth/password/reset"
                        component={PasswordReset}
                      />
                      <Route
                        path="/auth/administrator"
                        component={LoginAdministrator}
                      />
                      <Route
                        path="/auth/managment"
                        component={AdministratorManagement}
                      />
                      <Redirect from="*" path="/auth/login" />
                    </Switch>
                  </Col>
                </Row>
              </div>
            </Container>
          )}
        </div>
      </div>
    </>
  );
};

export default Auth;
