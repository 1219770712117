import { useState, useEffect } from "react";

import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import Orders from "../Overview/Orders";
import Assets from "../Overview/Management";
import Spares from "../Overview/Spares";
import Requests from "../Overview/Requests";
import Team from "../Overview/Team";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import GroupIcon from "@mui/icons-material/Group";
import "./MenuDashboard.css";

const MenuDashboard = () => {
  // state = {
  //   tabs: 2
  // };
  // toggleNavs = (e, state, index) => {
  //   e.preventDefault();
  //   this.setState({
  //     [state]: index
  //   });
  // };
  // render() {
  const [value, setValue] = useState(0);
  const [content, setContent] = useState();
  useEffect(() => {
    if (
      window.screen.width >= 750 &&
      window.screen.width <= 970 &&
      window.screen.width <= 670
    ) {
      setContent("");
    } else {
      setContent(null);
    }
  }, []);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
    // TabPanel.propTypes = {
    //   children: PropTypes.node,
    //   index: PropTypes.number.isRequired,
    //   value: PropTypes.number.isRequired,
    // };
  }
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const theme = useTheme();
  return (
    <>
      <div className="nav-wrapper header bg-gradient-info pb-3 pt-5 pt-md-7">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="2">
                <CardBody>
                  <Row>
                    {/* <div className="col">
                          <Nav
            className="nav-fill flex-column flex-md-row"
            id="tabs-icons-text"
            pills
            role="tablist"
          >
                                        <NavLink
                aria-selected={this.state.tabs === 5}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: this.state.tabs === 5
                })}
                onClick={e => this.toggleNavs(e, "tabs", 5)}
                 
                role="tab"
              >
                USUÁRIOS
              </NavLink>
              </Nav>
                      </div>

                    </Row>
                    </CardBody>
                </Col>

                <Col lg="6" xl="2">
                  <CardBody>
                    <Row>
                      <div className="col">

<Nav
            className="nav-fill flex-column flex-md-row"
            id="tabs-icons-text"
            pills
            role="tablist"
          >

                                        <NavLink
                aria-selected={this.state.tabs === 3}
                className={classnames("", {
                  active: this.state.tabs === 3
                })}
                onClick={e => this.toggleNavs(e, "tabs", 3)}
                 
                role="tab"
              >
                ESTOQUE
              </NavLink>

              </Nav>
                      </div>

                    </Row>
                    </CardBody>
                </Col>

                <Col lg="6" xl="2">
                  <CardBody>
                    <Row>
                      <div className="col">
                          <Nav
            className="nav-fill flex-column flex-md-row"
            id="tabs-icons-text"
            pills
            role="tablist"
          >
                                        <NavLink
                aria-selected={this.state.tabs === 4}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: this.state.tabs === 4
                })}
                onClick={e => this.toggleNavs(e, "tabs", 4)}
                 
                role="tab"
              >
                REQUISIÇÕES
              </NavLink>
              </Nav>
                      </div>
                      

                    </Row>
                    </CardBody>
                </Col>

              <Col lg="6" xl="2">
                  <CardBody>
                    <Row>
                      <div className="col">
                          <Nav
            className="nav-fill flex-column flex-md-row"
            id="tabs-icons-text"
            pills
            role="tablist"
          >
                                        <NavLink
                aria-selected={this.state.tabs === 1}
                className={classnames("", {
                  active: this.state.tabs === 1
                })}
                onClick={e => this.toggleNavs(e, "tabs", 1)}
                 
                role="tab"
              >
                ORDENS
              </NavLink>
</Nav>

                      </div>

                    </Row>
                    </CardBody>
                </Col>

                <Col lg="6" xl="2">
                  <CardBody>
                    <Row>
                      <div className="col">
<Nav
            className="nav-fill flex-column flex-md-row"
            id="tabs-icons-text"
            pills
            role="tablist"
          >

                                        <NavLink
                aria-selected={this.state.tabs === 2}
                className={classnames("", {
                  active: this.state.tabs === 2
                })}
                onClick={e => this.toggleNavs(e, "tabs", 2)}
                 
                role="tab"
              >
                ATIVOS
              </NavLink>

              </Nav>
                      </div> */}
                  </Row>
                </CardBody>
              </Col>
            </Row>
          </div>
          <Box style={{ border: "none" }}>
            <AppBar
              style={{
                border: "none",
                background: "transparent",
                boxShadow: "none",
              }}
              position="static"
            >
              <Tabs
                style={{
                  background: "transparent",
                  boxShadow: "none",
                  border: "none",
                  color: "#fff",
                }}
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
              >
                <Tab
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                  icon={<GroupIcon style={{ marginRight: "1em" }} />}
                  label="Usuários"
                  {...a11yProps(0)}
                />
                <Tab
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                  icon={
                    <i
                      style={{ marginRight: "1em" }}
                      className="ni ni-building text-white"
                    ></i>
                  }
                  label="Estoque"
                  {...a11yProps(1)}
                />
                <Tab
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                  icon={
                    <i
                      style={{ marginRight: "1em" }}
                      className="ni ni-mobile-button text-white"
                    ></i>
                  }
                  label="Requisições"
                  {...a11yProps(2)}
                />
                <Tab
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                  icon={
                    <i
                      style={{ marginRight: "1em" }}
                      className="ni ni-single-copy-04 text-white"
                    ></i>
                  }
                  label="Ordens"
                  {...a11yProps(3)}
                />
                <Tab
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                  icon={
                    <i
                      style={{ marginRight: "1em" }}
                      className="ni ni-settings text-white"
                    ></i>
                  }
                  label="Gestão"
                  {...a11yProps(4)}
                />
              </Tabs>
            </AppBar>
          </Box>
        </Container>
      </div>
      {/* <Card className="shadow">
          <CardBody>
            <TabContent >
              <TabPane tabId="tabs1">
                <Orders />

              </TabPane>
              <TabPane tabId="tabs2">
                <Assets />

              </TabPane>
              <TabPane tabId="tabs3">
                <Spares />

              </TabPane>
              <TabPane tabId="tabs4">
                <Requests />

              </TabPane>
              <TabPane tabId="tabs5">
                <Team />

              </TabPane>
            </TabContent>
          </CardBody>
        </Card> */}
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Team />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Spares />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <Requests />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <Orders />
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          <Assets />
        </TabPanel>
      </SwipeableViews>
    </>
  );
  // }
};

export default MenuDashboard;
