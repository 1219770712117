import { Badge } from "reactstrap";

const templatePriority = (props) => {
  switch (props?.prioridade) {
    case "Nenhuma":
      return <Badge color="info">Nenhuma</Badge>;
    case "Baixa":
      return <Badge color="success">Baixa</Badge>;
    case "Média":
      return (
        <Badge
          style={{
            background: "#FFD600",
            color: "rgba(29, 27, 27, 0.844)",
            fontWeight: "600",
          }}
          color="warning"
        >
          Média
        </Badge>
      );
    case "Alta":
      return <Badge color="danger">Alta</Badge>;
    default:
      return <Badge color="info">Nenhuma</Badge>;
  }
};

export default templatePriority;
