import React from "react";

/* node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts */

import Chart from "chart.js";
/* react plugin used to create charts
import { Line, Bar } from "react-chartjs-2"; */
// reactstrap components

// core components
import {
  chartOptions,
  parseOptions,
  //chartExample1,
  // chartExample2,
} from "variables/charts.js";

import MenuDashboard from "components/Navbars/MenuDashboard";

const Index = (props) => {
  /* const [activeNav, setActiveNav] = useState(1);
  const [chartExample1Data, setChartExample1Data] = useState("data1"); */

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  /* const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
  }; */

  return (
    <>
      <MenuDashboard />
    </>
  );
};

export default Index;
