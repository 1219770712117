import { convertDate } from "../../utils/convertDate.js";
import moment from "moment";

import { DatePickerComponent } from "./styles";

const DatePicker = ({ setDate, date, placeholder, disable, title }) => {
  const brDateFormat = "YYYY-MM-DD";

  let dateValue = date ? moment(date).format() : new Date();

  return (
    <>
      {title && <p style={{ margin: 0 }}>{title}</p>}
      <DatePickerComponent
        type={"date"}
        placeholder={placeholder}
        disabled={disable ?? false}
        value={convertDate(dateValue, brDateFormat)}
        onChange={(e) => setDate(e.target?.value)}
      />
    </>
  );
};

export default DatePicker;
