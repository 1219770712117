import { useState, useEffect, useContext, createRef } from "react";
import CreateUserManagment from "./components/CreateEnterpriseManagement";
import { EnterpriseNameContext } from "context/EnterpriseContext";
import UserService from "../../components/Users/UserService";
import NotificationAlert from "react-notification-alert";
import { useHistory } from "react-router-dom";
import AdminManagement from "./components/AdminManagement";

const notification = createRef();

const AdministratorManagement = () => {
  const history = useHistory();

  const { enterpriseName } = useContext(EnterpriseNameContext);
  const [users, setUsers] = useState([]);
  const [usersTemplate, setUsersTemplate] = useState([]);
  const [session, setSession] = useState(() => {
    const session = localStorage.getItem("tokenAdministrator");

    if (session) {
      return session;
    }

    return "";
  });

  useEffect(() => {
    if (!session) {
      history.push("/auth/administrator");
    }

    UserService.getUsers({}).then((response) => {
      console.log("response");
      console.log(response.data);
      console.log(response.data.data);
      setUsers(response.data);
      setUsersTemplate(response.data);
    });
  }, [enterpriseName]);

  const notifyCode = (event, title, content) => {
    let options = {
      place: "tc",
      message: (
        <div>
          <h2
            style={{ color: "#fff", marginBottom: "0" }}
            className="alert-title"
            data-notify="title"
          >
            {" "}
            {title ? title : "Atenção!"}
            <br />
          </h2>
          <span data-notify="message">
            {content ? content : "Algo deu errado!"}
          </span>
        </div>
      ),
      type: event,
      // icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    notification?.current?.notificationAlert(options);
  };

  return (
    <div>
      <NotificationAlert ref={notification} />
      <header
        style={{
          borderBottom: "1px solid #EEEE",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          padding: "2rem 0 1rem 0",
        }}
      >
        <h1>Gestão de usuários</h1>
        {localStorage.getItem("nameAdministrator") === "darwinX" && (
          <CreateUserManagment users={users} setUsers={setUsers} />
        )}
      </header>
      <section
        style={{ display: "flex", backgroundColor: "#FFF", height: "100%" }}
      >
        <aside style={{ marginLeft: "2rem", borderRight: "1px solid #EEE" }}>
          <div>
            <img
              style={{ maxWidth: "300px" }}
              alt="..."
              src={require("../../assets/img/brand/logo.svg").default}
            />
          </div>
        </aside>
        <main style={{ backgroundColor: "#F8F9FE", padding: "1rem" }}>
          <AdminManagement />
        </main>
      </section>
    </div>
  );
};
export default AdministratorManagement;
