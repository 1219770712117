import React, { useState, useEffect, useContext, useMemo } from "react";
import { EnterpriseNameContext } from "../../../context/EnterpriseContext";

import swal from "sweetalert";
import TextField from "@material-ui/core/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ResponsiveLine } from "@nivo/line";
import DeleteIcon from "@material-ui/icons/Delete";

import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";

import { Bar } from "react-chartjs-2";
import moment from "moment";
import FormControlMui from "@mui/material/FormControl";

import {
  Card,
  CardHeader,
  CardBody,
  Progress,
  Table,
  Row,
  Col,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
  Button,
} from "reactstrap";

import { chartOptions, parseOptions } from "variables/charts.js";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Tooltip from "@mui/material/Tooltip";

import DashboardAdminService from "../services/DashboardAdmin.service";
import UserService from "../../../components/Users/UserService";

import CreateUser from "./CreateUserManagement";

var colors = {
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529",
  },
  theme: {
    default: "#172b4d",
    primary: "#5e72e4",
    secondary: "#f4f5f7",
    info: "#11cdef",
    success: "#2dce89",
    danger: "#f5365c",
    warning: "#fb6340",
  },
  black: "#12263F",
  white: "#FFFFFF",
  transparent: "transparent",
};

const AdminManagement = (props) => {
  const levelForm = [
    "Administrador",
    "Corpo Técnico",
    "Executante",
    "Requisitante",
    "Amoxarife",
    "Ferramenteiro",
  ];

  const [toolVisible, setToolVisible] = useState(false);
  const { enterpriseName } = useContext(EnterpriseNameContext);
  const [enterprises, setEnterprises] = useState([]);
  const [cardsAdmin, setCardAdmin] = useState([]);
  const [ordersOpen, setOrdersOpen] = useState([]);
  const [ordersCompleted, setOrdersCompleted] = useState([]);
  const [tableOfManagement, setTableOfManagement] = useState([]);
  const [modal, setModal] = useState(false);
  const [enterpriseData, setEnterpriseData] = useState([]);
  const [materialAutomatic, setMaterialAutomatic] = useState(false);
  const [printOrder, setPrintOrder] = useState(false);
  const [graphHistoric, setGraphHistoric] = useState([]);
  const [paretoGraphFirst, setParetoGraphFirst] = useState({
    names: [],
    quantities: [],
  });
  const [paretoGraphSecond, setParetoGraphSecond] = useState({
    names: [],
    quantities: [],
  });
  const [availabilityGraph, setAvailabilityGraph] = useState([]);
  const [isAdmin] = useState(() => {
    const user = localStorage.getItem("nameAdministrator");
    if (user === "darwinX") {
      return true;
    }
    return false;
  });

  const [user] = useState(() => {
    const userLocalStorage = localStorage.getItem("nameAdministrator");
    return userLocalStorage;
  });

  const toggle = () => setModal(!modal);

  useEffect(() => {
    DashboardAdminService.getData({ firstName: user })
      .then((response) => {
        const data = response.data;
        console.log(data, "data");
        setCardAdmin(data);
        if (data.enterpriseArray) {
          setEnterprises(data.enterpriseArray);
        }
        if (data.paretoOfCost) {
          setParetoGraphSecond(data.paretoOfCost);
        }

        if (data.availabilityGraph) {
          setAvailabilityGraph(data.availabilityGraph);
        }

        if (data.graphHistoric) {
          setGraphHistoric(data.graphHistoric);
        }
        if (data.graphOrdersOpenAndCompleted) {
          setOrdersOpen(data.graphOrdersOpenAndCompleted.ordersOpens);
          setOrdersCompleted(data.graphOrdersOpenAndCompleted.ordersCompleted);
        }

        if (data.tableOfEnterprises && isAdmin) {
          setTableOfManagement(data.tableOfEnterprises);
        }

        if (data.tableOfEnterprises && !isAdmin) {
          setTableOfManagement(
            data.tableOfEnterprises.filter((data) => {
              return (
                data.enterprise === "Supermercado Carro Chefe" ||
                data.enterprise === "Supermercado Super Puppo"
              );
            })
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [enterpriseName]);

  // const deleteEnterprise = (enterprise) => {
  //   if (enterprise) {
  //     swal("Deseja realmente apagar a empresa?", "", "warning", {
  //       buttons: {
  //         nope: {
  //           text: "Me enganei!",
  //           value: "nope",
  //         },
  //         sure: {
  //           text: "Ok",
  //           value: "sure",
  //         },
  //       },
  //     })
  //       .then(async (result) => {
  //         switch (result) {
  //           case "sure":
  //             await DeleteEnterpriseService.deleteEnteprise(enterprise, {
  //               empresa: enterprise,
  //             });
  //             swal("Operação realizada com sucesso!", "", "success");
  //             break;
  //           case "nope":
  //             swal("Operação cancelada", "", "success");
  //             break;
  //           default:
  //         }
  //       })
  //       .catch((error) => {
  //         swal("Aconteceu um erro", "", "danger");
  //       });
  //   }
  // };

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const columnHeader = [
    {
      Header: "Master",
      Footer: "Master",
      accessor: "name",
      sticky: "left",
    },
    {
      Header: "Email",
      Footer: "Email",
      accessor: "email",
      sticky: "left",
    },
    {
      Header: "Empresa",
      Footer: "Empresa",
      accessor: "enterprise",
      sticky: "left",
    },
    {
      Header: "Usuários",
      Footer: "Usuários",
      accessor: "quantityOfUsers",
      sticky: "left",
    },
    {
      Header: "Data Início",
      Footer: "Data Início",
      accessor: (originalRow, rowIndex) => {
        if (originalRow.created) {
          return moment(originalRow.created).format("DD/MM/YYYY");
        } else if (originalRow.createdAt) {
          return moment(originalRow.createdAt).format("DD/MM/YYYY");
        }
      },
      sticky: "left",
    },
    // ...(isAdmin && {
    //   Header: "Excluir Empresa",
    //   Footer: "Excluir Empresa",
    //   accessor: "execution",
    //   accessor: (originalRow, rowIndex) => {
    //     return (
    //       <Button
    //         onClick={() => deleteEnterprise(originalRow.enterprise)}
    //         color="danger"
    //       >
    //         Excluir
    //       </Button>
    //     );
    //   },
    //   sticky: "left",
    // }),
  ];

  const columns = useMemo(() => columnHeader, []);
  const data = useMemo(() => tableOfManagement, [tableOfManagement]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const [startDate, setStartDate] = useState(
    new Date(new Date().setFullYear(new Date().getFullYear() - 1))
  );
  const [endDate, setEndDate] = useState(new Date());

  const [selectedEnterprise, setSelectedEnterprise] = useState([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const DataLine_Abertas_Concluídas = [
    {
      id: "Ordens Abertas",
      color: "hsl(281, 70%, 50%)",
      data: ordersOpen,
    },
    {
      id: "Ordens Concluídas",
      color: "hsl(281, 70%, 50%)",
      data: ordersCompleted,
    },
  ];

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedEnterprise(typeof value === "string" ? value.split(",") : value);
  };

  const [lineSource, setLineSource] = useState({});

  const lineClicked = (data) => {
    setModal(true);
    if (data) {
      UserService.getUsers({ empresa: data.enterprise })
        .then((response) => {
          setEnterpriseData(response.data);
        })
        .catch((error) => {
          console.error(error);
        });

      if (data.id) {
        UserService.getUserById(data.id)
          .then((response) => {
            setMaterialAutomatic(response.data.materialAutomatic ?? false);
            setSignatureRequiredInOrder(
              response.data.signatureRequiredInOrder ?? false
            );
            setToolVisible(response.data.toolVisible ?? false);
          })
          .catch((error) => {
            console.error(error);
          });
      }

      setLineSource(data);
    }
  };

  const columnHeaderModal = [
    {
      Header: "Nome",
      Footer: "Nome",
      accessor: "first_name",
      sticky: "left",
      width: 250,
    },
    {
      Header: "E-mail",
      Footer: "E-mail",
      accessor: "email",
      sticky: "left",
      width: 250,
    },
    {
      Header: "Função",
      Footer: "Função",
      accessor: "level",
      sticky: "left",
      width: 200,
    },
    {
      Header: "Fone",
      Footer: "Fone",
      accessor: "phone",
      sticky: "left",
      width: 200,
    },
    {
      Header: "Último login",
      Footer: "Último login",
      accessor: (originalRow) => {
        if (!originalRow.lastLogin) {
          return " - ";
        }
        return moment(originalRow.lastLogin).format("DD/MM/YYYY  HH:mm");
      },
      sticky: "left",
    },
  ];

  const columnsModal = useMemo(() => columnHeaderModal, []);
  const dataModal = useMemo(() => enterpriseData, [enterpriseData]);

  const {
    getTableProps: getTablePropsModal,
    getTableBodyProps: getTableBodyPropsModal,
    headerGroups: headerGroupsModal,
    page: pageModal,
    nextPage: nextPageModal,
    previousPage: previousPageModal,
    canPreviousPage: canPreviousPageModal,
    canNextPage: canNextPageModal,
    state: stateModal,
    gotoPage: gotoPageModal,
    pageCount: pageCountModal,
    prepareRow: prepareRowModal,
  } = useTable(
    {
      columns: columnsModal,
      data: dataModal,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const [first_name, setFirst_name] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [level, setLevel] = useState("");
  const [area, setArea] = useState("");
  const [row_id, setRow_id] = useState("");
  const [signatureRequiredInOrder, setSignatureRequiredInOrder] =
    useState(false);

  const [modalSecond, setModalSecond] = useState(false);

  const toggleSecond = () => setModalSecond(!modalSecond);

  function rowClicked(row) {
    setRow_id(row);
    setModalSecond(true);
    UserService.getUserById(row).then((res) => {
      let users = res.data;
      setFirst_name(users.first_name);
      setArea(users.area);
      setEmail(users.email);
      setLevel(users.level);
      setPrintOrder(users?.printOrder);
      setPhone(users.phone);
    });
  }

  const updateMaterialAutomatic = (value) => {
    const materialAutomaticDTO = {
      materialAutomatic: value,
    };
    UserService.updateUser(materialAutomaticDTO, lineSource.id).then(() => {
      UserService.getUsers({ empresa: lineSource.enterprise })
        .then((response) => {
          setEnterpriseData(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  };

  const updatePrintOrder = (value) => {
    const printOrderDTO = {
      printOrder: value,
    };
    UserService.updateUser(printOrderDTO, lineSource.id).then((userUpdated) => {
      UserService.getUsers({ empresa: lineSource.enterprise })
        .then((response) => {
          setEnterpriseData(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  };

  const updateSignatureRequiredInOrder = (value) => {
    const signatureRequiredInOrderDTO = {
      signatureRequiredInOrder: value,
    };
    UserService.updateUser(signatureRequiredInOrderDTO, lineSource.id).then(
      (userUpdated) => {
        UserService.getUsers({ empresa: lineSource.enterprise })
          .then((response) => {
            setEnterpriseData(response.data);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    );
  };

  const updateToolVisible = (value) => {
    const toolVisibleDTO = {
      toolVisible: value,
    };
    UserService.updateUser(toolVisibleDTO, lineSource.id).then(() => {
      UserService.getUsers({ empresa: lineSource.enterprise })
        .then((response) => {
          setEnterpriseData(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  };

  const update = () => {
    let users = {
      first_name,
      area,
      email,
      level,
      phone,
    };
    UserService.updateUser(users, row_id)
      .then(() => {
        UserService.getUsers({ empresa: lineSource.enterprise }).then(
          (response) => {
            setEnterpriseData(response.data);
          }
        );
        setModalSecond(!modalSecond);
      })
      .catch((error) => console.error(error.response));
  };

  const deleteUser = () => {
    swal("Tem certeza que deletar o usuário?", "", "warning", {
      buttons: {
        nope: {
          text: "Me enganei!",
          value: "nope",
        },
        sure: {
          text: "Sim, claro.",
          value: "sure",
        },
      },
    }).then((value) => {
      switch (value) {
        case "sure":
          swal("Usuário deletado com sucesso", "", "success").then((val) => {
            UserService.deleteUser(row_id)
              .then((res) => {
                UserService.getUsers({ empresa: lineSource.enterprise }).then(
                  (response) => {
                    setEnterpriseData(response.data);
                    setModalSecond(!modalSecond);
                  }
                );
              })
              .catch((error) => console.error(error.response));
          });
          break;
        case "nope":
          swal("Operação cancelada", "", "success");
          break;
        default:
          swal("Equipamento excluído!");
      }
    });
  };

  const DataLine = [
    {
      id: "Consumo de Materiais",
      color: "hsl(281, 70%, 50%)",
      data: graphHistoric,
    },
  ];

  const Pareto_Consumo_Spare = {
    optionsCost: {
      responsive: true,
      title: {
        display: true,
        text: "Custo",
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              color: colors.gray[900],
              zeroLineColor: colors.gray[900],
            },
            ticks: {
              callback: function (value, index, length) {
                return "R$ " + value;
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (item, data) {
            var label = data.datasets[item.datasetIndex].label || "";
            var yLabel = item.yLabel;
            var content = "";

            if (data.datasets.length > 1) {
              content += label;
            }

            //content += "$" + yLabel + "k";
            content += yLabel;
            return content;
          },
        },
      },
    },
    optionsQuantity: {
      responsive: true,
      title: {
        display: true,
        text: "Quantidade",
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              color: colors.gray[900],
              zeroLineColor: colors.gray[900],
            },
            ticks: {
              callback: function (value, index, length) {
                return value;
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (item, data) {
            var label = data.datasets[item.datasetIndex].label || "";
            var yLabel = item.yLabel;
            var content = "";

            if (data.datasets.length > 1) {
              content += label;
            }

            //content += "$" + yLabel + "k";
            content += yLabel;
            return content;
          },
        },
      },
    },
    data1: (canvas) => {
      return {
        labels: paretoGraphFirst.names,
        datasets: [
          {
            label: "Performance",
            data: paretoGraphFirst.quantities,
          },
        ],
      };
    },
    data2: (canvas) => {
      return {
        labels: paretoGraphSecond.names,
        datasets: [
          {
            label: "Performance",
            data: paretoGraphSecond?.quantities,
          },
        ],
      };
    },
  };

  const availabilityTotal = [
    {
      id: "Disponibilidade",
      color: "hsl(281, 70%, 50%)",
      data: availabilityGraph ?? [],
    },
  ];

  return (
    <>
      <Row>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <FormControlMui sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-checkbox-label">Empresa</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={selectedEnterprise}
              onChange={handleChange}
              input={<OutlinedInput label="Empresa" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {enterprises.map((name) => (
                <MenuItem key={name} value={name}>
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControlMui>
          <Button
            style={{ marginLeft: "10px", marginRight: "20px" }}
            type="button"
            color="success"
            onClick={() => {
              DashboardAdminService.getData({ empresa: selectedEnterprise })
                .then((response) => {
                  const data = response.data;
                  setCardAdmin(data);
                  if (data.paretoOfCost) {
                    setParetoGraphSecond(data.paretoOfCost);
                  }

                  if (data.availabilityGraph) {
                    setAvailabilityGraph(data.availabilityGraph);
                  }

                  if (data.graphHistoric) {
                    setGraphHistoric(data.graphHistoric);
                  }
                  if (data.graphOrdersOpenAndCompleted) {
                    setOrdersOpen(data.graphOrdersOpenAndCompleted.ordersOpens);
                    setOrdersCompleted(
                      data.graphOrdersOpenAndCompleted.ordersCompleted
                    );
                  }
                })
                .catch((error) => {
                  console.error(error);
                });
            }}
          >
            Filtrar
          </Button>
        </div>
        <Col className="mb-5 mb-xl-0" xl="12">
          <div className="header-body">
            <Row>
              <Col lg="6" xl="3">
                <Card>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Número de OS Atendidas
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {cardsAdmin.quantityOfOrders ?? 0}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <Tooltip
                          style={{ cursor: "pointer" }}
                          title="Número de Ordens de Serviço atendidas"
                        >
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Tooltip>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-nowrap">
                        Entre {moment(startDate).format("DD/MM/YYYY")} e{" "}
                        {moment(endDate).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Qtde Equipamento
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {cardsAdmin.quantityOfEquipments ?? 0}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <Tooltip
                          style={{ cursor: "pointer" }}
                          title="Tempo para concluir todas as ordens de manutenção atrasadas usando toda a equipe."
                        >
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-chart-pie" />
                          </div>
                        </Tooltip>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-nowrap">
                        Entre {moment(startDate).format("DD/MM/YYYY")} e{" "}
                        {moment(endDate).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Qtde Usuários
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {cardsAdmin.quantityOfUsers ?? 0}
                        </span>
                      </div>

                      <Col className="col-auto">
                        <Tooltip
                          style={{ cursor: "pointer" }}
                          title="Quantidade de horas programadas"
                        >
                          <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Tooltip>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-nowrap">
                        Entre {moment(startDate).format("DD/MM/YYYY")} e{" "}
                        {moment(endDate).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Fora de Operação
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {cardsAdmin.machineStopped ?? 0}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <Tooltip
                          style={{ cursor: "pointer" }}
                          title={
                            "% de ordens de manutenção concluídas dentro do prazo dentre as que venciam entre " +
                            `${moment(startDate).format(
                              "DD/MM/YYYY"
                            )} e ${moment(endDate).format("DD/MM/YYYY")}`
                          }
                        >
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="fas fa-percent" />
                          </div>
                        </Tooltip>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-nowrap">
                        Entre {moment(startDate).format("DD/MM/YYYY")} e{" "}
                        {moment(endDate).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col className="mb-5 mb-xl-0" xl="12">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col">
                  <h6 className="text-uppercase text-muted ls-1 mb-1">
                    Desempenho da Empresa
                  </h6>
                  <h3 className="mb-0">Empresa</h3>
                </div>
              </Row>
            </CardHeader>
            <Table
              {...getTableProps()}
              className="align-items-center table-flush"
              responsive
              hover
            >
              <thead className="thead-light">
                {headerGroups.map((headerGroup) => (
                  <tr
                    style={{ textAlign: "center", cursor: "pointer" }}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => {
                      return (
                        <>
                          <th
                            className="text-wrap"
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            scope="col"
                          >
                            {column.render("Header")}{" "}
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <ArrowDropDownIcon />
                              ) : (
                                <ArrowDropUpIcon />
                              )
                            ) : (
                              ""
                            )}
                          </th>
                        </>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      style={{ cursor: "pointer", textAlign: "center" }}
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell) => {
                        if (
                          cell.column.Header === "Taxa de ocupação programada"
                        ) {
                          return (
                            <td
                              className="caractersTableSize centerContent"
                              {...cell.getCellProps()}
                            >
                              <div className="d-flex align-items-center justify-content-center">
                                <span className="mr-2">
                                  {(
                                    (cell.row?.original?.duration / 180) *
                                    100
                                  ).toFixed(2)}
                                  %
                                </span>
                                <div>
                                  <Progress
                                    max="100"
                                    value={(
                                      (cell.row?.original?.duration / 180) *
                                      100
                                    ).toFixed(2)}
                                    barClassName="bg-gradient-info"
                                  />
                                </div>
                              </div>
                            </td>
                          );
                        }

                        if (cell.column.Header === "Taxa de execução") {
                          return (
                            <td
                              className="caractersTableSize centerContent"
                              {...cell.getCellProps()}
                            >
                              <div className="d-flex align-items-center justify-content-center">
                                <span className="mr-2">
                                  {cell.render("Cell")}%
                                </span>
                                <div>
                                  <Progress
                                    max="100"
                                    value={(
                                      (cell.row.original?.quantity /
                                        cell.row.original?.quantityOrderTotal) *
                                      100
                                    ).toFixed(2)}
                                    barClassName="bg-gradient-danger"
                                  />
                                </div>
                              </div>
                            </td>
                          );
                        }

                        if (
                          isAdmin &&
                          cell.column.Header === "Excluir Empresa"
                        ) {
                          return (
                            <td
                              className="caractersTableSize"
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        }

                        return (
                          <td
                            className="caractersTableSize"
                            {...cell.getCellProps()}
                            onClick={() => lineClicked(row.original)}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card>
          <CardFooter className="py-4">
            <nav aria-label="...">
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem disabled={!canPreviousPage}>
                  <PaginationLink onClick={() => gotoPage(0)} tabIndex="-1">
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                {state.pageIndex > 0 ? (
                  <>
                    <PaginationItem disabled={!canPreviousPage}>
                      <PaginationLink onClick={(e) => previousPage()}>
                        {state.pageIndex}
                      </PaginationLink>
                    </PaginationItem>
                  </>
                ) : null}
                <PaginationItem className="active">
                  <PaginationLink>{state.pageIndex + 1}</PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={!canNextPage}>
                  <PaginationLink onClick={(e) => nextPage()}>
                    {state.pageIndex + 2}
                    <span className="sr-only">(current)</span>
                  </PaginationLink>
                </PaginationItem>

                <PaginationItem disabled={!canNextPage}>
                  <PaginationLink onClick={(e) => gotoPage(pageCount - 1)}>
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </CardFooter>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col xl="6">
          <Card>
            <CardHeader className="bg-transparent">
              <Row className="align-items-center">
                <Col>
                  <h6 className="text-uppercase ls-1 mb-1">Visão Geral</h6>
                  <h2 className=" mb-0">Ordens Abertas vs Concluídas</h2>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <div style={{ height: 350 }}>
                <ResponsiveLine
                  data={DataLine_Abertas_Concluídas}
                  margin={{ top: 20, right: 110, bottom: 20, left: 60 }}
                  xScale={{ type: "point" }}
                  yScale={{
                    type: "linear",
                    min: "auto",
                    max: "auto",
                    stacked: false,
                    reverse: false,
                  }}
                  yFormat=" >-.0f"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    orient: "bottom",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Mês",
                    legendOffset: 30,
                    legendPosition: "middle",
                  }}
                  axisLeft={{
                    orient: "left",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Quantidade",
                    legendOffset: -40,
                    legendPosition: "middle",
                    tickValues: 8,
                  }}
                  enableGridX={false}
                  enableGridY={false}
                  colors={{ scheme: "set1" }}
                  pointSize={7}
                  pointColor={{ theme: "background" }}
                  pointBorderWidth={2}
                  pointBorderColor={{ from: "serieColor", modifiers: [] }}
                  pointLabelYOffset={-12}
                  useMesh={true}
                  enableArea={false}
                  enableSlices="x"
                  legends={[]}
                  animate={true}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col className="mb-5 mb-xl-0" xl="6">
          <Card>
            <CardHeader className="bg-transparent">
              <Row className="align-items-center">
                <div className="col">
                  <h6 className="text-uppercase ls-1 mb-1">Visão Geral</h6>
                  <h2 className=" mb-0">Histórico de Consumo</h2>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <div style={{ height: 350 }}>
                <ResponsiveLine
                  data={DataLine}
                  margin={{ top: 20, right: 110, bottom: 20, left: 60 }}
                  xScale={{ type: "point" }}
                  yScale={{
                    type: "linear",
                    min: 0,
                    max: "auto",
                    stacked: true,
                    reverse: false,
                  }}
                  yFormat=" >-.2f"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    orient: "bottom",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Mês",
                    legendOffset: 30,
                    legendPosition: "middle",
                  }}
                  axisLeft={{
                    orient: "left",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Quantidade",
                    legendOffset: -40,
                    legendPosition: "middle",
                    tickValues: 4,
                  }}
                  enableGridX={false}
                  enableGridY={false}
                  colors={{ scheme: "set1" }}
                  pointSize={7}
                  pointColor={{ theme: "background" }}
                  pointBorderWidth={2}
                  pointBorderColor={{ from: "serieColor", modifiers: [] }}
                  pointLabelYOffset={-12}
                  useMesh={true}
                  enableArea={true}
                  enableSlices="x"
                  legends={[]}
                  animate={true}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col className="mb-5 mb-xl-0" xl="6">
          <Card>
            <CardHeader className="bg-transparent">
              <Row className="align-items-center">
                <div className="col">
                  <h6 className="text-uppercase ls-1 mb-1">Pareto</h6>
                  <h2 className="mb-0">Custo total de manutenção</h2>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <div className="chart">
                <Bar
                  data={Pareto_Consumo_Spare["data2"]}
                  options={Pareto_Consumo_Spare.optionsCost}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col className="mb-5 mb-xl-0" xl="6">
          <Card>
            <CardHeader className="bg-transparent">
              <Row className="align-items-center">
                <div className="col">
                  <h6 className="text-uppercase ls-1 mb-1">Visão Geral</h6>
                  <h2 className=" mb-0">Disponibilidade (%)</h2>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <div className="chart">
                <ResponsiveLine
                  data={availabilityTotal}
                  margin={{ top: 20, right: 110, bottom: 20, left: 60 }}
                  xScale={{ type: "point" }}
                  yScale={{
                    type: "linear",
                    min: 0,
                    max: 100,
                    stacked: false,
                    reverse: false,
                  }}
                  // yFormat=" >-.3%"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    orient: "bottom",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Mês",
                    legendOffset: 30,
                    legendPosition: "middle",
                  }}
                  axisLeft={{
                    orient: "left",
                    tickSize: 5,
                    tickPadding: 0,
                    format: (value) => {
                      return `${parseFloat(value)}%`;
                    },
                  }}
                  enableGridX={false}
                  enableGridY={false}
                  colors={{ scheme: "set1" }}
                  pointSize={7}
                  pointColor={{ theme: "background" }}
                  pointBorderWidth={2}
                  pointBorderColor={{ from: "serieColor", modifiers: [] }}
                  pointLabelYOffset={-12}
                  useMesh={true}
                  enableArea={false}
                  enableSlices="x"
                  legends={[]}
                  animate={true}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal
        isOpen={modal}
        toggle={toggle}
        style={{
          maxWidth: "90%",
        }}
      >
        <ModalHeader className="mt-3" toggle={toggle} tag="h2">
          Detalhe da empresa: <b>{lineSource.enterprise}</b>
        </ModalHeader>
        <ModalBody className="mb-1 mt--2 mb-2">
          <Row className="mt-3">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <h3>Configurações da empresa</h3>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <FormControl
                      style={{ display: "block" }}
                      className="form-group"
                      component="fieldset"
                    >
                      <FormLabel component="legend">
                        Baixa de material automática?
                      </FormLabel>
                      <Col>
                        <RadioGroup
                          row
                          aria-label="position"
                          name="position"
                          defaultValue="top"
                        >
                          <FormControlLabel
                            control={<Radio color="primary" />}
                            label="Sim"
                            checked={materialAutomatic}
                            onChange={(e, v) => {
                              if (v) {
                                updateMaterialAutomatic(!materialAutomatic);
                                setMaterialAutomatic(!materialAutomatic);
                              }
                            }}
                          />
                          <FormControlLabel
                            control={<Radio color="primary" />}
                            label="Não"
                            checked={!materialAutomatic}
                            onChange={(e, v) => {
                              if (v) {
                                updateMaterialAutomatic(!materialAutomatic);
                                setMaterialAutomatic(!materialAutomatic);
                              }
                            }}
                          />
                        </RadioGroup>
                      </Col>
                    </FormControl>
                  </Row>
                  <Row style={{ marginTop: "1rem" }}>
                    <FormControl
                      style={{ display: "block" }}
                      className="form-group"
                      component="fieldset"
                    >
                      <FormLabel component="legend">
                        Assinatura no encerramento da ordem?
                      </FormLabel>
                      <Col>
                        <RadioGroup
                          row
                          aria-label="position"
                          name="position"
                          defaultValue="top"
                        >
                          <FormControlLabel
                            control={<Radio color="primary" />}
                            label="Sim"
                            checked={signatureRequiredInOrder}
                            onChange={(e, v) => {
                              if (v) {
                                updateSignatureRequiredInOrder(
                                  !signatureRequiredInOrder
                                );
                                setSignatureRequiredInOrder(
                                  !signatureRequiredInOrder
                                );
                              }
                            }}
                          />
                          <FormControlLabel
                            control={<Radio color="primary" />}
                            label="Não"
                            checked={!signatureRequiredInOrder}
                            onChange={(e, v) => {
                              if (v) {
                                updateSignatureRequiredInOrder(
                                  !signatureRequiredInOrder
                                );
                                setSignatureRequiredInOrder(
                                  !signatureRequiredInOrder
                                );
                              }
                            }}
                          />
                        </RadioGroup>
                      </Col>
                    </FormControl>
                  </Row>
                  <Row style={{ marginTop: "1rem" }}>
                    <FormControl
                      style={{ display: "block" }}
                      className="form-group"
                      component="fieldset"
                    >
                      <FormLabel component="legend">
                        Ferramentaria visível?
                      </FormLabel>
                      <Col>
                        <RadioGroup
                          row
                          aria-label="position"
                          name="position"
                          defaultValue="top"
                        >
                          <FormControlLabel
                            control={<Radio color="primary" />}
                            label="Sim"
                            checked={toolVisible}
                            onChange={(e, v) => {
                              if (v) {
                                updateToolVisible(!toolVisible);
                                setToolVisible(!toolVisible);
                              }
                            }}
                          />
                          <FormControlLabel
                            control={<Radio color="primary" />}
                            label="Não"
                            checked={!toolVisible}
                            onChange={(e, v) => {
                              if (v) {
                                updateToolVisible(!toolVisible);
                                setToolVisible(!toolVisible);
                              }
                            }}
                          />
                        </RadioGroup>
                      </Col>
                    </FormControl>
                  </Row>
                  <Row>
                    <FormControl
                      style={{ display: "block" }}
                      className="form-group"
                      component="fieldset"
                    >
                      <FormLabel component="legend">
                        Print de ordem concluída?
                      </FormLabel>
                      <Col>
                        <RadioGroup
                          row
                          aria-label="position"
                          name="position"
                          defaultValue="top"
                        >
                          <FormControlLabel
                            control={<Radio color="primary" />}
                            label="Sim"
                            checked={printOrder}
                            onChange={(e, v) => {
                              if (v) {
                                updatePrintOrder(!printOrder);
                                setPrintOrder(!printOrder);
                              }
                            }}
                          />
                          <FormControlLabel
                            control={<Radio color="primary" />}
                            label="Não"
                            checked={!printOrder}
                            onChange={(e, v) => {
                              if (v) {
                                updatePrintOrder(!printOrder);
                                setPrintOrder(!printOrder);
                              }
                            }}
                          />
                        </RadioGroup>
                      </Col>
                    </FormControl>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Usuários da empresa: <b>{lineSource.enterprise}</b>
                      </h6>
                      <h3 className="mb-0">Usuários</h3>
                    </div>
                    <CreateUser
                      setEnterpriseData={setEnterpriseData}
                      enterprise={lineSource.enterprise}
                      lineSource={lineSource}
                    />
                  </Row>
                </CardHeader>
                <Table
                  {...getTablePropsModal()}
                  className="align-items-center table-flush"
                  responsive
                  hover
                >
                  <thead className="thead-light">
                    {headerGroupsModal.map((headerGroup) => (
                      <tr
                        style={{ textAlign: "center", cursor: "pointer" }}
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column) => {
                          return (
                            <>
                              <th
                                className="text-wrap"
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                                scope="col"
                              >
                                {column.render("Header")}{" "}
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <ArrowDropDownIcon />
                                  ) : (
                                    <ArrowDropUpIcon />
                                  )
                                ) : (
                                  ""
                                )}
                              </th>
                            </>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyPropsModal()}>
                    {pageModal.map((row) => {
                      prepareRowModal(row);
                      return (
                        <tr
                          style={{ cursor: "pointer", textAlign: "center" }}
                          {...row.getRowProps()}
                          onClick={() => rowClicked(row.original?._id)}
                        >
                          {row.cells.map((cell) => {
                            if (
                              cell.column.Header ===
                              "Taxa de ocupação programada"
                            ) {
                              return (
                                <td
                                  className="caractersTableSize centerContent"
                                  {...cell.getCellProps()}
                                >
                                  <div className="d-flex align-items-center justify-content-center">
                                    <span className="mr-2">
                                      {(
                                        (cell.row.original?.duration / 180) *
                                        100
                                      ).toFixed(2)}
                                      %
                                    </span>
                                    <div>
                                      <Progress
                                        max="100"
                                        value={(
                                          (cell.row.original?.duration / 180) *
                                          100
                                        ).toFixed(2)}
                                        barClassName="bg-gradient-info"
                                      />
                                    </div>
                                  </div>
                                </td>
                              );
                            }

                            if (cell.column.Header === "Taxa de execução") {
                              return (
                                <td
                                  className="caractersTableSize centerContent"
                                  {...cell.getCellProps()}
                                >
                                  <div className="d-flex align-items-center justify-content-center">
                                    <span className="mr-2">
                                      {cell.render("Cell")}%
                                    </span>
                                    <div>
                                      <Progress
                                        max="100"
                                        value={(
                                          (cell.row.original?.quantity /
                                            cell.row?.original
                                              ?.quantityOrderTotal) *
                                          100
                                        ).toFixed(2)}
                                        barClassName="bg-gradient-danger"
                                      />
                                    </div>
                                  </div>
                                </td>
                              );
                            }

                            return (
                              <td
                                className="caractersTableSize"
                                {...cell.getCellProps()}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={!canPreviousPageModal}>
                      <PaginationLink
                        onClick={(e) => gotoPageModal(0)}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    {stateModal.pageIndex > 0 ? (
                      <>
                        <PaginationItem disabled={!canPreviousPageModal}>
                          <PaginationLink onClick={(e) => previousPageModal()}>
                            {stateModal.pageIndex}
                          </PaginationLink>
                        </PaginationItem>
                      </>
                    ) : null}
                    <PaginationItem className="active">
                      <PaginationLink>
                        {stateModal.pageIndex + 1}
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem disabled={!canNextPageModal}>
                      <PaginationLink onClick={(e) => nextPageModal()}>
                        {stateModal.pageIndex + 2}
                        <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>

                    <PaginationItem disabled={!canNextPageModal}>
                      <PaginationLink
                        onClick={(e) => gotoPageModal(pageCountModal - 1)}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalSecond}
        toggle={toggleSecond}
        size="lg"
        className="mr-0 mt--1 mb--1 mt-5"
      >
        <ModalHeader className="mt-3" toggle={toggleSecond} tag="h2">
          Editar Usuário{" "}
        </ModalHeader>
        <ModalBody className="mb-1 mt--2 mb-2">
          <form>
            <div className="form-group">
              <label> Nome: </label>
              <input
                name="name"
                className="form-control"
                value={first_name}
                onChange={(e) => setFirst_name(e.target?.value)}
              />
            </div>
            <div className="form-group">
              <label> E-mail: </label>
              <input
                name="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target?.value)}
              />
            </div>

            <div className="form-group">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={levelForm}
                onChange={(e, v) => {
                  setLevel(v);
                }}
                value={level}
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} label="Função" />
                )}
              />
            </div>

            <div className="form-group">
              <label> Telefone: </label>
              <input
                placeholder="telefone para contato"
                name="phone"
                className="form-control"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </form>

          <Row>
            <Col className="mb-5 mb-xl-0" xl="9">
              <Button color="success" onClick={update}>
                Atualizar
              </Button>{" "}
              <Button color="secondary" onClick={toggleSecond}>
                Cancelar
              </Button>
            </Col>

            <Col className="">
              <Button color="secondary" onClick={deleteUser}>
                <DeleteIcon /> Apagar
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AdminManagement;
