import "./style.css";

const TimePicker = ({ setTime, time, placeholder, disable, auxFunction }) => {
  return (
    <>
      <input
        disabled={disable ?? false}
        placeholder={placeholder}
        value={time}
        className="datetimePicker"
        type={"time"}
        onChange={(e) => {
          if (setTime) {
            setTime(e.target.value);
          }
          if (auxFunction) auxFunction();
        }}
      />
    </>
  );
};

export default TimePicker;
