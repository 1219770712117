// GESTÃO DE USUARIOS

import axios from "axios";

const Equipment_API_BASE_URL = "https://api.darwinx.com.br/equipments";

class EquipmentService {
  getEquipment(equipment) {
    console.log(equipment);
    return axios.get(Equipment_API_BASE_URL, { params: equipment });
  }

  createEquipment(equipment) {
    return axios.post(Equipment_API_BASE_URL, equipment);
  }

  getEquipmentById(equipmentId, token) {
    return axios.get(Equipment_API_BASE_URL + "/" + equipmentId, {
      params: token,
    });
  }

  updateEquipment(equipment, equipmentId) {
    return axios.put(Equipment_API_BASE_URL + "/" + equipmentId, equipment);
  }

  deleteEquipment(equipmentId, token) {
    return axios.delete(Equipment_API_BASE_URL + "/" + equipmentId, {
      params: token,
    });
  }
}

export default new EquipmentService();
