import { useState } from "react";
import moment from "moment";
import "moment/locale/pt-br";
import {
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Col,
    Row
  } from "reactstrap";

import ReactDatetime from "react-datetime";

const DatepickerArgon = (props) => {
    return (
        <Row style={{width:"100%"}}>
          <Col>
            <FormGroup style={{width: "100%"}}>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  value={props.startDate}
                  dateFormat="DD/MM/YYYY"
                  inputProps={{
                    placeholder: "Início"
                  }}
                  timeFormat={false}
                  renderDay={(Renderprops, currentDate, selectedDate) => {
                    let classes = Renderprops.className;
                    if (
                      props.startDate &&
                      props.endDate &&
                      props.startDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " start-date";
                    } else if (
                      props.startDate &&
                      props.endDate &&
                      new Date(props.startDate._d + "") <
                        new Date(currentDate._d + "") &&
                      new Date(props.endDate._d + "") >
                        new Date(currentDate._d + "")
                    ) {
                      classes += " middle-date";
                    } else if (
                      props.endDate &&
                      props.endDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " end-date";
                    }
                    return (
                      <td {...Renderprops} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  onChange={e => props.setStartDate(e)}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  value={props.endDate}
                  dateFormat="DD/MM/YYYY"
                  inputProps={{
                    placeholder: "Fim"
                  }}
                  timeFormat={false}
                  renderDay={(Renderprops, currentDate, selectedDate) => {
                    let classes = Renderprops.className;
                    if (
                      props.startDate &&
                      props.endDate &&
                      props.startDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " start-date";
                    } else if (
                      props.startDate &&
                      props.endDate &&
                      new Date(props.startDate._d + "") <
                        new Date(currentDate._d + "") &&
                      new Date(props.endDate._d + "") >
                        new Date(currentDate._d + "")
                    ) {
                      classes += " middle-date";
                    } else if (
                      props.endDate &&
                      props.endDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " end-date";
                    }
                    return (
                      <td {...Renderprops} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  onChange={e => props.setEndDate(e)}
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
    );
}

export default DatepickerArgon;