import { createRef } from "react";

import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Toolbar,
  Inject,
  Filter,
  Sort,
  Resize,
  PdfExport,
  ExcelExport,
  ColumnChooser,
} from "@syncfusion/ej2-react-grids";
import "./style.css";

const ref = createRef();

const Datagrid = ({ lineClicked, dataSource }) => {
  const toolbarClick = (args) => {
    if (
      args.item.text === "PDF Export" ||
      args.item.text === "Exportação de PDF"
    ) {
      ref.current.pdfExport();
    } else if (
      args.item.text === "Excel Export" ||
      args.item.text === "Exportação Excel"
    ) {
      ref.current.excelExport();
    }
  };

  const groupOptions = { showGroupedColumn: true };

  const formatPrice = (value) => {
    return value.preco?.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  return (
    <GridComponent
      ref={ref}
      dataSource={dataSource ?? []}
      allowPaging={true}
      allowFiltering={true}
      filterSettings={{ type: "CheckBox" }}
      allowSorting={true}
      allowTextWrap={true}
      allowResizing={true}
      allowPdfExport={true}
      allowExcelExport={true}
      enableHeaderFocus={true}
      hierarchyPrintMode={"All"}
      allowReordering={true}
      showColumnChooser={true}
      groupSettings={groupOptions}
      toolbarClick={toolbarClick}
      toolbar={["PdfExport", "ExcelExport", "Print", "Search", "ColumnChooser"]}
      rowSelected={lineClicked}
    >
      <ColumnsDirective>
        <ColumnDirective
          field="tipoFerramenta"
          headerText="Tipo de ferramenta"
        />
        <ColumnDirective field="descricao" headerText="Descrição" />
        <ColumnDirective field="quantidade" headerText="Quantidade" />
        <ColumnDirective field="preco" headerText="Valor" />
      </ColumnsDirective>
      <Inject
        services={[
          Page,
          Toolbar,
          Filter,
          Sort,
          ColumnChooser,
          Resize,
          PdfExport,
          ExcelExport,
        ]}
      />
    </GridComponent>
  );
};

export default Datagrid;
