import { useState, useEffect, useContext } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import { TextField, Autocomplete } from '@mui/material'
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { EnterpriseNameContext } from "../../../context/EnterpriseContext";
import axios from "axios";
import ToolsService from "../service";
import { StructureEditContext } from "../../../context/StructureEdit";
import AWS from "aws-sdk";

const EditTool = (props) => {
  const { structureModal, setStructureModal } =
    useContext(StructureEditContext);
  const { enterpriseName } = useContext(EnterpriseNameContext);
  // const [modal, setModal] = useState(false);
  const [image, setImage] = useState();
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const toggle = () => props.setModal(!props.modal);
  const [typeTool, setTypeTool] = useState("");
  const [comments, setComments] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [photograph, setPhotograph] = useState();

  // const structure = () => {
  //     console.log(structureModal);
  // }
  useEffect(() => {
    const structure = () => {
      console.log(props.structureModal);
      setTypeTool(props.structureModal?.tipoFerramenta);
      setDescription(props.structureModal?.descricao);
      setQuantity(props.structureModal?.quantidade);
      setPrice(
        props.structureModal?.preco?.toFixed(2)?.toString()?.replace(".", ",")
      );
      setComments(props.structureModal?.caracteristica);
      setPhotograph(props.structureModal?.imagem);
    };
    structure();
  }, [props.structureModal]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let url;
    let filename;
    const formData = new FormData();
    if (image) {
      ToolsService.getToolById(props.structureModal._id)
        .then((response) => {
          const imageInTool = response.data.data.imagem;
          if (imageInTool) {
            const s3 = new AWS.S3({
              accessKeyId: "AKIAVSM7L4TZDZIH7KGO",
              region: "sa-east-1",
              secretAccessKey: "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",
            });
            s3.deleteObject(
              {
                Key: response.data.data.nomeImagem
                  ? response.data.data.nomeImagem
                  : response.data.data.imagem,
                Bucket: "darwinxperfil",
              },
              function (err, data) {}
            );
            formData.append("image", image[0]);
            axios({
              method: "post",
              url: "https://api.darwinx.com.br/images",
              data: formData,
              params: { empresa: enterpriseName },
              headers: { "Content-Type": "multipart/form-data" },
            })
              .then((response) => {
                console.log(response.data);
                url = response.data.url;
                filename = response.data.name;
                if (url) {
                  const data = {
                    tipoFerramenta: typeTool,
                    descricao: description,
                    caracteristica: comments,
                    empresa: enterpriseName,
                    preco: price,
                    quantidade: quantity,
                    imagem: url,
                    nomeImagem: filename,
                  };
                  ToolsService.updateTool(data, props.structureModal._id).then(
                    (sender) => {
                      console.log("criou");
                      ToolsService.getTools({ empresa: enterpriseName }).then(
                        (res) => {
                          props.setTools(res.data.data);
                          props.setModal(false);
                          // setQuantity('');
                          // setComments('');
                          // setTypeTool('');
                          // setImage(null)
                          // setPrice(0)
                          // setDescription('')
                        }
                      );
                      console.log(sender.data.data);
                    }
                  );
                }
              })
              .catch((error) => {
                console.log("erro na imagem", error);
              });
          } else {
            formData.append("image", image[0]);
            axios({
              method: "post",
              url: "https://api.darwinx.com.br/images",
              data: formData,
              params: { empresa: enterpriseName },
              headers: { "Content-Type": "multipart/form-data" },
            })
              .then((response) => {
                console.log(response.data);
                url = response.data.url;
                filename = response.data.name;
                if (url) {
                  const data = {
                    tipoFerramenta: typeTool,
                    descricao: description,
                    caracteristica: comments,
                    empresa: enterpriseName,
                    preco: price,
                    quantidade: quantity,
                    imagem: url,
                    nomeImagem: filename,
                  };
                  ToolsService.updateTool(data, props.structureModal._id).then(
                    (sender) => {
                      console.log("criou");
                      ToolsService.getTools({ empresa: enterpriseName }).then(
                        (res) => {
                          props.setTools(res.data.data);
                          props.setModal(false);
                          // setQuantity('');
                          // setComments('');
                          // setTypeTool('');
                          // setImage(null)
                          // setPrice(0)
                          // setDescription('')
                        }
                      );
                      console.log(sender.data.data);
                    }
                  );
                }
              })
              .catch((error) => {
                console.log("erro na imagem", error);
              });
          }
        })
        .catch((error) => {
          console.log("deu erro");
          console.error(error);
        });
    } else {
      console.log("nao tem imagem");
      const data = {
        tipoFerramenta: typeTool,
        descricao: description,
        caracteristica: comments,
        empresa: enterpriseName,
        preco: parseFloat(price.replace(",", ".")),
        quantidade: quantity,
      };
      ToolsService.updateTool(data, props.structureModal._id)
        .then((response) => {
          console.log("atualizou");
          ToolsService.getTools({ empresa: enterpriseName }).then((res) => {
            props.setTools(res.data.data);
            props.setModal(false);
            // setQuantity('');
            // setComments('');
            // setTypeTool('');
            // setPrice(0)
            // setDescription('')
          });
          console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error, "deu erro");
        });
    }
  };
  // console.log(props);
  // console.log(props.modal);
  // console.log(typeTool)
  // console.log(quantity)
  return (
    <>
      <Modal
        isOpen={props.modal}
        toggle={toggle}
        className="mr-0 mt--1 mb--1 mt-5"
      >
        <ModalHeader className="mt-3" toggle={toggle} tag="h2">
          Editar ferramenta
        </ModalHeader>
        <ModalBody className="mb-1 mt--2 mb-2">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <TextField
                value={typeTool}
                style={{ width: "100%" }}
                id="outlined-basic"
                label="Tipo de ferramenta"
                placeholder="Alicate"
                variant="outlined"
                onChange={(e) => {
                  setTypeTool(e.target.value);
                }}
              />
            </div>

            <div className="form-group">
              <TextField
                id="outlined-basic"
                placeholder="50 MHz a 200MHz, Display colorido 7 polegadas, Carregador 220V..."
                label="Descrição"
                variant="outlined"
                rows={4}
                name="descrição"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                multiline="true"
                style={{ width: "100%" }}
              />
            </div>
            <div className="form-group">
              <TextField
                id="outlined-basic"
                label="Quantidade"
                margin="normal"
                //variant="outlined"
                // InputProps={{
                // startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                // }}
                placeholder="00,00"
                name="Quantidade"
                value={quantity}
                // error={requirePrice}
                // helperText={messageRequirePrice}
                onChange={(e) => {
                  setQuantity(e.target.value);
                }}
                style={{ width: "100%" }}
              />
            </div>
            <div className="form-group">
              <TextField
                id="outlined-basic"
                label="Valor"
                margin="normal"
                //variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
                placeholder="00,00"
                name="preco"
                value={price}
                // error={requirePrice}
                // helperText={messageRequirePrice}
                onChange={(e) => {
                  setPrice(e.target.value);
                }}
                style={{ width: "100%" }}
              />
            </div>

            <div className="form-group">
              <TextField
                style={{ width: "100%" }}
                id="outlined-basic"
                rows={2}
                label="Observações"
                placeholder="Observações pertinentes para sua empresa"
                variant="outlined"
                multiline="true"
                onChange={(e) => {
                  setComments(e.target.value);
                }}
                value={comments}
              />
            </div>

            <div className="form-group">
              <label> Foto: </label>
              <input
                type="file"
                placeholder="Equipamento"
                name="file"
                className="form-control"
                accept="image/png, image/jpeg"
                onChange={(e) => {
                  setImage(e.target.files);
                  // setNameImage()
                  console.log(e.target.files);
                }}
              />
              {photograph && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "1em",
                  }}
                >
                  <img style={{ width: "80%" }} src={photograph} alt="imagem" />
                </div>
              )}
            </div>

            <ModalFooter>
              <Button type="submit" color="success">
                Atualizar
              </Button>{" "}
              <Button
                color="secondary"
                onClick={() => {
                  toggle();
                  // setQuantity('');
                  // setComments('');
                  // setTypeTool('');
                  // setPrice(0)
                  // setDescription('')
                }}
              >
                Cancelar
              </Button>
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditTool;
