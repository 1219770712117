import { createRef } from "react";
import { templatePriority } from "../../../../templates";
import templateStatus from "./templates/status";

import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Toolbar,
  Inject,
  Filter,
  Sort,
  Resize,
  PdfExport,
  ExcelExport,
  ColumnChooser,
  Scroll,
} from "@syncfusion/ej2-react-grids";
import "./style.css";
import moment from "moment";

const ref = createRef();

const Datagrid = ({ lineClicked, dataSource }) => {
  const toolbarClick = (args) => {
    if (
      args.item.text === "PDF Export" ||
      args.item.text === "Exportação de PDF"
    ) {
      ref.current.pdfExport();
    } else if (
      args.item.text === "Excel Export" ||
      args.item.text === "Exportação Excel"
    ) {
      ref.current.excelExport();
    }
  };

  const groupOptions = { showGroupedColumn: true };

  return (
    <GridComponent
      ref={ref}
      dataSource={dataSource ?? []}
      allowPaging={true}
      allowFiltering={true}
      filterSettings={{ type: "CheckBox" }}
      allowSorting={true}
      allowTextWrap={true}
      allowResizing={true}
      allowPdfExport={true}
      allowExcelExport={true}
      enableHeaderFocus={true}
      hierarchyPrintMode={"All"}
      allowReordering={true}
      showColumnChooser={true}
      groupSettings={groupOptions}
      toolbarClick={toolbarClick}
      toolbar={["PdfExport", "ExcelExport", "Print", "Search", "ColumnChooser"]}
      rowSelected={lineClicked}
    >
      <ColumnsDirective>
        <ColumnDirective
          field="equipamento"
          headerText="Código do Equipamento"
          width={200}
        />
        <ColumnDirective
          field="descricao_equipamento"
          headerText="Descrição do equipamento"
        />
        <ColumnDirective
          field="descricao"
          headerText="Descrição da requisição"
        />
        <ColumnDirective field="local" headerText="Setor instalado" />
        <ColumnDirective
          field="prioridade"
          headerText="Prioridade"
          template={templatePriority}
        />
        <ColumnDirective
          field="status"
          headerText="Status"
          template={templateStatus}
        />
        <ColumnDirective
          field="createdAt"
          headerText="Criado em"
          template={(cell) => moment(cell.createdAt).format("DD/MM/yyyy HH:mm")}
        />
      </ColumnsDirective>
      <Inject
        services={[
          Page,
          Toolbar,
          Filter,
          Sort,
          ColumnChooser,
          Resize,
          PdfExport,
          ExcelExport,
        ]}
      />
    </GridComponent>
  );
};

export default Datagrid;
