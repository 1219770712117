// GESTÃO DE Estoque DE MANUTENÇÂO

import axios from "axios";

const SPARE_API_BASE_URL = "https://api.darwinx.com.br/sparesreserve";

class SpareReserveService {
  getSparesReserve(spare) {
    return axios.get(SPARE_API_BASE_URL, { params: spare });
  }

  createSpareReserve(spare) {
    return axios.post(SPARE_API_BASE_URL, spare);
  }

  getSpareReserveById(spareId) {
    return axios.get(SPARE_API_BASE_URL + "/" + spareId);
  }

  updateSpareReserve(spare, spareId) {
    return axios.put(SPARE_API_BASE_URL + "/" + spareId, spare);
  }

  deleteSpareReserve(spareId) {
    return axios.delete(SPARE_API_BASE_URL + "/" + spareId);
  }
}

export default new SpareReserveService();
