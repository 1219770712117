import React, { useState, useEffect, useContext, useMemo } from "react";
import RequestService from "./RequestService";
import { EnterpriseNameContext } from "../../context/EnterpriseContext";
import { UserType } from "../../context/UserType";
import { IdUserContext } from "../../context/IdUser";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import {
  Container,
  Button,
  Modal,
  Card,
  CardBody,
  CardHeader,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  ButtonGroup,
} from "reactstrap";
import EquipmentService from "../Equipments/EquipmentService";
import Switch from "@material-ui/core/Switch";
import { Menu, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
// core components
import Header from "components/Headers/Headerfor.js";
import CreateRequest from "./CreateRequest";

import OrderService from "../Orders/OrderService";
import DateTimePicker from "../DateTimePicker";

import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";

import Datagrid from "./Components/Datagrid";

import AWS from "aws-sdk";

import "./ListRequest.css";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

function ListRequests(props) {
  const history = useHistory();
  const { enterpriseName } = useContext(EnterpriseNameContext);

  const { userType } = useContext(UserType);
  const { idUser } = useContext(IdUserContext);

  const [rSelected, setRSelected] = useState(null);
  const [usuarioResponsavel, setUsuarioResponsavel] = useState(false);
  const [secondButton, setSecondButton] = useState(false);
  const [image, setImage] = useState();
  const [nameImage, setNameImagem] = useState();
  const [, setColor] = useState("");
  const [setor, setSetor] = useState();
  const [initialDateAverege, setInitialDateAverage] = useState();
  const [machineStop, setMachineStop] = useState(null);
  const [, setStatus] = useState("");
  const [equipmentArea, setEquipmentArea] = useState();

  const [token] = useState(() => {
    const tokenStoraged = localStorage.getItem("TOKEN_KEY");
    if (tokenStoraged) {
      return tokenStoraged;
    }
    return "";
  });
  const [hash] = useState(() => {
    const hashStoraged = localStorage.getItem("TOKEN_HASH");
    if (hashStoraged) {
      return hashStoraged;
    }
    return "";
  });
  const [idUserStorage] = useState(() => {
    const idUserStoraged = localStorage.getItem("TOKEN_ID_USER");
    if (idUserStoraged) {
      return idUserStoraged;
    }
    return "";
  });

  const [showImage, setShowImage] = useState();

  const [row_id, setRow_id] = useState("");
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [equipamento, setEquipamento] = useState("");
  const [descricao, setDescricao] = useState("");
  const [local, setLocal] = useState("");
  const [, setSolicitante] = useState("");
  const [denominacao, setDenominacao] = useState();
  const [nameUser] = useState(() => {
    const userNameStoraged = window.localStorage.getItem("TOKEN_USERNAME");

    if (userNameStoraged) {
      return userNameStoraged;
    }
    return "";
  });
  const [ownerOpenRequest, setOwnerOpenRequest] = useState();

  const redirectLogin = () => {
    swal(
      "Hmm... vi que você não está logado. Clique em ok para fazer o login",
      "",
      "warning",
      {
        buttons: {
          // nope: {
          //   text: 'Me enganei!',
          //   value: 'nope',
          // },
          sure: {
            text: "Ok",
            value: "sure",
          },
        },
      }
    ).then((value) => {
      switch (value) {
        case "sure":
          swal("Operação realizada com sucesso!", "", "success").then((val) => {
            history.push("/auth/login");
          });
          break;
        case "nope":
          swal("Operação cancelada", "", "success");
          break;
        default:
      }
    });
  };
  const handleSubmit = (evt) => {
    evt.preventDefault();
  };

  const aprovar = (evt) => {
    let requests = {
      equipamento: equipamento,
      descricao: descricao,
      local: local,
      empresa: enterpriseName,
      prioridade: rSelected,
      maquina_parou: maquina,
      quandoMaquinaParou: machineStop,
      setor_equipamento: setor,
      tipo: "Corretiva",
      start: null,
      end: null,
      foto: image,
      equipmentArea,
      nomeFoto: nameImage,
      equipamento_denominacao: denominacao,
      status: "Aberta",
      dates: { dateOrderOpen: `${Date.now()}` },
      responsibles: {
        OwnerOrderOpen: nameUser,
      },
      token: token,
      hash: hash,
      idUser: idUserStorage,
    };
    console.log(requests);
    OrderService.createOrder(requests)
      .then((res) => {
        console.log(res);
        let data;
        data = {
          status: "Aprovada",
          datesToAverage: {
            initialDate: initialDateAverege,
            finishedDate: Date.now(),
          },
          token: token,
          hash: hash,
          idUser: idUserStorage,
        };
        console.log(data);
        RequestService.updateRequest(data, row_id).then((response) => {
          RequestService.getRequests({
            empresa: enterpriseName,
            token: token,
            hash: hash,
            idUser: idUserStorage,
          }).then((res) => {
            setRequest(res.data.data);
            localStorage.removeItem("TOKEN_KEY");
            localStorage.setItem("TOKEN_KEY", res.data.token);
            setModal(!modal);
          });
        });
      })
      .catch((error) => {
        if (error?.response?.data?.auth) {
        } else {
          // redirectLogin()
        }
      });
  };

  const reprovar = (evt) => {
    let novo_status;
    setModal(!modal);
    novo_status = {
      status: "Reprovada",
      token: token,
      hash: hash,
      idUser: idUserStorage,
    };
    RequestService.updateRequest(novo_status, row_id)
      .then((response) => {
        RequestService.getRequests({
          empresa: enterpriseName,
          token: token,
          hash: hash,
          idUser: idUserStorage,
        })
          .then((res) => {
            setRequest(res.data.data);
            localStorage.removeItem("TOKEN_KEY");
            localStorage.setItem("TOKEN_KEY", res.data.token);
          })
          .catch((error) => {
            if (error?.response?.data?.auth) {
            } else {
              // redirectLogin()
            }
          });
      })
      .catch((error) => {
        if (error?.response?.data?.auth) {
        } else {
          // redirectLogin()
        }
      });

    setModal(!modal);
  };

  const [request, setRequest] = useState([]);
  const [requestStoraged, setRequestStoraged] = useState([]);
  const [equipament, setEquipament] = useState([]);
  const [maquina, setMaquina] = useState("");

  useEffect(() => {
    EquipmentService.getEquipment({
      empresa: enterpriseName,
      token: token,
      hash: hash,
      idUser: idUserStorage,
    })
      .then((res) => {
        setEquipament(res.data.data);
        localStorage.removeItem("TOKEN_KEY");
        localStorage.setItem("TOKEN_KEY", res.data.token);
      })
      .catch((error) => {
        if (error?.response?.data?.auth) {
        } else {
          // redirectLogin()
        }
      });
    RequestService.getRequests({
      empresa: enterpriseName,
      token: token,
      hash: hash,
      idUser: idUserStorage,
    })
      .then((res) => {
        setRequest(res.data.data);
        setRequestStoraged(res.data);
        console.log(res.data);
        localStorage.removeItem("TOKEN_KEY");
        localStorage.setItem("TOKEN_KEY", res.data.token);
      })
      .catch((error) => {
        if (error?.response?.data?.auth) {
        } else {
          // redirectLogin()
        }
      });
  }, [enterpriseName, token, idUserStorage, hash]);
  // const [state, setState] = React.useState({
  //   Equipamento: false,
  //   Descrição: false,
  //   Local: false,
  //   Prioridade: false,
  // });

  const [stateFilterList, setStateFilterList] = useState({
    Equipamento: false,
    Descrição: false,
    Local: false,
    Prioridade: false,
  });
  const [filterColumn, setFilterColumn] = useState({
    Equipamento: true,
    Descricao: true,
    Local: true,
    Prioridade: true,
  });
  const handleChange = (event) => {
    setStateFilterList({
      ...stateFilterList,
      [event.target.name]: event.target.checked,
    });
  };

  const TranslateOptions = {
    rowsPerPageText: "Requisições por Página:",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsText: "Todos",
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const cellcolor = {
    color: "#1193EF",
    marginRight: "0.2em",
    width: "19px",
    // zIndex: 2,
    // position: 'relative',
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const PurpleSwitch = withStyles({
    switchBase: {
      color: "#58a3fb",
      "&$checked": {
        color: "#58a3fb",
      },
      "&$checked + $track": {
        backgroundColor: "#58a3fb",
      },
    },
    checked: {},
    track: {},
  })(Switch);

  function linhaClicada(source) {
    const row = source.data._id;
    console.log(row);
    setRow_id(row);
    setModal(true);
    RequestService.getRequestById(row, {
      token: token,
      hash: hash,
      idUser: idUserStorage,
    })
      .then((res) => {
        let requests = res.data.data;
        setOwnerOpenRequest(requests.ownerOpenRequest);
        if (userType === "Administrador" || userType === "Corpo Técnico") {
          setUsuarioResponsavel(true);
        } else {
          setUsuarioResponsavel(false);
        }
        if (userType === "Executante" || userType === "Requisitante") {
          if (res.data.usuarioResponsavel === idUser) {
            setSecondButton(true);
          }
        }
        if (requests.datesToAverage.initialDateAverege) {
          setInitialDateAverage(requests.datesToAverage.initialDateAverege);
        } else {
          setInitialDateAverage(Date.now());
        }
        if (requests.status === "Aprovada" || requests.status === "Reprovada") {
          setUsuarioResponsavel(false);
        }
        setEquipmentArea(requests.equipmentArea);
        setStatus(requests.status);
        setMachineStop(requests.quandoMaquinaParou);
        console.log(requests.maquina_parou);
        setEquipamento(requests.equipamento);
        setDescricao(requests.descricao);
        setLocal(requests.local);
        setRSelected(requests.prioridade);
        setSolicitante(requests.solicitante);
        setMaquina(requests.maquina_parou);
        setImage(requests.foto);
        setNameImagem(requests.nomeFoto);
        setDenominacao(requests.descricao_equipamento);
        setSetor(requests.setor_equipamento);
        // setShowImage(requests.foto);
        console.log("foto: ");
        console.log(res.data.data.nomeFoto);
        if (requests.nomeFoto) {
          try {
            const myBucket = "darwinxperfil";
            const myKey = res.data.data.nomeFoto;
            const signedUrlExpireSeconds = 60 * 1;
            const s3 = new AWS.S3({
              accessKeyId: "AKIAVSM7L4TZDZIH7KGO",
              region: "sa-east-1",
              secretAccessKey: "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",
            });

            const url = s3.getSignedUrl("getObject", {
              Bucket: myBucket,
              Key: myKey,
              Expires: signedUrlExpireSeconds,
            });

            setShowImage(url);
          } catch (error) {
            console.log(error);
          }
        } else {
          setShowImage(null);
        }
        localStorage.removeItem("TOKEN_KEY");
        localStorage.setItem("TOKEN_KEY", res.data.token);
      })
      .catch((error) => {
        if (error?.response?.data?.auth) {
        } else {
          // redirectLogin()
        }
      });
  }

  const columnHeader = [
    {
      Header: "Código do Equipamento",
      Footer: "Código do Equipamento",
      accessor: "equipamento",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Descrição do equipamento",
      Footer: "Descrição do equipamento",
      accessor: "descricao_equipamento",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Descrição da requisição",
      Footer: "Descrição da requisição",
      accessor: "descricao",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Setor instalado",
      Footer: "Setor instalado",
      accessor: "local",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Prioridade",
      Footer: "Prioridade",
      accessor: "prioridade",
      // disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: "status",
      // disableFilters: true,
      sticky: "left",
    },
  ];

  const columns = useMemo(() => columnHeader, []);
  const data = useMemo(() => request, [request]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    allColumns,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
    // useResizeColumns,
    // useBlockLayout
  );
  console.log(page);
  const [value, setValue] = useState(state.globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value);
  });
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  className="pl-5"
                >
                  <h3 className="ml-2 mr-3">
                    {" "}
                    Selecione as opções para ocultar{" "}
                  </h3>

                  {allColumns.map((column) => (
                    <MenuItem>
                      <FormControlLabel
                        {...column.getToggleHiddenProps()}
                        control={
                          <PurpleSwitch
                            name={column.Header}
                            {...column.getToggleHiddenProps()}
                          />
                        }
                        label={column.Header}
                      />{" "}
                    </MenuItem>
                    // <Checkbox {...column.getToggleHiddenProps()}/>
                    // <input type="checkbox" {...column.getToggleHiddenProps()}/>
                  ))}
                </Menu>
                <CreateRequest setRequest={setRequest} />
              </CardHeader>
              <CardBody>
                <Datagrid lineClicked={linhaClicada} dataSource={request} />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

      <Modal isOpen={modal} toggle={toggle} size="lg" className="mr-0 mt-2">
        <ModalHeader toggle={toggle} className="" tag="h2">
          Avaliar Requisição
        </ModalHeader>
        <ModalBody className="mb-3 mt--2">
          <h4>Resumo da Requisição </h4>
          <Card className="shadow-lg">
            <CardBody>
              <Row>
                <Col>
                  <p>
                    <b style={{ fontWeight: "550" }}>Equipamento: </b>{" "}
                    {equipamento}
                  </p>

                  <p>
                    <b style={{ fontWeight: "550" }}>Descrição: </b>
                    {denominacao}
                  </p>

                  <p>
                    <b style={{ fontWeight: "550" }}>Setor Instalado: </b>
                    {local}
                  </p>

                  <p>
                    <b style={{ fontWeight: "550" }}>Área: </b>
                    {equipmentArea}
                  </p>
                </Col>

                <Col className="col-auto">
                  <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                    <i className="ni ni-settings" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <br></br>

          {/*ATE AQUI!!!*/}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <TextField
                disabled={true}
                id="outlined-basic"
                label="Descrição"
                variant="outlined"
                name="descrição"
                value={descricao}
                multiline="true"
                onChange={(e) => setDescricao(e.target.value)}
                style={{ width: "100%" }}
              />
            </div>

            <div className="form-group">
              <label> Prioridade: </label> <br></br>
              <ButtonGroup className="mb-4">
                <Button
                  color="default"
                  className=""
                  outline
                  onClick={() => {
                    setRSelected("Nenhuma");
                    setColor("#7BF7CD");
                  }}
                  active={rSelected === "Nenhuma"}
                >
                  Nenhuma
                </Button>
                <Button
                  color="default"
                  outline
                  onClick={() => {
                    setRSelected("Baixa");
                    setColor("#0091F7");
                  }}
                  active={rSelected === "Baixa"}
                >
                  {" "}
                  &nbsp; &nbsp; Baixa &nbsp; &nbsp;{" "}
                </Button>
                <Button
                  color="default"
                  outline
                  onClick={() => {
                    setRSelected("Média");
                    setColor("#ffc400");
                  }}
                  active={rSelected === "Média"}
                >
                  {" "}
                  &nbsp; &nbsp; Média &nbsp; &nbsp;
                </Button>
                <Button
                  color="default"
                  outline
                  onClick={() => {
                    setRSelected("Alta");
                    setColor("#dc4664");
                  }}
                  active={rSelected === "Alta"}
                >
                  {" "}
                  &nbsp; &nbsp; Alta &nbsp; &nbsp;{" "}
                </Button>
              </ButtonGroup>
            </div>

            {/* <div>
                                        <label> O equipamento parou? </label> <br></br>
                                          <input type="radio" value="Sim" name="parou" /> Sim
                                          <input type="radio" value="Não" name="parou" className="ml-4 mb-4"/> Não
                                        </div> */}
            <div className="form-group">
              <label>Foto:</label>
              {showImage ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img style={{ width: "70%" }} src={showImage} alt="" />
                </div>
              ) : (
                " Sem imagem"
              )}
            </div>
            {/* <div className="form-group">
                                          <label>Foto:</label>
                                          <input
                                            type="file"
                                            placeholder="Equipamento"
                                            name="file"
                                            className="form-control"
                                            accept="image/png, image/jpeg"
                                            onChange={(e)=>setImage(e.target.files)}
                                          />
                                        </div> */}
            <FormControl style={{ display: "block" }} component="fieldset">
              <FormLabel component="legend">O equipamento parou?</FormLabel>
              <Row>
                <Col>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="top"
                  >
                    <FormControlLabel
                      value="Sim"
                      disabled={true}
                      checked={maquina === "Sim"}
                      control={<Radio color="primary" />}
                      label="Sim"
                      onChange={(e, v) => {
                        if (v) {
                          setMaquina("Sim");
                          console.log(maquina);
                        }
                      }}
                    />
                    <FormControlLabel
                      value="Não"
                      disabled={true}
                      control={<Radio color="primary" />}
                      label="Não"
                      checked={maquina === "Não"}
                      onChange={(e, v) => {
                        if (v) {
                          setMaquina("Não");
                          console.log(maquina);
                        }
                      }}
                    />
                  </RadioGroup>
                </Col>
                <Col>
                  {maquina === "Sim" && (
                    <DateTimePicker
                      disable={true}
                      placeholder="Quando parou?"
                      date={machineStop}
                      setDate={setMachineStop}
                    />
                  )}
                </Col>
              </Row>
            </FormControl>
            {ownerOpenRequest && (
              <div style={{ display: "flex" }} className="form-group">
                <label>Requisitante:</label>
                <p style={{ marginLeft: "1rem" }}>{ownerOpenRequest}</p>
              </div>
            )}
          </form>
        </ModalBody>
        <ModalFooter>
          {usuarioResponsavel ? (
            <>
              <Button color="success" onClick={aprovar}>
                Aprovar
              </Button>
              <Button color="danger" onClick={reprovar}>
                Reprovar
              </Button>
            </>
          ) : null}
          {secondButton ? (
            <>
              <Button color="success" onClick={aprovar}>
                Atualizar
              </Button>{" "}
              <Button
                color="success"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancelar
              </Button>
            </>
          ) : null}
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ListRequests;
