// GESTÃO DE Requisições DE MANUTENÇÂO

import axios from "axios";

const REQUEST_API_BASE_URL = "https://api.darwinx.com.br/requests";

class RequestService {
  getRequests(request) {
    console.log(request);
    return axios.get(REQUEST_API_BASE_URL, { params: request });
  }

  createRequest(request) {
    return axios.post(REQUEST_API_BASE_URL, request);
  }

  getRequestById(requestId, token) {
    return axios.get(REQUEST_API_BASE_URL + "/" + requestId, { params: token });
  }

  updateRequest(request, requestId) {
    return axios.put(REQUEST_API_BASE_URL + "/" + requestId, request);
  }

  deleteRequest(requestId, token) {
    return axios.delete(REQUEST_API_BASE_URL + "/" + requestId, {
      params: token,
    });
  }
}

export default new RequestService();
