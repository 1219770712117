import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  createRef,
  useMemo,
} from "react";
import FornecedoresService from "./FornecedoresService";
// import DataTable, { createTheme } from 'react-data-table-component';
// import DataTableExtensions from 'react-data-table-component-extensions';
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";
import { UserType } from "../../context/UserType";
import swal from "sweetalert";
import SearchImage from "../../assets/img/icons/search.svg";
import FilterColumn from "../../assets/img/icons/filterColumn.svg";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
//Pacotes de import para os botoes de filtrar tabelas
import { purple } from "@material-ui/core/colors";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import Header from "components/Headers/Headerfor.js";
import CreateFornecedores from "./CreateFornecedores";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { EnterpriseNameContext } from "../../context/EnterpriseContext";
import SearchIcon from "@material-ui/icons/Search";
import AWS from "aws-sdk";
import Checkbox from "@material-ui/core/Checkbox";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
  useBlockLayout,
  useResizeColumns,
} from "react-table";
import "./ListFornecedores.css";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Avatar from "@material-ui/core/Avatar";
import ReactPDF from "react-pdf";
import PDFComponent from "./PDF";
import NotificationAlert from "react-notification-alert";
// reactstrap
import {
  Card,
  CardHeader,
  Container,
  Row,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Table,
  Badge,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";

import { Button as ButtonMui } from "@mui/material";

import ReactToPrint from "react-to-print";
import { yellow, deepOrange, green } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";
// import { useTable, usePagination } from 'react-table'

import Datagrid from "./Components/Datagrid";

const useStylesCheck = makeStyles((theme) => ({
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    marginRight: "10px",
    marginLeft: "-5px",
    backgroundColor: deepOrange[500],
  },
  yellow: {
    color: "#fff",
    marginRight: "10px",
    marginLeft: "-5px",
    backgroundColor: yellow[500],
  },
  green: {
    color: "#fff",
    marginRight: "10px",
    marginLeft: "-5px",
    backgroundColor: green[500],
  },
}));
const createRefModal = createRef();
const notification = createRef();
function ListFornecedores(props) {
  const history = useHistory();

  const classesCheck = useStylesCheck();
  const qrCodeRef = useRef();
  const [fornecedor, setFornecedor] = useState([]);
  const [fornecedorStoraged, setFornecedorStoraged] = useState([]);
  // const [showImage, setShowImage] = useState();
  const { enterpriseName } = useContext(EnterpriseNameContext);
  const { userType } = useContext(UserType);
  const [showPDF, setShowPDF] = useState();
  const [contact, setContact] = useState("");
  const [token, setToken] = useState(() => {
    const tokenStoraged = localStorage.getItem("TOKEN_KEY");
    if (tokenStoraged) {
      return tokenStoraged;
    }
    return "";
  });
  const [hash, setHash] = useState(() => {
    const hashStoraged = localStorage.getItem("TOKEN_HASH");
    if (hashStoraged) {
      return hashStoraged;
    }
    return "";
  });
  const [idUserStorage, setIdUserStorage] = useState(() => {
    const idUserStoraged = localStorage.getItem("TOKEN_ID_USER");
    if (idUserStoraged) {
      return idUserStoraged;
    }
    return "";
  });
  const [descricao, setDescricao] = useState("");

  useEffect(() => {
    if (enterpriseName) {
      FornecedoresService.getFornecedores({
        empresa: enterpriseName,
        // token: token,
        // hash: hash,
        // idUser: idUserStorage,
      })
        .then((res) => {
          console.log(res.data);
          console.log("chegou aqui");
          setFornecedor(res.data.data);
          // localStorage.removeItem("TOKEN_KEY");
          // localStorage.setItem("TOKEN_KEY", res.data.token);
        })
        .catch((error) => {
          if (error?.response?.data?.auth) {
          } else {
            // redirectLogin();
          }
        });
    }
  }, [enterpriseName, hash, token, idUserStorage]);

  // FIM dos botoes

  const [anchorEl, setAnchorEl] = React.useState(null);

  const { buttonLabel, className, actual_id } = props;

  const [row_id, setRow_id] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [empresa, setEmpresa] = useState("");
  const [codigo, setCodigo] = useState("");
  const [responsavel_fornecedor, setResponsavel_fornecedor] = useState("");
  const [telefone, setTelefone] = useState("");
  const [website, setWebsite] = useState("");
  const [idUser, setIdUser] = useState("");
  const handleSubmit = (evt) => {
    evt.preventDefault();

    //alert(`Submitting Name ${firstName} ${lastName}`);
    //resetFirstName();
    //resetLastName();
  };
  // const columns = useMemo(() => COLUMNS, [])
  console.log(fornecedor);

  // const redirectLogin = () => {
  //   swal(
  //     "Hmm... vi que você não está logado. Clique em ok para fazer o login",
  //     "",
  //     "warning",
  //     {
  //       buttons: {
  //         // nope: {
  //         //   text: 'Me enganei!',
  //         //   value: 'nope',
  //         // },
  //         sure: {
  //           text: "Ok",
  //           value: "sure",
  //         },
  //       },
  //     }
  //   ).then((value) => {
  //     switch (value) {
  //       case "sure":
  //         swal("Operação realizada com sucesso!", "", "success").then((val) => {
  //           // history.pushState
  //           // return <Redirect to="/auth/login"/>
  //           history.push("/auth/login");
  //           // window.location.href = '/auth/login'
  //         });
  //         break;
  //       case "nope":
  //         swal("Operação cancelada", "", "success");
  //         break;
  //       default:
  //     }
  //   });
  // };

  const update = (evt) => {
    console.log(row_id);
    let fornecedores = {
      empresa: enterpriseName,
      codigo: codigo,
      responsavel_fornecedor: responsavel_fornecedor,
      contato: contact,
      telefone: telefone,
      website: website,
      token: token,
      hash: hash,
      idUser: idUserStorage,
    };
    console.log(fornecedores);
    FornecedoresService.updateFornecedores(fornecedores, row_id)
      .then((res) => {
        FornecedoresService.getFornecedores({
          empresa: enterpriseName,
          // token: token,
          // hash: hash,
          // idUser: idUserStorage,
        })
          .then((res) => {
            setFornecedor(res.data.data);
            // localStorage.removeItem("TOKEN_KEY");
            // localStorage.setItem("TOKEN_KEY", res.data.token);
          })
          .catch((error) => {
            if (error?.response?.data?.auth) {
            } else {
              // redirectLogin();
            }
          });
        console.log(res);
        setModal(!modal);
      })
      .catch((error) => console.log(error.response));
  };

  const deletar = (evt) => {
    swal("Tem certeza que deletar o fornecedor?", "", "warning", {
      buttons: {
        nope: {
          text: "Me enganei!",
          value: "nope",
        },
        sure: {
          text: "Sim, claro.",
          value: "sure",
        },
      },
    }).then((value) => {
      switch (value) {
        case "sure":
          swal("Fornecedor deletado com sucesso", "", "success").then((val) => {
            FornecedoresService.deleteFornecedores(row_id, {
              token: token,
              hash: hash,
              idUser: idUserStorage,
            })
              .then((res) => {
                //console.log("Etapa 4");
                window.location.reload();
              })
              .catch((error) => console.log(error.response));
            return window.location.reload();
          });
          break;
        case "nope":
          swal("Operação cancelada", "", "success");
          break;
        default:
          swal("Fornecedor excluído!");
      }
    });
  };

  function linhaClicada(source) {
    const row = source.data._id;
    setRow_id(row);
    setModal(true);
    FornecedoresService.getFornecedoresById(row, {
      token: token,
      hash: hash,
      idUser: idUserStorage,
    })
      .then((res) => {
        let fornecedores = res.data.data;
        setEmpresa(fornecedores.empresa);
        setContact(fornecedores.contato);
        setCodigo(fornecedores.codigo);
        setResponsavel_fornecedor(fornecedores.responsavel_fornecedor);
        setWebsite(fornecedores.website);
        setTelefone(fornecedores.telefone);
        setShowPDF(fornecedores.PDF);
        setDescricao(fornecedores.descricao);
        localStorage.removeItem("TOKEN_KEY");
        localStorage.setItem("TOKEN_KEY", res.data.token);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));
  return (
    <>
      <Header />
      {/* Page content */}
      <NotificationAlert ref={notification} />
      <Container className="mt--7" fluid>
        {/* Table */}

        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <CreateFornecedores
                  fornecedor={fornecedor}
                  setFornecedor={setFornecedor}
                />
              </CardHeader>
              <CardBody>
                <Datagrid lineClicked={linhaClicada} dataSource={fornecedor} />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

      <Modal
        isOpen={modal}
        toggle={toggle}
        size="lg"
        className="mr-0 mt--1 mb--1"
      >
        <ModalHeader tag="h1" toggle={toggle}>
          Editar Fornecedor{" "}
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <TextField
                id="outlined-basic"
                label="Código do Fornecedor"
                variant="outlined"
                value={codigo}
                onChange={(e) => setCodigo(e.target.value)}
                style={{ width: "100%" }}
              />
            </div>

            <div className="form-group">
              <TextField
                id="outlined-basic"
                label="Fornecedor"
                variant="outlined"
                value={responsavel_fornecedor}
                onChange={(e) => setResponsavel_fornecedor(e.target.value)}
                style={{ width: "100%" }}
              />
            </div>
            <div className="form-group">
              {/* <label> Descrição: </label>*/}
              <TextField
                id="outlined-basic"
                label="Descrição"
                style={{ width: "100%" }}
                placeholder="Ex: Fornecedor da bomba de água"
                variant="outlined"
                onChange={(e) => setDescricao(e.target.value)}
                value={descricao}
              />
            </div>

            <div className="form-group">
              <TextField
                id="outlined-basic"
                label="Website"
                variant="outlined"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
                style={{ width: "100%" }}
              />
            </div>

            <Row>
              <Col>
                <div className="form-group">
                  <TextField
                    id="outlined-basic"
                    label="Pessoa de Contato"
                    variant="outlined"
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <TextField
                    id="outlined-basic"
                    label="Telefone"
                    variant="outlined"
                    value={telefone}
                    onChange={(e) => setTelefone(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </div>
              </Col>
            </Row>
            {showPDF && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PDFComponent url={showPDF} />
                </div>
                <div
                  style={{
                    marginTop: "1em",
                    marginBottom: "1em",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button>
                    <a href={showPDF}>Baixar PDF</a>
                  </Button>
                </div>
              </>
            )}
          </form>

          <Row>
            <Col className="mb-5 mb-xl-0" xl="9">
              {userType !== "Executante" && userType !== "Requisitante" ? (
                <>
                  <Button color="success" onClick={update}>
                    Atualizar
                  </Button>{" "}
                  <Button color="secondary" onClick={toggle}>
                    Cancelar
                  </Button>
                </>
              ) : null}
            </Col>

            <Col className="">
              {userType !== "Executante" && userType !== "Requisitante" ? (
                <>
                  <Button color="secondary" onClick={deletar}>
                    <DeleteIcon /> Apagar
                  </Button>
                </>
              ) : null}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}

export default ListFornecedores;
